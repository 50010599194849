import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EnterCodeResponses } from 'src/app/auth/models/two-factor/enums/enter-code-responses';
import { Sources } from 'src/app/auth/models/two-factor/enums/sources';
import { EnterCodeDialogData } from 'src/app/auth/models/two-factor/types/enter-code-dialog-data';
import { TwoFactorRemoteService } from 'src/app/auth/services/two-factor-remote.service';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';

@Component({
  selector: 'app-enter-code-dialog',
  templateUrl: './enter-code-dialog.component.html',
  styleUrls: ['./enter-code-dialog.component.scss'],
})
export class EnterCodeDialogComponent implements OnDestroy {
  private readonly MAX_SECONDS = 30;

  translationModes: typeof TranslationModeEnum;

  sources: typeof Sources;

  code: string;

  subscriptions: Subscription[];

  isCodeIncorrect: boolean;

  responses: typeof EnterCodeResponses;

  censoredValue: string;

  countdown: NodeJS.Timer | undefined;

  remainingSeconds: number;

  constructor(
    private dialogRef: MatDialogRef<EnterCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EnterCodeDialogData,
    private twoFactorRemoteService: TwoFactorRemoteService
  ) {
    this.translationModes = TranslationModeEnum;
    this.sources = Sources;
    this.code = '';
    this.subscriptions = [];
    this.isCodeIncorrect = false;
    this.responses = EnterCodeResponses;
    this.censoredValue = '';
    this.remainingSeconds = 0;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.censoredValue =
      this.data.source === Sources.EMAIL
        ? this.censorEmail(this.data.data)
        : this.censorPhone(this.data.data);
  }

  public closeDialog(
    response: EnterCodeResponses = EnterCodeResponses.DEFAULT
  ) {
    this.dialogRef.close(response);
  }

  public continue() {
    this.subscriptions.push(
      this.twoFactorRemoteService.verifyCode(this.code).subscribe(
        () => {
          this.closeDialog(EnterCodeResponses.NEXT);
        },
        () => {
          this.isCodeIncorrect = true;
        }
      )
    );
  }

  public generateNewCode() {
    const service =
      this.data.source === Sources.EMAIL
        ? this.twoFactorRemoteService.activateWithEmail()
        : this.twoFactorRemoteService.activateWithPhone(this.data.data);
    service.subscribe(
      () => {
        this.remainingSeconds = this.MAX_SECONDS;
        this.countdown = setInterval(() => {
          this.remainingSeconds--;
          if (this.remainingSeconds === 0) {
            clearInterval(this.countdown);
          }
        }, 1000);
      },
      (error) => {
        if (error.error === 3) {
          this.closeDialog(EnterCodeResponses.LIMIT);
        }
      }
    );
  }

  public onCodeChange() {
    this.isCodeIncorrect = false;
  }

  private censorPhone(phone: string, lastNDigits: number = 2) {
    return (
      phone.slice(0, -lastNDigits).replace(/./g, '*') +
      phone.slice(-lastNDigits)
    );
  }

  private censorEmail(email: string) {
    return email.replace(
      /^(.)(.*)(.@.*)$/,
      (_, a, b, c) => a + b.replace(/./g, '*') + c
    );
  }
}
