<div class="error-404-container">
  <div class="error-msg">
    <h2 class="error-404" [innerHTML]="'error_Data1' | i18n"></h2>
    <div [innerHTML]="'error_Data2' | i18n"></div>
    <button [innerHTML]="'error_Data3' | i18n" [routerLink]="getRouterLink('Home')"></button>
  </div>
  <div class="model-carrousel" #carrousel>
    <img src="/assets/images/home/memorial.webp" alt="{{ 'error_Data4' | i18n: translationModes.PLACEHOLDER }}" class="model-3d">
    <img src="/assets/images/home/statue.webp" alt="Modelo 3D" class="model-3d">
    <img src="/assets/images/home/car.webp" alt="Modelo 3D" class="model-3d">
  </div>
</div>
