import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WindowService } from '../../services/window.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-redirect-to-app',
  template: '',
})
export class RedirectToAppComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private windowService: WindowService
  ) {}

  ngOnInit(): void {
    const modelId = this.route.snapshot.paramMap.get('modelId');
    const viewerType = this.route.snapshot.paramMap.get('viewerType');
    if (this.windowService.isBrowser) {
      if (viewerType) {
        window.location.href = `${environment.appUrl}/viewer/${modelId}/${viewerType}`;
      } else {
        window.location.href = `${environment.appUrl}/model/${modelId}`;
      }
    }
  }
}
