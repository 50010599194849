export const PopularSearches = [
  // {
  //   key: 'Cómo generar mi modelo 3D',
  //   url: 'ayuda/inicio',
  //   urlParams: '',
  //   show: true,
  // },
  // {
  //   key: 'Uso de targets',
  //   url: 'ayuda/inicio',
  //   urlParams: '#4',
  //   show: false,
  // },
  {
    key: 'Funcionamiento matching 3D',
    url: 'ayuda/biblioteca/seguridad',
    urlParams: '#4',
    show: false,
  },
  {
    key: 'Cómo recortar modelo 3D',
    url: 'ayuda/biblioteca/estandar',
    urlParams: '#3',
    show: false,
  },
  {
    key: 'Cómo medir en el modelo 3D',
    url: 'ayuda/biblioteca/estandar',
    urlParams: '',
    show: true,
  },
  {
    key: 'Formato para importar modelos 3D',
    url: 'ayuda/faq',
    urlParams: '',
    show: false,
  },
  {
    key: 'Cómo calcular volumen',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=m#3',
    show: false,
  },
  {
    key: '¿Qué es IOTA?',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=iota',
    show: false,
  },
  {
    key: '¿Cómo guardo mis modificaciones?',
    url: '',
    urlParams: '',
    show: false,
  },
  {
    key: '¿Qué cámara usar?',
    url: 'ayuda/faq',
    urlParams: '',
    show: true,
  },
  {
    key: '¿Es gratis?',
    url: 'ayuda/faq',
    urlParams: '',
    show: false,
  },
  {
    key: '¿Cuánto cuesta generar un modelo?',
    url: '/politica-contratacion/freeRate',
    urlParams: '',
    show: false,
  },
];

export const PopularListSearch = [
  // {
  //   key: 'Primeros pasos',
  //   url: 'ayuda/inicio',
  //   urlParams: '',
  //   keywords: [
  //     'Importar modelos 3D',
  //     'Modelo 3D',
  //     'Modelo 3D en proceso',
  //     'Cargando',
  //     'Se queda cargando modelo 3D',
  //     'Tiempo de carga modelo 3D'
  //   ],
  // },
  // {
  //   key: 'Generar modelos 3D',
  //   url: 'ayuda/inicio',
  //   urlParams: '',
  //   keywords: [
  //     'Generar modelos 3D',
  //     'Hacer modelos 3D',
  //     'Hacer 3D',
  //     'Crear 3D',
  //     'Consejos',
  //   ],
  // },
  // {
  //   key: 'Importa/exporta',
  //   url: 'ayuda/inicio',
  //   urlParams: '#1',
  //   keywords: [
  //     'Importa',
  //     'Exporta modelos 3D',
  //     'exporta',
  //     'Carga modelos 3D',
  //     'carga',
  //     'Subir modelos 3D',
  //     'subir',
  //     'modelos',
  //     'Formato',
  //     'Formatos',
  //   ],
  // },
  // {
  //   key: 'Carga de modelos 3D',
  //   url: 'ayuda/inicio',
  //   urlParams: '#2',
  //   keywords: [
  //     'Carga modelos 3D',
  //     'carga',
  //     'Subir modelos 3D',
  //     'subir',
  //     'modelos 3d',
  //   ],
  // },
  // {
  //   key: 'Modelos 3D en proceso',
  //   url: 'ayuda/inicio',
  //   urlParams: '#3',
  //   keywords: [
  //     'Modelo 3d en proceso',
  //     'proceso',
  //     'cargando',
  //     'Cargando modelo 3d',
  //   ],
  // },
  // {
  //   key: 'Uso de Targets',
  //   url: 'ayuda/inicio',
  //   urlParams: '#4',
  //   keywords: [
  //     'Targets',
  //     'Apriltags',
  //     'Puntos de referencia',
  //     'referencia',
  //     'georeferenciados',
  //     'Puntos ',
  //     'Puntos georreferenciados',
  //   ],
  // },
  // {
  //   key: 'Galería',
  //   url: 'ayuda/inicio',
  //   urlParams: '#5',
  //   keywords: ['Galería', 'Modelos 3D', 'Ejemplos modelos', 'ejemplos'],
  // },
  {
    key: 'Biblioteca de herramientas',
    url: 'ayuda/biblioteca',
    urlParams: '',
    keywords: ['Biblioteca de herramientas'],
  },
  {
    key: 'Herramientas estándar',
    url: 'ayuda/biblioteca/estandar',
    urlParams: '',
    keywords: ['herramienta', 'herramientas', 'básicas', 'estandar'],
  },
  {
    key: 'Estándar: Medida',
    url: 'ayuda/biblioteca/estandar',
    urlParams: '',
    keywords: [
      'Como se mide',
      'medir modelo',
      'medir',
      'mide',
      'medición',
      'medida',
      'medidas',
      'calcular distancia',
      'longitud',
      'distancia',
      'metros ',
      'centimetros ',
      'milimetros',
    ],
  },
  {
    key: 'Estándar: Eje Z',
    url: 'ayuda/biblioteca/estandar',
    urlParams: '#1',
    keywords: [
      'Posición modelo',
      'Profundidad modelo',
      'Eje Z',
      'Mover modelo 3d',
      'Rotar modelo',
    ],
  },
  {
    key: 'Estándar: Notas',
    url: 'ayuda/biblioteca/estandar',
    urlParams: '#2',
    keywords: [
      'Guardar anotación',
      'nota',
      'notas',
      'indice',
      'marcador',
      'apunte',
      'anotación',
      'recordatorio',
    ],
  },
  {
    key: 'Estándar: Recorte',
    url: 'ayuda/biblioteca/estandar',
    urlParams: '#3',
    keywords: [
      'Como recortar un modelo',
      'recorte de modelo',
      'cortar modelos',
      'seccionar modelo',
      'dividir modelo',
      'recorte',
      'cortar',
      'seccionar',
      'dividir',
    ],
  },
  {
    key: 'Tour Virtual Reducido',
    url: 'ayuda/biblioteca/estandar',
    urlParams: '#4',
    keywords: [
      'Tour virtual reducido',
      'tour',
      'virtual',
      'reducido',
      'Reproducir',
      'Mover modelo',
      'Rotar modelo',
    ],
  },
  {
    key: 'Estándar: Establecer posición inicial',
    url: 'ayuda/biblioteca/estandar',
    urlParams: '#5',
    keywords: [
      'Posición de salida del modelo',
      'posición',
      'Posicion inicio modelo',
      'Marcar inicio modelo',
      'Establecer posición inicial',
      'punto',
      'referencia',
    ],
  },
  {
    key: 'Funcionalidades',
    url: 'ayuda/biblioteca/estandar',
    urlParams: '?g=fb',
    keywords: ['Funcionalidades'],
  },
  {
    key: 'Funcionalidades de descarga',
    url: 'ayuda/biblioteca/estandar',
    urlParams: '?g=fb',
    keywords: [
      'Funcionalidades',
      'Descarga',
      'Como descargar',
      'Formas de descargar',
      'formatos de descarga',
      'Archivos de descarga',
    ],
  },
  {
    key: 'Funcionalidades de interacción',
    url: 'ayuda/biblioteca/estandar',
    urlParams: '?g=fb#1',
    keywords: [
      'Funcionalidades de interacción',
      'zoom',
      'movimiento',
      'orbital',
      'ejes',
      'centrar',
      'grabar',
      'pantalla',
      'capturar',
      'vídeo',
      'mover',
      'interactuar',
    ],
  },
  {
    key: 'Funcionalidades de personalización',
    url: 'ayuda/biblioteca/estandar',
    urlParams: '?g=fb#2',
    keywords: [
      'Funcionalidades de personalización',
      'fondo pantalla',
      'pantalla',
      'personalizar',
      'pantalla completa',
      'red social',
      'social',
      'cambiar fondo',
      'fondo',
      'color',
      'compartir',
    ],
  },
  {
    key: 'Herramientas avanzadas',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=m',
    keywords: ['Avanzadas', 'Herramientas'],
  },
  {
    key: 'Colección Medición',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=m',
    keywords: ['Medición', 'Colección'],
  },
  {
    key: 'Avanzadas: Medida',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=m',
    keywords: [
      'Como se mide',
      'medir modelo',
      'medición',
      'medida',
      'medidas',
      'longitud',
      'distancia',
      'calcular distancia',
      'metros ',
      'centimetros ',
      'milimetros',
    ],
  },
  {
    key: 'Altura',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=m#1',
    keywords: [
      'suelo cerrado',
      'cálculo',
      'altura profundidad',
      'altura',
      'distancia',
      'dimensión',
    ],
  },
  {
    key: 'Área',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=m#2',
    keywords: ['Calcular área', 'cálculo', 'área', 'dimensiones', 'dimensión'],
  },
  {
    key: 'Volumen',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=m#3',
    keywords: [
      'Calcular volumen',
      'cálculo',
      'Volumen ',
      'cuerpo',
      'masa',
      'dimensión',
      'dimensiones',
    ],
  },
  {
    key: 'Superficies',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=m#4',
    keywords: ['distancia', 'área ', 'volumen', 'Superficie'],
  },
  {
    key: 'Medidas Múltiples',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=m#5',
    keywords: [
      'Altura',
      'Área',
      'volumen',
      'calculo ',
      'profundidades',
      'contornos',
      'múltiple',
    ],
  },
  {
    key: 'Contorno',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=m#6',
    keywords: ['Cálculo', 'Perímetro', 'Contorno', 'medición', 'medidas'],
  },
  {
    key: 'Ángulo',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=m#7',
    keywords: ['Ángulo', 'grados', 'cálculo', 'cálcular'],
  },
  {
    key: 'Colección Escala',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=e',
    keywords: ['Escala', 'Colección'],
  },
  {
    key: 'Escala',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=e',
    keywords: ['dimensiones', 'modificar', 'cambiar dimensiones', 'escala'],
  },
  {
    key: 'Coordenada',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=e#1',
    keywords: [
      'Georreferencia',
      'xyz',
      'WGS',
      'UTM',
      'seleccionar',
      'elegir punto',
      'Sistema de referencia relativo',
      'Coordenada',
      'escala',
    ],
  },
  {
    key: 'Sistema de referencia',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=e#2',
    keywords: [
      'Sistema de referencia relativo',
      'nuevas dimensiones',
      'nuevas medidas',
      'modificar medidas',
      'escala',
    ],
  },
  {
    key: 'Sistema de georreferenciación',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=e#3',
    keywords: [
      'Sistema de georreferenciación',
      'Georreferencia',
      'georreferenciar',
      'WGS 84',
      'UTM',
      'escala',
      'mapa',
      'coordenadas',
    ],
  },
  {
    key: 'Establecer Suelo',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=e#4',
    keywords: ['Posición', 'plano', 'horizontal', 'Establecer suelo', 'escala'],
  },
  {
    key: 'Avanzadas: Eje Z',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=e#5',
    keywords: [
      'Posición modelo',
      'Profundidad modelo',
      'Eje Z',
      'Mover modelo 3d',
      'Rotar modelo',
      'escala',
    ],
  },
  {
    key: 'Colección Modificar',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=mo',
    keywords: ['Modificar', 'Colección'],
  },
  {
    key: 'Avanzadas: Notas',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=mo',
    keywords: [
      'Guardar anotación',
      'nota',
      'notas',
      'indice',
      'marcador',
      'apunte',
      'anotación',
      'recordatorio',
      'modificar',
    ],
  },
  {
    key: 'Avanzadas: Recorte',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=mo#1',
    keywords: [
      'Como recortar un modelo',
      'recorte de modelo',
      'cortar modelos',
      'seccionar modelo',
      'dividir modelo',
      'modificar',
    ],
  },
  {
    key: 'Unión de modelos',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=mo#2',
    keywords: [
      'Unir',
      'Unión de modelos',
      'Sumar',
      'Juntar',
      'Anexar',
      'Unificar',
      'cohesionar',
      'modificar',
    ],
  },
  {
    key: 'Selección de Objetos',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=mo#3',
    keywords: [
      'Selección de objetos',
      'Recortar un objeto',
      'modificar',
      'categoría',
      'categorizar',
    ],
  },
  {
    key: 'Sección',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=mo#4',
    keywords: ['Sección', 'área corte', 'Intersección', 'modificar'],
  },
  {
    key: 'Colección Vista',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=v',
    keywords: ['Vista', 'Colección'],
  },
  {
    key: 'Proyección',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=v',
    keywords: ['Proyección', 'Vista', 'ortogonal'],
  },
  {
    key: 'Mapa de Niveles',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=v#1',
    keywords: ['Mapa de niveles', 'niveles', 'profundidad', 'altura', 'vista'],
  },
  {
    key: 'Vértices/Puntos',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=v#2',
    keywords: ['Vértices', 'Puntos', 'Poligono ', 'Malla', 'vista', 'Nubes'],
  },
  {
    key: 'Tour Virtual',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=v#3',
    keywords: [
      'Tour virtual',
      'tour',
      'Reproducir',
      'Mover modelo',
      'modelo',
      'Rotar modelo',
      'vista',
    ],
  },
  {
    key: 'Primera persona',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=v#4',
    keywords: ['Primera persona', 'vista'],
  },
  {
    key: 'IOTA',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=iota',
    keywords: [
      'IOTA',
      'informe',
      'información',
      'técnico',
      'cadena',
      'custodia',
      'seguridad',
      'seguros',
      'datos',
      'trazabilidad',
      'seguimiento',
      'blockchain',
    ],
  },
  {
    key: 'Movimiento',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=mov',
    keywords: ['Movimiento', 'Colección'],
  },
  {
    key: 'Centro de Rotación',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=mov',
    keywords: [
      'Centro de rotación',
      'punto',
      'referencia',
      'eje',
      'movimiento',
    ],
  },
  {
    key: 'Avanzadas: Establecer posición inicial',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=mov#1',
    keywords: [
      'Posición de salida del modelo',
      'Posicion inicio modelo',
      'posición',
      'pose',
      'Marcar inicio modelo',
      'Establecer posición inicial',
      'punto',
      'referencia',
      'movimiento',
    ],
  },
  {
    key: 'Multicam',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=cam',
    keywords: ['Multicam', 'vistas', 'pantallas', 'divididas'],
  },
  {
    key: 'Mapa',
    url: 'ayuda/biblioteca/avanzadas',
    urlParams: '?g=map',
    keywords: [
      'mapa',
      'ubicación',
      'georreferencia',
      'georreferenciados',
      'localización',
    ],
  },
  {
    key: 'Herramientas seguridad',
    url: 'ayuda/biblioteca/seguridad',
    urlParams: '',
    keywords: ['herramientas', 'seguridad', 'addon'],
  },
  {
    key: 'Energía deformación',
    url: 'ayuda/biblioteca/seguridad',
    urlParams: '',
    keywords: [
      'Energía de deformación',
      'impacto',
      'accidente',
      'golpe',
      'calculo',
    ],
  },
  {
    key: 'Seguridad: Proyección en 3D',
    url: 'ayuda/biblioteca/seguridad',
    urlParams: '#1',
    keywords: ['Proyeccción en 3d', 'Proyección de imagenes'],
  },
  {
    key: 'Trayectoria de Balas',
    url: 'ayuda/biblioteca/seguridad',
    urlParams: '#2',
    keywords: [
      'Trayectoria bala',
      'bala',
      'balística',
      'Recorrido ',
      'trazado',
      'dirección',
      'disparo',
    ],
  },
  {
    key: 'Salpicadura de Sangre',
    url: 'ayuda/biblioteca/seguridad',
    urlParams: '#3',
    keywords: [
      'Salpicadura de sangre',
      'sangre',
      'salpicadura',
      'dirección ',
      'trayectoria',
      'mancha',
      'bala',
      'proyectil',
    ],
  },
  {
    key: 'Seguridad: Matching 3D',
    url: 'ayuda/biblioteca/seguridad',
    urlParams: '#4',
    keywords: ['Matching 3D', 'Comparación', 'modelos'],
  },
  {
    key: 'Seguridad: Grosor',
    url: 'ayuda/biblioteca/seguridad',
    urlParams: '#5',
    keywords: [
      'Grosor',
      'Calculo',
      'distancia',
      'medida',
      'medir',
      'espesor ',
      'ancho',
    ],
  },
  {
    key: 'Seguridad: Sensórica',
    url: 'ayuda/biblioteca/seguridad',
    urlParams: '#6',
    keywords: ['Sensórica', 'meteorologicos', 'metereologia', 'contaminación'],
  },
  {
    key: 'Seguridad: Planos interiores',
    url: 'ayuda/biblioteca/seguridad',
    urlParams: '#7',
    keywords: ['Plantas interiores', 'modelos 2d', 'integrar'],
  },
  {
    key: 'Seguridad: Unión exteriores/interiores',
    url: 'ayuda/biblioteca/seguridad',
    urlParams: '#8',
    keywords: [
      'Unión exteriores / interiores',
      'union modelos 3d',
      'Sumar',
      'Juntar',
      'Anexar',
      'Unificar',
      'cohesionar',
    ],
  },
  {
    key: 'Seguridad: Visor offline',
    url: 'ayuda/biblioteca/seguridad',
    urlParams: '#9',
    keywords: [
      'Visor offline',
      'sin conexión',
      'offline',
      'Sin internet',
      'sin conexion',
      'herramienta',
      'agricultura',
    ],
  },
  {
    key: 'Seguridad: Hiperespectral',
    url: 'ayuda/biblioteca/seguridad',
    urlParams: '#10',
    keywords: [
      'Hiperespectral',
      'cámara',
      'multiespectral',
      'bandas',
      'espectro',
    ],
  },
  // {
  //   key: 'Seguridad: Punto a Punto (P2P)',
  //   url: 'ayuda/biblioteca/seguridad',
  //   urlParams: '#11',
  //   keywords: [
  //     'Punto a punto',
  //     'p2p',
  //     'altura',
  //     'profundidad',
  //     'medidas',
  //     'medir',
  //     'áreas',
  //   ],
  // },
  // {
  //   key: 'Seguridad: Fotorrectificación',
  //   url: 'ayuda/biblioteca/seguridad',
  //   urlParams: '#12',
  //   keywords: [
  //     'Fotorectificación',
  //     'Fotorrectificación',
  //     'rectificar',
  //     '2d',
  //     'imágenes',
  //     'perspectiva',
  //     'corregir',
  //     'agricultura',
  //   ],
  // },
  {
    key: 'Seguridad: Segmentación',
    url: 'ayuda/biblioteca/seguridad',
    urlParams: '#11',
    keywords: [
      'Segmentación',
      'Clasificar',
      'Identificar',
      'Inteligencia artificial',
      'IA',
    ],
  },
  {
    key: 'Herramientas ingeniería',
    url: 'ayuda/biblioteca/ingenieria',
    urlParams: '',
    keywords: ['herramientas', 'ingeniería', 'addon'],
  },
  {
    key: 'IFC',
    url: 'ayuda/biblioteca/ingenieria',
    urlParams: '',
    keywords: ['IFC', 'Obras', 'construcción', 'herramientas', 'ingenieria'],
  },
  {
    key: 'Ingeniería: Proyección en 3D',
    url: 'ayuda/biblioteca/ingenieria',
    urlParams: '#1',
    keywords: ['Proyeccción en 3d', 'Proyección de imagenes'],
  },
  {
    key: 'Ingeniería: Cálculo de Vegetación',
    url: 'ayuda/biblioteca/ingenieria',
    urlParams: '#2',
    keywords: [
      'Cálculo de Vegetación',
      'vegetación',
      'densidad',
      'arbol',
      'masa',
      'forestal',
      'altura',
      'arbórea',
      'arbóreo',
      'herramientas ',
      'agricultura',
      'calculo',
    ],
  },
  {
    key: 'Ingeniería: Matching 3D',
    url: 'ayuda/biblioteca/ingenieria',
    urlParams: '#3',
    keywords: ['Matching 3D', 'Comparación', 'modelos'],
  },
  {
    key: 'Ingeniería: Grosor',
    url: 'ayuda/biblioteca/ingenieria',
    urlParams: '#4',
    keywords: [
      'Grosor',
      'Calculo',
      'distancia',
      'medida',
      'medir',
      'espesor ',
      'ancho',
    ],
  },
  {
    key: 'Ingeniería: Sensórica',
    url: 'ayuda/biblioteca/ingenieria',
    urlParams: '#5',
    keywords: ['Sensórica', 'meteorologicos', 'metereologia', 'contaminación'],
  },
  {
    key: 'Ingeniería: Planos interiores',
    url: 'ayuda/biblioteca/ingenieria',
    urlParams: '#6',
    keywords: [
      'Plantas interiores',
      'modelos 2d',
      'integrar',
      'plano',
      'planos',
    ],
  },
  {
    key: 'Ingeniería: Unión Exteriores/Interiores',
    url: 'ayuda/biblioteca/ingenieria',
    urlParams: '#6',
    keywords: [
      'Unión exteriores / interiores',
      'union modelos 3d',
      'Sumar',
      'Juntar',
      'Anexar',
      'Unificar',
      'cohesionar',
    ],
  },
  {
    key: 'Ingeniería: Visor offline',
    url: 'ayuda/biblioteca/ingenieria',
    urlParams: '#7',
    keywords: ['Visor ', 'offline', 'Sin internet', 'Sin conexion'],
  },
  {
    key: 'Ingeniería: Hiperespectral',
    url: 'ayuda/biblioteca/ingenieria',
    urlParams: '#8',
    keywords: [
      'Hiperespectral',
      'cámara',
      'multiespectral',
      'bandas',
      'espectro',
    ],
  },
  // {
  //   key: 'Ingeniería: Punto a Punto (P2P)',
  //   url: 'ayuda/biblioteca/ingenieria',
  //   urlParams: '#9',
  //   keywords: [
  //     'Punto a punto',
  //     'p2p',
  //     'altura',
  //     'profundidad',
  //     'medidas',
  //     'medir',
  //     'áreas',
  //   ],
  // },
  // {
  //   key: 'Ingeniería: Fotorrectificación',
  //   url: 'ayuda/biblioteca/ingenieria',
  //   urlParams: '#10',
  //   keywords: [
  //     'Fotorectificación',
  //     'Fotorrectificación',
  //     'rectificar',
  //     '2d',
  //     'imágenes',
  //     'perspectiva',
  //     'corregir',
  //   ],
  // },
  {
    key: 'Ingeniería: Segmentación',
    url: 'ayuda/biblioteca/ingenieria',
    urlParams: '#9',
    keywords: [
      'Segmentación',
      'Clasificar',
      'Identificar',
      'Inteligencia artificial',
      'IA',
    ],
  },
  {
    key: 'Herramientas agricultura',
    url: 'ayuda/biblioteca/agricultura',
    urlParams: '',
    keywords: ['herramienta', 'agricultura', 'addon'],
  },
  {
    key: 'Agricultura: Cálculo de Vegetación',
    url: 'ayuda/biblioteca/agricultura',
    urlParams: '',
    keywords: [
      'herramientas ',
      'agricultura',
      'calculo',
      'vegetación',
      'densidad',
      'arbol',
      'masa',
      'forestal',
      'altura',
    ],
  },
  {
    key: 'Agricultura: Unión exteriores/interiores',
    url: 'ayuda/biblioteca/agricultura',
    urlParams: '#1',
    keywords: [
      'Unión exteriores / interiores',
      'union modelos 3d',
      'Sumar',
      'Juntar',
      'Anexar',
      'Unificar',
      'cohesionar',
    ],
  },
  {
    key: 'Agricultura: Visor offline',
    url: 'ayuda/biblioteca/agricultura',
    urlParams: '#2',
    keywords: [
      'Visor offline',
      'offline',
      'Sin internet',
      'sin conexion',
      'herramienta',
      'agricultura',
    ],
  },
  // {
  //   key: 'Agricultura: Punto a Punto (P2P)',
  //   url: 'ayuda/biblioteca/agricultura',
  //   urlParams: '#3',
  //   keywords: [
  //     'Punto a punto',
  //     'p2p',
  //     'altura',
  //     'profundidad',
  //     'medidas',
  //     'medir',
  //     'áreas',
  //     'herramienta',
  //     'agricultura',
  //   ],
  // },
  // {
  //   key: 'Agricultura: Fotorrectificación',
  //   url: 'ayuda/biblioteca/agricultura',
  //   urlParams: '#4',
  //   keywords: [
  //     'Fotorectificación',
  //     'Fotorrectificación',
  //     'rectificar',
  //     '2d',
  //     'imágenes',
  //     'perspectiva',
  //     'corregir',
  //   ],
  // },
  {
    key: 'Agricultura: Segmentación',
    url: 'ayuda/biblioteca/agricultura',
    urlParams: '#3',
    keywords: [
      'Segmentación',
      'Clasificar',
      'Identificar',
      'Inteligencia artificial',
      'IA',
    ],
  },
  {
    key: 'Faq',
    url: 'ayuda/faq',
    urlParams: '',
    keywords: [
      'cámara',
      'dispositivos',
      'smartphone',
      'GoPro',
      'camara',
      'fotografias',
      'videos',
      'hiperespectrales',
      'como tomar fotos',
      'como hacer fotos',
      'cuantas fotos',
      'cuantos videos',
      'cantidad videos',
      'cantidad de fotos',
      'formatos importación y descargas',
      '.obj',
      '.glb',
      '.stl',
      '.ply',
      '.stp',
      '.dae',
      '.pdf',
      '.gtiff',
      '.png',
      'nube de puntos',
      'software',
      'gratuita',
    ],
  },
  {
    key: 'Agricultura: Sensórica',
    url: 'ayuda/biblioteca/agricultura',
    urlParams: '#4',
    keywords: ['Sensórica', 'meteorologicos', 'metereologia', 'contaminación'],
  },
];
