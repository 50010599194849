import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserToken } from './user-token';
import { SecurityTokenStorage } from './security-token-storage';
import { BaseProfile } from 'src/app/auth/models/base-profile';
import { AcceptedLogin } from 'src/app/auth/models/accepted-login';
import { LoginRemoteService } from 'src/app/auth/services/login-remote.service';
import { Credential } from 'src/app/auth/models/credential';
import { StorageService } from './storage.service';
import { AdminTokenStorage } from './admin-token-storage';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public static scope: UserService;
  // private securityTokenStorage!: SecurityTokenStorage<UserToken>;
  private OnLogin = new Subject<AcceptedLogin<BaseProfile>>();
  constructor(
    private loginRemoteService: LoginRemoteService,
    private adminTokenStorage: AdminTokenStorage<UserToken>
  ) { }

  login<T extends BaseProfile>(
    email: string,
    password: string
  ): Observable<AcceptedLogin<T>> {
    const subject = new Subject<AcceptedLogin<T>>();
    this.loginRemoteService.login(new Credential(email, password)).subscribe(
      (acceptedLogin) => { },
      (error) => {
        subject.error(error.error);
      }
    );
    return subject;
  }

  private configure<T extends BaseProfile>(
    acceptedLogin: AcceptedLogin<T>
  ): void {
    const tokenObj = {
      token: acceptedLogin,
      tokenExpirationDate: undefined,
      locale: undefined,
    };
    // this.securityTokenStorage?.saveObject(tokenObj);
  }
  onLogin(): Observable<any> {
    return this.OnLogin;
  }

  blogLogin<T extends BaseProfile>(
    username: string,
    password: string
  ): Observable<null> {
    const subject = new Subject<null>();
    this.loginRemoteService.blogLogin(username, password).subscribe(
      (acceptedLogin) => {
        const tokenObj = {
          token: acceptedLogin.token,
          tokenExpirationDate: undefined,
          locale: undefined,
        };
        this.adminTokenStorage.saveObject(tokenObj);
        subject.next(null);
      },
      (error) => {
        subject.error(error.error);
      }
    );
    return subject;
  }
}
