import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminBlogComponent } from './components/admin-blog/admin-blog.component';
import { AdminBlogPostComponent } from './components/admin-blog-post/admin-blog-post.component';
import { CommonsModule } from 'src/app/commons/commons.module';
import { I18nService } from 'src/app/commons/services/i18n.service';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AdminBlogLoginComponent } from './components/admin-blog-login/admin-blog-login.component';
import { WepConversorComponent } from './webpConversor/wep-conversor/wep-conversor.component';
import { MatSelectModule } from '@angular/material/select';
import { CATEGORY_EYESCLOUD_I18N_ENTRIES } from '../../i18n/category-eyescloud-i18n-entries';

@NgModule({
  declarations: [
    AdminBlogComponent,
    AdminBlogPostComponent,
    AdminBlogLoginComponent,
  ],
  imports: [
    MatSelectModule,
    CommonModule,
    CommonsModule,
    EditorModule,
    WepConversorComponent,
  ],
})
export class AdminBlogModule {
  constructor(private i18nService: I18nService) {
    i18nService.addI18nEntriesObject(CATEGORY_EYESCLOUD_I18N_ENTRIES);
  }
}
