import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenerateRouteService } from 'src/app/commons/services/generate-route.service';

@Component({
  selector: 'app-email-registered-dialog',
  templateUrl: './email-registered-dialog.component.html',
  styleUrl: './email-registered-dialog.component.scss'
})
export class EmailRegisteredDialogComponent {

  constructor(
    private dialog: MatDialogRef<EmailRegisteredDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private generateRouteService: GenerateRouteService
  ) { }

  close(): void {
    this.dialog.close();
  }

  getRouterLink(routeKey: any): string {
    return this.generateRouteService.generateRoute(routeKey);
  }
}
