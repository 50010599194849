import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CookiesConfigEnum } from 'src/app/commons/enums/cookies-config-enum';
import { cookiesLists } from 'src/app/commons/enums/cookies-list';
import { ResponsiveService } from 'src/app/commons/services/responsive.service';
import { WindowService } from 'src/app/commons/services/window.service';

interface CookieType {
  type: CookiesConfigEnum;
  label: string;
}

@Component({
  selector: 'app-optional-nc-dialog',
  templateUrl: './optional-cookies-dialog.component.html',
  styleUrl: './optional-cookies-dialog.component.scss'
})
export class OptionalNcDialogComponent implements OnInit {

  cookiesConfigEnum: typeof CookiesConfigEnum;
  cookiesLists: typeof cookiesLists;
  currentCookies: CookiesConfigEnum;
  isTablet: boolean = this.responsiveService.isTablet();
  showDetails: boolean = false;
  analysisToggle: boolean = true;
  functionalityToggle: boolean = true;
  marketingToggle: boolean = true;
  fraudToggle: boolean = true;
  securityToggle: boolean = true;
  cookieTypes: CookieType[] = [
    { type: CookiesConfigEnum.ESSENTIAL, label: 'cookies_config_Data6' },
    { type: CookiesConfigEnum.ANALYSIS, label: 'cookies_config_Data7' },
    { type: CookiesConfigEnum.FUNCTIONALITY, label: 'cookies_config_Data8' },
    { type: CookiesConfigEnum.MARKETING, label: 'cookies_config_Data9' },
    { type: CookiesConfigEnum.FRAUD, label: 'cookies_config_Data10' },
    { type: CookiesConfigEnum.SECURITY, label: 'cookies_config_Data13' }
  ];
  scrollPosition = 0;

  constructor(
    private matDialogRef: MatDialogRef<OptionalNcDialogComponent>,
    private responsiveService: ResponsiveService,
    private windowService: WindowService
  ) {
    this.cookiesConfigEnum = CookiesConfigEnum;
    this.cookiesLists = cookiesLists;
    this.currentCookies = CookiesConfigEnum.ESSENTIAL;
  }

  ngOnInit(): void {
  }

  setCurrentCookies(cookiesType: CookiesConfigEnum): void {
    this.currentCookies = cookiesType;
    this.showDetails = false;
  }

  showCookiesDetails(): void {
    this.showDetails = !this.showDetails;
  }

  acceptCookies(response: CookiesConfigEnum): void {
    if (response === CookiesConfigEnum.ALL) {
      this.updateToggles(true);
    } else if (response === CookiesConfigEnum.ESSENTIAL) {
      this.updateToggles(false);
    }

    const selectedToggles: { [key: string]: boolean } = {
      ESSENTIAL: true,
      ANALYSIS: this.analysisToggle,
      FUNCTIONALITY: this.functionalityToggle,
      MARKETING: this.marketingToggle,
      FRAUD: this.fraudToggle,
      SECURITY: this.securityToggle
    };
    if (this.windowService.isBrowser) {
      localStorage.setItem('selectedCookies', JSON.stringify(selectedToggles));
    }
    this.matDialogRef.close();
  }

  private updateToggles(value: boolean): void {
    this.analysisToggle = value;
    this.functionalityToggle = value;
    this.marketingToggle = value;
    this.fraudToggle = value;
    this.securityToggle = value;
  }
}
