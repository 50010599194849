<footer class="footer" [ngClass]="{'white-bg': isWhiteBgActive}" (window:resize)="onResize()">
  <div class="first-line">
    <img src="/assets/images/footer/ec3d-logo.svg" alt="eyesCloud3D logo" class="logo">
    <nav class="footer-nav">
      <div class="first-column">
        <a target="_self" [href]="getRouterLink('Faq')" class="Footer_interaction-click-help">
          <div class="bold" [innerHTML]="'footer_Data1' | i18n"></div>
        </a>
        <a target="_self" [href]="getRouterLink('Blog')" class="Footer_interaction-click-blog">
          <div class="bold" [innerHTML]="'footer_Data2' | i18n"></div>
        </a>
        <a target="_self" [href]="getRouterLink('Contact')" class="Footer_interaction-click-contact">
          <div class="bold" [innerHTML]="'footer_Data3' | i18n"></div>
        </a>
      </div>
      <div class="second-column">
        <div class="bold" [innerHTML]="'footer_Data4' | i18n"></div>
        <a [href]="getRouterLink('TermsOfUse')" target="_blank">
          <div [innerHTML]="'footer_Data5' | i18n"></div>
        </a>
        <a [href]="getRouterLink('Cookies')" target="_blank">
          <div [innerHTML]="'footer_Data6' | i18n"></div>
        </a>
        <a [href]="getRouterLink('Privacy')" target="_blank">
          <div [innerHTML]="'footer_Data7' | i18n"></div>
        </a>
        <!-- <a [href]="getRouterLink('Hiring')">
          <div [innerHTML]="'footer_Data13' | i18n"></div>
        </a> -->
      </div>
    </nav>

    <div class="newsletter" *ngIf="!subscriptionSent">
      <form (ngSubmit)="onSubmit()">
        <span [innerHTML]="'footer_Data8' | i18n" class="h4"></span>
        <div class="email-input">
          <input type="email" name="newsletterEmail" [(ngModel)]="newsletterEmail"
            placeholder="{{ 'footer_Data12' | i18n: translationModes.PLACEHOLDER }}">
          <button class="send-logo" [ngClass]="{'active': isValidEmail() && isTosChecked && isMailTosChecked}"
            id="Footer_interaction-send-mail-newsletter" type="submit">
            <img src="/assets/images/footer/send-icon.svg" alt="Send icon">
          </button>
          <div class="subscribed" [innerHTML]="'footer_Data16' | i18n" *ngIf="alreadySubscribed"></div>
        </div>
        <div class="tos-container">
          <input type="checkbox" name="tos" id="tosAccepted" [(ngModel)]="isTosChecked">
          <div [innerHTML]="'footer_Data9' | i18n"></div>
        </div>
        <div class="contact-container">
          <input type="checkbox" name="mailTos" id="mailTosAccepted" [(ngModel)]="isMailTosChecked">
          <div [innerHTML]="'footer_Data10' | i18n"></div>
        </div>
      </form>
    </div>

    <div class="newsletter news-sent" *ngIf="subscriptionSent">
      <div class="message" [innerHTML]="'footer_Data14' | i18n"></div>
      <div class="subscribe" [innerHTML]="'footer_Data15' | i18n" (click)="subscribeAgain()"></div>
    </div>
  </div>
  <div class="second-line">
    <div class="privacy" *ngIf="!isLandscape">
      <div class="bold" [innerHTML]="'footer_Data4' | i18n"></div>
      <a [href]="getRouterLink('TermsOfUse')" target="_blank">
        <div [innerHTML]="'footer_Data5' | i18n"></div>
      </a>
      <a [href]="getRouterLink('Cookies')" target="_blank">
        <div [innerHTML]="'footer_Data6' | i18n"></div>
      </a>
      <a [href]="getRouterLink('Privacy')" target="_blank">
        <div [innerHTML]="'footer_Data7' | i18n"></div>
      </a>
      <!-- <a [href]="getRouterLink('Hiring')">
        <div [innerHTML]="'footer_Data13' | i18n"></div>
      </a> -->
    </div>
    <h5 [innerHTML]="'footer_Data11' | i18n"></h5>
    <div class="social-container">
      <a href="https://www.tiktok.com/@eyescloud3d" target="_blank" id="tiktok-button"
        class="Footer_interaction-social-tiktok">
        <img src="/assets/images/commons/tiktok.svg" alt="TikTok">
      </a>
      <a href="https://twitter.com/eyesCloud3d" target="_blank" id="x-button" class="Footer_interaction-social-x">
        <img src="/assets/images/commons/x.svg" alt="X">
      </a>
      <a href="https://www.instagram.com/eyescloud3d/" target="_blank" id="instagram-button"
        class="Footer_interaction-social-instagram">
        <img src="/assets/images/commons/instagram.svg" alt="Instagram">
      </a>
      <a href="https://www.facebook.com/eyesCloud3d/" target="_blank" id="facebook-button"
        class="Footer_interaction-social-facebook">
        <img src="/assets/images/commons/facebook.svg" alt="Facebook">
      </a>
      <a href="https://www.youtube.com/channel/UCmKCyehTYjj5L7oesp4xokw" target="_blank" id="youtube-button"
        class="Footer_interaction-social-youtube">
        <img src="/assets/images/commons/youtube.svg" alt="Youtube">
      </a>
      <a href="https://es.linkedin.com/showcase/eyescloud3d/" target="_blank" id="linkedin-button"
        class="Footer_interaction-social-linkedin">
        <img src="/assets/images/commons/linkedin.svg" alt="LinkedIn">
      </a>
    </div>
  </div>
</footer>