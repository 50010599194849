<img src="/assets/images/login/dialog/close.svg" alt="Cerrar" class="close-icon" (click)="closeDialog()">
<div class="title" [innerHTML]="'twoFactorDialog_Data18' | i18n"></div>
<div class="subtitle" [innerHTML]="(data.source === sources.EMAIL ? 'twoFactorDialog_Data20' : 'twoFactorDialog_Data19') | i18n: translationModes.INSERT_VALUE : data.data"></div>
<input type="text" [(ngModel)]="code" (ngModelChange)="onCodeChange()" [placeholder]="'twoFactorDialog_Data16' | i18n: translationModes.PLACEHOLDER">
<div class="generate-new-code incorrect-code" *ngIf="isCodeIncorrect" [innerHTML]="'twoFactorDialog_Data17' | i18n"></div>
<div class="generate-new-code" *ngIf="!isCodeIncorrect">
  <p *ngIf="remainingSeconds === 0" [innerHTML]="'twoFactorDialog_Data21' | i18n"></p>
  <p class="underline" *ngIf="remainingSeconds === 0" (click)="generateNewCode()" [innerHTML]="'twoFactorDialog_Data22' | i18n"></p>
  <p *ngIf="remainingSeconds !== 0"
    [innerHTML]="'twoFactorDialog_Data26' | i18n: translationModes.INSERT_VALUE : remainingSeconds"></p>
  <p [innerHTML]="'twoFactorDialog_Data23' | i18n"></p>
</div>
<button class="btn" [ngClass]="{'disabled': !code}" (click)="continue()" [innerHTML]="'twoFactorDialog_Data11' | i18n"></button>
