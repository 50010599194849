export const LANDING_RATES_I18N_ENTRIES = {
  landing_rates_Data1: {
    es: 'Tarifas',
    en: 'Plans',
    pt: 'Tarifas',
    fr: 'Tarifs',
  },
  landing_rates_Data2: {
    es: '<p>Descarga</p>',
    en: '<p>Download</p>',
    pt: '<p>Transferência</p>',
    fr: '<p>Téléchargement</p>',
  },
  landing_rates_Data3: {
    es: '<p>De modelos</p>',
    en: '<p>models</p>',
    pt: '<p>De modelos</p>',
    fr: '<p>de modèles</p>',
  },
  landing_rates_Data4: {
    es: '<p>2,95€/</p>',
    en: '<p>€2.95/</p>',
    pt: '<p>2,95€/</p>',
    fr: '<p>2,95€/</p>',
  },
  landing_rates_Data5: {
    es: '<p>modelo</p>',
    en: '<p>model</p>',
    pt: '<p>modelo</p>',
    fr: '<p>modèle</p>',
  },
  landing_rates_Data6: {
    es: '<p>Descargas: elige el nº de descargas que quieras.</p>',
    en: '<p>Downloads: choose the number of downloads you want.</p>',
    pt: '<p>Transferências: escolha o n.º de transferências que pretende.</p>',
    fr: '<p>Téléchargements : choisissez le nombre de téléchargements souhaités.</p>',
  },
  landing_rates_Data7: {
    es: '<p>Comprar</p>',
    en: '<p>Purchase</p>',
    pt: '<p>Comprar</p>',
    fr: '<p>Acheter</p>',
  },
  landing_rates_Data8: {
    es: '<p>%</p>',
    en: '<p>%</p>',
    pt: '<p>%</p>',
    fr: '<p>%</p>',
  },
  landing_rates_Data9: {
    es: '<p>Herramientas básicas +</p>',
    en: '<p>Basic</p>',
    pt: '<p>Ferramentas básicas +</p>',
    fr: '<p>Outils de base +</p>',
  },
  landing_rates_Data10: {
    es: '<p>avanzadas</p>',
    en: '<p>+ Advanced Tools</p>',
    pt: '<p>avançadas</p>',
    fr: '<p>avancés</p>',
  },
  landing_rates_Data11: {
    es: '<p>Mensual</p>',
    en: '<p>Monthly</p>',
    pt: '<p>Mensal</p>',
    fr: '<p>Mensuel</p>',
  },
  landing_rates_Data12: {
    es: '<p>Anual</p>',
    en: '<p>Annual</p>',
    pt: '<p>Anual</p>',
    fr: '<p>Annuel</p>',
  },
  landing_rates_Data13: {
    es: '<p>+2</p>',
    en: '<p>+2</p>',
    pt: '<p>+2</p>',
    fr: '<p>+2</p>',
  },
  landing_rates_Data14: {
    es: '<p>Meses gratis</p>',
    en: '<p>Months free</p>',
    pt: '<p>Meses grátis</p>',
    fr: '<p>mois gratuits</p>',
  },
  landing_rates_Data15: {
    es: '<p>7,95€/</p>',
    en: '<p>€7.95 </p>',
    pt: '<p>7,95/€</p>',
    fr: '<p>7,95€/</p>',
  },
  landing_rates_Data16: {
    es: '<p>mes</p>',
    en: '<p>per month</p>',
    pt: '<p>mês</p>',
    fr: '<p>mois</p>',
  },
  landing_rates_Data17: {
    es: '<p>(79.95€ / facturado anualmente)</p>',
    en: '<p>(€79.95 / billed annually)</p>',
    pt: '<p>(79,95 € / faturado anualmente)</p>',
    fr: '<p>(79,95€ / facturé annuellement)</p>',
  },
  landing_rates_Data18: {
    es: '<p>Usuarios:</p>',
    en: '<p>Users:</p>',
    pt: '<p>Utilizadores:</p>',
    fr: '<p>Utilisateurs :</p>',
  },
  landing_rates_Data19: {
    es: '<p>1</p>',
    en: '<p>1</p>',
    pt: '<p>1</p>',
    fr: '<p>1</p>',
  },
  landing_rates_Data20: {
    es: '<span>Límites de subida:</span>',
    en: '<span>Upload limit:</span>',
    pt: '<span>Limites de registo:</span>',
    fr: '<span>Limites de téléchargement :</span>',
  },
  landing_rates_Data21: {
    es: '<span>Hasta 200 fotos / 4 min de vídeo</span>',
    en: '<span>Up to 200 photos / 4 min of video</span>',
    pt: '<span>Até 200 fotografias / 4 minutos de vídeo</span>',
    fr: '<span>Jusqu’à 200 photos/vidéo de 4 min</span>',
  },
  landing_rates_Data22: {
    es: '<p>Descargas:</p>',
    en: '<p>Downloads:</p>',
    pt: '<p>Transferências:</p>',
    fr: '<p>Téléchargements :</p>',
  },
  landing_rates_Data23: {
    es: '<p>10</p>',
    en: '<p>10</p>',
    pt: '<p>10</p>',
    fr: '<p>10</p>',
  },
  landing_rates_Data24: {
    es: '<p>Importaciones:</p>',
    en: '<p>Imports:</p>',
    pt: '<p>Importações:</p>',
    fr: '<p>Importations :</p>',
  },
  landing_rates_Data25: {
    es: '<p>5</p>',
    en: '<p>5</p>',
    pt: '<p>5</p>',
    fr: '<p>5</p>',
  },
  landing_rates_Data26: {
    es: '<p>Comprar</p>',
    en: '<p>Purchase</p>',
    pt: '<p>Comprar</p>',
    fr: '<p>Acheter</p>',
  },
  landing_rates_Data27: {
    es: '<p>Descarga</p>',
    en: '<p>Download</p>',
    pt: '<p>Transferência</p>',
    fr: '<p>Téléchargement</p>',
  },
  landing_rates_Data28: {
    es: '<p>De importaciones</p>',
    en: '<p>imports</p>',
    pt: '<p>De importações</p>',
    fr: '<p>d’importations</p>',
  },
  landing_rates_Data29: {
    es: '<p>2,95€/</p>',
    en: '<p>€2.95 </p>',
    pt: '<p>2,95€/</p>',
    fr: '<p>2,95€/</p>',
  },
  landing_rates_Data30: {
    es: '<p>modelo</p>',
    en: '<p>model</p>',
    pt: '<p>modelo</p>',
    fr: '<p>modèle</p>',
  },
  landing_rates_Data31: {
    es: '<p>Importaciones: elige el nº de importaciones que quieras.</p>',
    en: '<p>Imports: choose the number of imports you want.</p>',
    pt: '<p>Importações: escolha o n.º de importações que pretende.</p>',
    fr: '<p>Importations : choisissez le nombre d’importations souhaitées.</p>',
  },
  landing_rates_Data32: {
    es: '<p>Comprar</p>',
    en: '<p>Purchase</p>',
    pt: '<p>Comprar</p>',
    fr: '<p>Acheter</p>',
  },
  landing_rates_Data33: {
    es: '<p>Ver tarifas</p>',
    en: '<p>See plans</p>',
    pt: '<p>Ver tarifas</p>',
    fr: '<p>Voir tarifs</p>',
  },
  landing_rates_Data34: {
    es: '<p>IMPORTACIÓN</p>',
    en: '<p>IMPORT</p>',
    pt: '<p>IMPORTAÇÃO</p>',
    fr: '<p>IMPORTATION</p>',
  },
  landing_rates_Data35: {
    es: '<p>desde</p>',
    en: '<p>from</p>',
    pt: '<p>desde</p>',
    fr: '<p>à partir de</p>',
  },
  landing_rates_Data36: {
    es: '<p>DE LA GALERÍA</p>',
    en: '<p>GALLERY</p>',
    pt: '<p>DA GALERIA</p>',
    fr: '<p>DE LA GALERIE</p>',
  },
  landing_rates_Data37: {
    es: '<p>ALMACENAMIENTO</p>',
    en: '<p>STORAGE</p>',
    pt: '<p>ARMAZENAMENTO</p>',
    fr: '<p>CAPACITÉ DE STOCKAGE</p>',
  },
  landing_rates_Data38: {
    es: '<p>19€/</p>',
    en: '<p>€19/</p>',
    pt: '<p>19€/</p>',
    fr: '<p>19€/</p>',
  },
  landing_rates_Data39: {
    es: '<p>Incluye: (+50 modelos) en tu galería al mes</p>',
    en: '<p>Includes: (+50 Models) in your gallery per month</p>',
    pt: '<p>Inclui: (+50 modelos) na sua galeria por mês</p>',
    fr: '<p>Inclus : (+50 modèles) dans votre galerie par mois</p>',
  },
  landing_rates_Data40: {
    es: '<p>Adicionales</p>',
    en: '<p>Additional</p>',
    pt: '<p>Adicionais</p>',
    fr: '<p>Supplémentaires</p>',
  },
  landing_rates_Data41: {
    es: '<p>HERRAMIENTAS ESTÁNDAR +</p>',
    en: '<p>STANDARD +</p>',
    pt: '<p>FERRAMENTAS PADRÃO +</p>',
    fr: '<p>OUTILS STANDARD +</p>',
  },
};
