<div class="inicio-blog">
  <h1 class="title" [innerHTML]="post?.title"></h1>
  <div class="img-container">
    <img [src]="imgMovil" class="img-principal" alt="Imagen principal" [ngClass]="{'hide':!checkResolution()}" loading="lazy">
    <img [src]="post.imgDestacada" class="img-principal" alt=" Imagen principal PC"
      [ngClass]="{'hide':checkResolution()}" loading="lazy">
    <div class="bg-border"></div>
    <div class="bg-border"></div>
  </div>
</div>
<div class="container">
  <div class="go-back" (click)="getRouterLink('Blog')">
    <img src="/assets/images/blog/arrow.svg" class="arrow" loading="lazy">
    <div [innerHTML]="'blogpost_data1'|i18n"></div>
  </div>
  <h2 class="subtitle" [innerHTML]="post?.subtitle"></h2>
  <div class="body-blog-text" [innerHTML]="post?.body | safeHtml">
  </div>
  <div class="last-row">
    <div class="categorias">
      <div *ngFor="let elemento of categorias" class="categoria">
        <div [innerHTML]="elemento|i18n:1"></div>
      </div>
    </div>
    <div class="social-container">
      <div [innerHTML]="'blogpost_data2'|i18n"></div>
      <a id="tweetLink" href="https://twitter.com/share" target="_blank">
        <img src="/assets/images/commons/x.svg" alt="X" loading="lazy">
        </a>
      <a href="https://www.instagram.com/eyescloud3d/" target="_blank">
        <img src="/assets/images/commons/instagram.svg" alt="Instagram" loading="lazy">
        </a>
      <a id="facebookLink" href="https://www.facebook.com/sharer/sharer.php?u=#url" target="_blank">
        <img src="/assets/images/commons/facebook.svg" alt="Facebook" loading="lazy">
      </a>
      <a id="linkedinLink" href="https://es.linkedin.com/showcase/eyescloud3d/" target="_blank">
        <img src="/assets/images/commons/linkedin.svg" alt="LinkedIn" loading="lazy">
      </a>
      <a [href]="'whatsapp://send?text=https://eyescloud3d.com/blog/'+post.url" target="_blank"
        rel="noopener noreferrer" *ngIf="checkResolution()">
        <img src="../../../../assets/images/blog/whatsapp-ico.svg" loading="lazy">
      </a>
      <a [href]="'https://web.whatsapp.com://send?text=https://eyescloud3d.com/blog/'+post.url" target="_blank"
        rel="noopener noreferrer" *ngIf="!checkResolution()">
        <img src="../../../../assets/images/blog/whatsapp-ico.svg" loading="lazy">
      </a>
    </div>
  </div>

</div>
<app-last-blogs></app-last-blogs>
