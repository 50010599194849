export const meta_links = {
  //HOME
  home_links1: {
    es: '<link rel="canonical" href="https://eyescloud3d.com">',
    en: '<link rel="canonical" href="https://eyescloud3d.com/us">',
    fr: '<link rel="canonical" href="https://eyescloud3d.com/fr">',
    pt: '<link rel="canonical" href="https://eyescloud3d.com/pt">',
  },
  home_links2: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/us" hreflang="en">',
    en: '<link rel="alternate" href="https://eyescloud3d.com" hreflang="es">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/us" hreflang="en">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/us" hreflang="en">',
  },
  home_links3: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/fr" hreflang="fr">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/fr" hreflang="fr">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com" hreflang="es">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/fr" hreflang="fr">',
  },
  home_links4: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/pt" hreflang="pt">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/pt" hreflang="pt">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/pt" hreflang="pt">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com" hreflang="es">',
  },
  //TARIFA
  tarifa_links1: {
    es: '<link rel="canonical" href="https://eyescloud3d.com/tarifas">',
    en: '<link rel="canonical" href="https://eyescloud3d.com/us/rates">',
    fr: '<link rel="canonical" href="https://eyescloud3d.com/fr/tarifs">',
    pt: '<link rel="canonical" href="https://eyescloud3d.com/pt/taixas">',
  },
  tarifa_links2: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/us/rates" hreflang="en">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/tarifas" hreflang="es">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/us/rates" hreflang="en">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/us/rates" hreflang="en">',
  },
  tarifa_links3: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/fr/tarifs" hreflang="fr">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/fr/tarifs" hreflang="fr">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/tarifas" hreflang="es">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/fr/tarifs" hreflang="fr">',
  },
  tarifa_links4: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/pt/taixas" hreflang="pt">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/pt/taixas" hreflang="pt">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/pt/taixas" hreflang="pt">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/tarifas" hreflang="es">',
  },
  //FAQ
  faq_links1: {
    es: '<link rel="canonical" href="https://eyescloud3d.com/faq">',
    en: '<link rel="canonical" href="https://eyescloud3d.com/us/faq">',
    fr: '<link rel="canonical" href="https://eyescloud3d.com/fr/faq">',
    pt: '<link rel="canonical" href="https://eyescloud3d.com/pt/faq">',
  },
  faq_links2: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/us/faq" hreflang="en">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/faq" hreflang="es">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/us/faq" hreflang="en">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/us/faq" hreflang="en">',
  },
  faq_links3: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/fr/faq" hreflang="fr">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/fr/faq" hreflang="fr">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/faq" hreflang="es">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/fr/faq" hreflang="fr">',
  },
  faq_links4: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/pt/faq" hreflang="pt">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/pt/faq" hreflang="pt">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/pt/faq" hreflang="pt">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/faq" hreflang="es">',
  },
  //BLOG
  blog_links1: {
    es: '<link rel="canonical" href="https://eyescloud3d.com/blog">',
    en: '<link rel="canonical" href="https://eyescloud3d.com/us/blog">',
    fr: '<link rel="canonical" href="https://eyescloud3d.com/fr/blog">',
    pt: '<link rel="canonical" href="https://eyescloud3d.com/pt/blog">',
  },
  blog_links2: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/us/blog" hreflang="en">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/blog" hreflang="es">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/us/blog" hreflang="en">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/us/blog" hreflang="en">',
  },
  blog_links3: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/fr/blog" hreflang="fr">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/fr/blog" hreflang="fr">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/blog" hreflang="es">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/fr/blog" hreflang="fr">',
  },
  blog_links4: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/pt/blog" hreflang="pt">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/pt/blog" hreflang="pt">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/pt/blog" hreflang="pt">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/blog" hreflang="es">',
  },
  //CONTACTO
  contact_links1: {
    es: '<link rel="canonical" href="https://eyescloud3d.com/contacto">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/us/contact" hreflang="en">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/fr/contact" hreflang="fr">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/pt/contacto" hreflang="pt">',
  },
  contact_links2: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/contacto" hreflang="es">',
    en: '<link rel="canonical" href="https://eyescloud3d.com/us/contact">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/fr/contact" hreflang="fr">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/pt/contacto" hreflang="pt">',
  },
  contact_links3: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/contacto" hreflang="es">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/us/contact" hreflang="en">',
    fr: '<link rel="canonical" href="https://eyescloud3d.com/fr/contact">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/pt/contacto" hreflang="pt">',
  },
  contact_links4: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/contacto" hreflang="es">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/us/contact" hreflang="en">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/fr/contact" hreflang="fr">',
    pt: '<link rel="canonical" href="https://eyescloud3d.com/pt/contacto">',
  },
  //LOGIN
  login_links1: {
    es: '<link rel="canonical" href="https://eyescloud3d.com/iniciar-sesion">',
    en: '<link rel="canonical" href="https://eyescloud3d.com/us/login">',
    fr: '<link rel="canonical" href="https://eyescloud3d.com/fr/login">',
    pt: '<link rel="canonical" href="https://eyescloud3d.com/pt/login">',
  },
  login_links2: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/us/login" hreflang="en">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/iniciar-sesion" hreflang="es">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/us/login" hreflang="en">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/us/login" hreflang="en">',
  },
  login_links3: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/fr/login" hreflang="fr">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/fr/login" hreflang="fr">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/iniciar-sesion" hreflang="es">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/fr/login" hreflang="fr">',
  },
  login_links4: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/pt/login" hreflang="pt">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/pt/login" hreflang="pt">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/pt/login" hreflang="pt">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/iniciar-sesion" hreflang="es">',
  },

  //REGISTRO
  registro_links1: {
    es: '<link rel="canonical" href="https://eyescloud3d.com/registro">',
    en: '<link rel="canonical" href="https://eyescloud3d.com/us/register">',
    fr: '<link rel="canonical" href="https://eyescloud3d.com/fr/enregistrement">',
    pt: '<link rel="canonical" href="https://eyescloud3d.com/pt/registo">',
  },
  registro_links2: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/us/register" hreflang="en">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/registro" hreflang="es">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/us/register" hreflang="en">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/us/register" hreflang="en">',
  },
  registro_links3: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/fr/enregistrement" hreflang="fr">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/fr/enregistrement" hreflang="fr">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/registro" hreflang="es">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/fr/enregistrement" hreflang="fr">',
  },
  registro_links4: {
    es: '<link rel="alternate" href="https://eyescloud3d.com/pt/registo" hreflang="pt">',
    en: '<link rel="alternate" href="https://eyescloud3d.com/pt/registo" hreflang="pt">',
    fr: '<link rel="alternate" href="https://eyescloud3d.com/pt/registo" hreflang="pt">',
    pt: '<link rel="alternate" href="https://eyescloud3d.com/registro" hreflang="es">',
  },


};
