import { Injectable } from '@angular/core';
import { PopularListSearch, PopularSearches } from '../enums/popular-list-config';
import { Observable, Subject, debounceTime } from 'rxjs';
import { RestClientService } from 'src/app/commons/services/rest-client.service';
import { EndPointEnum } from 'src/app/commons/enums/end-point';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private URL = '/search';
  private searchInputSubject: Subject<string> = new Subject();

  constructor(
    private restClientService: RestClientService
  ) {
    this.searchInputSubject.pipe(debounceTime(3000)).subscribe(input => {
      if (input && input.trim().length > 0) {
        const formData = new FormData();
        formData.append('search', input);
        this.addSearch(formData).subscribe((data) => {
        }, (error) => {
        });
      }
    });
  }

  search(input: string): string[] {
    input = this.normalizeInput(input); // Normalizar el input
    // Emitir el texto escrito para guardar en el log después del debounce
    this.searchInputSubject.next(input);
    const found: Map<string, number> = new Map(); // Usar un mapa para almacenar los resultados y su número de coincidencias
    const searchTerms = input.trim().split(/\s+/); // Dividir la entrada en términos de búsqueda individuales y eliminar espacios en blanco al principio y al final
    PopularListSearch.forEach(item => {
      const keywords = item.keywords.map(keyword => this.normalizeInput(keyword)); // Normalizar las palabras clave
      let matchCount = 0; // Contador para el número de términos coincidentes
      searchTerms.forEach(term => {
        keywords.forEach(keyword => {
          if (keyword.includes(term)) {
            matchCount++; // Incrementar el contador si se encuentra una coincidencia
          }
        });
      });
      if (matchCount > 0) {
        found.set(item.key, matchCount); // Agregar la clave y el número de coincidencias al mapa
      }
    });
    // Ordenar los resultados por el número de coincidencias en orden descendente
    const sortedResults = [...found.entries()].sort((a, b) => b[1] - a[1]);
    // Obtener solo las claves (los resultados) ordenadas
    const matches = sortedResults.slice(0, 6).map(entry => entry[0]); // Limitar a 6 resultados
    return matches;
  }

  getUrlByKey(key: string): string | undefined {
    const result = PopularListSearch.find(item => item.key === key);
    if (result) {
      return result.url + (result.urlParams ? result.urlParams : '');
    } else {
      return undefined;
    }
  }

  // Método para normalizar el input (convertir a minúsculas y quitar tildes)
  private normalizeInput(input: string): string {
    return input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  // Añadir búsqueda a base de datos
  addSearch(search: any): Observable<any> {
    return this.restClientService.post(`${this.URL}`, search, EndPointEnum.BLOG);
  }

  // Obtener listado de los términos
  getSearch(): Observable<any> {
    return this.restClientService.get(`${this.URL}`, null, EndPointEnum.BLOG);
  }

  getPopularSearches() {
    return PopularSearches.filter(item => item.show); // Devuelve las búsquedas populares marcadas como true
  }
}
