export const EnglishRoutes = {
  Home: {
    URL: 'us',
  },
  Rates: {
    URL: 'us/rates',
  },
  Faq: {
    URL: 'us/help/faq',
  },
  Contact: {
    URL: 'us/contact',
  },
  Blog: {
    URL: 'us/blog',
  },
  BlogWithParams: {
    URL: 'us/blog/:id',
  },
  Register: {
    URL: 'us/register',
  },
  RegisterWithPromotion: {
    URL: 'us/register/:promotion',
  },
  Login: {
    URL: 'us/login',
  },
  TermsOfUse: {
    URL: 'us/terms',
  },
  Cookies: {
    URL: 'us/cookies',
  },
  Privacy: {
    URL: 'us/privacy',
  },
  Hiring: {
    URL: 'us/contracting-policy',
  },
  HiringWithRate: {
    URL: 'us/contracting-policy/:rate',
  },
  HelpCenter: {
    URL: 'us/help',
  },
  HCLibraryExtended: {
    URL: 'us/help/library',
  },
  // HCFirstStepsExtended: {
  //   URL: 'us/help/home',
  // },
  HCLibraryExtendedTools: {
    URL: 'us/help/library/:tool',
  },
  'android-login': {
    URL: 'us/android-login',
  },
  'android-register': {
    URL: 'us/android-register',
  },
  'email-verified': {
    URL: 'us/email-verified/:token',
  },
};
