import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Blog } from 'src/app/commons/models/blog';
import { Category } from 'src/app/commons/models/category';
import { Languages } from 'src/app/commons/models/languages';
import { Platform } from 'src/app/commons/models/platforms';
import { BlogService } from 'src/app/commons/services/blog.service';
import { CategoryService } from 'src/app/commons/services/category.service';
import { PlatformsService } from 'src/app/commons/services/platforms.service';
import { WindowService } from 'src/app/commons/services/window.service';

@Component({
  selector: 'app-admin-blog-post',
  templateUrl: './admin-blog-post.component.html',
  styleUrls: ['./admin-blog-post.component.scss'],
})
export class AdminBlogPostComponent implements OnInit {
  post: any;
  id: any;
  selectedBlogs: any[] = [];
  timeStamp: any;
  previewMobile: string = '';
  previewPC: string = '';
  previewDestacada: string = '';
  previewRRSS: string = '';
  tinyconfig = {
    plugins:
      'print preview fullpage powerpaste searchreplace autolink directionality advcode visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount tinymcespellchecker a11ychecker imagetools mediaembed  linkchecker contextmenu colorpicker textpattern help',
    toolbar1:
      'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',

    convert_urls: false,
  };
  categorias!: Category[];
  categoriasChecked!: any[];
  newCategory: any[] = [];
  allBlogs: any;
  platforms: any[] = [];
  languages: any[] = [];

  apiKey: string = 'sxt467nuiqj00c48tl46cwn8n2p2spqtu8bn5x3bg9u6r5m8'; //Key para eyesCloud3D

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private categoryService: CategoryService,
    private blogService: BlogService,
    private platformsService: PlatformsService,
    private windowService: WindowService
  ) { }

  ngOnInit() {
    this.categoryService.getAll().subscribe((data: Category[]) => {
      this.categorias = data;

      this.route.params.subscribe((params) => {
        this.id = params['id'];

        if (this.id === 'new') {
          this.post = new Blog();
          this.categoriasChecked = this.categorias;
        } else {
          this.blogService.get(this.id).subscribe((blog: Blog) => {
            this.post = blog;
            if (
              this.post.subtitle == undefined ||
              this.post.subtitle == 'undefined' ||
              this.post.subtitle == null ||
              this.post.subtitle == 'null'
            ) {
              this.post.subtitle = '';
            }
            this.blogService.getRelatedBlogs(this.post.id).subscribe((res) => {
              res['data'].forEach((blog: any) => {
                this.selectedBlogs.push(blog.id);
              });
            });
            forkJoin({
              mainBlog: this.blogService.get(this.id),
              relatedBlogs: this.blogService.getRelatedBlogs(this.id),
            }).subscribe(({ mainBlog, relatedBlogs }) => {
              this.post = mainBlog;
              const selectedBlogIds = (relatedBlogs['data'] as any[]).map(
                (blog) => blog.id
              );
              this.selectedBlogs = selectedBlogIds;
              console.log('que hay en selectedBlogs', this.selectedBlogs);
            });
            const date = new Date();
            this.previewMobile = `${this.post.imgMovil}?date=${date.getTime()}`;
            this.previewPC = `${this.post.imgPC}?date=${date.getTime()}`;
            this.previewDestacada = `${this.post.imgDestacada
              }?date=${date.getTime()}`;
            this.previewRRSS = `${this.post.imgRRSS}?date=${date.getTime()}`;
            this.categoriasChecked = this.categorias;
            this.categoriasChecked = this.categorias.map((elemento) => {
              const presente = this.post.categories.includes(elemento.id);

              return { ...elemento, presente };
            });
          });
        }
      });
    });

    this.platformsService.getAll().subscribe((platforms: Platform[]) => {
      this.platforms = platforms;
    });

    this.platformsService
      .getAllLanguages()
      .subscribe((languages: Languages[]) => {
        this.languages = languages;
      });

    this.uploadImage();
    this.blogService.getAll().subscribe((res: any) => {
      this.allBlogs = res;
      console.log(this.allBlogs);
    });
  }

  cloudName = 'angular-creating-a-blog';
  unsignedUploadPreset = 'wyx68xom';

  uploadImage() {
    let fileSelect = document.getElementById('fileSelect'),
      fileElem = document.getElementById('fileElem');
    if (fileSelect) {
      fileSelect.addEventListener(
        'click',
        function (e) {
          if (fileElem) {
            fileElem.click();
          }
          e.preventDefault(); // prevent navigation to "#"
        },
        false
      );
    }
  }

  handleFiles() {
    const fileInput = document.getElementById('fileElem') as HTMLInputElement;
    let files = null;
    if (fileInput && fileInput.files) {
      files = fileInput.files;
      for (var i = 0; i < files.length; i++) {
        // this.uploadFile(files[i]); // call the function to upload the file
      }
    }
  }

  onSubmit() {
    this.categoriasChecked?.forEach((elemento) => {
      if (elemento.presente) {
        this.newCategory.push(elemento.id);
      }
    });
    this.post.categories = this.newCategory;

    if (this.id === 'new') {
      this.crearBlog();
    } else {
      this.updateBlog();
    }
    this.goList();
  }

  temporaryBlog() {
    this.categoriasChecked?.forEach((elemento) => {
      if (elemento.presente) {
        this.newCategory.push(elemento.id);
      }
    });
    this.post.categories = this.newCategory;
    if (this.id === 'new') {
      this.crearBlog(false);
    } else {
      this.updateBlog(false);
    }
    this.goList();
  }

  updateBlog(published: boolean = true) {
    const formData = new FormData();
    formData.append('title', this.post.title || ''); // Verifica si el campo tiene valor, si no, utiliza una cadena vacía
    formData.append('subtitle', this.post.subtitle || '');
    formData.append('url', this.post.url || '');
    formData.append('titulo_rrss', this.post.titulo_rrss || '');
    formData.append('description', this.post.description || '');
    formData.append('imgMovil', this.post.imgMovil || '');
    formData.append('imgPC', this.post.imgPC || '');
    formData.append('imgDestacada', this.post.imgDestacada || '');
    formData.append('imgRRSS', this.post.imgRRSS || '');
    formData.append('body', this.post.body || '');
    formData.append('categories', this.post.categories || '');
    formData.append('platform_id', this.post.platform_id || '');
    formData.append('languages_id', this.post.languages_id || '');
    formData.append('metatitle', this.post.metatitle || '');
    formData.append('metadescription', this.post.metadescription || '');
    formData.append('schema', this.post.schema || '');
    formData.append('is_published', published.toString());
    formData.append('blog_ids', this.selectedBlogs.join(','));
    formData.forEach((value, key) => {
      const values = formData.getAll(key);
    });

    this.blogService
      .makePublic(this.post.id, published)
      .subscribe((data) => { });

    this.blogService.update(formData, this.post.id).subscribe(
      (data) => {
        if (this.windowService.isBrowser) {
          window.location.reload();
          window.alert('Blog editado correctamente');
        }
      },
      (error) => {
        if (this.windowService.isBrowser) {
          window.alert('No se ha podido editar: ' + error.message);
        }
      }
    );
  }

  crearBlog(published: boolean = true) {
    const formData = new FormData();
    formData.append('title', this.post.title || ''); // Verifica si el campo tiene valor, si no, utiliza una cadena vacía
    formData.append('subtitle', this.post.subtitle || '');
    formData.append('url', this.post.url || '');
    formData.append('titulo_rrss', this.post.titulo_rrss || '');
    formData.append('description', this.post.description || '');
    formData.append('imgMovil', this.post.imgMovil || '');
    formData.append('imgPC', this.post.imgPC || '');
    formData.append('imgDestacada', this.post.imgDestacada || '');
    formData.append('imgRRSS', this.post.imgRRSS || '');
    formData.append('body', this.post.body || '');
    formData.append('categories', this.post.categories || '');
    formData.append('platform_id', this.post.platform_id || '');
    formData.append('languages_id', this.post.languages_id || '');
    formData.append('metatitle', this.post.metatitle || '');
    formData.append('metadescription', this.post.metadescription || '');
    formData.append('schema', this.post.schema || '');
    formData.append('is_published', published.toString());
    formData.append('blog_ids', this.selectedBlogs.join(','));
    this.blogService.create(formData).subscribe(
      (data) => {
        if (this.windowService.isBrowser) {
          window.location.reload();
          if (published) {
            window.alert('Blog publicado correctamente');
          } else {
            window.alert('Blog añadido a borradores');
          }
        }
      },
      (error) => {
        if (this.windowService.isBrowser) {
          window.alert('No se ha podido crear: ' + error.message);
        }
      }
    );

    this.blogService
      .makePublic(this.post.id, published)
      .subscribe((data) => { });
  }

  goList() {
    this.router.navigate(['/admin']);
  }

  goBack() {
    this.router.navigate(['/admin']);
  }

  onCancel() {
    this.goBack();
  }

  onChangeKeyTitle() {
    this.post.url = this.getCleanedString(this.post.title).replace(/_/g, '-');
  }

  getCleanedString(cadena: any) {
    // Definimos los caracteres que queremos eliminar
    var specialChars = '!@#$^&%*()+=-[]/{}|:<>?,.';

    // Los eliminamos todos
    for (var i = 0; i < specialChars.length; i++) {
      cadena = cadena.replace(new RegExp('\\' + specialChars[i], 'gi'), '');
    }

    // Lo queremos devolver limpio en minusculas
    cadena = cadena.toLowerCase();

    // Quitamos espacios y los sustituimos por _ porque nos gusta mas asi
    cadena = cadena.replace(/ /g, '_');

    // Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
    cadena = cadena.replace(/á/gi, 'a');
    cadena = cadena.replace(/é/gi, 'e');
    cadena = cadena.replace(/í/gi, 'i');
    cadena = cadena.replace(/ó/gi, 'o');
    cadena = cadena.replace(/ú/gi, 'u');
    cadena = cadena.replace(/ñ/gi, 'n');
    return cadena;
  }

  selectImgMovil(event: any) {
    this.post.imgMovil = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.previewMobile = reader.result as string;
    };
    reader.readAsDataURL(this.post.imgMovil);
  }

  selectImgPC(event: any) {
    this.post.imgPC = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.previewPC = reader.result as string;
    };
    reader.readAsDataURL(this.post.imgPC);
  }

  selectImgDestacada(event: any) {
    this.post.imgDestacada = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.previewDestacada = reader.result as string;
    };
    reader.readAsDataURL(this.post.imgDestacada);
  }

  selectImgRRSS(event: any) {
    this.post.imgRRSS = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.previewRRSS = reader.result as string;
    };
    reader.readAsDataURL(this.post.imgRRSS);
  }

  atLeastOneCategorySelected(): boolean {
    return this.categoriasChecked.some((opcion) => opcion.presente);
  }
}
