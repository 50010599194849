import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RegisterStepOneResponse } from 'src/app/auth/models/account-verification/register-step-one-response';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';

@Component({
  selector: 'app-register-step-one-dialog',
  templateUrl: './register-step-one-dialog.component.html',
  styleUrls: ['./register-step-one-dialog.component.scss'],
})
export class RegisterStepOneDialogComponent {
  responses: typeof RegisterStepOneResponse;
  translationModes: any = TranslationModeEnum;

  constructor(
    private matDialogRef: MatDialogRef<RegisterStepOneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public email: string
  ) {
    this.responses = RegisterStepOneResponse;
  }

  public closeDialog(
    response: RegisterStepOneResponse = RegisterStepOneResponse.DEFAULT
  ) {
    this.matDialogRef.close(response);
  }
}
