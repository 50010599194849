import { Component, OnInit } from '@angular/core';
import { TranslationModeEnum } from '../../enums/translation-mode-enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContactSentDialogComponent } from './dialogs/contact-sent-dialog/contact-sent-dialog.component';
import { RestClientService } from '../../services/rest-client.service';
import { UpdateMetaService } from '../../services/meta-service.service';
import { Subscription, filter } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { GenerateRouteService } from '../../services/generate-route.service';
import { ComponentsEnum } from '../../enums/components-enum';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  translationModes: any = TranslationModeEnum;
  contactForm: FormGroup;
  formSubmitted: boolean = false;
  routeSubscription!: Subscription;
  isHelpCenterContact: boolean = false;
  componentsEnum: typeof ComponentsEnum = ComponentsEnum;
  loadedComponents: Set<ComponentsEnum> = new Set();

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private restClientService: RestClientService,
    private updateMetaService: UpdateMetaService,
    private router: Router,
    private generateRouteService: GenerateRouteService,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.isHelpCenterContact = this.router.url.includes(this.getRouterLink('HelpCenter'));
    if (this.router.url.includes(this.getRouterLink('Contact'))) {
      this.updateMetaService.updateMetaTags('Contact');
    }
  }

  initForm(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      mail: ['', [Validators.required, Validators.email]],
      phone: [''],
      message: ['', Validators.required],
      termsAndConditions: [false, Validators.requiredTrue],
      receiveCommunications: [false]
    });
  }

  submitForm(): void {
    this.formSubmitted = true;
    if (this.contactForm.valid) {
      this.restClientService.post('/mail-functionality/contact-form', this.contactForm.value).subscribe(
        (send) => {
          this.contactForm.reset();
        },
        (error) => {
        }
      );
      this.openContactSentDialog();
    }
  }

  openContactSentDialog(): void {
    this.dialog.open(ContactSentDialogComponent, {
      autoFocus: false,
      disableClose: true,
      hasBackdrop: true,
      maxWidth: '100vw',
      panelClass: 'contactSentDialog',
    });
  }

  getRouterLink(routeKey: any): string {
    return this.generateRouteService.generateRoute(routeKey);
  }

  loadComponent(componentType: ComponentsEnum): void {
    this.loadedComponents.add(componentType);
  }

  isComponentLoaded(componentType: ComponentsEnum): boolean {
    return this.loadedComponents.has(componentType);
  }

}
