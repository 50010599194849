<div *ngIf="isFaqsActive" class="faqs-first-row">
  <a target="_self" [href]="getRouterLink('HelpCenter')">
    <div class="back">
      <img src="/assets/images/help-center/arrow.svg" alt="Arrow">
      <div [innerHTML]="'library_extended_Data1' | i18n"></div>
    </div>
  </a>
  <h1 [innerHTML]="'faqs_Data14' |i18n"></h1>
  <div class="search" [ngClass]="{'searching': !isBlank(searchTerm)}">
    <input type="text" name="search" id="search" [(ngModel)]="searchTerm" (input)="onSearch()"
      placeholder="{{ 'library_extended_Data3' | i18n: translationModes.PLACEHOLDER }}" autocomplete="off">
    <div class="search-results" *ngIf="!isBlank(searchTerm)">
      <div *ngFor="let result of searchResults"><a target="_self" [href]="getUrlForKey(result)">{{ result }}</a></div>
      <div *ngIf="searchResults.length === 0" [innerHTML]="'tools_Data198' | i18n"></div>
    </div>
    <div class="popular">
      <div class="topics" [innerHTML]="'library_extended_Data4' | i18n"></div>
      <div *ngFor="let item of popularItems; let last = last">
        <div>
          <a target="_self" [href]="getUrl(item)">
            {{ item.key }}{{ !last ? ',' : '...' }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="faqs-container" (window:resize)="onResize()" [ngClass]="{'white-bg': isFaqsActive || isHelpCenterContact}">

  <div class="title" [innerHTML]="'faqs_Data1' | i18n" *ngIf="!isFaqsActive"></div>
  <div class="line"></div>
  <div class="question">
    <div [innerHTML]="'faqs_Data2' | i18n" (click)="expand(1)"></div>
    <img class="arrow" src="/assets/images/faqs/arrow.svg" alt="Flecha" (click)="expand(1)"
      [ngClass]="{'arrow-rotated': isExpanded === 1}">
    <div class="expanded" [innerHTML]="'faqs_Data20' | i18n" [ngClass]="{'visible': isExpanded === 1}"></div>
  </div>
  <div class="line"></div>
  <div class="question">
    <div [innerHTML]="'faqs_Data3' | i18n" (click)="expand(2)"></div>
    <img class="arrow" src="/assets/images/faqs/arrow.svg" alt="Flecha" (click)="expand(2)"
      [ngClass]="{'arrow-rotated': isExpanded === 2}">
    <div class="expanded" [innerHTML]="'faqs_Data21' | i18n" [ngClass]="{'visible': isExpanded === 2}"></div>
    <div class="expanded" [innerHTML]="'faqs_Data22' | i18n" [ngClass]="{'visible': isExpanded === 2}"></div>
  </div>
  <div class="line"></div>
  <div class="question">
    <div [innerHTML]="'faqs_Data4' | i18n" (click)="expand(3)"></div>
    <img class="arrow" src="/assets/images/faqs/arrow.svg" alt="Flecha" (click)="expand(3)"
      [ngClass]="{'arrow-rotated': isExpanded === 3}">
    <div class="expanded" [innerHTML]="'faqs_Data23' | i18n" [ngClass]="{'visible': isExpanded === 3}"></div>
  </div>
  <div class="line"></div>
  <div class="question">
    <div [innerHTML]="'faqs_Data5' | i18n" (click)="expand(4)"></div>
    <img class="arrow" src="/assets/images/faqs/arrow.svg" alt="Flecha" (click)="expand(4)"
      [ngClass]="{'arrow-rotated': isExpanded === 4}">
    <div class="expanded" [innerHTML]="'faqs_Data24' | i18n" [ngClass]="{'visible': isExpanded === 4}"></div>
    <div class="expanded" [innerHTML]="'faqs_Data25' | i18n" [ngClass]="{'visible': isExpanded === 4}"></div>
  </div>

  <div *ngIf="isFaqsActive" class="flex">
    <div class="line"></div>
    <div class="question">
      <div [innerHTML]="((isPC) ? 'faqs_Data10' : 'faqs_Data10v2') | i18n" (click)="expand(6)"></div>
      <img class="arrow" src="/assets/images/faqs/arrow.svg" alt="Flecha" (click)="expand(6)"
        [ngClass]="{'arrow-rotated': isExpanded === 6}">
      <div class="expanded" [innerHTML]="'faqs_Data26' | i18n" [ngClass]="{'visible': isExpanded === 6}"></div>
      <div class="expanded" [innerHTML]="'faqs_Data27' | i18n" [ngClass]="{'visible': isExpanded === 6}"></div>
    </div>
    <div class="line"></div>
  </div>
  <div class="goto-youtube-container" *ngIf="isFaqsActive">
    <div class="first-column">
      <h3 [innerHTML]="'faqs_Data17' | i18n"></h3>
      <div class="info" [innerHTML]="'faqs_Data18' | i18n"></div>
      <div class="img-container" *ngIf="!isLandscape">
        <iframe width="100%" src="https://www.youtube.com/embed/UEPTjx1Vrxk?autoplay=1&mute=1&loop=1" frameborder="0"
          allowfullscreen></iframe>
      </div>
      <a href="https://www.youtube.com/channel/UCmKCyehTYjj5L7oesp4xokw" target="_blank" class="link-goto-ytb">
        <button class="goto-youtube" [innerHTML]="'faqs_Data19' | i18n"></button>
      </a>
    </div>
    <div class="img-container" *ngIf="isLandscape">
      <iframe width="100%" height="365px" src="https://www.youtube.com/embed/UEPTjx1Vrxk?autoplay=1&mute=1&loop=1"
        frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
  <div *ngIf="isFaqsActive" class="flex">
    <div class="line"></div>
    <div class="question">
      <div [innerHTML]="'faqs_Data11' | i18n" (click)="expand(7)"></div>
      <img class="arrow" src="/assets/images/faqs/arrow.svg" alt="Flecha" (click)="expand(7)"
        [ngClass]="{'arrow-rotated': isExpanded === 7}">
      <div class="expanded" [innerHTML]="'faqs_Data28' | i18n" [ngClass]="{'visible': isExpanded === 7}"></div>
      <div class="expanded" [innerHTML]="'faqs_Data29' | i18n" [ngClass]="{'visible': isExpanded === 7}"></div>
    </div>
    <div class="line"></div>
    <div class="question">
      <div [innerHTML]="'faqs_Data12' | i18n" (click)="expand(8)"></div>
      <img class="arrow" src="/assets/images/faqs/arrow.svg" alt="Flecha" (click)="expand(8)"
        [ngClass]="{'arrow-rotated': isExpanded === 8}">
      <div class="expanded" [innerHTML]="'faqs_Data30' | i18n" [ngClass]="{'visible': isExpanded === 8}"></div>
      <a target="_self" [href]="getRouterLink('Rates')">
        <div class="expanded link" [innerHTML]="'faqs_Data31' | i18n" [ngClass]="{'visible': isExpanded === 8}"></div>
      </a>
    </div>
    <div class="line"></div>
    <div class="question">
      <div [innerHTML]="'faqs_Data13' | i18n" (click)="expand(9)"></div>
      <img class="arrow" src="/assets/images/faqs/arrow.svg" alt="Flecha" (click)="expand(9)"
        [ngClass]="{'arrow-rotated': isExpanded === 9}">
      <div class="expanded" [innerHTML]="'faqs_Data32' | i18n" [ngClass]="{'visible': isExpanded === 9}"></div>
      <a target="_self" [href]="standardRouterLink">
        <div class="expanded link" [innerHTML]="'faqs_Data33' | i18n" [ngClass]="{'visible': isExpanded === 9}"></div>
      </a>
    </div>
  </div>

  <div class="line" *ngIf="!isFaqsActive"></div>
  <div class="question" *ngIf="!isFaqsActive">
    <div [innerHTML]="'faqs_Data6' | i18n" (click)="expand(5)"></div>
    <img class="arrow" src="/assets/images/faqs/arrow.svg" alt="Flecha" (click)="expand(5)"
      [ngClass]="{'arrow-rotated': isExpanded === 5}">
    <div class="expanded" [innerHTML]="'faqs_Data26' | i18n" [ngClass]="{'visible': isExpanded === 5}"></div>
  </div>
  <div class="line"></div>
  <a target="_self" [href]="getRouterLink('Faq')">
    <button class="goto-faqs" [innerHTML]="((!isPC) ? 'faqs_Data7' : 'faqs_Data8') | i18n"
      *ngIf="!isFaqsActive"></button>
  </a>
</div>