import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaConfig } from './meta-config';
import { I18nService } from './i18n.service';
import { TranslationModeEnum } from '../enums/translation-mode-enum';
import { METADATA } from 'src/app/blog/components/blog-post/metadata-blogs/metdata';
import { ToolGroupsEnum } from 'src/app/help-center/enums/help-center-tool-groups-enum';
import { ToolsEnum } from 'src/app/help-center/enums/help-center-tools-enum';

@Injectable({
  providedIn: 'root',
})
export class UpdateMetaService {
  constructor(
    private meta: Meta,
    private title: Title,
    private i18nService: I18nService
  ) { }

  updateMetaTags(componentKey: string): void {
    const config = MetaConfig[componentKey];
    if (config) {
      const titleKey = this.i18nService.getText(
        config.title,
        TranslationModeEnum.PLACEHOLDER
      );
      const descriptionKey = this.i18nService.getText(
        config.description,
        TranslationModeEnum.PLACEHOLDER
      );
      const robotsKey = this.i18nService.getText(
        config.robots,
        TranslationModeEnum.PLACEHOLDER
      );

      if (titleKey !== '--') {
        this.title.setTitle(titleKey);
      }
      if (descriptionKey !== '--') {
        this.meta.updateTag({ name: 'description', content: descriptionKey });
      }
      if (robotsKey !== '--') {
        this.meta.updateTag({ name: 'robots', content: robotsKey });
      }
    } else {
    }
  }

  updateBlogTags(post) {
    this.title.setTitle(METADATA[post]?.TITLE);
    this.meta.updateTag({
      name: 'description',
      content: METADATA[post]?.DESCRIPTION,
    });
    this.meta.updateTag({
      property: 'og:title',
      content: METADATA[post]?.TITLE,
    });
    this.meta.updateTag({
      property: 'og:description',
      content: METADATA[post]?.DESCRIPTION,
    });
    // this.meta.updateTag({ property: 'og:url', content: `https://ecapturedtech.com/blog/${post.url}` });
    this.meta.updateTag({
      property: 'og:image',
      content: METADATA[post]?.IMAGEN,
    });
    this.meta.updateTag({ property: 'og:type', content: 'article' });
    // this.meta.updateTag({ property: 'og:site_name', content: 'eyesCloud3D' });
  }

  removeBlogTags() {
    this.meta.removeTag("property='og:title'");
    this.meta.removeTag("property='og:description'");
    this.meta.removeTag("property='og:url'");
    this.meta.removeTag("property='og:image'");
    this.meta.removeTag("property='og:type'");
    this.meta.removeTag("property='og:site_name'");
  }

  /**
   * Actualiza los metadatos en función de cada grupo y herramienta.
   * @param group
   * @param tool
   * @param subgroup
   */
  updateHelpCenterToolMeta(group: ToolGroupsEnum, tool: ToolsEnum, subgroup: ToolsEnum): void {
    let key;

    switch (group) {
      case ToolGroupsEnum.STANDARD:
        key = this.obtainStandardToolKey(tool, subgroup);
        break;
      case ToolGroupsEnum.ADVANCED:
        key = this.obtainAdvancedToolKey(tool, subgroup);
        break;
      case ToolGroupsEnum.SECURITY:
        key = this.obtainSecurityToolKey(tool);
        break;
      case ToolGroupsEnum.ENGINEERING:
        key = this.obtainEngineeringToolKey(tool);
        break;
      case ToolGroupsEnum.AGRICULTURE:
        key = this.obtainAgricultureToolKey(tool);
        break;
    }

    this.updateMetaTags(key);
  }

  /**
   * Obtiene la key en función de la herramienta del grupo de herramientas estándar
   * @param tool
   * @param subgroup
   * @returns
   */
  obtainStandardToolKey(tool: ToolsEnum, subgroup: ToolsEnum): string {
    switch (subgroup) {
      case ToolsEnum.FUNCTIONALITIES:
        switch (tool) {
          case ToolsEnum.DOWNLOADS:
            return 'HCLibraryStandardFeature';
          case ToolsEnum.INTERACTION:
            return 'HCLibraryStandardInteractionFeature';
          case ToolsEnum.PERSONALIZATION:
            return 'HCLibraryStandardCustomizationFeature';
        }
        return 'HCLibraryStandardFeature';
      default:
        switch (tool) {
          case ToolsEnum.MEASURE:
            return 'HCLibraryStandard';
          case ToolsEnum.Z_AXIS:
            return 'HCLibraryStandardZAxis';
          case ToolsEnum.NOTES:
            return 'HCLibraryStandardNotes';
          case ToolsEnum.CLIPPING:
            return 'HCLibraryStandardCrop';
          case ToolsEnum.REDUCED_VIRTUAL_TOUR:
            return 'HCLibraryStandardReducedVirtualTour';
          case ToolsEnum.INITIAL_POSITION:
            return 'HCLibraryStandardInitialPosition';
        }
        return 'HCLibraryStandard';
    }
  }

  /**
   * Obtiene la key en función de la herramienta del grupo de herramientas avanzadas
   * @param tool
   * @param subgroup
   * @returns
   */
  obtainAdvancedToolKey(tool: ToolsEnum, subgroup: ToolsEnum): string {
    switch (subgroup) {
      case ToolsEnum.MEASURE_COLLECTION:
        switch (tool) {
          case ToolsEnum.HEIGHT:
            return 'HCLibraryAdvancedHeight';
          case ToolsEnum.AREA:
            return 'HCLibraryAdvancedArea';
          case ToolsEnum.VOLUME:
            return 'HCLibraryAdvancedVolume';
          case ToolsEnum.SURFACE:
            return 'HCLibraryAdvancedSurfaces';
          case ToolsEnum.MEASURE_MULTIPLE:
            return 'HCLibraryAdvancedMultiple';
          case ToolsEnum.CONTOUR:
            return 'HCLibraryAdvancedOutline';
          case ToolsEnum.ANGLE:
            return 'HCLibraryAdvancedAngle';
        }
        return 'HCLibraryAdvanced';
      case ToolsEnum.SCALE_COLLECTION:
        switch (tool) {
          case ToolsEnum.SCALE:
            return 'HCLibraryScale';
          case ToolsEnum.COORDINATE:
            return 'HCLibraryScaleCoordinate';
          case ToolsEnum.REFERENCE_SYSTEM:
            return 'HCLibraryScaleReferenceSystem';
          case ToolsEnum.GEOREFERENCE_SYSTEM:
            return 'HCLibraryScaleGeoreferenceSystem';
          case ToolsEnum.FLOOR:
            return 'HCLibraryScaleGround';
          case ToolsEnum.Z_AXIS:
            return 'HCLibraryScaleZAxis';
        }
        return 'HCLibraryScale';
      case ToolsEnum.MODIFY_COLLECTION:
        switch (tool) {
          case ToolsEnum.NOTES:
            return 'HCLibraryModification';
          case ToolsEnum.CLIPPING:
            return 'HCLibraryModificationCrop';
          case ToolsEnum.MODEL_JOIN:
            return 'HCLibraryModificationMerge';
          case ToolsEnum.OBJECT_SELECTION:
            return 'HCLibraryModificationSelection';
          case ToolsEnum.SECTION:
            return 'HCLibraryModificationSection';
        }
        return 'HCLibraryModification';
      case ToolsEnum.VIEW_COLLECTION:
        switch (tool) {
          case ToolsEnum.PROJECTION:
            return 'HCLibraryView';
          case ToolsEnum.ELEVATION_MAP:
            return 'HCLibraryViewLevelMap';
          case ToolsEnum.VERTEX_POINTS:
            return 'HCLibraryViewVertex';
          case ToolsEnum.VIRTUAL_TOUR:
            return 'HCLibraryViewVirtualTour';
          case ToolsEnum.FIRST_PERSON:
            return 'HCLibraryViewFP';
        }
        return 'HCLibraryView';
      case ToolsEnum.MOVEMENT_COLLECTION:
        switch (tool) {
          case ToolsEnum.ROTATION_CENTER:
            return 'HCLibraryMovement';
          case ToolsEnum.INITIAL_POSITION:
            return 'HCLibraryMovementPosition';
        }
        return 'HCLibraryMovement';
      case ToolsEnum.IOTA_COLLECTION:
        switch (tool) {
          case ToolsEnum.IOTA:
            return 'HCLibraryViewIOTA';
        }
        return 'HCLibraryViewIOTA';
      case ToolsEnum.MULTICAM_COLLECTION:
        switch (tool) {
          case ToolsEnum.MULTICAM:
            return 'HCLibraryMovementMulticam';
        }
        return 'HCLibraryMovementMulticam';
      case ToolsEnum.MAP_COLLECTION:
        switch (tool) {
          case ToolsEnum.MULTICAM:
            return 'HCLibraryMovementMap';
        }
        return 'HCLibraryMovementMap';
    }
    return 'HCLibraryAdvancedMeasurement';
  }

  /**
   * Obtiene la key en función de la herramienta del grupo de addons seguridad
   * @param tool
   * @returns
   */
  obtainSecurityToolKey(tool: ToolsEnum): string {
    switch (tool) {
      case ToolsEnum.DEFORMATION_ENERGY:
        return 'HCLibrarySecurity';
      case ToolsEnum.THREE_D_PROJECTION_S:
        return 'HCLibrarySecurity3DProjection';
      case ToolsEnum.BULLET_PATH:
        return 'HCLibrarySecurityBullet';
      case ToolsEnum.BLOOD_SPLATTER:
        return 'HCLibrarySecurityBloodSpatter';
      case ToolsEnum.THREE_D_MATCHING_S:
        return 'HCLibrarySecurity3DMatching';
      case ToolsEnum.THICKNESS_S:
        return 'HCLibrarySecurityThickness';
      case ToolsEnum.SENSORIC_S:
        return 'HCLibrarySecuritySensory';
      case ToolsEnum.INTERIOR_BLUEPRINTS_S:
        return 'HCLibrarySecurityInteriorBlueprint';
      case ToolsEnum.EXTERIOR_INTERIOR_S:
        return 'HCLibrarySecurityInteriorExterior';
      case ToolsEnum.OFFLINE_VIEWER_S:
        return 'HCLibrarySecurityOfflineViewer';
      case ToolsEnum.HYPERSPECTRAL_S:
        return 'HCLibrarySecurityHyper';
      case ToolsEnum.SEGMENTATION_S:
        return 'HCLibrarySecuritySegmentation';
    }
    return 'HCLibrarySecurity';
  }

  /**
   * Obtiene la key en función de la herramienta del grupo de addons ingeniería
   * @param tool
   * @returns
   */
  obtainEngineeringToolKey(tool: ToolsEnum): string {
    switch (tool) {
      case ToolsEnum.IFC:
        return 'HCLibraryEngineering';
      case ToolsEnum.THREE_D_PROJECTION_E:
        return 'HCLibraryEngineering3DProjection';
      case ToolsEnum.VEGETATION_CALCULATION_E:
        return 'HCLibraryEngineeringVegetation';
      case ToolsEnum.THREE_D_MATCHING_E:
        return 'HCLibraryEngineering3DMatching';
      case ToolsEnum.THICKNESS_E:
        return 'HCLibraryEngineeringThickness';
      case ToolsEnum.SENSORIC_E:
        return 'HCLibraryEngineeringSensory';
      case ToolsEnum.INTERIOR_BLUEPRINTS_E:
        return 'HCLibraryEngineeringInteriorBlueprint';
      case ToolsEnum.EXTERIOR_INTERIOR_E:
        return 'HCLibraryEngineeringInteriorExterior';
      case ToolsEnum.OFFLINE_VIEWER_E:
        return 'HCLibraryEngineeringOfflineViewer';
      case ToolsEnum.HYPERSPECTRAL_E:
        return 'HCLibraryEngineeringHyper';
      case ToolsEnum.SEGMENTATION_E:
        return 'HCLibraryEngineeringSegmentation';
    }
    return 'HCLibraryEngineering';
  }

  /**
   * Obtiene la key en función de la herramienta del grupo de addons agricultura
   * @param tool
   * @returns
   */
  obtainAgricultureToolKey(tool: ToolsEnum): string {
    switch (tool) {
      case ToolsEnum.VEGETATION_CALCULATION_A:
        return 'HCLibraryAgriculture';
      case ToolsEnum.EXTERIOR_INTERIOR_A:
        return 'HCLibraryAgricultureInteriorExterior';
      case ToolsEnum.OFFLINE_VIEWER_A:
        return 'HCLibraryAgricultureOfflineViewer';
      case ToolsEnum.THICKNESS_A:
        return 'HCLibraryAgricultureThickness';
    }
    return 'HCLibraryAgriculture';
  }
}
