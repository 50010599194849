import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestClientService } from 'src/app/commons/services/rest-client.service';

@Injectable({ providedIn: 'root' })
export class ForgotPasswordRemoteService {
  private URL = '/forgot-password';

  constructor(private restClientService: RestClientService) { }

  forgotPassword(login): Observable<any> {
    return this.restClientService.post(`${this.URL}/email`, login);
  }

  resetPassword(parameters): Observable<any> {
    return this.restClientService.post(`${this.URL}/reset`, parameters);
  }
}
