import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AdminTokenStorage } from './admin-token-storage';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  isAuthenticated: boolean = false;
  localStorageLoggedIn: any = '';
  constructor(
    private router: Router,
    private adminTokenStorage: AdminTokenStorage<string>
  ) {
  }

  canActivate(): boolean {
    if (this.adminTokenStorage.getObjectValue() !== null) {
      return true; // El usuario puede acceder a la ruta protegida
    } else {
      this.router.navigate(['/admin/login']); // Redirige al usuario a la página de inicio de sesión
      return false; // El usuario no puede acceder a la ruta protegida
    }
  }
}
