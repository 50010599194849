export class Blog {
  id: number | undefined;
  platform_id: number | undefined;
  title: string | undefined;
  metatitle: string | undefined;
  metadescription: string | undefined;
  url: string | undefined;
  subtitle: string | undefined;
  description: string | undefined;
  created_at: Date | undefined;
  updated_at: Date | undefined;
  imgMovil: any;
  imgPC: any;
  imgDestacada: any;
  body: any;
  categories: number[] | undefined;
  is_fav: boolean | undefined;
  is_published: boolean;
  languages_id: any;
  publico: boolean;
}
