import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterData } from '../../models/register-data';
import { RegisterService } from '../../services/register.service';
import { GenerateRouteService } from 'src/app/commons/services/generate-route.service';
import { UpdateMetaService } from 'src/app/commons/services/meta-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { updateMetaLinks } from 'src/app/commons/services/updateMetaLinks.service';
@Component({
  selector: 'app-android-register',
  templateUrl: './android-register.component.html',
  styleUrl: './android-register.component.scss',
})
export class AndroidRegisterComponent implements OnInit {
  translationModes: any = TranslationModeEnum;
  showPassword = [false, false];
  formGroup: FormGroup;
  submitted: boolean = false;
  intSicurIntMwc = 0;
  promotion: string;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private registerService: RegisterService,
    private generateRouteService: GenerateRouteService,
    private updateMetaService: UpdateMetaService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private updateMetaLinks: updateMetaLinks
  ) { }

  ngOnInit(): void {
    this.updateMetaLinks.update('registro');
    this.promotion = this.activatedRoute.snapshot.paramMap.get('promotion');
    this.createForm();
    this.updateMetaService.updateMetaTags('AndroidRegister');
    if (this.router.url.includes('campaign-sicur')) {
      this.intSicurIntMwc = 1;
    } else if (this.router.url.includes('campaign-mwc')) {
      this.intSicurIntMwc = 2;
    }
  }
  public loginWithGoogle(): void {
    (
      document.querySelector(
        '#real-google-button > div > div > div'
      ) as HTMLButtonElement
    ).click();
  }
  onSubmit(): void {
    this.submitted = true;
    if (
      this.formGroup.valid &&
      this.formGroup.controls['rememberMe'].value === true
    ) {
      this.authenticationService
        .register(
          Object.assign(this.formGroup.value as RegisterData, {
            ...(this.promotion && {
              promotion: this.promotion,
            }),
          })
        )
        .subscribe({
          next: () => {
            this.registerService.email = (
              this.formGroup.value as RegisterData
            ).email;
            this.registerService.openRegisterStepOneDialog();
          },
          error: () => { },
        });
    }
  }

  hidePassword(index: number): void {
    this.showPassword[index] = !this.showPassword[index];
  }

  private createForm() {
    this.formGroup = this.formBuilder.group(
      {
        profile: this.formBuilder.group({
          name: ['', [Validators.required]],
          surname: ['', [Validators.required]],
        }),
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', [Validators.required]],
        rememberMe: [false, [Validators.required]],
      },
      {
        validator: [
          this.mustMatch('password', 'confirmPassword'),
          this.isPasswordValid(),
        ],
      }
    );
  }

  private isPasswordValid(controlName: string = 'password') {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      if (
        !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\W)|(?=.*_))^[^ ]+$/g.test(
          control.value as string
        )
      ) {
        control.setErrors({ isPasswordValid: true });
      }
    };
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  getRouterLink(routeKey: any): void {
    this.router.navigateByUrl(
      this.generateRouteService.generateRoute('android-login')
    );
  }
}
