import { CanActivateFn, Router } from '@angular/router';
import { I18nService } from '../services/i18n.service';

export const routingGuard: CanActivateFn = (route, state) => {
  const url = state.url;

  if (url.includes('/us/') || url === '/us') {
    I18nService.instance.setCurrentLanguage('en');
  } else if (url.includes('/pt/') || url === '/pt') {
    I18nService.instance.setCurrentLanguage('pt');
  } else if (url.includes('/fr/') || url === '/fr') {
    I18nService.instance.setCurrentLanguage('fr');
  } else {
    I18nService.instance.setCurrentLanguage('es');
  }

  return true;
};

