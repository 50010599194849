export const MAINTANCE_I18N_ENTRIES = {
  maintance_data1: {
    es: '<p>¡HOLA! :)</p>',
    en: '<p>Hi! :)</p>',
    pt: '<p>OLÁ! :)</p>',
    fr: '<p>Bonjour ! :-)</p>',
  },
  maintance_data2: {
    es: '<p>Gracias por visitar nuestra página.</p>',
    en: '<p>Thanks for visiting our page.</p>',
    pt: '<p>Obrigado por visitar a nossa página.</p>',
    fr: '<p>Nous vous remercions de visiter notre site web.</p>',
  },
  maintance_data3: {
    es: '<p>En este momento estamos realizando labores de mantenimiento para mejorar tu experiencia. Por favor, vuelve a visitarnos pronto para disfrutar de nuestro contenido actualizado. <span class="i18n-bold">¡Gracias por tu comprensión y paciencia!</span></p>',
    en: '<p>We are currently performing updates to improve your experience. Please come back soon to enjoy our new content. <span class="i18n-bold">Thank you for your patience and understanding!</span></p>',
    pt: '<p>Neste momento estamos a realizar trabalhos de manutenção para melhorar a sua experiência. Volte a visitar-nos em breve para desfrutar do nosso conteúdo. <span class="i18n-bold">Agradecemos a sua compreensão e paciência!</span></p>',
    fr: '<p>Nous effectuons actuellement des travaux de maintenance afin d’améliorer votre expérience utilisateur. Nous vous invitons donc à revenir bientôt pour profiter d’un contenu actualisé. <span class="i18n-bold">Nous vous remercions de votre compréhension et patience !</span></p>',
  },
};
