import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestClientService } from 'src/app/commons/services/rest-client.service';
import { AcceptedLogin } from '../models/accepted-login';
import { BaseProfile } from '../models/base-profile';

@Injectable({ providedIn: 'root' })
export class EmailVerifiedRemoteService {
  private URL = '/email-verified';

  constructor(private restClientService: RestClientService) { }

  verify<T extends BaseProfile>(token): Observable<AcceptedLogin<T>> {
    return this.restClientService.get(`${this.URL}/${token}`);
  }
}
