import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguagesEnum } from 'src/app/commons/enums/languages-enum';
import { PlatformsEnum } from 'src/app/commons/enums/platforms-enum';
import { Blog } from 'src/app/commons/models/blog';
import { BlogService } from 'src/app/commons/services/blog.service';
import { GenerateRouteService } from 'src/app/commons/services/generate-route.service';
import { I18nService } from 'src/app/commons/services/i18n.service';
import { UpdateMetaService } from 'src/app/commons/services/meta-service.service';
import { ResponsiveService } from 'src/app/commons/services/responsive.service';
import { WebpConverterService } from 'src/app/commons/services/webpConverter.service';
import { WelcomeService } from 'src/app/commons/services/welcome.service';
import { WindowService } from 'src/app/commons/services/window.service';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss'],
})
export class BlogPostComponent implements OnInit, OnDestroy {
  post: any;
  url: any;
  categorias: string[] = [];
  imgMovil: any;
  imgPC: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private blogService: BlogService,
    private welcomeService: WelcomeService,
    private responsiveService: ResponsiveService,
    private generateRouteService: GenerateRouteService,
    private meta: Meta,
    private title: Title,
    private renderer: Renderer2,
    private windowService: WindowService,
    private i18nService: I18nService,
    private metaService: UpdateMetaService,
    private webpConverterSerivce: WebpConverterService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.url = params['id'];
      this.metaService.updateBlogTags(this.url);
      this.blogService.getByUrl(this.url).subscribe(
        (blog: Blog) => {
          if (blog.platform_id != PlatformsEnum.EYESCLOUD3D) {
            this.getRouterLink('Blog');
          }
          this.post = blog;
          this.webpConverterSerivce
            .toWebP(this.post.imgMovil)
            .then((webpData) => {
              this.imgMovil = webpData;
            })
            .catch((error) => {});

          this.webpConverterSerivce
            .toWebP(this.post.imgPC)
            .then((webpData) => {
              this.imgPC = webpData;
            })
            .catch((error) => {
              console.error(error);
            });
          let salir = true;
          let language = 'es';
          switch (this.post.languages_id) {
            case 1:
              language = 'es';
              break;
            case 2:
              language = 'en';
              break;
            case 3:
              language = 'pt';
              break;
            case 4:
              language = 'fr';
              break;
          }

          if (language === this.i18nService.getCurrentLanguage().code) {
            salir = false;
          }

          if (this.windowService.isBrowser && salir) {
            this.getRouterLink('Blog');
          }

          if (this.post.schema != null) {
            // Dividir el string usando el símbolo "}"
            var stringsSeparados = this.post.schema.split('*');

            // Eliminar el último elemento que está vacío o tiene solo "{" al final
            if (stringsSeparados[stringsSeparados.length - 1] === '') {
              stringsSeparados.pop();
            }

            // Agregar nuevamente el "}" a cada string, excepto el último
            for (var i = 0; i < stringsSeparados.length - 1; i++) {
              stringsSeparados[i] += '}';
            }
            for (var i = 0; i < stringsSeparados.length; i++) {
              const script = this.renderer.createElement('script');
              script.type = 'application/ld+json';
              script.text = stringsSeparados[i];
              if (this.windowService.isBrowser) {
                this.renderer.appendChild(document.head, script);
              }
            }
          }
          console.log(this.post.categories);
          this.post.categories.forEach((category: any) => {
            this.categorias.push(`category_data${category - 8}`);
          });
        },
        (error) => {
          // this.router.navigateByUrl(
          //   this.generateRouteService.generateRoute('Blog')
          // );
        }
      );
    });
    this.welcomeService.getShowWelcome().subscribe((response: any) => {
      if (response) {
        this.welcomeService.setShowWelcome();
      }
    });

    if (this.windowService.isBrowser) {
      var currentURL = window.location.href;
      //Twitter
      (document.getElementById('tweetLink') as HTMLAnchorElement).href =
        'https://twitter.com/intent/tweet?text=' +
        encodeURIComponent(currentURL);
      //FB
      (document.getElementById('facebookLink') as HTMLAnchorElement).href =
        'https://www.facebook.com/sharer/sharer.php?u=' +
        encodeURIComponent(currentURL);
      //LinkedIn
      (document.getElementById('linkedinLink') as HTMLAnchorElement).href =
        'https://www.linkedin.com/sharing/share-offsite/?url=' +
        encodeURIComponent(currentURL);
    }
  }

  /**
   * Comprueba si la resolución actual de la ventana es menor o igual a 834 píxeles de ancho.
   * @returns {boolean} Devuelve true si la resolución es menor o igual a 834 píxeles, de lo contrario, false.
   */
  checkResolution() {
    return !this.responsiveService.isLandscape();
  }

  getRouterLink(routeKey: any): void {
    this.router.navigateByUrl(
      this.generateRouteService.generateRoute(routeKey)
    );
  }

  ngOnDestroy(): void {
    // this.metaService.removeBlogTags();
  }
}
