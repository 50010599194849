import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SelectPreferenceResponses } from 'src/app/auth/models/two-factor/enums/select-preference-response';
import { TwoFactorRemoteService } from 'src/app/auth/services/two-factor-remote.service';

@Component({
  selector: 'app-select-preference-dialog',
  templateUrl: './select-preference-dialog.component.html',
  styleUrls: ['./select-preference-dialog.component.scss'],
})
export class SelectPreferenceDialogComponent implements OnDestroy {
  responses: typeof SelectPreferenceResponses;

  subscriptions: Subscription[];

  constructor(
    private dialogRef: MatDialogRef<SelectPreferenceDialogComponent>,
    private twoFactorRemoteService: TwoFactorRemoteService
  ) {
    this.responses = SelectPreferenceResponses;
    this.subscriptions = [];
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public closeDialog(
    response: SelectPreferenceResponses = SelectPreferenceResponses.DEFAULT
  ): void {
    return this.dialogRef.close(response);
  }

  public useEmail() {
    this.subscriptions.push(
      this.twoFactorRemoteService.activateWithEmail().subscribe(
        () => {
          this.closeDialog(SelectPreferenceResponses.EMAIL);
        },
        (error) => {
          if (error.error === 3) {
            this.closeDialog(SelectPreferenceResponses.LIMIT);
          }
        }
      )
    );
  }
}
