<div class="language-selector">
  <mat-select placeholder="{{ getSelectedLanguageLabel() | i18n: translationModes.PLACEHOLDER }}" [(ngModel)]="selectedLanguage"
  (ngModelChange)="setCurrentLanguage($event)" panelClass="language-selector-panel" disableOptionCentering
  [ngClass]="{'select-open': panelIsOpen}" (openedChange)="handleOpenChange()">
    <mat-option *ngIf="selectedLanguage !== 'es'" value="es" (mousedown)="preventDrag($event, 'es')"
    (touchstart)="preventDrag($event, 'es')">{{'language_selector_Data2' | i18n: translationModes.PLACEHOLDER}}</mat-option>
    <mat-option *ngIf="selectedLanguage !== 'en'" value="en" (mousedown)="preventDrag($event, 'en')"
    (touchstart)="preventDrag($event, 'en')">{{'language_selector_Data3' | i18n: translationModes.PLACEHOLDER}}</mat-option>
    <mat-option *ngIf="selectedLanguage !== 'pt'" value="pt" (mousedown)="preventDrag($event, 'pt')"
    (touchstart)="preventDrag($event, 'pt')">{{'language_selector_Data4' | i18n: translationModes.PLACEHOLDER}}</mat-option>
    <mat-option *ngIf="selectedLanguage !== 'fr'" value="fr" (mousedown)="preventDrag($event, 'fr')"
    (touchstart)="preventDrag($event, 'fr')">{{'language_selector_Data5' | i18n: translationModes.PLACEHOLDER}}</mat-option>
    <!-- <mat-option value="eus">{{'language_selector_Data6' | i18n: translationModes.PLACEHOLDER}}</mat-option>
    <mat-option value="cat">{{'language_selector_Data7' | i18n: translationModes.PLACEHOLDER}}</mat-option> -->
  </mat-select>
</div>
