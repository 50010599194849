import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Blog } from '../models/blog';
import { RestClientService } from './rest-client.service';
import { EndPointEnum } from '../enums/end-point';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  public static scope: BlogService;
  private URL = '/blog';
  constructor(private restClientService: RestClientService) {
    BlogService.scope = this;
  }

  public create(data: FormData): Observable<any> {
    return this.restClientService.post(`${this.URL}`, data, EndPointEnum.BLOG);
  }

  get(id: number): Observable<Blog> {
    return this.restClientService.get(
      `${this.URL}/item/${id}`,
      null,
      EndPointEnum.BLOG
    );
  }

  getAll(platformId?: number): Observable<Blog[]> {
    const url = platformId
      ? `${this.URL}/all/${platformId}`
      : `${this.URL}/all`;
    return this.restClientService.get<Blog[]>(url, null, EndPointEnum.BLOG);
  }

  public update(data: FormData, id: number): Observable<any> {
    return this.restClientService.post(
      `${this.URL}/update/${id}`,
      data,
      EndPointEnum.BLOG
    );
  }

  public delete(id: number): Observable<any> {
    return this.restClientService.delete(
      `${this.URL}/${id}`,
      null,
      EndPointEnum.BLOG
    );
  }

  getByUrl(url: string): Observable<Blog> {
    return this.restClientService.get(
      `${this.URL}/items/${url}`,
      null,
      EndPointEnum.BLOG
    );
  }

  getByPlatform(): Observable<Blog[]> {
    return this.restClientService.get<Blog[]>(
      `${this.URL}/all`,
      null,
      EndPointEnum.BLOG
    );
  }

  updateFavorite(id: number, isFav: boolean, platform_id): Observable<any> {
    return this.restClientService.put(
      `${this.URL}/update-fav/${id}`,
      { is_fav: isFav, platform_id: platform_id },
      EndPointEnum.BLOG
    );
  }

  makePublic(id: number, isPublic: boolean): Observable<any> {
    return this.restClientService.put(
      `${this.URL}/makePublic/${id}`,
      { publico: isPublic },
      EndPointEnum.BLOG
    );
  }
  getRelatedBlogs(id: any): any {
    return this.restClientService.get(`${this.URL}/relatedBlogs/${id}`);
  }
}
