import { Component, ElementRef, HostListener, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ResponsiveService } from 'src/app/commons/services/responsive.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { I18nService } from '../../services/i18n.service';
import { GenerateRouteService } from '../../services/generate-route.service';
import { Meta } from '@angular/platform-browser';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  isTablet: boolean = this.responsiveService.isTablet();
  isPC: boolean = this.responsiveService.isPC();
  isMenuOpen: boolean = false;
  private routerSubscription!: Subscription;

  @ViewChild('menuOpenDiv') menuOpenDiv!: ElementRef;
  @ViewChild('logo') logoElement!: ElementRef;

  constructor(
    private responsiveService: ResponsiveService,
    private router: Router,
    private generateRouteService: GenerateRouteService,
    private gtmService: GoogleTagManagerService,
  ) { }

  ngOnInit(): void {
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.isMenuOpen) {
          this.isMenuOpen = false;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])

  onOutsideInteraction(event: Event) {
    if (this.isMenuOpen && this.menuOpenDiv && !this.menuOpenDiv.nativeElement.contains(event.target)) {
      this.isMenuOpen = false;
    }
  }

  onResize(): void {
    this.isTablet = this.responsiveService.isTablet();
    this.isPC = this.responsiveService.isPC();
    if (this.isPC) {
      this.isMenuOpen = false;
    }
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  scroll(): void {
    if (this.logoElement) {
      this.logoElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getRouterLink(routeKey: any): string {
    return this.generateRouteService.generateRoute(routeKey);
  }


  sendGtmTag(origin: any) {
    const gtmTag = {
      event: 'button-click',
      class: origin,
    };
    this.gtmService.pushTag(gtmTag);
  }
}
