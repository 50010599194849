<div class="container">
  <h1>Panel de administración</h1>
  <a target="_self" class="btn btn-primary float-right" href="/admin/blog/new">CREAR BLOG</a>
  <div class="btn logout" (click)="logout()">CERRAR SESIÓN</div>

  <div class="posts">
    <table>
      <thead>
        <tr class="table100-head">
          <th class="column1">Título</th>
          <th class="column2 order" (click)="toggleOrderBy()">Fecha creación<span>Ordenar por fecha</span></th>
          <th class="column3 order2" (click)="toggleOrderByUpdated()">Fecha modificación<span>Ordenar por fecha</span>
          </th>
          <th class="column3">Destacado</th>
          <th class="column3">Plataforma</th>
          <th class="column3">Estado</th>
          <th class="column3">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let post of posts">
          <td class="title">{{post.title}}</td>
          <td class="date">{{post.created_at | date:'dd/MM/yyyy HH:mm:ss'}}</td>
          <td class="date">{{post.updated_at | date:'dd/MM/yyyy HH:mm:ss'}}</td>
          <td class="first-article">
            <input type="checkbox" name="favArticle{{post.id}}" id="favArticle{{post.id}}" [(ngModel)]="post.is_fav"
              (change)="confirmMarkAsFav($event, post.id)">
          </td>
          <td class="platform">{{ platformNames[post.platform_id] }}</td>
          <td class="published" [ngClass]="{'temporary': !post.is_published}">{{ post.is_published ? 'Publicado' :
            'Borrador' }}</td>
          <td class="buttons-container">
            <a target="_self" class="btn edit" href="/admin/blog/{{post.id}}">
              <img src="/assets/images/admin/edit.svg">
            </a>
            <button class="btn delete" (click)="onDelete(post.id)">
              <img src="/assets/images/admin/delete.svg">
            </button>
            <a target="_self" class="btn edit preview" href="/admin/blog/preview/{{post.url}}"
              *ngIf="post.platform_id==2">Preview</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>