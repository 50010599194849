import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResponsiveService } from '../../services/responsive.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GenerateRouteService } from '../../services/generate-route.service';
import { TranslationModeEnum } from '../../enums/translation-mode-enum';
import { NewsLetterService } from '../../services/newsletter.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  newsletterEmail: string = '';
  isTosChecked: boolean = false;
  isMailTosChecked: boolean = false;
  subscriptionSent: boolean = false;
  alreadySubscribed: boolean = false;

  isWhiteBgActive: boolean = false;

  isLandscape: boolean = this.responsiveService.isLandscape();

  routeSubscription!: Subscription;

  translationModes: any = TranslationModeEnum;

  constructor(
    private responsiveService: ResponsiveService,
    private router: Router,
    private generateRouteService: GenerateRouteService,
    private newsletterService: NewsLetterService
  ) { }

  ngOnInit(): void {
    this.isWhiteBgActive =
      this.router.url.includes(this.getRouterLink('Register')) ||
      this.router.url.includes(this.getRouterLink('Login')) ||
      this.router.url.includes(this.getRouterLink('Contact')) ||
      this.router.url.includes('email-verified') ||
      this.router.url.includes('forgot-password');
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  onResize(): void {
    this.isLandscape = this.responsiveService.isLandscape();
  }

  getRouterLink(routeKey: any): string {
    return this.generateRouteService.generateRoute(routeKey);
  }

  onSubmit(): void {
    this.alreadySubscribed = false;
    this.newsletterService.create(this.newsletterEmail).subscribe((res) => {
      if (res) {
        this.subscriptionSent = true;
      } else {
        this.alreadySubscribed = true;
      }
    });
  }

  subscribeAgain(): void {
    this.newsletterEmail = '';
    this.isTosChecked = false;
    this.isMailTosChecked = false;
    this.subscriptionSent = false;
    this.alreadySubscribed = false;
  }

  isValidEmail(): boolean {
    const emailPattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return emailPattern.test(this.newsletterEmail);
  }
}
