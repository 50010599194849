export enum ComponentsEnum {
  START,
  GENERATE_MODEL,
  LANDING_VIDEO,
  THREE_STEPS,
  EXPLORE_MODELS,
  LANDING_RATES,
  FAQS,
  LAST_BLOGS,
  RATES_INFO,
  HC_LIBRARY,
  CONTACT
}
