import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';
import { ResponsiveService } from 'src/app/commons/services/responsive.service';
import { WindowService } from 'src/app/commons/services/window.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-explore-models',
  templateUrl: './explore-models.component.html',
  styleUrls: ['./explore-models.component.scss'],
})
export class ExploreModelsComponent implements OnInit, OnDestroy {
  items = [
    {
      imageUrl: '/assets/images/explore-models/accidente.webp',
      alt: 'explore_models_Data8',
      modelUrl: `${environment.appUrl}/model/728349b5d30a5d51b27ef746d9660a6dfeb201ff`,
    },
    {
      imageUrl: '/assets/images/explore-models/choque.webp',
      alt: 'explore_models_Data9',
      modelUrl: `${environment.appUrl}/model/e04b7a09c69c62096ebf21bceaed6f9c915661e5`,
    },
    {
      imageUrl: '/assets/images/explore-models/crimen.webp',
      alt: 'explore_models_Data10',
      modelUrl: `${environment.appUrl}/model/c9216215f3213f83a946b6cb1ebaf68f261f129c`,
    },
    {
      imageUrl: '/assets/images/explore-models/apple.webp',
      alt: 'explore_models_Data11',
      modelUrl: `${environment.appUrl}/model/1eb27e4b31dd4d66812fa497ba762dd1`,
    },
  ];

  isTablet: boolean = this.responsiveService.isTablet();
  isPC: boolean = this.responsiveService.isPC();
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;

  selectedSector: string = '';

  @ViewChild('gallery', { static: true }) gallery: ElementRef;
  isDown: boolean = false;
  startX: any;
  scrollLeft: any;

  constructor(
    private responsiveService: ResponsiveService,
    private windowService: WindowService
  ) { }

  ngOnInit(): void {
    const el = this.gallery.nativeElement;

    el.addEventListener('mousedown', (e) => {
      this.isDown = true;
      this.startX = e.pageX - el.offsetLeft;
      this.scrollLeft = el.scrollLeft;
    });

    el.addEventListener('mouseleave', () => {
      this.isDown = false;
    });

    el.addEventListener('mouseup', () => {
      this.isDown = false;
    });

    el.addEventListener('mousemove', (e) => {
      if (!this.isDown) return;
      e.preventDefault();
      const x = e.pageX - el.offsetLeft;
      const walk = (x - this.startX) * 3; // Velocidad del arrastre
      el.scrollLeft = this.scrollLeft - walk;
    });
  }

  ngOnDestroy(): void { }

  onResize(): void {
    this.isTablet = this.responsiveService.isTablet();
    this.isPC = this.responsiveService.isPC();
  }

  setSector(sector: string): void {
    this.selectedSector = sector;
  }

  redirectToApp(): void {
    if (this.windowService.isBrowser) {
      window.location.href = `${environment.appUrl}/new3d`;
    }
  }

  goToModel(modelUrl: string): void {
    if (this.windowService.isBrowser) {
      window.open(modelUrl, '_blank');
    }
  }
}
