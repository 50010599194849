export const CATEGORY_EYESCLOUD_I18N_ENTRIES = {
  category_data1: {
    es: '<p>Empresa</p>',
    en: '<p>Company</p>',
    pt: '<p>Empresa</p>',
    fr: '<p>Entreprise</p>', 
  },
  category_data2: {
    es: '<p>Modelado 3D</p>',
    en: '<p>3D Modeling</p>',
    pt: '<p>Modelação 3D</p>',
    fr: '<p>Modélisation 3D</p>', 
  },
  category_data3: {
    es: '<p>Seguridad</p>',
    en: '<p>Safety</p>',
    pt: '<p>Segurança</p>',
    fr: '<p>Sécurité</p>', 
  },
  category_data4: {
    es: '<p>Ingeniería</p>',
    en: '<p>Engineering</p>',
    pt: '<p>Engenharia</p>',
    fr: '<p>Ingénierie</p>', 
  },
 
  category_data5: {
    es: '<p>Agricultura</p>',
    en: '<p>Agriculture</p>',
    pt: '<p>Agricultura</p>',
    fr: '<p>Agriculture</p>', 
  },
 
  category_data6: {
    es: '<p>Patrimonio Histórico</p>',
    en: '<p>Historic Heritage Site</p>',
    pt: '<p>Património Histórico</p>',
    fr: '<p>Patrimoine historique</p>', 
  },

  category_data7: {
    es: '<p>Tips</p>',
    en: '<p>Tips</p>',
    pt: '<p>Dicas</p>',
    fr: '<p>Astuces</p>', 
  },
  category_data8: {
    es: '<p>Otros</p>',
    en: '<p>Other</p>',
    pt: '<p>Outros</p>',
    fr: '<p>Autres</p>', 
  },
}
