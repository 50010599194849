import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { I18nService } from './i18n.service';
import { Injectable } from '@angular/core';
import { RestClientErrorHandlerService } from './rest-client-error-handler.service';
import { StorageService } from './storage.service';
import { SecurityTokenStorage } from './security-token-storage';
import { UserToken } from './user-token';
import { environment } from 'src/environments/environment';
import { forOwn } from 'lodash';
import { DateService } from './date.service';
import { EndPointEnum } from '../enums/end-point';
import { AdminTokenStorage } from './admin-token-storage';

@Injectable({
  providedIn: 'root',
})
export class RestClientService {
  constructor(
    private httpClient: HttpClient,
    private i18nService: I18nService,
    private restClientErrorHandler: RestClientErrorHandlerService,
    private securityTokenStorage: SecurityTokenStorage<UserToken>,
    private adminTokenStorage: AdminTokenStorage<UserToken>,
    private storageService: StorageService
  ) {}

  public get<T>(
    url: string,
    params?: any,
    endPoint: EndPointEnum = EndPointEnum.DEFAULT
  ): Observable<T> {
    const completeUrl = this.getCompleteUrl(url, endPoint);
    return this.httpClient
      .get<T>(
        completeUrl,
        this.getRequestOptions(params, endPoint, this.doesRequireToken(url))
      )
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  public post<T>(
    url: string,
    data: any,
    endPoint: EndPointEnum = EndPointEnum.DEFAULT
  ): Observable<T> {
    const completeUrl = this.getCompleteUrl(url, endPoint);
    return this.httpClient
      .post<T>(
        completeUrl,
        data,
        this.getRequestOptions({}, endPoint, this.doesRequireToken(url))
      )
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  public put<T>(
    url: string,
    data: any,
    endPoint: EndPointEnum = EndPointEnum.DEFAULT
  ): Observable<T> {
    const completeUrl = this.getCompleteUrl(url, endPoint);
    return this.httpClient
      .put<T>(
        completeUrl,
        data,
        this.getRequestOptions({}, endPoint, this.doesRequireToken(url))
      )
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  public delete<T>(
    url: string,
    params?: any,
    endPoint: EndPointEnum = EndPointEnum.DEFAULT
  ): Observable<T> {
    const completeUrl = this.getCompleteUrl(url, endPoint);
    return this.httpClient
      .delete<T>(
        completeUrl,
        this.getRequestOptions(params, endPoint, this.doesRequireToken(url))
      )
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  private getCompleteUrl(url: string, endPoint: EndPointEnum): string {
    return url.length > 0 && url[0] === '/'
      ? `${
          endPoint === EndPointEnum.DEFAULT
            ? environment.apiUrl
            : environment.apiBlogUrl
        }${url}`
      : `${
          endPoint === EndPointEnum.DEFAULT
            ? environment.apiUrl
            : environment.apiBlogUrl
        }/${url}`;
  }

  private getRequestOptions(
    params: any,
    endPoint: EndPointEnum,
    forceToken: boolean = false
  ) {
    return {
      headers: this.getHttpHeaders(endPoint, forceToken),
      params: this.getHttpParams(params),
    };
  }

  public getHttpHeaders(
    endPoint: EndPointEnum,
    forceToken: boolean = false
  ): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set(
      'Accept-Language',
      this.i18nService.getCurrentLanguage().httpHeader
    );
    if (endPoint === EndPointEnum.BLOG || forceToken) {
      const acceptedLogin =
        endPoint === EndPointEnum.DEFAULT
          ? this.securityTokenStorage.getAcceptedLogin()
          : this.adminTokenStorage.getAcceptedLogin();
      if (acceptedLogin && acceptedLogin.token) {
        httpHeaders = httpHeaders.set('Authorization', acceptedLogin.token);
      }
    }
    return httpHeaders;
  }

  public getHttpHeadersExternal(httpHeaders: HttpHeaders): HttpHeaders {
    httpHeaders = httpHeaders || new HttpHeaders();
    httpHeaders = httpHeaders.set(
      'Accept-Language',
      this.i18nService.getCurrentLanguage().httpHeader
    );
    const acceptedLogin = this.storageService.get('externalToken', '');
    if (acceptedLogin !== '') {
      httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + acceptedLogin);
    }
    return httpHeaders;
  }

  private getHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();
    forOwn(params, (value, key) => {
      if (value !== undefined && value !== null) {
        if (value instanceof Date) {
          httpParams = httpParams.set(
            key,
            DateService.getIsoStringDatePart(value)
          );
        } else {
          httpParams = httpParams.set(key, value);
        }
      }
    });
    return httpParams;
  }

  private doesRequireToken(route: string) {
    return ['auth-user', 'send-email', 'check-code', 'send-sms'].some((ele) =>
      route.includes(ele)
    );
  }
}
