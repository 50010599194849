<header class="header-container" (window:resize)="onResize()" #logo>
  <div class="header" [ngClass]="{'mobile': !isPC , 'menu-open': isMenuOpen && !isPC}" #menuOpenDiv>
    <a target="_self" [href]="getRouterLink('Home')">
      <img class="logo" src="/assets/images/commons/logo.svg" alt="eyescloud3d logo" (click)="scroll()">
    </a>
    <div class="options">
      <nav class="navbar">
        <a target="_self" [href]="getRouterLink('Rates')" class="Header_interaction-click-rates">
          <div class="rates-button" [innerHTML]="'header_Data1' | i18n"></div>
        </a>
        <a target="_self" [href]="getRouterLink('HelpCenter')" class="Header_interaction-click-help-center">
          <div class="faqs-button" [innerHTML]="'header_Data7' | i18n"></div>
        </a>
        <a target="_self" [href]="getRouterLink('Blog')" class="Header_interaction-click-blog">
          <div class="blogs-button" [innerHTML]="'header_Data3' | i18n"></div>
        </a>
        <a target="_self" [href]="getRouterLink('Contact')" class="Header_interaction-click-contact">
          <div class="contact-button" [innerHTML]="'header_Data4' | i18n"></div>
        </a>
      </nav>
      <div class="header-language-selector">
        <app-language-selector></app-language-selector>
      </div>
      <div class="buttons-container">
        <a target="_self" [href]="getRouterLink('Register')" class="seo-header-btn-register"
          (click)="sendGtmTag('seo-header-btn-register')" id="Header_interaction-click-register">
          <button class="register" [innerHTML]="'header_Data5' | i18n" id="Header_interaction-click-register"></button>
        </a>
        <a target="_self" [href]="getRouterLink('Login')" class="Header_interaction-click-login"
          id="Header_interaction-click-login">
          <button class="login" [innerHTML]="'header_Data6' | i18n" id="Header_interaction-click-login"></button>
        </a>
      </div>
    </div>
    <div class="menu-icon" (click)="toggleMenu()">
      <span [ngClass]="{'first-bar': isMenuOpen}"></span>
      <span [ngClass]="{'second-bar': isMenuOpen}"></span>
      <span [ngClass]="{'third-bar': isMenuOpen}"></span>
    </div>
    <div class="social-container">
      <a href="https://www.tiktok.com/@eyescloud3d" target="_blank">
        <img src="/assets/images/commons/tiktok.svg" alt="TikTok">
      </a>
      <a href="https://twitter.com/eyesCloud3d" target="_blank">
        <img src="/assets/images/commons/x.svg" alt="X">
      </a>
      <a href="https://www.instagram.com/eyescloud3d/" target="_blank">
        <img src="/assets/images/commons/instagram.svg" alt="Instagram">
      </a>
      <a href="https://www.facebook.com/eyesCloud3d/" target="_blank">
        <img src="/assets/images/commons/facebook.svg" alt="Facebook">
      </a>
      <a href="https://www.youtube.com/channel/UCmKCyehTYjj5L7oesp4xokw" target="_blank">
        <img src="/assets/images/commons/youtube-header.svg" alt="Youtube">
      </a>
      <a href="https://es.linkedin.com/showcase/eyescloud3d/" target="_blank">
        <img src="/assets/images/commons/linkedin.svg" alt="LinkedIn">
      </a>
    </div>
  </div>
</header>