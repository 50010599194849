<div class="maintance-container">
    <div class="header">
        <img class="logo" src="/assets/images/commons/logo.svg" alt="eyescloud3d logo">
    </div>
    <div class="title">
        <p [innerHTML]="'maintance_data1' | i18n"></p>
    </div>
    <div class="sub-title">
        <p [innerHTML]="'maintance_data2' | i18n"></p>
    </div>
    <div class="maintance-logo-container">
        <img class="maintance-logo" src="/assets/images/commons/maintance-logo.svg">
        <img class="maintance-background" src="/assets/images/commons/maintance-background.svg">
    </div>
    <div class="maintance-text">
        <p [innerHTML]="'maintance_data3' | i18n"></p>
    </div>
</div>