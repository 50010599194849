import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from 'src/app/commons/services/i18n.service';
import { updateMetaLinks } from 'src/app/commons/services/updateMetaLinks.service';
import { ComponentsEnum } from 'src/app/commons/enums/components-enum';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {

  componentsEnum: typeof ComponentsEnum = ComponentsEnum;
  loadedComponents: Set<ComponentsEnum> = new Set();

  constructor(
    private router: Router,
    private i18nService: I18nService,
    private updateMetaLinks: updateMetaLinks
  ) {
    this.updateMetaLinks.update('home');
    const currentUrl = this.router.url;
    let segundaYterceraLetra = 'es';
    if (currentUrl.length >= 2) {
      segundaYterceraLetra = currentUrl[1] + currentUrl[2];
      if (segundaYterceraLetra === 'us') {
        segundaYterceraLetra = 'en';
      }
    }
    this.i18nService.setCurrentLanguage(segundaYterceraLetra);
    if (currentUrl === '/home') {
      this.router.navigate(['/']);
    }
  }

  loadComponent(componentType: ComponentsEnum): void {
    this.loadedComponents.add(componentType);
  }

  isComponentLoaded(componentType: ComponentsEnum): boolean {
    return this.loadedComponents.has(componentType);
  }
}
