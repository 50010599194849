<div class="rates-container">
  <div class="rates-container max-container">
    <h1 [innerHTML]="'rates_Data12' | i18n"></h1>
    <div class="rate-type-selector" [ngClass]="{'selector-open': panelIsOpen}">
      <mat-select placeholder="{{ getSelectedRateLabel() | i18n: translationModes.PLACEHOLDER }}"
        [(ngModel)]="selectedRateType" (ngModelChange)="setRateType($event)" panelClass="rate-selector-panel"
        disableOptionCentering (openedChange)="handleOpenChange()" [ngClass]="{'select-open': panelIsOpen}">
        <mat-option *ngFor="let option of filteredRateTypeOptions" [value]="option.value">
          {{ option.label | i18n: translationModes.PLACEHOLDER }}
        </mat-option>
      </mat-select>
    </div>
    <div class="rate-type-options">
      <div class="rate-options">
        <div [innerHTML]="'landing_rates_Data40' | i18n" class="rate-big-padding"
          [ngClass]="{'option-selected': selectedRateType === rateTypeEnum.BASIC}"
          (click)="setRateType(rateTypeEnum.BASIC)"></div>
        <div [innerHTML]="'rates_Data14' | i18n" class="rate-big-padding"
          [ngClass]="{'option-selected': selectedRateType === rateTypeEnum.PROFESSIONAL}"
          (click)="setRateType(rateTypeEnum.PROFESSIONAL)"></div>
        <div [innerHTML]="'rates_Data15' | i18n" class="rate-big-padding"
          [ngClass]="{'option-selected': selectedRateType === rateTypeEnum.COMPANY}"
          (click)="setRateType(rateTypeEnum.COMPANY)"></div>
      </div>
      <div class="rate-lines">
        <div class="full-width-line"></div>
        <div class="moving-line" [style]="{ 'left': underlinePosition }"></div>
      </div>
    </div>
    <div class="rates basic-rates" #ratesContent *ngIf="selectedRateType === rateTypeEnum.BASIC">
      <div class="rate model-download">
        <div class="title" [innerHTML]="'landing_rates_Data2' | i18n"></div>
        <div class="subtitle" [innerHTML]="'landing_rates_Data3' | i18n"></div>
        <div class="from" [innerHTML]="'landing_rates_Data35' | i18n"></div>
        <div class="price" [innerHTML]="'landing_rates_Data4' | i18n"></div>
        <div class="subprice" [innerHTML]="'landing_rates_Data5' | i18n"></div>
        <button class="purchase" [innerHTML]="'landing_rates_Data7' | i18n" (click)="goToPurchase()"></button>
      </div>

      <div class="rate model-download">
        <div class="title" [innerHTML]="'landing_rates_Data34' | i18n"></div>
        <div class="subtitle" [innerHTML]="'landing_rates_Data3' | i18n"></div>
        <div class="from" [innerHTML]="'landing_rates_Data35' | i18n"></div>
        <div class="price" [innerHTML]="'landing_rates_Data4' | i18n"></div>
        <div class="subprice" [innerHTML]="'landing_rates_Data5' | i18n"></div>
        <button class="purchase" [innerHTML]="'landing_rates_Data7' | i18n" (click)="goToPurchase()"></button>
      </div>
      <div class="rate import-download">
        <div class="title" [innerHTML]="'landing_rates_Data37' | i18n"></div>
        <div class="subtitle" [innerHTML]="'landing_rates_Data36' | i18n"></div>
        <div class="from" [innerHTML]="'landing_rates_Data35' | i18n"></div>
        <div class="price" [innerHTML]="'landing_rates_Data38' | i18n"></div>
        <div class="subprice" [innerHTML]="'landing_rates_Data16' | i18n"></div>
        <div class="description" [innerHTML]="'landing_rates_Data39' | i18n"></div>
        <button class="purchase" [innerHTML]="'landing_rates_Data32' | i18n" (click)="goToPurchase()"></button>
      </div>
    </div>

    <div class="rates professional-rates" #ratesContent *ngIf="selectedRateType === rateTypeEnum.PROFESSIONAL">
      <div class="rate hb-ha">
        <div class="title" [innerHTML]="'landing_rates_Data41' | i18n"></div>
        <div class="subtitle" [innerHTML]="'landing_rates_Data10' | i18n"></div>

        <div class="payment-option-container" (click)="swapPeriod(1)">
          <div class="selector-paymnet"
            [ngClass]="{'selector-paymnet-checked': selectedRatePeriod[1] === ratePeriodEnum.MONTHLY}"></div>
          <div class="payment-options"
            [ngClass]="{'payment-options-checked': selectedRatePeriod[1] === ratePeriodEnum.MONTHLY}">
            <div class="month-option" [innerHTML]="'rates_Data38' | i18n"></div>
            <div class="year-option" [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="plus-2-month"
              [ngClass]="{'plus-2-month-checked': selectedRatePeriod[1] === ratePeriodEnum.ANNUAL}">
              <div class="plus-2" [innerHTML]="'rates_Data40' | i18n"></div>
              <div class="free-month" [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div>

        <!-- <div class="rate-type-container" [ngClass]="{'moving': selectedRatePeriod[1] === ratePeriodEnum.MONTHLY}">
          <div class="rate-type-annual"></div>
          <div class="monthly" [innerHTML]="'landing_rates_Data11' | i18n"
            (click)="changePeriod(1, ratePeriodEnum.MONTHLY)"></div>
          <div class="rate-type-annual annual" (click)="changePeriod(1, ratePeriodEnum.ANNUAL)">
            <div [innerHTML]="'landing_rates_Data12' | i18n"></div>
            <div class="annual-discount">
              <div [innerHTML]="'landing_rates_Data13' | i18n"></div>
              <div [innerHTML]="'landing_rates_Data14' | i18n"></div>
            </div>
          </div>
        </div> -->
        <div class="flex">
          <div class="price" [innerHTML]="'landing_rates_Data15' | i18n"></div>
          <div class="subprice" [innerHTML]="'landing_rates_Data16' | i18n"></div>
        </div>
        <div class="price-description" [innerHTML]="'landing_rates_Data17' | i18n"
          [ngClass]="{'show': selectedRatePeriod[1] === ratePeriodEnum.ANNUAL}"></div>
        <div class="tools-description">
          <div class="line">
            <div [innerHTML]="'landing_rates_Data18' | i18n"></div>
            <div class="quantity" [innerHTML]="'landing_rates_Data19' | i18n"></div>
          </div>
          <div class="line limit-line">
            <span class="semibold" [innerHTML]="'landing_rates_Data20' | i18n"></span>
            <span [innerHTML]="'rates_Data25' | i18n"></span>
          </div>
          <div class="line">
            <div [innerHTML]="'landing_rates_Data22' | i18n"></div>
            <div class="quantity" [innerHTML]="'landing_rates_Data23' | i18n"></div>
          </div>
          <div class="line">
            <div [innerHTML]="'landing_rates_Data24' | i18n"></div>
            <div class="quantity" [innerHTML]="'landing_rates_Data25' | i18n"></div>
          </div>
          <div class="line limit-line">
            <div [innerHTML]="'rates_Data58' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data31' | i18n"></div>
          </div>
        </div>
        <a [href]="goToPurchase()" target="_blank">
          <button class="purchase " [innerHTML]="'landing_rates_Data26' | i18n"></button>
        </a>
      </div>

      <div class="rate hb-ha all-pro">
        <div class="title" [innerHTML]="'rates_Data17' | i18n"></div>
        <div class="subtitle" [innerHTML]="'rates_Data18' | i18n"></div>

        <div class="payment-option-container" (click)="swapPeriod(2)">
          <div class="selector-paymnet"
            [ngClass]="{'selector-paymnet-checked selector-paymnet-checked-special': selectedRatePeriod[2] === ratePeriodEnum.MONTHLY}">
          </div>
          <div class="payment-options payment-options-special"
            [ngClass]="{'payment-options-checked': selectedRatePeriod[2] === ratePeriodEnum.MONTHLY}">
            <div class="month-option month-option-special" [innerHTML]="'rates_Data38' | i18n"></div>
            <div class="year-option" [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="plus-2-month"
              [ngClass]="{'plus-2-month-checked': selectedRatePeriod[2] === ratePeriodEnum.ANNUAL}">
              <div class="plus-2" [innerHTML]="'rates_Data40' | i18n"></div>
              <div class="free-month" [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div>
        <!-- <div class="rate-type-container" [ngClass]="{'moving': selectedRatePeriod[2] === ratePeriodEnum.MONTHLY}">
          <div class="rate-type-annual"></div>
          <div class="monthly" [innerHTML]="'landing_rates_Data11' | i18n"
            (click)="changePeriod(2, ratePeriodEnum.MONTHLY)"></div>
          <div class="rate-type-annual annual" (click)="changePeriod(2, ratePeriodEnum.ANNUAL)">
            <div [innerHTML]="'landing_rates_Data12' | i18n"></div>
            <div class="annual-discount">
              <div [innerHTML]="'landing_rates_Data13' | i18n"></div>
              <div [innerHTML]="'landing_rates_Data14' | i18n"></div>
            </div>
          </div>
        </div> -->
        <div class="flex">
          <div class="price" [innerHTML]="'rates_Data19' | i18n"></div>
          <div class="subprice" [innerHTML]="'rates_Data20' | i18n"></div>
        </div>
        <div class="price-description" [innerHTML]="'rates_Data21' | i18n"
          [ngClass]="{'show': selectedRatePeriod[2] === ratePeriodEnum.ANNUAL}"></div>
        <div class="tools-description">
          <div class="line">
            <div [innerHTML]="'rates_Data22' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data23' | i18n"></div>
          </div>
          <div class="line limit-line">
            <span class="semibold" [innerHTML]="'rates_Data24' | i18n"></span>
            <span [innerHTML]="'rates_Data25' | i18n"></span>
          </div>
          <div class="line">
            <div [innerHTML]="'rates_Data26' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data27' | i18n"></div>
          </div>
          <div class="line">
            <div [innerHTML]="'rates_Data28' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data29' | i18n"></div>
          </div>
          <div class="line limit-line">
            <div [innerHTML]="'rates_Data58' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data31' | i18n"></div>
          </div>
        </div>
        <a [href]="goToPurchase()" target="_blank">
          <button class="purchase" [innerHTML]="'rates_Data32' | i18n"></button>
        </a>
      </div>

      <div class="rate hb-ha">
        <div class="title" [innerHTML]="'rates_Data33' | i18n"></div>
        <div class="subtitle" [innerHTML]="'rates_Data34' | i18n"></div>
        <div class="payment-option-container" (click)="swapPeriod(3)">
          <div class="selector-paymnet"
            [ngClass]="{'selector-paymnet-checked': selectedRatePeriod[3] === ratePeriodEnum.MONTHLY}"></div>
          <div class="payment-options"
            [ngClass]="{'payment-options-checked': selectedRatePeriod[3] === ratePeriodEnum.MONTHLY}">
            <div class="month-option" [innerHTML]="'rates_Data38' | i18n"></div>
            <div class="year-option" [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="plus-2-month"
              [ngClass]="{'plus-2-month-checked': selectedRatePeriod[3] === ratePeriodEnum.ANNUAL}">
              <div class="plus-2" [innerHTML]="'rates_Data40' | i18n"></div>
              <div class="free-month" [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div>
        <!-- <div class="rate-type-container" [ngClass]="{'moving': selectedRatePeriod[3] === ratePeriodEnum.MONTHLY}">
          <div class="rate-type-annual"></div>
          <div class="monthly" [innerHTML]="'rates_Data38' | i18n" (click)="changePeriod(3, ratePeriodEnum.MONTHLY)">
          </div>
          <div class="rate-type-annual annual" (click)="changePeriod(3, ratePeriodEnum.ANNUAL)">
            <div [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="annual-discount">
              <div [innerHTML]="'rates_Data40' | i18n"></div>
              <div [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div> -->
        <div class="flex">
          <div class="price" [innerHTML]="'rates_Data35' | i18n"></div>
          <div class="subprice" [innerHTML]="'rates_Data36' | i18n"></div>
        </div>
        <div class="price-description" [innerHTML]="'rates_Data37' | i18n"
          [ngClass]="{'show': selectedRatePeriod[3] === ratePeriodEnum.ANNUAL}"></div>
        <div class="tools-description">
          <div class="line">
            <div [innerHTML]="'landing_rates_Data18' | i18n"></div>
            <div class="quantity" [innerHTML]="'landing_rates_Data19' | i18n"></div>
          </div>
          <div class="line limit-line">
            <span class="semibold" [innerHTML]="'landing_rates_Data20' | i18n"></span>
            <span [innerHTML]="'rates_Data25' | i18n"></span>
          </div>
          <div class="line">
            <div [innerHTML]="'landing_rates_Data22' | i18n"></div>
            <div class="quantity" [innerHTML]="'landing_rates_Data23' | i18n"></div>
          </div>
          <div class="line">
            <div [innerHTML]="'landing_rates_Data24' | i18n"></div>
            <div class="quantity" [innerHTML]="'landing_rates_Data25' | i18n"></div>
          </div>
          <div class="line limit-line">
            <div [innerHTML]="'rates_Data58' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data31' | i18n"></div>
          </div>
        </div>
        <a [href]="goToPurchase()" target="_blank">
          <button class="purchase" [innerHTML]="'rates_Data32' | i18n"></button>
        </a>
      </div>

      <div class="rate hb-ha">
        <div class="title" [innerHTML]="'rates_Data33' | i18n"></div>
        <div class="subtitle" [innerHTML]="'rates_Data42' | i18n"></div>
        <div class="payment-option-container" (click)="swapPeriod(4)">
          <div class="selector-paymnet"
            [ngClass]="{'selector-paymnet-checked': selectedRatePeriod[4] === ratePeriodEnum.MONTHLY}"></div>
          <div class="payment-options"
            [ngClass]="{'payment-options-checked': selectedRatePeriod[4] === ratePeriodEnum.MONTHLY}">
            <div class="month-option" [innerHTML]="'rates_Data38' | i18n"></div>
            <div class="year-option" [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="plus-2-month"
              [ngClass]="{'plus-2-month-checked': selectedRatePeriod[4] === ratePeriodEnum.ANNUAL}">
              <div class="plus-2" [innerHTML]="'rates_Data40' | i18n"></div>
              <div class="free-month" [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div>
        <!-- <div class="rate-type-container" [ngClass]="{'moving': selectedRatePeriod[4] === ratePeriodEnum.MONTHLY}">
          <div class="rate-type-annual"></div>
          <div class="monthly" [innerHTML]="'rates_Data38' | i18n" (click)="changePeriod(4, ratePeriodEnum.MONTHLY)">
          </div>
          <div class="rate-type-annual annual" (click)="changePeriod(4, ratePeriodEnum.ANNUAL)">
            <div [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="annual-discount">
              <div [innerHTML]="'rates_Data40' | i18n"></div>
              <div [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div> -->
        <div class="flex">
          <div class="price" [innerHTML]="'rates_Data43' | i18n"></div>
          <div class="subprice" [innerHTML]="'rates_Data36' | i18n"></div>
        </div>
        <div class="price-description" [innerHTML]="'rates_Data44' | i18n"
          [ngClass]="{'show': selectedRatePeriod[4] === ratePeriodEnum.ANNUAL}"></div>
        <div class="tools-description">
          <div class="line">
            <div [innerHTML]="'landing_rates_Data18' | i18n"></div>
            <div class="quantity" [innerHTML]="'landing_rates_Data19' | i18n"></div>
          </div>
          <div class="line limit-line">
            <span class="semibold" [innerHTML]="'landing_rates_Data20' | i18n"></span>
            <span [innerHTML]="'rates_Data25' | i18n"></span>
          </div>
          <div class="line">
            <div [innerHTML]="'landing_rates_Data22' | i18n"></div>
            <div class="quantity" [innerHTML]="'landing_rates_Data23' | i18n"></div>
          </div>
          <div class="line">
            <div [innerHTML]="'landing_rates_Data24' | i18n"></div>
            <div class="quantity" [innerHTML]="'landing_rates_Data25' | i18n"></div>
          </div>
          <div class="line limit-line">
            <div [innerHTML]="'rates_Data58' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data31' | i18n"></div>
          </div>
        </div>
        <a [href]="goToPurchase()" target="_blank">
          <button class="purchase" [innerHTML]="'rates_Data32' | i18n"></button>
        </a>
      </div>

      <div class="rate hb-ha">
        <div class="title" [innerHTML]="'rates_Data33' | i18n"></div>
        <div class="subtitle" [innerHTML]="'rates_Data45' | i18n"></div>
        <div class="payment-option-container" (click)="swapPeriod(5)">
          <div class="selector-paymnet"
            [ngClass]="{'selector-paymnet-checked': selectedRatePeriod[5] === ratePeriodEnum.MONTHLY}"></div>
          <div class="payment-options"
            [ngClass]="{'payment-options-checked': selectedRatePeriod[5] === ratePeriodEnum.MONTHLY}">
            <div class="month-option" [innerHTML]="'rates_Data38' | i18n"></div>
            <div class="year-option" [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="plus-2-month"
              [ngClass]="{'plus-2-month-checked': selectedRatePeriod[5] === ratePeriodEnum.ANNUAL}">
              <div class="plus-2" [innerHTML]="'rates_Data40' | i18n"></div>
              <div class="free-month" [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div>
        <!-- <div class="rate-type-container" [ngClass]="{'moving': selectedRatePeriod[5] === ratePeriodEnum.MONTHLY}">
          <div class="rate-type-annual"></div>
          <div class="monthly" [innerHTML]="'rates_Data38' | i18n" (click)="changePeriod(5, ratePeriodEnum.MONTHLY)">
          </div>
          <div class="rate-type-annual annual" (click)="changePeriod(5, ratePeriodEnum.ANNUAL)">
            <div [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="annual-discount">
              <div [innerHTML]="'rates_Data40' | i18n"></div>
              <div [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div> -->
        <div class="flex">
          <div class="price" [innerHTML]="'rates_Data46' | i18n"></div>
          <div class="subprice" [innerHTML]="'rates_Data36' | i18n"></div>
        </div>
        <div class="price-description" [innerHTML]="'rates_Data47' | i18n"
          [ngClass]="{'show': selectedRatePeriod[5] === ratePeriodEnum.ANNUAL}"></div>
        <div class="tools-description">
          <div class="line">
            <div [innerHTML]="'landing_rates_Data18' | i18n"></div>
            <div class="quantity" [innerHTML]="'landing_rates_Data19' | i18n"></div>
          </div>
          <div class="line limit-line">
            <span class="semibold" [innerHTML]="'landing_rates_Data20' | i18n"></span>
            <span [innerHTML]="'rates_Data25' | i18n"></span>
          </div>
          <div class="line">
            <div [innerHTML]="'landing_rates_Data22' | i18n"></div>
            <div class="quantity" [innerHTML]="'landing_rates_Data23' | i18n"></div>
          </div>
          <div class="line">
            <div [innerHTML]="'landing_rates_Data24' | i18n"></div>
            <div class="quantity" [innerHTML]="'landing_rates_Data25' | i18n"></div>
          </div>
          <div class="line limit-line">
            <div [innerHTML]="'rates_Data58' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data31' | i18n"></div>
          </div>
        </div>
        <a [href]="goToPurchase()" target="_blank">
          <button class="purchase" [innerHTML]="'rates_Data32' | i18n"></button>
        </a>
      </div>
    </div>

    <div class="rates company-rates" #ratesContent *ngIf="selectedRateType === rateTypeEnum.COMPANY">
      <div class="rate hb-ha">
        <div class="title" [innerHTML]="'landing_rates_Data9' | i18n"></div>
        <div class="subtitle" [innerHTML]="'landing_rates_Data10' | i18n"></div>
        <div class="payment-option-container" (click)="swapPeriod(6)">
          <div class="selector-paymnet"
            [ngClass]="{'selector-paymnet-checked': selectedRatePeriod[6] === ratePeriodEnum.MONTHLY}"></div>
          <div class="payment-options"
            [ngClass]="{'payment-options-checked': selectedRatePeriod[6] === ratePeriodEnum.MONTHLY}">
            <div class="month-option" [innerHTML]="'rates_Data38' | i18n"></div>
            <div class="year-option" [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="plus-2-month"
              [ngClass]="{'plus-2-month-checked': selectedRatePeriod[6] === ratePeriodEnum.ANNUAL}">
              <div class="plus-2" [innerHTML]="'rates_Data40' | i18n"></div>
              <div class="free-month" [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div>
        <!-- <div class="rate-type-container" [ngClass]="{'moving': selectedRatePeriod[6] === ratePeriodEnum.MONTHLY}">
          <div class="rate-type-annual"></div>
          <div class="monthly" [innerHTML]="'rates_Data38' | i18n" (click)="changePeriod(6, ratePeriodEnum.MONTHLY)">
          </div>
          <div class="rate-type-annual annual" (click)="changePeriod(6, ratePeriodEnum.ANNUAL)">
            <div [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="annual-discount">
              <div [innerHTML]="'rates_Data40' | i18n"></div>
              <div [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div> -->
        <div class="flex">
          <div class="price" [innerHTML]="'rates_Data35' | i18n"></div>
          <div class="subprice" [innerHTML]="'rates_Data36' | i18n"></div>
        </div>
        <div class="price-description" [innerHTML]="'rates_Data37' | i18n"
          [ngClass]="{'show': selectedRatePeriod[6] === ratePeriodEnum.ANNUAL}"></div>
        <div class="tools-description">
          <div class="line">
            <div [innerHTML]="'rates_Data22' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data48' | i18n"></div>
          </div>
          <div class="line limit-line">
            <span class="semibold" [innerHTML]="'rates_Data24' | i18n"></span>
            <span [innerHTML]="'rates_Data25' | i18n"></span>
          </div>
          <div class="line">
            <div [innerHTML]="'rates_Data26' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data49' | i18n"></div>
          </div>
          <div class="line">
            <div [innerHTML]="'rates_Data28' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data50' | i18n"></div>
          </div>
          <div class="line limit-line">
            <div [innerHTML]="'rates_Data58' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data51' | i18n"></div>
          </div>
        </div>
        <a [href]="goToPurchase()" target="_blank">
          <button class="purchase" [innerHTML]="'rates_Data32' | i18n"></button>
        </a>
      </div>

      <div class="rate hb-ha all-pro">
        <div class="title" [innerHTML]="'rates_Data17' | i18n"></div>
        <div class="subtitle" [innerHTML]="'rates_Data52' | i18n"></div>
        <div class="payment-option-container" (click)="swapPeriod(7)">
          <div class="selector-paymnet"
            [ngClass]="{'selector-paymnet-checked selector-paymnet-checked-special ': selectedRatePeriod[7] === ratePeriodEnum.MONTHLY}">
          </div>
          <div class="payment-options payment-options-special"
            [ngClass]="{'payment-options-checked': selectedRatePeriod[7] === ratePeriodEnum.MONTHLY}">
            <div class="month-option month-option-special" [innerHTML]="'rates_Data38' | i18n"></div>
            <div class="year-option" [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="plus-2-month"
              [ngClass]="{'plus-2-month-checked': selectedRatePeriod[7] === ratePeriodEnum.ANNUAL}">
              <div class="plus-2" [innerHTML]="'rates_Data40' | i18n"></div>
              <div class="free-month" [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div>
        <!-- <div class="rate-type-container" [ngClass]="{'moving': selectedRatePeriod[7] === ratePeriodEnum.MONTHLY}">
          <div class="rate-type-annual"></div>
          <div class="monthly" [innerHTML]="'rates_Data38' | i18n" (click)="changePeriod(7, ratePeriodEnum.MONTHLY)">
          </div>
          <div class="rate-type-annual annual" (click)="changePeriod(7, ratePeriodEnum.ANNUAL)">
            <div [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="annual-discount">
              <div [innerHTML]="'rates_Data40' | i18n"></div>
              <div [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div> -->
        <div class="flex">
          <div class="price" [innerHTML]="'rates_Data53' | i18n"></div>
          <div class="subprice" [innerHTML]="'rates_Data36' | i18n"></div>
        </div>
        <div class="price-description" [innerHTML]="'rates_Data54' | i18n"
          [ngClass]="{'show': selectedRatePeriod[7] === ratePeriodEnum.ANNUAL}"></div>
        <div class="tools-description">
          <div class="line">
            <div [innerHTML]="'rates_Data22' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data48' | i18n"></div>
          </div>
          <div class="line limit-line">
            <span class="semibold" [innerHTML]="'rates_Data24' | i18n"></span>
            <span [innerHTML]="'rates_Data25' | i18n"></span>
          </div>
          <div class="line">
            <div [innerHTML]="'rates_Data26' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data49' | i18n"></div>
          </div>
          <div class="line">
            <div [innerHTML]="'rates_Data28' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data50' | i18n"></div>
          </div>
          <div class="line limit-line">
            <div [innerHTML]="'rates_Data58' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data51' | i18n"></div>
          </div>
        </div>
        <a [href]="goToPurchase()" target="_blank">
          <button class="purchase" [innerHTML]="'rates_Data32' | i18n"></button>
        </a>
      </div>

      <div class="rate hb-ha">
        <div class="title" [innerHTML]="'rates_Data33' | i18n"></div>
        <div class="subtitle" [innerHTML]="'rates_Data34' | i18n"></div>
        <div class="payment-option-container" (click)="swapPeriod(8)">
          <div class="selector-paymnet"
            [ngClass]="{'selector-paymnet-checked': selectedRatePeriod[8] === ratePeriodEnum.MONTHLY}"></div>
          <div class="payment-options"
            [ngClass]="{'payment-options-checked': selectedRatePeriod[8] === ratePeriodEnum.MONTHLY}">
            <div class="month-option" [innerHTML]="'rates_Data38' | i18n"></div>
            <div class="year-option" [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="plus-2-month"
              [ngClass]="{'plus-2-month-checked': selectedRatePeriod[8] === ratePeriodEnum.ANNUAL}">
              <div class="plus-2" [innerHTML]="'rates_Data40' | i18n"></div>
              <div class="free-month" [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div>
        <!-- <div class="rate-type-container" [ngClass]="{'moving': selectedRatePeriod[8] === ratePeriodEnum.MONTHLY}">
          <div class="rate-type-annual"></div>
          <div class="monthly" [innerHTML]="'rates_Data38' | i18n" (click)="changePeriod(8, ratePeriodEnum.MONTHLY)">
          </div>
          <div class="rate-type-annual annual" (click)="changePeriod(8, ratePeriodEnum.ANNUAL)">
            <div [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="annual-discount">
              <div [innerHTML]="'rates_Data40' | i18n"></div>
              <div [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div> -->
        <div class="flex">
          <div class="price" [innerHTML]="'rates_Data55' | i18n"></div>
          <div class="subprice" [innerHTML]="'rates_Data36' | i18n"></div>
        </div>
        <div class="price-description" [innerHTML]="'rates_Data56' | i18n"
          [ngClass]="{'show': selectedRatePeriod[8] === ratePeriodEnum.ANNUAL}"></div>
        <div class="tools-description">
          <div class="line">
            <div [innerHTML]="'rates_Data22' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data48' | i18n"></div>
          </div>
          <div class="line limit-line">
            <span class="semibold" [innerHTML]="'rates_Data24' | i18n"></span>
            <span [innerHTML]="'rates_Data25' | i18n"></span>
          </div>
          <div class="line">
            <div [innerHTML]="'rates_Data26' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data49' | i18n"></div>
          </div>
          <div class="line">
            <div [innerHTML]="'rates_Data28' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data50' | i18n"></div>
          </div>
          <div class="line limit-line">
            <div [innerHTML]="'rates_Data58' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data51' | i18n"></div>
          </div>
        </div>
        <a [href]="goToPurchase()" target="_blank">
          <button class="purchase" [innerHTML]="'rates_Data32' | i18n"></button>
        </a>
      </div>

      <div class="rate hb-ha">
        <div class="title" [innerHTML]="'rates_Data33' | i18n"></div>
        <div class="subtitle" [innerHTML]="'rates_Data42' | i18n"></div>
        <div class="payment-option-container" (click)="swapPeriod(9)">
          <div class="selector-paymnet"
            [ngClass]="{'selector-paymnet-checked': selectedRatePeriod[9] === ratePeriodEnum.MONTHLY}"></div>
          <div class="payment-options"
            [ngClass]="{'payment-options-checked': selectedRatePeriod[9] === ratePeriodEnum.MONTHLY}">
            <div class="month-option" [innerHTML]="'rates_Data38' | i18n"></div>
            <div class="year-option" [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="plus-2-month"
              [ngClass]="{'plus-2-month-checked': selectedRatePeriod[9] === ratePeriodEnum.ANNUAL}">
              <div class="plus-2" [innerHTML]="'rates_Data40' | i18n"></div>
              <div class="free-month" [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div>
        <!-- <div class="rate-type-container" [ngClass]="{'moving': selectedRatePeriod[9] === ratePeriodEnum.MONTHLY}">
          <div class="rate-type-annual"></div>
          <div class="monthly" [innerHTML]="'rates_Data38' | i18n" (click)="changePeriod(9, ratePeriodEnum.MONTHLY)">
          </div>
          <div class="rate-type-annual annual" (click)="changePeriod(9, ratePeriodEnum.ANNUAL)">
            <div [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="annual-discount">
              <div [innerHTML]="'rates_Data40' | i18n"></div>
              <div [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div> -->
        <div class="flex">
          <div class="price" [innerHTML]="'rates_Data59' | i18n"></div>
          <div class="subprice" [innerHTML]="'rates_Data36' | i18n"></div>
        </div>
        <div class="price-description" [innerHTML]="'rates_Data60' | i18n"
          [ngClass]="{'show': selectedRatePeriod[9] === ratePeriodEnum.ANNUAL}"></div>
        <div class="tools-description">
          <div class="line">
            <div [innerHTML]="'landing_rates_Data18' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data48' | i18n"></div>
          </div>
          <div class="line limit-line">
            <span class="semibold" [innerHTML]="'landing_rates_Data20' | i18n"></span>
            <span [innerHTML]="'rates_Data25' | i18n"></span>
          </div>
          <div class="line">
            <div [innerHTML]="'landing_rates_Data22' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data49' | i18n"></div>
          </div>
          <div class="line">
            <div [innerHTML]="'landing_rates_Data24' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data50' | i18n"></div>
          </div>
          <div class="line limit-line">
            <div [innerHTML]="'rates_Data58' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data51' | i18n"></div>
          </div>
        </div>
        <a [href]="goToPurchase()" target="_blank">
          <button class="purchase" [innerHTML]="'rates_Data32' | i18n"></button>
        </a>
      </div>

      <div class="rate hb-ha">
        <div class="title" [innerHTML]="'rates_Data33' | i18n"></div>
        <div class="subtitle" [innerHTML]="'rates_Data45' | i18n"></div>
        <div class="payment-option-container" (click)="swapPeriod(10)">
          <div class="selector-paymnet"
            [ngClass]="{'selector-paymnet-checked': selectedRatePeriod[10] === ratePeriodEnum.MONTHLY}"></div>
          <div class="payment-options"
            [ngClass]="{'payment-options-checked': selectedRatePeriod[10] === ratePeriodEnum.MONTHLY}">
            <div class="month-option" [innerHTML]="'rates_Data38' | i18n"></div>
            <div class="year-option" [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="plus-2-month"
              [ngClass]="{'plus-2-month-checked': selectedRatePeriod[10] === ratePeriodEnum.ANNUAL}">
              <div class="plus-2" [innerHTML]="'rates_Data40' | i18n"></div>
              <div class="free-month" [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div>
        <!-- <div class="rate-type-container" [ngClass]="{'moving': selectedRatePeriod[10] === ratePeriodEnum.MONTHLY}">
          <div class="rate-type-annual"></div>
          <div class="monthly" [innerHTML]="'rates_Data38' | i18n" (click)="changePeriod(10, ratePeriodEnum.MONTHLY)">
          </div>
          <div class="rate-type-annual annual" (click)="changePeriod(10, ratePeriodEnum.ANNUAL)">
            <div [innerHTML]="'rates_Data39' | i18n"></div>
            <div class="annual-discount">
              <div [innerHTML]="'rates_Data40' | i18n"></div>
              <div [innerHTML]="'rates_Data41' | i18n"></div>
            </div>
          </div>
        </div> -->
        <div class="flex">
          <div class="price" [innerHTML]="'rates_Data61' | i18n"></div>
          <div class="subprice" [innerHTML]="'rates_Data36' | i18n"></div>
        </div>
        <div class="price-description" [innerHTML]="'rates_Data62' | i18n"
          [ngClass]="{'show': selectedRatePeriod[10] === ratePeriodEnum.ANNUAL}"></div>
        <div class="tools-description">
          <div class="line">
            <div [innerHTML]="'landing_rates_Data18' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data48' | i18n"></div>
          </div>
          <div class="line limit-line">
            <span class="semibold" [innerHTML]="'landing_rates_Data20' | i18n"></span>
            <span [innerHTML]="'rates_Data25' | i18n"></span>
          </div>
          <div class="line">
            <div [innerHTML]="'landing_rates_Data22' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data49' | i18n"></div>
          </div>
          <div class="line">
            <div [innerHTML]="'landing_rates_Data24' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data50' | i18n"></div>
          </div>
          <div class="line limit-line">
            <div [innerHTML]="'rates_Data58' | i18n"></div>
            <div class="quantity" [innerHTML]="'rates_Data51' | i18n"></div>
          </div>
        </div>
        <a [href]="goToPurchase()" target="_blank">
          <button class="purchase" [innerHTML]="'rates_Data32' | i18n"></button>
        </a>
      </div>
    </div>

    <img *ngIf="selectedRateType !== rateTypeEnum.BASIC" src="/assets/images/rates-info/l-arrow.svg" alt="Flecha"
      class="left-arrow" (click)="scrollLeft()">
    <img *ngIf="selectedRateType !== rateTypeEnum.BASIC" src="/assets/images/rates-info/r-arrow.svg" alt="Flecha"
      class="right-arrow" (click)="scrollRight()">
  </div>
</div>
