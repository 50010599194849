import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BlogService } from '../../services/blog.service';
import { PlatformsEnum } from '../../enums/platforms-enum';
import { Blog } from '../../models/blog';
import { I18nService } from '../../services/i18n.service';
import { GenerateRouteService } from '../../services/generate-route.service';
import { ResponsiveService } from '../../services/responsive.service';
import { WindowService } from '../../services/window.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-last-blogs',
  templateUrl: './last-blogs.component.html',
  styleUrl: './last-blogs.component.scss',
})
export class LastBlogsComponent implements OnInit {
  platforms: PlatformsEnum;
  posts: Blog[] = [];
  numElementosMostrados = 4;
  isPC: boolean = this.responsiveService.isPC();

  @ViewChild('lastBlogs', { static: true }) lastBlogs: ElementRef;
  isDown: boolean = false;
  startX: any;
  url: any;
  scrollLeft: any;
  constructor(
    private blogService: BlogService,
    private i18nService: I18nService,
    private generateRouteService: GenerateRouteService,
    private responsiveService: ResponsiveService,
    private windowService: WindowService,

    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.responsiveService.isLandscape()) {
      this.numElementosMostrados = 3;
    }
    this.carrouselOptions();
    this.route.params.subscribe((params) => {
      this.url = params['id'];
      if (this.url) {
        this.blogService.getByUrl(this.url).subscribe(
          (blog: Blog) => {
            if (blog != null && blog != undefined) {
              this.blogService.getRelatedBlogs(blog.id).subscribe(
                (res) => {
                  if (res && res['data'].length < 1) {
                    this.loadBlogs();
                  } else {
                    this.posts = res['data'];
                    console.log(this.posts);
                  }
                },
                (error) => {
                  // Manejo del error de getRelatedBlogs
                  console.error('Error en getRelatedBlogs:', error);
                  this.loadBlogs(); // O cualquier otra acción que desees tomar en caso de error
                }
              );
            }
          },
          (error) => {
            // Manejo del error de getByUrl
            console.error('Error en getByUrl:', error);
            this.loadBlogs(); // O cualquier otra acción que desees tomar en caso de error
          }
        );
      } else {
        this.loadBlogs();
      }

    });
  }

  loadBlogs(): void {
    this.blogService
      .getAll((this.platforms = PlatformsEnum.EYESCLOUD3D))
      .subscribe((response: any) => {
        this.posts = response.filter((post: any) => {
          return (
            post.languages_id === this.i18nService.getCurrentLanguage().id &&
            post.is_published
          );
        });

        this.posts.sort(
          (a: any, b: any) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
  }

  get objetosToShow() {
    return this.posts.slice(0, this.numElementosMostrados); // Limita los objetos a mostrar
  }

  getRouterLink(routeKey: any): string {
    return this.generateRouteService.generateRoute(routeKey);
  }

  onResize(): void {
    this.isPC = this.responsiveService.isPC();
  }

  carrouselOptions(): void {
    if (this.windowService.isBrowser) {
      const el = this.lastBlogs.nativeElement;

      el.addEventListener('mousedown', (e) => {
        this.isDown = true;
        this.startX = e.pageX - el.offsetLeft;
        this.scrollLeft = el.scrollLeft;
      });

      el.addEventListener('mouseleave', () => {
        this.isDown = false;
      });

      el.addEventListener('mouseup', () => {
        this.isDown = false;
      });

      el.addEventListener('mousemove', (e) => {
        if (!this.isDown) return;
        e.preventDefault();
        const x = e.pageX - el.offsetLeft;
        const walk = (x - this.startX) * 2; // Velocidad del arrastre
        el.scrollLeft = this.scrollLeft - walk;
      });
    }
  }
}
