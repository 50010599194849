import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GenerateRouteService } from '../../services/generate-route.service';
import { CheckCorrectRouteService } from '../../routes/check-correct-route.service';
import { Router } from '@angular/router';
import { WindowService } from '../../services/window.service';
import { TranslationModeEnum } from '../../enums/translation-mode-enum';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  @ViewChild('carrousel', { static: true }) carrousel: ElementRef;
  isDown: boolean = false;
  startX: any;
  scrollLeft: any;
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;

  constructor(
    private generateRouteService: GenerateRouteService,
    private checkCorrectRouteService: CheckCorrectRouteService,
    private router: Router,
    private windowService: WindowService
  ) {
  }

  ngOnInit(): void {
    const currentUrl = this.router.url;
    const translatedRoute = this.checkCorrectRouteService.findRouteInOtherLanguage(currentUrl);
    if (translatedRoute) {
      this.router.navigateByUrl(translatedRoute);
    } else {
    }

    if (this.windowService.isBrowser) {
      const el = this.carrousel.nativeElement;

      el.addEventListener('mousedown', (e) => {
        this.isDown = true;
        this.startX = e.pageX - el.offsetLeft;
        this.scrollLeft = el.scrollLeft;
      });

      el.addEventListener('mouseleave', () => {
        this.isDown = false;
      });

      el.addEventListener('mouseup', () => {
        this.isDown = false;
      });

      el.addEventListener('mousemove', (e) => {
        if (!this.isDown) return;
        e.preventDefault();
        const x = e.pageX - el.offsetLeft;
        const walk = (x - this.startX) * 3; // Velocidad del arrastre
        el.scrollLeft = this.scrollLeft - walk;
      });
    }
  }

  getRouterLink(routeKey: any): string {
    return this.generateRouteService.generateRoute(routeKey);
  }
}
