import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FaqsComponent } from './commons/components/faqs/faqs.component';
import { ErrorComponent } from './commons/components/error/error.component';
import { ContactComponent } from './commons/components/contact/contact.component';
import { RedirectToAppComponent } from './commons/components/redirect-to-app/redirect-to-app.component';
import { AdminBlogComponent } from './blog/components/admin-blog/components/admin-blog/admin-blog.component';
import { AdminBlogPostComponent } from './blog/components/admin-blog/components/admin-blog-post/admin-blog-post.component';
import { BlogPostComponent } from './blog/components/blog-post/blog-post.component';
import { BlogComponent } from './blog/components/blog/blog.component';
import { AuthGuard } from './commons/services/auth.guard';
import { AdminBlogLoginComponent } from './blog/components/admin-blog/components/admin-blog-login/admin-blog-login.component';
import { routingGuard } from './commons/guards/routing.guard';
import { SpanishRoutes } from './commons/routes/spanish-routes';
import { EnglishRoutes } from './commons/routes/english-routes';
import { FrenchRoutes } from './commons/routes/french-routes';
import { PortugueseRoutes } from './commons/routes/portuguese-routes';
import { AndroidLoginComponent } from './auth/components/android-login/android-login.component';
import { AndroidRegisterComponent } from './auth/components/android-register/android-register.component';
import { MaintanceComponent } from './commons/components/maintance/maintance.component';
import { HomeComponent } from './home/components/home/home.component';

const mergeRoutes = (routesObjects, urlKey, component, canActivate = []) => {
  return routesObjects.flatMap((routesObject) => [
    {
      path: routesObject[urlKey].URL,
      component: component,
      canActivate: canActivate,
    },
    {
      path: `${routesObject[urlKey].URL}?*`,
      component: component,
      canActivate: canActivate,
    },
  ]);
};

const mergeRoutesWithParam = (
  routesObjects,
  urlKey,
  component,
  canActivate = []
) => {
  return routesObjects.map((routesObject) => ({
    path: `${routesObject[urlKey].URL}/:id`,
    component: component,
    canActivate: canActivate,
  }));
};

const routes: Routes = [
  {
    path: 'maintance',
    component: MaintanceComponent,
  },

  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
        canActivateChild: [routingGuard],
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: '',
        loadChildren: () =>
          import('./auth/auth.module').then((m) => m.AuthModule),
        canActivateChild: [routingGuard],
      },
      {
        path: '',
        loadChildren: () => import('./tos/tos.module').then((m) => m.TosModule),
        canActivateChild: [routingGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('./rates/rates.module').then((m) => m.RatesModule),
        canActivateChild: [routingGuard],
      },
      {
        path: '',
        loadChildren: () => import('./help-center/help-center.module').then(m => m.HelpCenterModule),
        canActivateChild: [routingGuard],
      },
      ...mergeRoutes([SpanishRoutes, EnglishRoutes, FrenchRoutes, PortugueseRoutes], 'Faq', FaqsComponent, [routingGuard]),
      ...mergeRoutes([SpanishRoutes, EnglishRoutes, FrenchRoutes, PortugueseRoutes], 'Contact', ContactComponent, [routingGuard]),
      ...mergeRoutes([SpanishRoutes, EnglishRoutes, FrenchRoutes, PortugueseRoutes], 'BlogWithParams', BlogPostComponent, [routingGuard]),
      ...mergeRoutes([SpanishRoutes, EnglishRoutes, FrenchRoutes, PortugueseRoutes], 'Blog', BlogComponent, [routingGuard]),
      {
        path: 'model/:modelId',
        component: RedirectToAppComponent,
      },
      ...mergeRoutes([SpanishRoutes, EnglishRoutes, FrenchRoutes, PortugueseRoutes], 'android-login', AndroidLoginComponent, [routingGuard]),
      ...mergeRoutes([SpanishRoutes, EnglishRoutes, FrenchRoutes, PortugueseRoutes], 'android-register', AndroidRegisterComponent, [routingGuard]),
      {
        path: 'viewer/:modelId/:viewerType',
        component: RedirectToAppComponent,
      },
      {
        path: 'admin',
        component: AdminBlogComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'admin/blog/:id',
        component: AdminBlogPostComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'admin/blog/preview/:id',
        component: BlogPostComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'admin/login',
        component: AdminBlogLoginComponent,
      },
    ],
  },

  {
    path: '**',
    component: ErrorComponent,
    canActivate: [routingGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
