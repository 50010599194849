export const PortugueseRoutes = {
  Home: {
    URL: 'pt',
  },
  Rates: {
    URL: 'pt/taixas',
  },
  Faq: {
    URL: 'pt/ajuda/faq',
  },
  Contact: {
    URL: 'pt/contacto',
  },
  Blog: {
    URL: 'pt/blog',
  },
  BlogWithParams: {
    URL: 'pt/blog/:id',
  },
  Register: {
    URL: 'pt/registo',
  },
  RegisterWithPromotion: {
    URL: 'pt/registo/:promotion',
  },
  Login: {
    URL: 'pt/login',
  },
  TermsOfUse: {
    URL: 'pt/termos',
  },
  Cookies: {
    URL: 'pt/cookies',
  },
  Privacy: {
    URL: 'pt/privacidade',
  },
  Hiring: {
    URL: 'pt/condicoes',
  },
  HiringWithRate: {
    URL: 'pt/condicoes/:rate',
  },
  HelpCenter: {
    URL: 'pt/ajuda',
  },
  HCLibraryExtended: {
    URL: 'pt/ajuda/biblioteca',
  },
  // HCFirstStepsExtended: {
  //   URL: 'pt/ajuda/inicial',
  // },
  HCLibraryExtendedTools: {
    URL: 'pt/ajuda/biblioteca/:tool',
  },
  'android-login': {
    URL: 'pt/android-login',
  },
  'android-register': {
    URL: 'pt/android-register',
  },
  'email-verified': {
    URL: 'pt/email-verified/:token',
  },
};
