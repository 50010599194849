<div class="generate-model-container" #scroll (window:resize)="onResize()">
  <div class="generate-model-container max-container">
    <img src="/assets/images/generate-model/apple-model.webp" alt="{{ 'generate_model_Data6' | i18n: translationModes.PLACEHOLDER}}"
      class="first-model" [style.transform]="firstModelTransform">
    <div class="discover" [innerHTML]="'generate_model_Data1' | i18n" (click)="scrollToModule()"></div>
    <h2 [innerHTML]="'generate_model_Data2' | i18n"></h2>
    <div class="text" [innerHTML]="'generate_model_Data3' | i18n"></div>
    <button class="generate-btn" [innerHTML]="((isTablet) ? 'generate_model_Data4' : 'generate_model_Data5') | i18n"
      (click)="redirectToApp()"></button>
    <img src="/assets/images/generate-model/statue-model.webp" alt="{{ 'generate_model_Data7' | i18n: translationModes.PLACEHOLDER }}"
      class="second-model" [style.transform]="secondModelTransform">
  </div>
</div>
