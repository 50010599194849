<div class="three-steps-container">
  <h2 [innerHTML]="'three_steps_Data1' | i18n"></h2>
  <div class="options-container" #steps (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)"
    (touchend)="onTouchEnd($event)">
    <img class="steps" src="/assets/images/three-steps/step1.webp" alt="{{ 'three_steps_Data8' | i18n: translationModes.PLACEHOLDER }}"
      [ngClass]="{'hidden': currentStep !== 0}">
    <img class="steps" src="/assets/images/three-steps/step2.webp" alt="{{ 'three_steps_Data9' | i18n: translationModes.PLACEHOLDER }}"
      [ngClass]="{'hidden': currentStep !== 1}">
    <img class="steps" src="/assets/images/three-steps/step3.webp" alt="{{ 'three_steps_Data10' | i18n: translationModes.PLACEHOLDER }}"
      [ngClass]="{'hidden': currentStep !== 2}">
    <div class="selected-option" *ngIf="currentStep === 0">
      <h3 [innerHTML]="'three_steps_Data2' | i18n"></h3>
      <div [innerHTML]="'three_steps_Data3' | i18n"></div>
    </div>
    <div class="selected-option" *ngIf="currentStep === 1">
      <h3 [innerHTML]="'three_steps_Data4' | i18n"></h3>
      <div [innerHTML]="'three_steps_Data5' | i18n"></div>
    </div>
    <div class="selected-option" *ngIf="currentStep === 2">
      <h3 [innerHTML]="'three_steps_Data6' | i18n"></h3>
      <div [innerHTML]="'three_steps_Data7' | i18n"></div>
    </div>
    <div class="change-step-container">
      <img class="flecha-1" src="/assets/images/three-steps/Flecha_carrusel.svg" (click)="swapScreen(-1)">
      <img class="select" src="/assets/images/three-steps/select.svg" alt="Cambiar paso 1" *ngIf="currentStep !== 0"
        (click)="changeStep(0)">
      <img class="selected" src="/assets/images/three-steps/selected.svg" alt="Paso 1 seleccionado"
        *ngIf="currentStep === 0">
      <img class="select" src="/assets/images/three-steps/select.svg" alt="Cambiar paso 2" *ngIf="currentStep !== 1"
        (click)="changeStep(1)">
      <img class="selected" src="/assets/images/three-steps/selected.svg" alt="Paso 2 seleccionado"
        *ngIf="currentStep === 1">
      <img class="select" src="/assets/images/three-steps/select.svg" alt="Cambiar paso 3" *ngIf="currentStep !== 2"
        (click)="changeStep(2)">
      <img class="selected" src="/assets/images/three-steps/selected.svg" alt="Paso 3 seleccionado"
        *ngIf="currentStep === 2">
      <img class="flecha-2" src="/assets/images/three-steps/Flecha_carrusel.svg" (click)="swapScreen(1)">
    </div>
  </div>
</div>
