import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';
import { DateService } from './date.service';
import { UserToken } from './user-token';
import { BaseStorageService } from './base-storage.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AdminTokenStorage<T> extends BaseStorageService<T> {
  private OnSessionExpired = new Subject<UserToken>();

  constructor(storageService: StorageService) {
    super(storageService);
  }

  private static loginExpired(userToken: UserToken) {
    return DateService.datetimeIsBefore(
      DateService.getDate(userToken.tokenExpirationDate),
      DateService.currentDate()
    );
  }

  public onSessionExpired(): Observable<any> {
    return this.OnSessionExpired;
  }

  protected getStorageKey(): string {
    return 'eyesCloud3D-Blog';
  }

  public getAcceptedLogin(): T {
    return super.getObjectValue();
  }
}
