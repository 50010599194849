<div class="rates-container">
  <h2 [innerHTML]="'landing_rates_Data1' | i18n"></h2>
  <div class="rates">
    <div class="rate model-download">
      <div class="title" [innerHTML]="'landing_rates_Data2' | i18n"></div>
      <div class="subtitle" [innerHTML]="'landing_rates_Data3' | i18n"></div>
      <div class="from" [innerHTML]="'landing_rates_Data35' | i18n"></div>
      <div class="price" [innerHTML]="'landing_rates_Data4' | i18n"></div>
      <div class="subprice" [innerHTML]="'landing_rates_Data5' | i18n"></div>
      <button class="purchase" [innerHTML]="'landing_rates_Data7' | i18n" (click)="goToPurchase()"></button>
    </div>

    <div class="rate model-download">
      <div class="title" [innerHTML]="'landing_rates_Data34' | i18n"></div>
      <div class="subtitle" [innerHTML]="'landing_rates_Data3' | i18n"></div>
      <div class="from" [innerHTML]="'landing_rates_Data35' | i18n"></div>
      <div class="price" [innerHTML]="'landing_rates_Data4' | i18n"></div>
      <div class="subprice" [innerHTML]="'landing_rates_Data5' | i18n"></div>
      <button class="purchase" [innerHTML]="'landing_rates_Data7' | i18n" (click)="goToPurchase()"></button>
    </div>
    <div class="rate import-download">
      <div class="title" [innerHTML]="'landing_rates_Data37' | i18n"></div>
      <div class="subtitle" [innerHTML]="'landing_rates_Data36' | i18n"></div>
      <div class="from" [innerHTML]="'landing_rates_Data35' | i18n"></div>
      <div class="price" [innerHTML]="'landing_rates_Data38' | i18n"></div>
      <div class="subprice" [innerHTML]="'landing_rates_Data16' | i18n"></div>
      <div class="description" [innerHTML]="'landing_rates_Data39' | i18n"></div>
      <button class="purchase" [innerHTML]="'landing_rates_Data32' | i18n" (click)="goToPurchase()"></button>
    </div>
  </div>
  <a target="_self" [href]="getRouterLink('Rates')" class="Taxesbox_interaction-click-check-rates">
    <button class="check-rates" [innerHTML]="'landing_rates_Data33' | i18n"></button>
  </a>
</div>