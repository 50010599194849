import { Component } from '@angular/core';
import { GenerateRouteService } from 'src/app/commons/services/generate-route.service';
import { WindowService } from 'src/app/commons/services/window.service';
import { environment } from 'src/environments/environment';
import { RatePeriodEnum } from 'src/app/rates/components/rates-info/rates-info.component';

@Component({
  selector: 'app-landing-rates',
  templateUrl: './landing-rates.component.html',
  styleUrls: ['./landing-rates.component.scss'],
})
export class LandingRatesComponent {
  selectedRatePeriod: RatePeriodEnum = RatePeriodEnum.ANNUAL;
  ratePeriodEnum: typeof RatePeriodEnum = RatePeriodEnum;

  constructor(
    private generateRouteService: GenerateRouteService,
    private windowService: WindowService
  ) { }

  goToPurchase(): void {
    if (this.windowService.isBrowser) {
      window.open(
        `${environment.appUrl}/profile/user-account-layout/user-rates`,
        '_blank'
      );
    }
  }

  getRouterLink(routeKey: any): string {
    return this.generateRouteService.generateRoute(routeKey);
  }

  changePeriod(period: RatePeriodEnum) {
    this.selectedRatePeriod = period;
  }
}
