export const CONTACT_I18N_ENTRIES = {
  contact_Data1: {
    es: '<span>Disponemos de un <span class="gray-title">&nbsp;equipo de soporte&nbsp;</span> y atención para <span class="gray-title">&nbsp;responder&nbsp;</span> lo antes posible</span>',
    en: '<span>We have a <span class="gray-title">&nbsp;technical support&nbsp;</span> team that will <span class="gray-title">&nbsp;respond&nbsp;</span> as soon as possible</span>',
    pt: '<span>Dispomos de uma <span class="gray-title">&nbsp;equipa de suporte&nbsp;</span> e atendimento para <span class="gray-title">&nbsp;responder&nbsp;</span> às suas questões o mais brevemente possível</span>',
    fr: '<span>Nous disposons d’une <span class="gray-title">&nbsp;équipe technique&nbsp;</span> pour <span class="gray-title">&nbsp;répondre&nbsp;</span> à toutes vos questions dans les plus brefs délais</span>',
  },
  contact_Data2: {
    es: '<p>Rellena el formulario</p><p>de contacto</p>',
    en: '<p>Fill out the contact form</p>',
    pt: '<p>Preencha o formulário</p><p>de contacto</p>',
    fr: '<p>Remplissez le formulaire</p><p>de contact</p>',
  },
  contact_Data3: {
    es: '<p>Rellena el formulario y te contactaremos</p>',
    en: '<p>Fill out the form and we will contact you</p>',
    pt: '<p>Preencha o formulário e entraremos em contacto consigo</p>',
    fr: '<p>Remplissez le formulaire et nous vous contacterons</p>',
  },
  contact_Data4: {
    es: '<p>Nombre*</p>',
    en: '<p>Name*</p>',
    pt: '<p>Nome*</p>',
    fr: '<p>Nom*</p>',
  },
  contact_Data5: {
    es: '<p>Correo electrónico*</p>',
    en: '<p>Email*</p>',
    pt: '<p>E-mail*</p>',
    fr: '<p>E-mail*</p>',
  },
  contact_Data6: {
    es: '<p>Nº de teléfono</p>',
    en: '<p>Phone</p>',
    pt: '<p>N.º de telefone</p>',
    fr: '<p>Nº de téléphone</p>',
  },
  contact_Data7: {
    es: '<p>Escribe aquí tu consulta*</p>',
    en: '<p>Write your message here*</p>',
    pt: '<p>Escreva aqui o sua questão*</p>',
    fr: '<p>Écrivez ici votre message*</p>',
  },
  contact_Data8: {
    es: '<p>He leído y acepto las condiciones de uso</p>',
    en: '<p>I have read and accept the terms of use</p>',
    pt: '<p>Li e aceito as condições de utilização</p>',
    fr: '<p>J’ai lu et j’accepte les conditions générales d’utilisation</p>',
  },
  contact_Data9: {
    es: '<p>Acepto recibir comunicaciones comerciales relacionadas con la entidad <span class="i18n-bold">eyesCloud3D</span> a través de correo electrónico o medios electrónicos, incluído teléfono.</p>',
    en: '<p>I consent to receive commercial communications related to <span class="i18n-bold">eyesCloud3D</span> via email or electronic means, including phone.</p>',
    pt: '<p>Aceito receber comunicações comerciais relacionadas com a entidade <span class="i18n-bold">eyesCloud3D</span> por e-mail ou meios eletrónicos, incluindo telefone.</p>',
    fr: '<p>J’accepte de recevoir des communications commerciales de la société <span class="i18n-bold">eyesCloud3D</span>, par e-mail ou autre moyen électronique, ainsi que par téléphone.</p>',
  },
  contact_Data10: {
    es: '<p>Enviar consulta</p>',
    en: '<p>Send inquiry</p>',
    pt: '<p>Enviar questão</p>',
    fr: '<p>Envoyer le message</p>',
  },
  contact_Data11: {
    es: '<p>Gracias por ponerte en contacto con eyesCloud3D</p>',
    en: '<p>Thank you for contacting eyesCloud3D</p>',
    pt: '<p>Obrigado por contactar a eyesCloud3D</p>',
    fr: '<p>Nous vous remercions d’avoir contacté eyesCloud3D</p>',
  },
  contact_Data12: {
    es: '<p>Hemos recibido tu solicitud y nuestro equipo se pondrá en contacto contigo a la mayor brevedad posible.</p>',
    en: '<p>We have received your request, and our team will contact you as soon as possible.</p>',
    pt: '<p>Recebemos o seu pedido e a nossa equipa entrará em contacto consigo o mais brevemente possível.</p>',
    fr: '<p>Nous avons bien reçu votre demande et notre équipe vous contactera dans les plus brefs délais.</p>',
  },
  contact_Data13: {
    es: '<p>Cerrar</p>',
    en: '<p>Close</p>',
    pt: '<p>Fechar</p>',
    fr: '<p>Fermer</p>',
  },
  contact_Data14: {
    es: '<p>Campo obligatorio*</p>',
    en: '<p>Required field*</p>',
    pt: '<p>Campo obrigatório*</p>',
    fr: '<p>Champ requis*</p>',
  },
  contact_Data15: {
    es: '<p>Acepta las condiciones*</p>',
    en: '<p>Please accept the conditions*</p>',
    pt: '<p>Aceite as condições*</p>',
    fr: '<p>Veuillez accepter les conditions d’utilisation*</p>',
  },
  contact_Data16: {
    es: '<span>Ponte en contacto con nuestro equipo de soporte técnico</span>',
    en: '<span>Please contact our support team</span>',
    pt: '<span>Entre em contacto com a nossa equipa de apoio técnico</span>',
    fr: '<span>Contactez notre équipe technique</span>',
  },
  contact_Data17: {
    es: '<p>¿Tienes alguna duda que no hayas resuelto?</p>',
    en: '<p>Do you have more questions?</p>',
    pt: '<p>Tem alguma dúvida por esclarecer?</p>',
    fr: '<p>Avez-vous d’autres questions?</p>',
  },

};
