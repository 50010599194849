import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CookiesConfigEnum } from '../../enums/cookies-config-enum';
import { OptionalNcDialogComponent } from './optional-cookies-dialog/optional-cookies-dialog.component';
import { WindowService } from '../../services/window.service';
import { ResponsiveService } from '../../services/responsive.service';

@Component({
  selector: 'app-nc-config',
  templateUrl: './cookies-config.component.html',
  styleUrl: './cookies-config.component.scss'
})
export class NcConfigComponent implements OnInit {

  cookiesConfigEnum: typeof CookiesConfigEnum;

  constructor(
    private matDialogRef: MatDialogRef<NcConfigComponent>,
    private dialog: MatDialog,
    private windowService: WindowService,
    private responsiveService: ResponsiveService
  ) {
    this.cookiesConfigEnum = CookiesConfigEnum;
  }

  ngOnInit(): void {
  }

  openOptionalCookiesDialog(): void {
    this.dialog.open(OptionalNcDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      maxWidth: '100%',
      panelClass: 'optionalCookiesDialog',
    }).afterClosed().subscribe((response) => {
      this.matDialogRef.close();
    });
    if (!this.responsiveService.isTablet()) {
      this.matDialogRef.close();
    }
  }

  acceptCookies(response: CookiesConfigEnum): void {
    const selectedToggles = this.initializeSelectedToggles(response);
    if (this.windowService.isBrowser) {
      localStorage.setItem('selectedCookies', JSON.stringify(selectedToggles));
    }
    this.matDialogRef.close();
  }

  private initializeSelectedToggles(response: CookiesConfigEnum): { [key: string]: boolean } {
    const selectedToggles: { [key: string]: boolean } = {
      ESSENTIAL: true,
      ANALYSIS: false,
      FUNCTIONALITY: false,
      MARKETING: false,
      FRAUD: false,
      SECURITY: false
    };

    switch (response) {
      case CookiesConfigEnum.ALL:
        Object.keys(selectedToggles).forEach(key => selectedToggles[key] = true);
        break;
      case CookiesConfigEnum.ESSENTIAL:
        break;
      default:
        break;
    }

    return selectedToggles;
  }
}
