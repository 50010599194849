import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-limit-reached-dialog',
  templateUrl: './limit-reached-dialog.component.html',
  styleUrls: ['./limit-reached-dialog.component.scss']
})
export class LimitReachedDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<LimitReachedDialogComponent>
  ) {
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
