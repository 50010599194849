<div class="last-blogs-container" (window:resize)="onResize()">
  <div class="first-row">
    <div class="title" [innerHTML]="'articles_Data1' |i18n"></div>
    <a target="_self" [innerHTML]="'articles_Data2' |i18n" [href]="getRouterLink('Blog')" *ngIf="isPC"
      class="button"></a>
  </div>
  <div class="blogs-container" #lastBlogs>
    <a target="_self" [href]="getRouterLink('Blog') + '/' + objeto.url" *ngFor="let objeto of objetosToShow"
      class="blog-enlace">
      <div class="blog-item">
        <img [src]="isPC ? objeto.imgDestacada: objeto.imgMovil" alt="Imagen">
        <div class="title-blog-item">
          <p>{{ objeto.title }}</p>
        </div>
      </div>
    </a>
  </div>
  <a target="_self" [innerHTML]="'articles_Data2' |i18n" [href]="getRouterLink('Blog')" *ngIf="!isPC"
    class="button"></a>
</div>