export const COOKIES_CONFIG_I18N_ENTRIES = {
  cookies_config_Data1: {
    es: '<p><span class="i18n-bold">ecaptureDtech</span> utiliza cookies propias y de terceros que permiten mejorar la usabilidad de navegación y recopilar información. No se utilizarán las cookies para recoger información de carácter personal. Puede obtener más información <a target="_self" href="/cookies">aquí.</a></p>',
    en: '<p><span class="i18n-bold">ecaptureDtech</span> uses its own and third-party cookies to improve browsing usability and collect information. Cookies will not be used to collect any personal data. You can find more information <a target="_self" href="/us/cookies">here.</a></p>',
    pt: '<p><span class="i18n-bold">ecaptureDtech</span> uses its own and third-party cookies to improve browsing usability and collect information. Cookies will not be used to collect any personal data. You can find more information <a target="_self" href="/pt/cookies">here.</a></p>',
    fr: '<p><span class="i18n-bold">ecaptureDtech</span> uses its own and third-party cookies to improve browsing usability and collect information. Cookies will not be used to collect any personal data. You can find more information <a target="_self" href="/fr/cookies">here.</a></p>',
   },
  cookies_config_Data2: {
    es: '<p>Configurar cookies</p>',
    en: '<p>Configure cookies</p>',
    pt: '<p>Configure cookies</p>',
    fr: '<p>Configure cookies</p>',
   },

  cookies_config_Data3: {
    es: '<p>Rechazar opcionales</p>',
    en: '<p>Reject optional</p>',
    pt: '<p>Reject optional</p>',
    fr: '<p>Reject optional</p>',
  },

  cookies_config_Data4: {
    es: '<p>Aceptar todas</p>',
    en: '<p>Accept all</p>',
    pt: '<p>Accept all</p>',
    fr: '<p>Accept all</p>',
  },

  cookies_config_Data5: {
    es: '<p>Organización de la configuración de cookies</p>',
    en: '<p>Organization of cookie settings</p>',
    pt: '<p>Organization of cookie settings</p>',
    fr: '<p>Organization of cookie settings</p>',
   },

  cookies_config_Data6: {
    es: '<p>Cookies esenciales</p>',
    en: '<p>Essential cookies</p>',
    pt: '<p>Essential cookies</p>',
    fr: '<p>Essential cookies</p>',
  },

  cookies_config_Data7: {
    es: '<p>Cookies analíticas</p>',
    en: '<p>Analytical cookies</p>',
    pt: '<p>Analytical cookies</p>',
    fr: '<p>Analytical cookies</p>',
  },

  cookies_config_Data8: {
    es: '<p>Cookies de funcionalidad</p>',
    en: '<p>Functionality cookies</p>',
    pt: '<p>Functionality cookies</p>',
    fr: '<p>Functionality cookies</p>',
    },

  cookies_config_Data9: {
    es: '<p>Cookies de marketing y publicidad</p>',
    en: '<p>Marketing and advertising cookies</p>',
    pt: '<p>Marketing and advertising cookies</p>',
    fr: '<p>Marketing and advertising cookies</p>',
  },

  cookies_config_Data10: {
    es: '<p>Cookies de prevención del fraude</p>',
    en: '<p>Fraud prevention cookies</p>',
    pt: '<p>Fraud prevention cookies</p>',
    fr: '<p>Fraud prevention cookies</p>',
   },

  cookies_config_Data11: {
    es: '<p>Cookies de seguridad</p>',
    en: '<p>Security cookies</p>',
    pt: '<p>Security cookies</p>',
    fr: '<p>Security cookies</p>',
  },

  cookies_config_Data12: {
    es: '<p>Prevención del fraude</p>',
    en: '<p>Fraud prevention</p>',
    pt: '<p>Fraud prevention</p>',
    fr: '<p>Fraud prevention</p>',
  },

  cookies_config_Data13: {
    es: '<p>Seguridad</p>',
    en: '<p>Security</p>',
    pt: '<p>Security</p>',
    fr: '<p>Security</p>',
   },

  cookies_config_Data14: {
    es: '<p>Activas siempre</p>',
    en: '<p>Always enabled</p>',
    pt: '<p>Always enabled</p>',
    fr: '<p>Always enabled</p>',
   },

  cookies_config_Data15: {
    es: '<p>Estas cookies son necesarias para que el sitio web funcione correctamente. Incluyen cookies que te permiten iniciar sesión en áreas seguras de nuestro sitio web y utilizar funciones básicas como la navegación y el acceso a áreas seguras. Sin estas cookies, el usuario no podrá recibir correctamente nuestros contenidos y servicios. Estas cookies no almacenan ninguna información de identificación personal.</p>',
    en: '<p>These cookies are necessary for the website to function properly. They include cookies that allow you to log in to secure areas of our website and use basic functions, such as browsing and access to secure areas. Without these cookies, the user will not be able to correctly receive our content and services. These cookies do not store any personally identifiable information.</p>',
    pt: '<p>These cookies are necessary for the website to function properly. They include cookies that allow you to log in to secure areas of our website and use basic functions, such as browsing and access to secure areas. Without these cookies, the user will not be able to correctly receive our content and services. These cookies do not store any personally identifiable information.</p>',
    fr: '<p>These cookies are necessary for the website to function properly. They include cookies that allow you to log in to secure areas of our website and use basic functions, such as browsing and access to secure areas. Without these cookies, the user will not be able to correctly receive our content and services. These cookies do not store any personally identifiable information.</p>',
   },

  cookies_config_Data16: {
    es: '<p>Información de las cookies</p>',
    en: '<p>Cookie details</p>',
    pt: '<p>Cookie details</p>',
    fr: '<p>Cookie details</p>',
    },

  cookies_config_Data17: {
    es: '<p>Nombre</p>',
    en: '<p>Name</p>',
    pt: '<p>Name</p>',
    fr: '<p>Name</p>',
  },

  cookies_config_Data18: {
    es: '<p>Tipo</p>',
    en: '<p>Type</p>',
    pt: '<p>Type</p>',
    fr: '<p>Type</p>',
   },

  cookies_config_Data19: {
    es: '<p>Duración</p>',
    en: '<p>Duration</p>',
    pt: '<p>Duration</p>',
    fr: '<p>Duration</p>',
  },

  cookies_config_Data20: {
    es: '<p>Propia</p>',
    en: '<p>Own</p>',
    pt: '<p>Own</p>',
    fr: '<p>Own</p>',
  },

  cookies_config_Data21: {
    es: '<p>Activadas</p>',
    en: '<p>Enabled</p>',
    pt: '<p>Enabled</p>',
    fr: '<p>Enabled</p>',
   },

  cookies_config_Data22: {
    es: '<p>Estas cookies recopilan información sobre cómo los usuarios utilizan nuestro sitio web, como las páginas que visitan con mayor frecuencia y si experimentan algún error. Esta información nos ayuda a mejorar la funcionalidad y el rendimiento de nuestro sitio web, Si no permite utilizar estas cookies, no sabremos cuándo visitó nuestro sitio y no podremos evaluar si funcionó correctamente</p>',
    en: '<p>These cookies collect information about how users use our website, such as which pages they visit most often and whether they experience any errors. This information helps us improve the functionality and performance of our website. If you do not allow these cookies to be used, we will not know when you visited our site and we will not be able to assess whether it worked properly</p>',
    pt: '<p>These cookies collect information about how users use our website, such as which pages they visit most often and whether they experience any errors. This information helps us improve the functionality and performance of our website. If you do not allow these cookies to be used, we will not know when you visited our site and will not be able to assess whether it worked properly.</p>',
    fr: '<p>These cookies collect information about how users use our website, such as which pages they visit most often and whether they experience any errors. This information helps us improve the functionality and performance of our website. If you do not allow these cookies to be used, we will not know when you visited our site and will not be able to assess whether it worked properly.</p>',
  },

  cookies_config_Data23: {
    es: '<p>Terceros \n (Google Analytics)</p>',
    en: '<p>Third-party \n (Google Analytics)</p>',
    pt: '<p>Third-party \n (Google Analytics)</p>',
    fr: '<p>Third-party \n (Google Analytics)</p>',
   },

  cookies_config_Data24: {
    es: '<p>Terceros \n (Stripe)</p>',
    en: '<p>Third-party \n (Stripe)</p>',
    pt: '<p>Third-party \n (Stripe)</p>',
    fr: '<p>Third-party \n (Stripe)</p>',
   },

  cookies_config_Data25: {
    es: '<p>Terceros \n (Clarity)</p>',
    en: '<p>Third-party \n (Clarity)</p>',
    pt: '<p>Third-party \n (Clarity)</p>',
    fr: '<p>Third-party \n (Clarity)</p>',
   },

  cookies_config_Data26: {
    es: '<p>Terceros \n (Google Ads)</p>',
    en: '<p>Third-party \n (Google Ads)</p>',
    pt: '<p>Third-party \n (Google Ads)</p>',
    fr: '<p>Third-party \n (Google Ads)</p>',
   },

  cookies_config_Data27: {
    es: '<p>720 días</p>',
    en: '<p>720 days</p>',
    pt: '<p>720 days</p>',
    fr: '<p>720 days</p>',
  },

  cookies_config_Data28: {
    es: '<p>1 día</p>',
    en: '<p>1 day</p>',
    pt: '<p>1 day</p>',
    fr: '<p>1 day</p>',
   },

  cookies_config_Data29: {
    es: '<p>1 minuto</p>',
    en: '<p>1 minute</p>',
    pt: '<p>1 minute</p>',
    fr: '<p>1 minute</p>',
  },

  cookies_config_Data30: {
    es: '<p>360 días</p>',
    en: '<p>360 days</p>',
    pt: '<p>360 days</p>',
    fr: '<p>360 days</p>',
   },

  cookies_config_Data31: {
    es: '<p>90 días</p>',
    en: '<p>90 days</p>',
    pt: '<p>90 days</p>',
    fr: '<p>90 days</p>',
  },

  cookies_config_Data32: {
    es: '<p>10 minutos</p>',
    en: '<p>10 minutes</p>',
    pt: '<p>10 minutes</p>',
    fr: '<p>10 minutes</p>',
   },

  cookies_config_Data33: {
    es: '<p>30 minutos</p>',
    en: '<p>30 minutes</p>',
    pt: '<p>30 minutes</p>',
    fr: '<p>30 minutes</p>',
  },

  cookies_config_Data34: {
    es: '<p>180 días</p>',
    en: '<p>180 days</p>',
    pt: '<p>180 days</p>',
    fr: '<p>180 days</p>',
   },

  cookies_config_Data35: {
    es: '<p>540 días</p>',
    en: '<p>540 days</p>',
    pt: '<p>540 days</p>',
    fr: '<p>540 days</p>',
   },

  cookies_config_Data36: {
    es: '<p>Sesión</p>',
    en: '<p>Session</p>',
    pt: '<p>Session</p>',
    fr: '<p>Session</p>',
  },

  cookies_config_Data37: {
    es: '<p>Guardar selección</p>',
    en: '<p>Save selection</p>',
    pt: '<p>Save selection</p>',
    fr: '<p>Save selection</p>',
   },

  cookies_config_Data38: {
    es: '<p>Estas cookies se utilizan para recordar las elecciones que haces y personalizar tu experiencia en nuestro sitio web. Esto puede incluir recordar tu nombre de usuario, idioma preferido y región. Si no permite utilizar estás cookies, es posible que algunos de estos servicios no funcionen correctamente.</p>',
    en: '<p>These cookies are used to remember the choices you make and customize your experience on our website. This may include remembering your username, preferred language, and region. If you don\'t allow these cookies, some of these services may not work properly.</p>',
    pt: '<p>These cookies are used to remember the choices you make and customize your experience on our website. This may include remembering your username, preferred language, and region. If you don\'t allow these cookies, some of these services may not work properly.</p>',
    fr: '<p>These cookies are used to remember the choices you make and customize your experience on our website. This may include remembering your username, preferred language, and region. If you don\'t allow these cookies, some of these services may not work properly.</p>',
   },

  cookies_config_Data39: {
    es: '<p>Trabajamos con terceros para mostrar anuncios relevantes en nuestro sitio web y en otros sitios web. Estas cookies se utilizan para rastrear tu actividad en línea y mostrar anuncios que pueden ser de tu interés. Si no permite utilizar estas cookies, verá menos publicidad dirigida.</p>',
    en: '<p>We work with third parties to display relevant advertisements on our website and on other websites. These cookies are used to track your online activity and display advertisements that may be of interest to you. If you do not allow these cookies to be used, you will see less targeted advertising.</p>',
    pt: '<p>We work with third parties to display relevant advertisements on our website and on other websites. These cookies are used to track your online activity and display advertisements that may be of interest to you. If you do not allow these cookies to be used, you will see less targeted advertising.</p>',
    fr: '<p>We work with third parties to display relevant advertisements on our website and on other websites. These cookies are used to track your online activity and display advertisements that may be of interest to you. If you do not allow these cookies to be used, you will see less targeted advertising.</p>',
   },

  cookies_config_Data40: {
    es: '<p>Estas cookies se utilizan para prevenir y detectar actividades fraudulentas en nuestro sitio web. Ayudan a proteger tus datos personales y asegurar que tengas una experiencia segura mientras navegas por nuestro sitio. Si no permites el uso de estas cookies, podrías estar más expuesto/a a posibles actividades fraudulentas.</p>',
    en: '<p>These cookies are used to prevent and detect fraudulent activity on our website. They help protect your personal data and ensure that you have a secure browsing experience on our site. If you do not allow the use of these cookies, you could be more exposed to possible fraudulent activities.</p>',
    pt: '<p>These cookies are used to prevent and detect fraudulent activity on our website. They help protect your personal data and ensure that you have a secure browsing experience on our site. If you do not allow the use of these cookies, you could be more exposed to possible fraudulent activities.</p>',
    fr: '<p>These cookies are used to prevent and detect fraudulent activity on our website. They help protect your personal data and ensure that you have a secure browsing experience on our site. If you do not allow the use of these cookies, you could be more exposed to possible fraudulent activities.</p>',
    },

  cookies_config_Data41: {
    es: '<p>Estas cookies se utilizan para garantizar la seguridad de nuestro sitio web y protegerlo contra posibles amenazas, como ataques de hackers o malware. Ayudan a mantener la integridad y confiabilidad de nuestro sitio, asegurando que tus datos y transacciones estén protegidos. Si no permites el uso de estas cookies, el sitio podría ser más vulnerable a posibles ataques de seguridad.</p>',
    en: '<p>These cookies are used to ensure that our website is secure and protect it against possible threats, such as hacker attacks or malware. They help maintain the integrity and reliability of our site, ensuring that your data and transactions are protected. If you do not allow the use of these cookies, the site may be more vulnerable to possible security attacks.</p>',
    pt: '<p>These cookies are used to ensure that our website is secure and protect it against possible threats, such as hacker attacks or malware. They help maintain the integrity and reliability of our site, ensuring that your data and transactions are protected. If you do not allow the use of these cookies, the site may be more vulnerable to possible security attacks.</p>',
    fr: '<p>These cookies are used to ensure that our website is secure and protect it against possible threats, such as hacker attacks or malware. They help maintain the integrity and reliability of our site, ensuring that your data and transactions are protected. If you do not allow the use of these cookies, the site may be more vulnerable to possible security attacks.</p>',
  },

  cookies_config_Data42: {
    es: '<p>Desactivadas</p>',
    en: '<p>Disabled</p>',
    pt: '<p>Disabled</p>',
    fr: '<p>Disabled</p>',
   },

  cookies_config_Data43: {
    es: '<p>< Lista de cookies</p>',
    en: '<p>< Cookie list</p>',
    pt: '<p>< Cookie list</p>',
    fr: '<p>< Cookie list</p>',
  },

  cookies_config_Data44: {
    es: '<p>30 días</p>',
    en: '<p>30 days</p>',
    pt: '<p>30 days</p>',
    fr: '<p>30 days</p>',
  },

  cookies_config_Data45: {
    es: '<p>390 días</p>',
    en: '<p>390 days</p>',
    pt: '<p>390 days</p>',
    fr: '<p>390 days</p>',
 },

};
