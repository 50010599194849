export const SpanishRoutes = {
  Home: {
    URL: '',
  },
  Rates: {
    URL: 'tarifas',
  },
  Faq: {
    URL: 'ayuda/faq',
  },
  Contact: {
    URL: 'contacto',
  },
  Blog: {
    URL: 'blog',
  },
  BlogWithParams: {
    URL: 'blog/:id',
  },
  Register: {
    URL: 'registro',
  },
  RegisterWithPromotion: {
    URL: 'registro/:promotion',
  },
  Login: {
    URL: 'iniciar-sesion',
  },
  TermsOfUse: {
    URL: 'condiciones-uso',
  },
  Cookies: {
    URL: 'cookies',
  },
  Privacy: {
    URL: 'politica-privacidad',
  },
  Hiring: {
    URL: 'politica-contratacion',
  },
  HiringWithRate: {
    URL: 'politica-contratacion/:rate',
  },
  HelpCenter: {
    URL: 'ayuda',
  },
  HCLibraryExtended: {
    URL: 'ayuda/biblioteca',
  },
  // HCFirstStepsExtended: {
  //   URL: 'ayuda/inicio',
  // },
  HCLibraryExtendedTools: {
    URL: 'ayuda/biblioteca/:tool',
  },
  'android-login': {
    URL: 'android-login',
  },
  'android-register': {
    URL: 'android-register',
  },
  'email-verified': {
    URL: 'email-verified/:token',
  },
};
