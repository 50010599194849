export const FrenchRoutes = {
  Home: {
    URL: 'fr',
  },
  Rates: {
    URL: 'fr/tarifs',
  },
  Faq: {
    URL: 'fr/aide/faq',
  },
  Contact: {
    URL: 'fr/contact',
  },
  Blog: {
    URL: 'fr/blog',
  },
  BlogWithParams: {
    URL: 'fr/blog/:id',
  },
  Register: {
    URL: 'fr/enregistrement',
  },
  RegisterWithPromotion: {
    URL: 'fr/enregistrement/:promotion',
  },
  Login: {
    URL: 'fr/login',
  },
  TermsOfUse: {
    URL: 'fr/terms',
  },
  Cookies: {
    URL: 'fr/cookies',
  },
  Privacy: {
    URL: 'fr/privacy',
  },
  Hiring: {
    URL: 'fr/contract',
  },
  HiringWithRate: {
    URL: 'fr/contract/:rate',
  },
  HelpCenter: {
    URL: 'fr/aide',
  },
  HCLibraryExtended: {
    URL: 'fr/aide/bibliotheque',
  },
  // HCFirstStepsExtended: {
  //   URL: 'fr/aide/accueil',
  // },
  HCLibraryExtendedTools: {
    URL: 'fr/aide/bibliotheque/:tool',
  },
  'android-login': {
    URL: 'fr/android-login',
  },
  'android-register': {
    URL: 'fr/android-register',
  },
  'email-verified': {
    URL: 'fr/email-verified/:token',
  },
};
