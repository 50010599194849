<app-start></app-start>
<app-generate-model></app-generate-model>
<div appLazyLoad (inViewport)="loadComponent(componentsEnum.LANDING_VIDEO)">
  <app-landing-video *ngIf="isComponentLoaded(componentsEnum.LANDING_VIDEO)"></app-landing-video>
</div>
<div appLazyLoad (inViewport)="loadComponent(componentsEnum.THREE_STEPS)">
  <app-three-steps *ngIf="isComponentLoaded(componentsEnum.THREE_STEPS)"></app-three-steps>
</div>
<div appLazyLoad (inViewport)="loadComponent(componentsEnum.EXPLORE_MODELS)">
  <app-explore-models *ngIf="isComponentLoaded(componentsEnum.EXPLORE_MODELS)"></app-explore-models>
</div>
<div appLazyLoad (inViewport)="loadComponent(componentsEnum.LANDING_RATES)">
  <app-landing-rates *ngIf="isComponentLoaded(componentsEnum.LANDING_RATES)"></app-landing-rates>
</div>
<div appLazyLoad (inViewport)="loadComponent(componentsEnum.FAQS)">
  <app-faqs *ngIf="isComponentLoaded(componentsEnum.FAQS)"></app-faqs>
</div>
<div appLazyLoad (inViewport)="loadComponent(componentsEnum.LAST_BLOGS)">
  <app-last-blogs *ngIf="isComponentLoaded(componentsEnum.LAST_BLOGS)"></app-last-blogs>
</div>
