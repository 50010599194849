import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WelcomeService {
  showWelcome: boolean = true;
  showWelcomeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  constructor() {}
  setShowWelcome(): void {
    this.showWelcome = false;
    this.showWelcomeSubject.next(this.showWelcome);
  }
  //TEST
  getShowWelcome(): Observable<boolean> {
    return this.showWelcomeSubject.asObservable();
  }
}
