import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, filter } from 'rxjs';
import { I18nService } from 'src/app/commons/services/i18n.service';
import { SecurityTokenStorage } from 'src/app/commons/services/security-token-storage';
import { StorageService } from 'src/app/commons/services/storage.service';
import { UserToken } from 'src/app/commons/services/user-token';
import { User } from '../models/user';
import { AcceptedLogin } from '../models/accepted-login';
import { BaseProfile } from '../models/base-profile';
import { LoginRemoteService } from './login-remote.service';
import { RestClientService } from 'src/app/commons/services/rest-client.service';
import { ForgotPasswordRemoteService } from './forgot-password-remote.service';
import { Credential } from '../models/credential';
import { SocialCredential } from '../models/socialcredential';
import { EmailVerifiedRemoteService } from './email-verified-remote.service';
import { TwoFactorStorage } from 'src/app/commons/services/two-factor-storage';
import { TwoFactorService } from './two-factor.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Sources } from '../models/two-factor/enums/sources';
import { RegisterData } from '../models/register-data';
import { RegisterService } from './register.service';
import { WindowService } from 'src/app/commons/services/window.service';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { LastLoginCall } from '../models/last-login-call';
import { LoginType } from '../models/login-type';

type AppleAuthData = {
  authorization: {
    code: string;
    id_token: string;
  };
  email: string;
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
};
import { CookiesService } from './cookies-service.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private user: User = null;

  authUser$ = new BehaviorSubject<User>(null);
  private OnLogin = new Subject<AcceptedLogin<BaseProfile>>();
  private OnLogout = new Subject();

  private URL = '/two-factor';

  private lastLoginCall: LastLoginCall;

  constructor(
    private loginRemoteService: LoginRemoteService,
    private securityTokenStorage: SecurityTokenStorage<UserToken>,
    private router: Router,
    private restClientService: RestClientService,
    private i18nService: I18nService,
    private forgotPasswordRemoteService: ForgotPasswordRemoteService,
    private storageService: StorageService,
    private emailVerifiedRemoteService: EmailVerifiedRemoteService,
    private twoFactorStorage: TwoFactorStorage<boolean>,
    private twoFactorService: TwoFactorService,
    private httpClient: HttpClient,
    private registerService: RegisterService,
    private windowService: WindowService,
    private cookiesService: CookiesService
  ) {
    this.setDefaultLanguage();
    this.securityTokenStorage
      .onSessionExpired()
      .subscribe(() => this.redirectToLogin());
  }

  static getAuthenticationUrl(): string {
    return 'login';
  }

  get(redirectToLogin = true): Observable<User> {
    const subject = new Subject<any>();
    this.restClientService.get('auth-user').subscribe(
      (user) => {
        subject.next(user);
        this.authUser$.next(user as User);
      },
      () => {
        if (redirectToLogin) {
          this.redirectToLogin();
        } else {
          subject.error('Not authenticated user');
        }
      }
    );
    return subject;
  }

  public repeatLastLogin<T extends BaseProfile>(): Observable<
    AcceptedLogin<T>
  > {
    return new Observable((subscriber) => {
      if (this.lastLoginCall) {
        if (this.lastLoginCall.type === LoginType.DEFAULT) {
          this.login(
            this.lastLoginCall.data.email,
            this.lastLoginCall.data.password,
            true
          ).subscribe({
            next: (response: any) => {
              subscriber.next(response);
              subscriber.complete();
            },
            error: () => {
              subscriber.error(null);
              subscriber.complete();
            },
          });
        } else if (this.lastLoginCall.type === LoginType.GOOGLE) {
          this.googleLogin(this.lastLoginCall.data, true).subscribe({
            next: (response) => {
              subscriber.next(response);
              subscriber.complete();
            },
            error: () => {
              subscriber.error(null);
              subscriber.complete();
            },
          });
        } else if (this.lastLoginCall.type === LoginType.APPLE) {
          this.appleLogin(this.lastLoginCall.data, true).subscribe({
            next: (response) => {
              subscriber.next(response);
              subscriber.complete();
            },
            error: () => {
              subscriber.error(null);
              subscriber.complete();
            },
          });
        }
      } else {
        subscriber.next(null);
        subscriber.complete();
      }
    });
  }

  login<T extends BaseProfile>(
    email: string,
    password: string,
    ignoreResponse: boolean = false,
    rememberMe: boolean = false
  ): Observable<AcceptedLogin<T>> {
    this.lastLoginCall = { type: LoginType.DEFAULT, data: { email, password } };
    const subject = new Subject<AcceptedLogin<T>>();
    this.loginRemoteService.login(new Credential(email, password)).subscribe(
      (loginResponse) => {
        if (!ignoreResponse) {
          this.processLoginResponse(loginResponse, email, password, subject);
        } else {
          subject.next(null);
        }
        if (rememberMe) {
          localStorage.setItem('eyescloud3dUser', email);
          localStorage.setItem('eyescloud3dPassword', password);
        } else {
          localStorage.removeItem('eyescloud3dUser');
          localStorage.removeItem('eyescloud3dPassword');
        }
      },
      (error) => {
        if (error.error === 3) {
          this.twoFactorService.openLimitReachedDialog();
        }
        subject.error(error.error);
      }
    );
    return subject;
  }

  logout() {
    const tokenObj = this.securityTokenStorage.getAcceptedLogin();
    if (tokenObj !== null) {
      this.loginRemoteService.logout().subscribe(() => {
        this.redirectToLogin();
      });
    } else {
      this.redirectToLogin();
    }
  }

  deleteSecurityTokenInfo() {
    this.securityTokenStorage.deleteFromStorage();
    this.redirectToLogin();
  }

  onLogin(): Observable<any> {
    return this.OnLogin;
  }

  onLogout(): Observable<any> {
    return this.OnLogout;
  }

  socialLogin<T extends BaseProfile>(
    email: string,
    authToken: string
  ): Observable<AcceptedLogin<T>> {
    const subject = new Subject<AcceptedLogin<T>>();
    this.loginRemoteService
      .socialLogin<T>(new SocialCredential(email, authToken))
      .subscribe({
        next: (acceptedLogin) => {
          if (acceptedLogin.token) {
            this.configure(acceptedLogin);
            subject.next(acceptedLogin);
            this.OnLogin.next(acceptedLogin);
          } else {
            subject.error(acceptedLogin.error);
          }
        },
        error: (error) => {
          subject.error(error.error);
        },
      });
    return subject;
  }

  forgotPassword(email) {
    const subject = new Subject<any>();
    const emailParameter = {
      email: email.email,
      language_code: this.i18nService.getCurrentLanguage().code,
    };

    this.forgotPasswordRemoteService.forgotPassword(emailParameter).subscribe(
      (response) => {
        subject.next(response);
      },
      (error) => {
        subject.error(error);
      }
    );
    return subject;
  }

  resetPassword(parameters) {
    const subject = new Subject<any>();
    this.forgotPasswordRemoteService.resetPassword(parameters).subscribe(
      (acceptedLogin) => {
        if (acceptedLogin.token) {
          this.configure(acceptedLogin);
          subject.next(acceptedLogin);
          this.OnLogin.next(acceptedLogin);
        } else {
          subject.error(acceptedLogin.error);
        }
      },
      (error) => {
        subject.error(error);
      }
    );
    return subject;
  }

  verifiedEmail<T extends BaseProfile>(token): Observable<AcceptedLogin<T>> {
    const subject = new Subject<any>();
    this.emailVerifiedRemoteService.verify(token).subscribe(
      (acceptedLogin) => {
        if (acceptedLogin.token) {
          this.configure(acceptedLogin);
          subject.next(acceptedLogin);
          this.OnLogin.next(acceptedLogin);
        } else {
          subject.error(acceptedLogin.error);
        }
      },
      (error) => {
        subject.error(error.error);
      }
    );

    return subject;
  }

  loginWithToken<T extends BaseProfile>(token): Observable<AcceptedLogin<T>> {
    const subject = new Subject<any>();
    this.loginRemoteService.loginWithToken(token).subscribe(
      (acceptedLogin) => {
        if (acceptedLogin.token) {
          this.configure(acceptedLogin);
          subject.next(acceptedLogin);
          this.OnLogin.next(acceptedLogin);
        } else {
          subject.error(acceptedLogin.error);
        }
      },
      (error) => {
        subject.error(error.error);
      }
    );

    return subject;
  }

  public register(data: RegisterData): Observable<any> {
    return this.restClientService.post('register', data);
  }

  private setDefaultLanguage(): void {
    const userInfo = this.securityTokenStorage.getObjectValue();
    const defaultLanguage = userInfo
      ? userInfo.locale
      : this.i18nService.getCurrentLanguage().code;
    if (
      this.storageService.get('language') &&
      this.cookiesService.getSelectedCookieValue('FUNCTIONALITY')
    ) {
      this.i18nService.setCurrentLanguage(this.storageService.get('language'));
    } else if (userInfo) {
      this.i18nService.setCurrentLanguage(userInfo.locale);
    } else {
      if (this.windowService.isBrowser) {
        // const browserLang = navigator.language;
        // const browserFormatted = browserLang.substr(0, 2);
        const browserFormatted = this.i18nService.getCurrentLanguage().code;

        if (browserFormatted === 'en') {
          this.i18nService.setCurrentLanguage('en');
        } else if (browserFormatted === 'es') {
          this.i18nService.setCurrentLanguage('es');
        } else {
          this.i18nService.setCurrentLanguage(defaultLanguage);
        }
      } else {
        this.i18nService.setCurrentLanguage(defaultLanguage);
      }
    }
  }

  private redirectToLogin(): void {
    this.securityTokenStorage.deleteFromStorage();
    this.router
      .navigateByUrl(AuthenticationService.getAuthenticationUrl())
      .then(() => {});
  }

  private configure<T extends BaseProfile>(
    acceptedLogin: AcceptedLogin<T>
  ): void {
    const tokenObj = {
      token: acceptedLogin.token,
      tokenExpirationDate: acceptedLogin.tokenExpirationDate,
      locale: acceptedLogin.locale,
    };
    this.securityTokenStorage.saveObject(tokenObj);
    this.twoFactorService.setUserData(acceptedLogin);
    this.setDefaultLanguage();
  }

  public useCommasForDecimals(): Observable<boolean> {
    return this.restClientService.get('commas-period-style');
  }

  public checkIp(): Observable<any> {
    return this.restClientService.get('check-ip');
  }

  public sendTwoFactorCodeByEmail(email = null): Observable<any> {
    return this.restClientService.post(`${this.URL}/send-email`, email);
  }

  public sendTwoFactorCodeBySms(): Observable<any> {
    return this.restClientService.post(`${this.URL}/send-sms`, null);
  }

  public checkTwoFactorCode(
    mCode: string,
    mSetNullIfCorrect: boolean = true
  ): Observable<any> {
    return this.restClientService.put(`${this.URL}/check-code`, {
      code: mCode,
      setNullIfCorrect: mSetNullIfCorrect,
    });
  }

  public isEmailAvailable(email: string): Observable<any> {
    return this.restClientService.get(
      `${this.URL}/is-email-available/${email}`
    );
  }

  public resendVerificationEmail(email: string): Observable<any> {
    return this.restClientService.post('resend-validation/email', { email });
  }

  public googleLogin<T extends BaseProfile>(
    user: SocialUser,
    ignoreResponse: boolean = false
  ): Observable<any> {
    this.lastLoginCall = { type: LoginType.GOOGLE, data: user };
    const subject = new Subject<AcceptedLogin<T>>();
    this.restClientService
      .post('login/google', { token: user.idToken })
      .subscribe({
        next: (loginResponse) => {
          if (!ignoreResponse) {
            this.processLoginResponse(loginResponse, user.email, '', subject);
          } else {
            subject.next(null);
          }
        },
        error: (error) => {
          if (error.error === 3) {
            this.twoFactorService.openLimitReachedDialog();
          }
          subject.error(error.error);
        },
      });
    return subject;
  }

  public appleLogin<T extends BaseProfile>(
    authData: AppleAuthData,
    ignoreResponse: boolean = false
  ): Observable<any> {
    this.lastLoginCall = { type: LoginType.APPLE, data: authData };
    const subject = new Subject<AcceptedLogin<T>>();
    this.restClientService.post('login/apple', authData).subscribe({
      next: (loginResponse: any) => {
        if (!ignoreResponse) {
          this.processLoginResponse(loginResponse, authData.email, '', subject);
        } else {
          subject.next(null);
        }
      },
      error: (error) => {
        if (error.error === 3) {
          this.twoFactorService.openLimitReachedDialog();
        }
        subject.error(error.error);
      },
    });
    return subject;
  }

  private processLoginResponse<T extends BaseProfile>(
    loginResponse: any,
    email: string,
    password: string,
    subject: Subject<AcceptedLogin<T>>
  ) {
    if (loginResponse.status === 0) {
      if (loginResponse.data.token) {
        this.configure(loginResponse.data);
        subject.next(loginResponse.data);
        this.OnLogin.next(loginResponse.data);
      }
      if (this.twoFactorStorage.getObjectValue()) {
        this.twoFactorService.openProtectYourAccountDialog(loginResponse.data);
      } else {
        this.twoFactorService.goToAppEyescloud3d();
      }
    } else if ([1, 2].includes(loginResponse.status)) {
      this.twoFactorService.openIsActiveDialog(
        loginResponse.status === 1 ? Sources.EMAIL : Sources.PHONE,
        loginResponse.data,
        new Credential(email, password)
      );
    } else if (loginResponse.status === 4) {
      this.registerService.email = email;
      this.registerService.openRegisterStepTwoDialog();
    }
  }
}
