import { CookiesConfigEnum } from "./cookies-config-enum";

export const cookiesLists = {
  [CookiesConfigEnum.ESSENTIAL]: [
    { name: 'eyescloud3d-acceptedLogin', type: 'cookies_config_Data20', duration: 'cookies_config_Data27' },
    { name: 'eyesCloud3D', type: 'cookies_config_Data20', duration: 'cookies_config_Data27' }
  ],
  [CookiesConfigEnum.ANALYSIS]: [
    { name: '_ga', type: 'cookies_config_Data23', duration: 'cookies_config_Data27' },
    { name: '_ga_<container-id>', type: 'cookies_config_Data23', duration: 'cookies_config_Data27' },
    { name: '_gid', type: 'cookies_config_Data23', duration: 'cookies_config_Data28' },
    { name: '_gat', type: 'cookies_config_Data23', duration: 'cookies_config_Data29' },
    { name: 'AMP_TOKEN', type: 'cookies_config_Data23', duration: 'cookies_config_Data30' },
    { name: '_gac_<property-id>', type: 'cookies_config_Data23', duration: 'cookies_config_Data31' },
    { name: '_gaexp', type: 'cookies_config_Data23', duration: 'cookies_config_Data31' },
    { name: '_gcl_au', type: 'cookies_config_Data23', duration: 'cookies_config_Data31' },
    { name: '_stripe_orig_props', type: 'cookies_config_Data24', duration: 'cookies_config_Data30' },
    { name: 'cid', type: 'cookies_config_Data24', duration: 'cookies_config_Data31' }
  ],
  [CookiesConfigEnum.FUNCTIONALITY]: [
    { name: 'CookiesRead', type: 'cookies_config_Data20', duration: 'cookies_config_Data27' },
    { name: 'IotaRead', type: 'cookies_config_Data20', duration: 'cookies_config_Data27' },
    { name: 'language', type: 'cookies_config_Data20', duration: 'cookies_config_Data27' },
    { name: 'userColor', type: 'cookies_config_Data20', duration: 'cookies_config_Data27' },
    { name: 'userColorId', type: 'cookies_config_Data20', duration: 'cookies_config_Data27' },
    { name: '_utma', type: 'cookies_config_Data23', duration: 'cookies_config_Data27' },
    { name: '_utmt', type: 'cookies_config_Data23', duration: 'cookies_config_Data32' },
    { name: '_utmb', type: 'cookies_config_Data23', duration: 'cookies_config_Data33' },
    { name: '_utmz', type: 'cookies_config_Data23', duration: 'cookies_config_Data34' },
    { name: '_utmv', type: 'cookies_config_Data23', duration: 'cookies_config_Data27' },
    { name: '_utmx', type: 'cookies_config_Data23', duration: 'cookies_config_Data35' },
    { name: '_utmxx', type: 'cookies_config_Data23', duration: 'cookies_config_Data35' },
    { name: 'Cookie-perms', type: 'cookies_config_Data24', duration: 'cookies_config_Data34' },
    { name: 'Locale', type: 'cookies_config_Data24', duration: 'cookies_config_Data36' },
    { name: '_clck', type: 'cookies_config_Data25', duration: 'cookies_config_Data30' },
    { name: '_clsk', type: 'cookies_config_Data25', duration: 'cookies_config_Data28' }
  ],
  [CookiesConfigEnum.MARKETING]: [
    { name: '1P_JAR', type: 'cookies_config_Data26', duration: 'cookies_config_Data44' },
    { name: 'AID', type: 'cookies_config_Data23', duration: 'cookies_config_Data45' },
    { name: 'IDE', type: 'cookies_config_Data23', duration: 'cookies_config_Data45' },
    { name: 'NID', type: 'cookies_config_Data26', duration: 'cookies_config_Data34' }
  ],
  [CookiesConfigEnum.FRAUD]: [
    { name: 'private_machine_identifier', type: 'cookies_config_Data24', duration: 'cookies_config_Data30' },
    { name: 'Machine_identifier', type: 'cookies_config_Data24', duration: 'cookies_config_Data30' },
    { name: 'm', type: 'cookies_config_Data24', duration: 'cookies_config_Data27' },
  ],
  [CookiesConfigEnum.SECURITY]: [
    { name: 'stripe.csrf', type: 'cookies_config_Data24', duration: 'cookies_config_Data36' }
  ],
};
