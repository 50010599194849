import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';
import { UpdateMetaService } from 'src/app/commons/services/meta-service.service';
import { ResponsiveService } from 'src/app/commons/services/responsive.service';
import { environment } from 'src/environments/environment';

export enum RateTypeEnum {
  DEFAULT,
  BASIC,
  PROFESSIONAL,
  COMPANY,
}

export enum RatePeriodEnum {
  DEFAULT,
  MONTHLY,
  ANNUAL,
}

@Component({
  selector: 'app-rates-info',
  templateUrl: './rates-info.component.html',
  styleUrls: ['./rates-info.component.scss'],
})
export class RatesInfoComponent implements OnInit {
  translationModes: any = TranslationModeEnum;
  selectedRateType: RateTypeEnum = RateTypeEnum.BASIC;
  rateTypeEnum = RateTypeEnum;
  ratePeriodEnum: typeof RatePeriodEnum = RatePeriodEnum;
  selectedRatePeriod: RatePeriodEnum[] = [];
  underlinePosition: string = "0%";
  isTablet: boolean = this.responsiveService.isTablet();
  isPC: boolean = this.responsiveService.isPC();
  panelIsOpen: boolean = false;

  rateTypeOptions = [
    { value: RateTypeEnum.BASIC, label: "landing_rates_Data40" },
    { value: RateTypeEnum.PROFESSIONAL, label: "rates_Data57" },
    { value: RateTypeEnum.COMPANY, label: "rates_Data15" },
  ];

  @ViewChild("ratesContent") ratesContent: ElementRef;

  constructor(
    private responsiveService: ResponsiveService,
    private updateMetaService: UpdateMetaService
  ) { }

  ngOnInit(): void {
    this.updateMetaService.updateMetaTags("Rates");
    this.initSelectedRatePeriod();
  }

  goToPurchase(): string {
    return `${environment.appUrl}/profile/user-account-layout/user-rates`;
  }

  setRateType(rateType: RateTypeEnum): void {
    this.selectedRateType = rateType;
    this.updateUnderlinePosition();
  }

  private updateUnderlinePosition(): void {
    switch (this.selectedRateType) {
      case RateTypeEnum.BASIC:
        this.underlinePosition = "0%";
        break;
      case RateTypeEnum.PROFESSIONAL:
        this.underlinePosition = "33%";
        break;
      case RateTypeEnum.COMPANY:
        this.underlinePosition = "66%";
        break;
      default:
        this.underlinePosition = "0%";
    }
  }

  scrollLeft(): void {
    if (this.isPC) {
      this.ratesContent.nativeElement.scrollLeft -= 512;
    } else {
      this.ratesContent.nativeElement.scrollLeft -= 303;
    }
  }

  scrollRight(): void {
    if (this.isPC) {
      this.ratesContent.nativeElement.scrollLeft += 512;
    } else {
      this.ratesContent.nativeElement.scrollLeft += 303;
    }
  }

  handleOpenChange() {
    this.panelIsOpen = !this.panelIsOpen;
  }

  get filteredRateTypeOptions() {
    return this.selectedRateType
      ? this.rateTypeOptions.filter(
        (option) => option.value !== this.selectedRateType
      )
      : this.rateTypeOptions;
  }

  getSelectedRateLabel(): string {
    const selectedOption = this.rateTypeOptions.find(
      (option) => option.value === this.selectedRateType
    );
    return selectedOption ? selectedOption.label : '';
  }

  initSelectedRatePeriod(): void {
    this.selectedRatePeriod = Array(11).fill(RatePeriodEnum.ANNUAL);
  }

  changePeriod(index: number, period: RatePeriodEnum) {
    this.selectedRatePeriod[index] = period;
  }

  swapPeriod(index) {
    this.selectedRatePeriod[index] =
      this.selectedRatePeriod[index] === RatePeriodEnum.ANNUAL
        ? RatePeriodEnum.MONTHLY
        : RatePeriodEnum.ANNUAL;
  }
}
