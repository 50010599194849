import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';
import { ResponsiveService } from 'src/app/commons/services/responsive.service';
import { WindowService } from 'src/app/commons/services/window.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-generate-model',
  templateUrl: './generate-model.component.html',
  styleUrls: ['./generate-model.component.scss'],
})
export class GenerateModelComponent implements OnInit {
  isTablet: boolean = this.responseService.isTablet();
  firstModelTransform = '';
  secondModelTransform = '';
  lastScrollPosition = 0;
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;

  @ViewChild('scroll') scrollTarget!: ElementRef;
  constructor(
    private responseService: ResponsiveService,
    private windowService: WindowService
  ) {}

  ngOnInit(): void {}

  onResize(): void {
    this.isTablet = this.responseService.isTablet();
  }

  redirectToApp(): void {
    if (this.windowService.isBrowser) {
      window.location.href = `${environment.appUrl}/new3d`;
    }
  }

  scrollToModule(): void {
    if (this.scrollTarget) {
      this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    if (this.windowService.isBrowser) {
      const currentScrollPosition = window.scrollY;
      if (currentScrollPosition > this.lastScrollPosition) {
        this.animateModelsOnScroll(currentScrollPosition, 'down');
      } else {
        this.animateModelsOnScroll(currentScrollPosition, 'up');
      }
      this.lastScrollPosition = currentScrollPosition;
    }
  }

  private animateModelsOnScroll(
    scrollPosition: number,
    scrollDirection: 'up' | 'down'
  ): void {
    const componentRect =
      this.scrollTarget.nativeElement.getBoundingClientRect();
    const maxTranslateY = 200;
    if (
      componentRect.top >= 0 &&
      componentRect.bottom - 200 <= window.innerHeight
    ) {
      const visibleHeight = Math.min(
        componentRect.height,
        window.innerHeight - componentRect.top
      );
      let translateY: number;
      if (scrollDirection === 'down') {
        translateY = Math.min(maxTranslateY, scrollPosition / 2, visibleHeight);
      } else {
        translateY = Math.max(0, (visibleHeight - scrollPosition / 2) * -1);
      }
      this.firstModelTransform = `translateY(${translateY}px)`;
      this.secondModelTransform = `translateY(${translateY}px)`;
    }
  }
}
