import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestClientService } from './rest-client.service';
import { EndPointEnum } from '../enums/end-point';

@Injectable({
  providedIn: 'root',
})
export class NewsLetterService {
  public static scope: NewsLetterService;
  private URL = '/newsletter';
  constructor(
    private restClientService: RestClientService
  ) {
    NewsLetterService.scope = this;
  }

  public create(data: string): Observable<any> {
    const body = {
      mail: data,
    };
    return this.restClientService.post(`${this.URL}`, body, EndPointEnum.BLOG);
  }
}
