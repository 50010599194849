import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';
import { DateService } from './date.service';
import { UserToken } from './user-token';
import { BaseStorageService } from './base-storage.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorStorage<T extends boolean> extends BaseStorageService<T> {
  constructor(storageService: StorageService) {
    super(storageService);
  }

  override getObjectValue(): T {
    return this.storageService.getObject(this.getStorageKey()) ?? true;
  }

  protected getStorageKey(): string {
    return 'e3d_should_show_two_factor_popup';
  }
}
