import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RegisterStepThreeResponse } from 'src/app/auth/models/account-verification/register-step-three-response';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';
import { GenerateRouteService } from 'src/app/commons/services/generate-route.service';

@Component({
  selector: 'app-register-step-three-dialog',
  templateUrl: './register-step-three-dialog.component.html',
  styleUrls: ['./register-step-three-dialog.component.scss'],
})
export class RegisterStepThreeDialogComponent {
  responses: typeof RegisterStepThreeResponse;
  translationModes: any = TranslationModeEnum;

  constructor(
    private matDialogRef: MatDialogRef<RegisterStepThreeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public email: string,
    private generateRouteService: GenerateRouteService
  ) {
    this.responses = RegisterStepThreeResponse;
  }

  public closeDialog(
    response: RegisterStepThreeResponse = RegisterStepThreeResponse.DEFAULT
  ) {
    this.matDialogRef.close(response);
  }

  getRouterLink(routeKey: any): string {
    return this.generateRouteService.generateRoute(routeKey);
  }
}
