export const ERROR_I18N_ENTRIES = {
  error_Data1: {
    es: '<span>ERROR 404</span>',
    en: '<span>ERROR 404</span>',
    pt: '<span>ERROR 404</span>',
    fr: '<span>ERREUR 404</span>',
  },
  error_Data2: {
    es: '<p>Ups, la página que buscas no existe o no se encuentra disponible actualmente. Mientras puedes interactuar con nuestros modelos 3D, o mucho mejor, generar tu propio modelo 3D.</p>',
    en: '<p>Oops, the page you\'re looking for doesn’t exist or is not available at this time. However, you can interact with our 3D models, or better yet, generate your own 3D model.</p>',
    pt: '<p>Ups, a página que procura não existe ou não se encontra disponível de momento. Enquanto isso, pode interagir com os nossos modelos 3D, ou melhor, criar o seu próprio modelo 3D.</p>',
    fr: '<p>La page que vous recherchez n’existe pas ou est actuellement indisponible. En revanche, vous pouvez interagir avec nos modèles 3D ou, mieux encore, générer votre propre modèle 3D.</p>',
  },
  error_Data3: {
    es: '<p>IR A PÁGINA PRINCIPAL</p>',
    en: '<p>GO TO HOMEPAGE</p>',
    pt: '<p>IR PARA A PÁGINA PRINCIPAL</p>',
    fr: '<p>ALLER À LA PAGE PRINCIPALE</p>',
  },
  error_Data4: {
    es: '<p>Modelo 3D memorial Thomas Jefferson</p>',
    en: '<p>3D model Tomas Jefferson memorial</p>',
    pt: '<p>Modelo 3D memorial Thomas Jefferson</p>',
    fr: '<p>Modèle 3D mémorial Thomas Jefferson</p>',
  },
};
