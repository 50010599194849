<div class="contact-container" [ngClass]="{'help-center': isHelpCenterContact}">
  <div class="first-column">
    <h1 [innerHTML]="(isHelpCenterContact ? 'contact_Data16' : 'contact_Data1') | i18n"></h1>
  </div>
  <div class="second-column">
    <div class="form-title" [innerHTML]="(isHelpCenterContact ? 'contact_Data17' : 'contact_Data2') | i18n"></div>
    <div class="description" [innerHTML]="'contact_Data3' | i18n"></div>
    <form [formGroup]="contactForm" (ngSubmit)="submitForm()">
      <div class="form-group inputs">
        <input class="input" type="text" id="name" formControlName="name" required
          placeholder="{{ 'contact_Data4' | i18n: translationModes.PLACEHOLDER }}">
          <div class="required-invalid" *ngIf="formSubmitted && contactForm.get('name').invalid"
            [innerHTML]="'contact_Data14' | i18n"></div>
      </div>
      <div class="form-group inputs">
        <input class="input" type="email" id="mail" formControlName="mail" required
          placeholder="{{ 'contact_Data5' | i18n: translationModes.PLACEHOLDER }}">
          <div class="required-invalid" *ngIf="formSubmitted && contactForm.get('mail').invalid"
            [innerHTML]="'contact_Data14' | i18n"></div>
      </div>
      <div class="form-group inputs">
        <input class="input" type="tel" id="phone" formControlName="phone"
          placeholder="{{ 'contact_Data6' | i18n: translationModes.PLACEHOLDER }}">
      </div>
      <div class="form-group inputs">
        <textarea class="input textarea" id="message" formControlName="message" required
          placeholder="{{ 'contact_Data7' | i18n: translationModes.PLACEHOLDER }}"></textarea>
      </div>
      <div class="form-group checkbox" [ngClass]="{ 'invalid-check': formSubmitted && contactForm.get('termsAndConditions').invalid }">
        <input type="checkbox" formControlName="termsAndConditions">
        <div [innerHTML]="'contact_Data8' | i18n"></div>
      </div>
      <div class="required-invalid check-invalid" *ngIf="formSubmitted && contactForm.get('termsAndConditions').invalid"
        [innerHTML]="'contact_Data15' | i18n"></div>
      <div class="form-group checkbox last-checkbox">
        <input type="checkbox" formControlName="receiveCommunications">
        <div [innerHTML]="'contact_Data9' | i18n"></div>
      </div>
      <div class="form-group">
        <button type="submit" [innerHTML]="'contact_Data10' | i18n" class="Form_interaction-click-sendmessage"></button>
      </div>
    </form>
  </div>
</div>
<div appLazyLoad (inViewport)="loadComponent(componentsEnum.LAST_BLOGS)">
  <app-last-blogs *ngIf="isComponentLoaded(componentsEnum.LAST_BLOGS) && !isHelpCenterContact"></app-last-blogs>
</div>
