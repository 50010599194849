import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { CommonsModule } from './commons/commons.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlogModule } from './blog/blog.module';
import { AdminBlogModule } from './blog/components/admin-blog/admin-blog.module';
import { HttpClientModule } from '@angular/common/http';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    CommonsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BlogModule,
    AdminBlogModule,
  ],
  providers: [
    provideClientHydration(),
    { provide: 'googleTagManagerId', useValue: 'GTM-WVNPLNSK' },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '333176237235-46dn7djlh9560e1m2grfd30tvm558kje.apps.googleusercontent.com'
            ),
          },
        ],
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() { }
}
