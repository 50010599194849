import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestClientService } from './rest-client.service';
import { Category } from '../models/category';
import { EndPointEnum } from '../enums/end-point';
import { PlatformsEnum } from '../enums/platforms-enum';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  public static scope: CategoryService;
  private URL = '/category';
  constructor(private restClientService: RestClientService) {
    CategoryService.scope = this;
  }

  getAll(
    platformId: number = PlatformsEnum.EYESCLOUD3D
  ): Observable<Category[]> {
    const url = platformId
      ? `${this.URL}/all/${platformId}`
      : `${this.URL}/all`;
    return new Observable((subscriber) => {
      this.restClientService
        .get<any[]>(url, null, EndPointEnum.BLOG)
        .subscribe((categoryIds) => {
          const categories: Category[] = [];
          categoryIds.forEach((categoryId) => {
            categories.push(
              new Category(categoryId.id, categoryId.blogs_count)
            );
          });
          subscriber.next(categories);
          subscriber.complete();
        });
    });
  }
}
