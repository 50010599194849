export const BLOGLIST_I18N_ENTRIES = {
  bloglist_data1: {
    es: '<p>LEER ARTÍCULO</p>',
    en: '<p>READ ARTICLE</p>',
    pt: '<p>LER ARTIGO</p>',
    fr: '<p>LIRE L’ARTICLE</p>', 
  },
  bloglist_data2: {
    es: '<p>Categorías</p>',
    en: '<p>Categories</p>',
    pt: '<p>Categorias</p>',
    fr: '<p>Catégories</p>', 
  },

  bloglist_data3: {
    es: '<p>Filtrar y ordenar por:</p>',
    en: '<p>Filter and sort by:</p>',
    pt: '<p>Filtrar e ordenar por:</p>',
    fr: '<p>Filtrer et trier par :</p>', 
  },
  bloglist_data4: {
    es: '<p>Ascendente</p>',
    en: '<p>Ascending</p>',
    pt: '<p>Ascendente</p>',
    fr: '<p>Ordre croissant</p>', 
  },
  bloglist_data5: {
    es: '<p>Descendente</p>',
    en: '<p>Descending</p>',
    pt: '<p>Descendente</p>',
    fr: '<p>Ordre décroissant</p>', 
  },
  bloglist_data6: {
    es: '<p>CARGAR MÁS</p>',
    en: '<p>LOAD MORE</p>',
    pt: '<p>CARREGAR MAIS</p>',
    fr: '<p>VOIR PLUS</p>', 
  },
  bloglist_data7: {
    es: '<p>Leer más.</p>',
    en: '<p>Read more.</p>',
    pt: '<p>Ler mais.</p>',
    fr: '<p>Lire plus.</p>', 
  },
  bloglist_data8: {
    es: '<p>Fecha</p>',
    en: '<p>Date</p>',
    pt: '<p>Data</p>',
    fr: '<p>Date</p>', 
  },
  bloglist_data9: {
    es: '<p>Todos</p>',
    en: '<p>All</p>',
    pt: '<p>Todos</p>',
    fr: '<p>Tous</p>', 
  },
};
