import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GenerateRouteService } from './commons/services/generate-route.service';
import { TwoFactorService } from './auth/services/two-factor.service';
import { SpanishRoutes } from './commons/routes/spanish-routes';
import { EnglishRoutes } from './commons/routes/english-routes';
import { FrenchRoutes } from './commons/routes/french-routes';
import { PortugueseRoutes } from './commons/routes/portuguese-routes';
import { WindowService } from './commons/services/window.service';
import { AuthenticationService } from './auth/services/authentication.service';
import { UserToken } from './commons/services/user-token';
import { SecurityTokenStorage } from './commons/services/security-token-storage';
import { I18nService } from './commons/services/i18n.service';
import { ResponsiveService } from './commons/services/responsive.service';
import { MatDialog } from '@angular/material/dialog';
import { NcConfigComponent } from './commons/components/cookies-config/cookies-config.component';
import { CookiesService } from './auth/services/cookies-service.service';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import packageJson from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  isDarkMode: boolean = false;
  routeSubscription!: Subscription;
  isLoggedIn: boolean;
  mWindow: Window;
  url;
  showFooterHeader: boolean = false;
  mobileXS: boolean = this.responsiveService.mobileXS();
  isLandscape: boolean = this.responsiveService.isLandscape();
  isPC: boolean = this.responsiveService.isPC();
  isFirefox: boolean = false;
  maintenanceMode: boolean = false;
  public version: string = packageJson.version;

  constructor(
    private securityTokenStorage: SecurityTokenStorage<UserToken>,
    private router: Router,
    private generateRouteService: GenerateRouteService,
    private windowService: WindowService,
    private authenticationService: AuthenticationService,
    private twoFactorService: TwoFactorService,
    private i18nService: I18nService,
    private responsiveService: ResponsiveService,
    private dialog: MatDialog,
    private cookiesService: CookiesService,
    private route: ActivatedRoute
  ) {
    this.isLoggedIn = false;
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/maintance')) {
          this.maintenanceMode = true;
          this.showFooterHeader = false;
        } else if (
          this.router.url.includes('/android-login') ||
          this.router.url.includes('/android-register')
        ) {
          this.showFooterHeader = false;
          this.maintenanceMode = false;
        } else {
          this.showFooterHeader = true;
          this.maintenanceMode = false;
        }
      }
    });

    if (this.windowService.isBrowser) {
      const selectedCookies = localStorage.getItem('selectedCookies');
      if (!selectedCookies) {
        this.openCookiesConfigDialog();
      } else {
        this.loadScripts();
      }
    }
    let idioma = this.i18nService.getCurrentLanguage().code;
    if (this.windowService.isBrowser) {
      this.isFirefox =
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      document.querySelector('html')?.setAttribute('lang', idioma);
    }
    this.adjustMobileZoom();
    this.makeRouteSubscriptions();
    setTimeout(() => {
      if (this.getAuthRoutes().includes(this.router.url.slice(1))) {
        this.authenticationService.get(false).subscribe({
          next: () => {
            this.isLoggedIn = true;
            this.twoFactorService.setUserData(
              this.securityTokenStorage.getAcceptedLogin()
            );
            this.twoFactorService.goToAppEyescloud3d();
          },
          error: () => {
            this.securityTokenStorage.deleteFromStorage();
          },
        });
      }
    }, 1);
    console.log('eyesCloud3d ' + this.version);
  }

  private getAuthRoutes(): string[] {
    const keys = ['Login', 'Register', 'RegisterWithPromotion'];
    const routes: string[] = [];
    keys.forEach((key) => {
      routes.push(SpanishRoutes[key].URL);
      routes.push(EnglishRoutes[key].URL);
      routes.push(FrenchRoutes[key].URL);
      routes.push(PortugueseRoutes[key].URL);
    });
    for (let i = 0; i < routes.length; i++) {
      routes[i] = routes[i].replace(/:.*\/|:.*/g, '');
    }
    return routes;
  }

  private makeRouteSubscriptions() {
    this.routeSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.goUp();
      });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  goUp(): void {
    if (this.windowService.isBrowser) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  getRouterLink(routeKey: any): string {
    return this.generateRouteService.generateRoute(routeKey);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.mobileXS = this.responsiveService.mobileXS();
    this.isLandscape = this.responsiveService.isLandscape();
    this.isPC = this.responsiveService.isPC();
    this.adjustMobileZoom();
  }

  openCookiesConfigDialog() {
    this.dialog
      .open(NcConfigComponent, {
        disableClose: true,
        hasBackdrop: true,
        maxWidth: '100%',
        scrollStrategy: new NoopScrollStrategy(),
        panelClass: 'cookiesConfigDialog',
      })
      .afterClosed()
      .subscribe((response) => {
        this.cookiesService.retrieveSelectedCookies();
        this.loadScripts();
      });
  }

  private adjustMobileZoom() {
    if (this.windowService.isBrowser) {
      const zoomClass = this.isFirefox ? 'mz-zoom' : 'zoom';
      if (this.isPC) {
        document.body.classList.add(zoomClass);
        let vw = window.innerWidth;
        let zoom = 1 - (1920 - vw) / 2000;
        document.documentElement.style.setProperty('--zoom', zoom.toString());
      } else if (this.isLandscape) {
        document.body.classList.add(zoomClass);
        let vw = window.innerWidth;
        let zoom = 1 - (1024 - vw) / 1100;
        document.documentElement.style.setProperty('--zoom', zoom.toString());
      } else if (this.mobileXS) {
        document.body.classList.add(zoomClass);
        let vw = window.innerWidth;
        let zoom = 1 - (390 - vw) / 350;
        document.documentElement.style.setProperty('--zoom', zoom.toString());
      } else {
        document.body.classList.remove(zoomClass);
        document.documentElement.style.removeProperty('--zoom');
      }
    }
  }

  private loadScripts() {
    if (this.cookiesService.hasAnalysisCookies()) {
      // Crea y añade los elementos script al DOM
      const scriptElement = document.createElement('script');
      scriptElement.async = true;
      scriptElement.src =
        'https://www.googletagmanager.com/gtag/js?id=G-96KNG049K8';
      document.head.appendChild(scriptElement);

      // Crea y añade el script de configuración al DOM
      const configScript = document.createElement('script');
      configScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'G-96KNG049K8');
      `;
      // document.head.appendChild(configScript);

      //Google tag manager
      const gtmScript = document.createElement('script');
      gtmScript.innerHTML = `
        (function (w, d, s, l, i) {
          w[l] = w[l] || []; w[l].push({
            'gtm.start': new Date().getTime(), event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-WVNPLNSK');
      `;
      document.head.appendChild(gtmScript);

      // Noscript body etiqueta google
      const noscriptIframe = document.createElement('iframe');
      noscriptIframe.src =
        'https://www.googletagmanager.com/ns.html?id=GTM-WVNPLNSK';
      noscriptIframe.height = '0';
      noscriptIframe.width = '0';
      noscriptIframe.style.display = 'none';
      noscriptIframe.style.visibility = 'hidden';
      document.body.appendChild(noscriptIframe);
    }

    if (this.cookiesService.hasFunctionalityCookies()) {
      // Crea y añade el script de Clarity al DOM
      const clarityScript = document.createElement('script');
      clarityScript.innerHTML = `
        (function (c, l, a, r, i, t, y) {
          c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
          t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
          y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "kucf7dhbbm");
      `;
      document.head.appendChild(clarityScript);
    }
  }
}
