export const FOOTER_I18N_ENTRIES = {
  footer_Data1: {
    es: '<p>Centro ayuda</p>',
    en: '<p>Help Center</p>',
    pt: '<p>Centro de ajuda</p>',
    fr: '<p>Centre d’aide</p>',
  },
  footer_Data2: {
    es: '<p>Blog</p>',
    en: '<p>Blog</p>',
    pt: '<p>Blog</p>',
    fr: '<p>Blog</p>',
  },
  footer_Data3: {
    es: '<p>Contacto</p>',
    en: '<p>Contact</p>',
    pt: '<p>Contacto</p>',
    fr: '<p>Contact</p>',
  },
  footer_Data4: {
    es: '<p>Privacidad</p>',
    en: '<p>Privacy</p>',
    pt: '<p>Privacidade</p>',
    fr: '<p>Confidentialité</p>',
  },
  footer_Data5: {
    es: '<p>Términos</p>',
    en: '<p>Terms</p>',
    pt: '<p>Termos</p>',
    fr: '<p>Conditions d’utilisation</p>',
  },
  footer_Data6: {
    es: '<p>Cookies</p>',
    en: '<p>Cookies</p>',
    pt: '<p>Cookies</p>',
    fr: '<p>Cookies</p>',
  },
  footer_Data7: {
    es: '<p>Privacidad</p>',
    en: '<p>Privacy</p>',
    pt: '<p>Privacidade</p>',
    fr: '<p>Confidentialité</p>',
  },
  footer_Data8: {
    es: '<span>Suscríbete a nuestra Newsletter</span>',
    en: '<span>Sign up for our newsletter</span>',
    pt: '<span>Subscreva a nossa Newsletter</span>',
    fr: '<span>Abonnez-vous à notre newsletter</span>',
  },
  footer_Data9: {
    es: '<p>He leído y acepto la política de privacidad.</p>',
    en: '<p>I have read and accept the privacy policy.</p>',
    pt: '<p>Li e aceito a política de privacidade.</p>',
    fr: '<p>J’ai lu et j’accepte la politique de confidentialité.</p>',
  },
  footer_Data10: {
    es: '<span>Acepto recibir comunicaciones comerciales relacionadas con la entidad ecaptureDtech, S.L. a través de correo electrónico o medios electrónicos, incluído teléfono.</span>',
    en: '<span>I consent to receive commercial communications related to ecaptureDtech, S.L. via email or electronic means, including phone.</span>',
    pt: '<span>Aceito receber comunicações comerciais relacionadas com a entidade ecaptureDtech, S.L por e-mail ou meios eletrónicos, incluindo telefone.</span>',
    fr: '<span>J’accepte de recevoir des communications commerciales de la société ecaptureDtech, S.L., par e-mail ou autre moyen électronique, ainsi que par téléphone.</span>',
  },
  footer_Data11: {
    es: '<p>Todos los derechos reservados ecaptureDtech©2024</p>',
    en: '<p>All rights reserved ecaptureDtech©2024</p>',
    pt: '<p>Todos os direitos reservados ecaptureDtech©2024</p>',
    fr: '<p>Tous droits réservés ecaptureDtech©2024</p>',
  },
  footer_Data12: {
    es: '<p>Correo electrónico</p>',
    en: '<p>Email</p>',
    pt: '<p>E-mail</p>',
    fr: '<p>E-mail</p>',
  },
  footer_Data13: {
    es: '<p>Contratación</p>',
    en: '<p>Terms and Conditions</p>',
    pt: '<p>Contratação</p>',
    fr: '<p>Conditions générales de vente</p>',
  },
  footer_Data14: {
    es: '<p>Gracias por suscribirte a nuestra Newsletter</p>',
    en: '<p>Thank you for subscribing to our newsletter</p>',
    pt: '<p>Obrigado por subscrever a nossa Newsletter</p>',
    fr: '<p>Merci de vous être abonné à notre newsletter</p>',
  },
  footer_Data15: {
    es: '<p>Suscribirme con otro correo</p>',
    en: '<p>Subscribe using a different email address</p>',
    pt: '<p>Subscrever com outro e-mail</p>',
    fr: '<p>M’abonner en utilisant une autre adresse mail</p>',
  },
  footer_Data16: {
    es: '<p>Ya estás suscrito con este correo*</p>',
    en: '<p>You have already subscribed with this email*</p>',
    pt: '<p>Já está subscrito com este e-mail*</p>',
    fr: '<p>Vous êtes déjà abonné avec cette adresse mail*</p>',
  },
};
