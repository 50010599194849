<img src="/assets/images/login/dialog/close.svg" alt="Cerrar" class="close-icon" (click)="close()">

<div class="changed-password" *ngIf="!isError">
  <div class="title" [innerHTML]="'changePasswordDialog_Data7' | i18n"></div>
  <div class="text" [innerHTML]="'changePasswordDialog_Data8' | i18n"></div>
  <button [innerHTML]="'changePasswordDialog_Data9' |i18n" (click)="close()" [routerLink]="getRouterLink('Login')"></button>
</div>

<div class="error-changing-password" *ngIf="isError">
  <div class="title" [innerHTML]="'changePasswordDialog_Data10' | i18n"></div>
  <div class="text error-text" [innerHTML]="'changePasswordDialog_Data11' | i18n"></div>
</div>
