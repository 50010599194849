/**
 * Clase que guarda la estructura del lenguaje
 */
export class Language {
  constructor(
    public code: string,
    public name: string,
    public httpHeader: string,
    public id: number,
    public generalCode: string | null = ''
  ) { }
}

