import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent implements OnInit {
  translationModes: any = TranslationModeEnum;
  email: string = '';
  emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  isError: boolean = false;

  constructor(
    private dialog: MatDialogRef<ForgotPasswordDialogComponent>,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {

  }

  close() {
    this.dialog.close();
  }

  send() {
    this.authenticationService.forgotPassword({
      email: this.email
    }).subscribe(() => {
      this.isError = false;
      this.close();
    }, (error) => {
      if (error) {
        this.isError = true;
      }
    });
  }
}
