import { Component } from '@angular/core';

@Component({
  selector: 'app-wep-conversor',
  standalone: true,
  imports: [],
  templateUrl: './wep-conversor.component.html',
  styleUrl: './wep-conversor.component.scss',
})
export class WepConversorComponent {
  imagePreviews: HTMLElement;
  fileSelector: HTMLInputElement;

  constructor() {}

  ngOnInit(): void {
    this.imagePreviews = document.querySelector('#previews');
    this.fileSelector = document.querySelector('input[type=file]');

    this.fileSelector.addEventListener(
      'change',
      this.fileSelectorChanged.bind(this)
    );
    this.setDragDrop(document.documentElement, this.processFiles.bind(this));
  }

  addImageBox(container: HTMLElement): HTMLElement {
    let imageBox = document.createElement('div');
    let progressBox = document.createElement('progress');
    imageBox.appendChild(progressBox);
    container.appendChild(imageBox);

    return imageBox;
  }

  processFile(file: File): void {
    if (!file) {
      return;
    }
    console.log(file);

    let imageBox = this.addImageBox(this.imagePreviews);

    // Load the data into an image
    new Promise(function (resolve, reject) {
      let rawImage = new Image();

      rawImage.addEventListener('load', function () {
        resolve(rawImage);
      });

      rawImage.src = URL.createObjectURL(file);
    })
      .then(function (rawImage: HTMLImageElement) {
        // Convert image to webp ObjectURL via a canvas blob
        return new Promise(function (resolve, reject) {
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');

          canvas.width = rawImage.width;
          canvas.height = rawImage.height;
          ctx.drawImage(rawImage, 0, 0);

          canvas.toBlob(function (blob) {
            resolve(URL.createObjectURL(blob));
          }, 'image/webp');
        });
      })
      .then(function (imageURL: string) {
        // Load image for display on the page
        return new Promise(function (resolve, reject) {
          let scaledImg = new Image();

          scaledImg.addEventListener('load', function () {
            resolve({ imageURL, scaledImg });
          });

          scaledImg.setAttribute('src', imageURL);
        });
      })
      .then(function (data: any) {
        // Inject into the DOM
        let imageLink = document.createElement('a');

        imageLink.setAttribute('href', data.imageURL);
        imageLink.setAttribute('download', `${file.name}.webp`);
        imageLink.appendChild(data.scaledImg);

        imageBox.innerHTML = '';
        imageBox.appendChild(imageLink);
      });
  }

  processFiles(files: FileList): void {
    for (let file of Array.from(files)) {
      this.processFile(file);
    }
  }

  fileSelectorChanged(): void {
    this.processFiles(this.fileSelector.files);
    this.fileSelector.value = '';
  }

  dragenter(e: DragEvent): void {
    e.stopPropagation();
    e.preventDefault();
  }

  dragover(e: DragEvent): void {
    e.stopPropagation();
    e.preventDefault();
  }

  drop(callback: any, e: DragEvent): void {
    e.stopPropagation();
    e.preventDefault();
    callback(e.dataTransfer.files);
  }

  setDragDrop(area: HTMLElement, callback: any): void {
    area.addEventListener('dragenter', this.dragenter, false);
    area.addEventListener('dragover', this.dragover, false);
    area.addEventListener(
      'drop',
      function (e) {
        this.drop(callback, e);
      }.bind(this),
      false
    );
  }
}
