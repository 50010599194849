import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { RegisterStepFourResponse } from 'src/app/auth/models/account-verification/register-step-four-response';
import { GenerateRouteService } from 'src/app/commons/services/generate-route.service';
import { I18nService } from 'src/app/commons/services/i18n.service';

@Component({
  selector: 'app-register-step-four-dialog',
  templateUrl: './register-step-four-dialog.component.html',
  styleUrls: ['./register-step-four-dialog.component.scss'],
})
export class RegisterStepFourDialogComponent {
  responses: typeof RegisterStepFourResponse;

  constructor(
    private matDialogRef: MatDialogRef<RegisterStepFourDialogComponent>,
    private generateRouteService: GenerateRouteService,
    private i18nservice: I18nService
  ) {
    this.responses = RegisterStepFourResponse;
    console.log('Finished register');
  }

  public closeDialog(
    response: RegisterStepFourResponse = RegisterStepFourResponse.DEFAULT
  ) {
    this.getRouterLink('Login');
    this.matDialogRef.close(response);
  }

  getRouterLink(routeKey: any): string {
    return this.generateRouteService.generateRoute(routeKey);
  }
}
