<div class="admin-container">
  <form [formGroup]="formulario" (ngSubmit)="onSubmit(formulario.value)">
    <label for="name">Nombre</label>
    <input type="text" formControlName="name" placeholder="Nombre" id="name">
    <label for="password">Contraseña</label>
    <input type="password" formControlName="password" placeholder="Contraseña" id="password">
    <button type="submit">Enviar</button>
  </form>
</div>

