<div class="optional-nc-container">
  <img class="logo" src="/assets/images/commons/logo.svg" alt="eyescloud3d logo">
  <div class="title" [innerHTML]="'cookies_config_Data5' |i18n"></div>
  <ng-container *ngIf="!isTablet; then mobileCookies else pcCookies"></ng-container>
  <div class="nc-options-container">
    <div class="btn nc-config" [innerHTML]="'cookies_config_Data37' | i18n" (click)="acceptCookies(cookiesConfigEnum.CUSTOM)"></div>
    <div>
      <div class="btn nc-deny" [innerHTML]="'cookies_config_Data3' | i18n" (click)="acceptCookies(cookiesConfigEnum.ESSENTIAL)"></div>
      <div class="btn nc-accept" [innerHTML]="'cookies_config_Data4' | i18n" (click)="acceptCookies(cookiesConfigEnum.ALL)"></div>
    </div>
  </div>
</div>

<ng-template #mobileCookies>
  <div class="nc-info-container" *ngIf="!showDetails">
    <div class="nc-navbar essential" *ngIf="currentCookies !== cookiesConfigEnum.ESSENTIAL" (click)="setCurrentCookies(cookiesConfigEnum.ESSENTIAL)">
      <div class="navbar-title" [innerHTML]="'cookies_config_Data6' | i18n"></div>
      <img src="/assets/images/rates-info/arrow.svg" alt="arrow">
    </div>
    <div class="nc-details essential" *ngIf="currentCookies === cookiesConfigEnum.ESSENTIAL">
      <div class="details-header">
        <div class="details-title" [innerHTML]="'cookies_config_Data6' | i18n"></div>
        <div class="details-active" [innerHTML]="'cookies_config_Data14' | i18n"></div>
      </div>
      <div class="details-description" [innerHTML]="'cookies_config_Data15' | i18n"></div>
      <div class="details-info" [innerHTML]="'cookies_config_Data16' | i18n" (click)="showCookiesDetails()"></div>
    </div>
    <div class="line"></div>
    <div class="nc-navbar analysis" *ngIf="currentCookies !== cookiesConfigEnum.ANALYSIS" (click)="setCurrentCookies(cookiesConfigEnum.ANALYSIS)">
      <div class="navbar-title" [innerHTML]="'cookies_config_Data7' | i18n"></div>
      <img src="/assets/images/rates-info/arrow.svg" alt="arrow">
    </div>
    <div class="nc-details analysis" *ngIf="currentCookies === cookiesConfigEnum.ANALYSIS">
      <div class="details-header">
        <div class="details-title" [innerHTML]="'cookies_config_Data7' | i18n"></div>
        <div class="toggle-container">
          <div class="details-active" [ngClass]="{'disabled': !analysisToggle}"
          [innerHTML]="(analysisToggle ? 'cookies_config_Data21' : 'cookies_config_Data42') | i18n"></div>
          <mat-slide-toggle class="nc-toggle" [(ngModel)]="analysisToggle"></mat-slide-toggle>
        </div>
      </div>
      <div class="details-description" [innerHTML]="'cookies_config_Data22' | i18n"></div>
      <div class="details-info" [innerHTML]="'cookies_config_Data16' | i18n" (click)="showCookiesDetails()"></div>
    </div>
    <div class="line"></div>
    <div class="nc-navbar functionality" *ngIf="currentCookies !== cookiesConfigEnum.FUNCTIONALITY" (click)="setCurrentCookies(cookiesConfigEnum.FUNCTIONALITY)">
      <div class="navbar-title" [innerHTML]="'cookies_config_Data8' | i18n"></div>
      <img src="/assets/images/rates-info/arrow.svg" alt="arrow">
    </div>
    <div class="nc-details functionality" *ngIf="currentCookies === cookiesConfigEnum.FUNCTIONALITY">
      <div class="details-header">
        <div class="details-title" [innerHTML]="'cookies_config_Data8' | i18n"></div>
        <div class="toggle-container">
          <div class="details-active" [ngClass]="{'disabled': !functionalityToggle}"
            [innerHTML]="(functionalityToggle ? 'cookies_config_Data21' : 'cookies_config_Data42') | i18n"></div>
          <mat-slide-toggle class="nc-toggle" [(ngModel)]="functionalityToggle"></mat-slide-toggle>
        </div>
      </div>
      <div class="details-description" [innerHTML]="'cookies_config_Data38' | i18n"></div>
      <div class="details-info" [innerHTML]="'cookies_config_Data16' | i18n" (click)="showCookiesDetails()"></div>
    </div>
    <div class="line"></div>
    <div class="nc-navbar marketing" *ngIf="currentCookies !== cookiesConfigEnum.MARKETING" (click)="setCurrentCookies(cookiesConfigEnum.MARKETING)">
      <div class="navbar-title" [innerHTML]="'cookies_config_Data9' | i18n"></div>
      <img src="/assets/images/rates-info/arrow.svg" alt="arrow">
    </div>
    <div class="nc-details marketing" *ngIf="currentCookies === cookiesConfigEnum.MARKETING">
      <div class="details-header">
        <div class="details-title" [innerHTML]="'cookies_config_Data9' | i18n"></div>
        <div class="toggle-container">
          <div class="details-active" [ngClass]="{'disabled': !marketingToggle}"
            [innerHTML]="(marketingToggle ? 'cookies_config_Data21' : 'cookies_config_Data42') | i18n"></div>
          <mat-slide-toggle class="nc-toggle" [(ngModel)]="marketingToggle"></mat-slide-toggle>
        </div>
      </div>
      <div class="details-description" [innerHTML]="'cookies_config_Data39' | i18n"></div>
      <div class="details-info" [innerHTML]="'cookies_config_Data16' | i18n" (click)="showCookiesDetails()"></div>
    </div>
    <div class="line"></div>
    <div class="nc-navbar fraud" *ngIf="currentCookies !== cookiesConfigEnum.FRAUD" (click)="setCurrentCookies(cookiesConfigEnum.FRAUD)">
      <div class="navbar-title" [innerHTML]="'cookies_config_Data12' | i18n"></div>
      <img src="/assets/images/rates-info/arrow.svg" alt="arrow">
    </div>
    <div class="nc-details fraud" *ngIf="currentCookies === cookiesConfigEnum.FRAUD">
      <div class="details-header">
        <div class="details-title" [innerHTML]="'cookies_config_Data10' | i18n"></div>
        <div class="toggle-container">
          <div class="details-active" [ngClass]="{'disabled': !fraudToggle}"
            [innerHTML]="(fraudToggle ? 'cookies_config_Data21' : 'cookies_config_Data42') | i18n"></div>
          <mat-slide-toggle class="nc-toggle" [(ngModel)]="fraudToggle"></mat-slide-toggle>
        </div>
      </div>
      <div class="details-description" [innerHTML]="'cookies_config_Data40' | i18n"></div>
      <div class="details-info" [innerHTML]="'cookies_config_Data16' | i18n" (click)="showCookiesDetails()"></div>
    </div>
    <div class="line"></div>
    <div class="nc-navbar security" *ngIf="currentCookies !== cookiesConfigEnum.SECURITY" (click)="setCurrentCookies(cookiesConfigEnum.SECURITY)">
      <div class="navbar-title" [innerHTML]="'cookies_config_Data13' | i18n"></div>
      <img src="/assets/images/rates-info/arrow.svg" alt="arrow">
    </div>
    <div class="nc-details security" *ngIf="currentCookies === cookiesConfigEnum.SECURITY">
      <div class="details-header">
        <div class="details-title" [innerHTML]="'cookies_config_Data11' | i18n"></div>
        <div class="toggle-container">
          <div class="details-active" [ngClass]="{'disabled': !securityToggle}"
            [innerHTML]="(securityToggle ? 'cookies_config_Data21' : 'cookies_config_Data42') | i18n"></div>
          <mat-slide-toggle class="nc-toggle" [(ngModel)]="securityToggle"></mat-slide-toggle>
        </div>
      </div>
      <div class="details-description" [innerHTML]="'cookies_config_Data41' | i18n"></div>
      <div class="details-info" [innerHTML]="'cookies_config_Data16' | i18n" (click)="showCookiesDetails()"></div>
    </div>
  </div>

  <div class="nc-info-container list" *ngIf="showDetails">
    <div class="back" [innerHTML]="'cookies_config_Data43' | i18n" (click)="showCookiesDetails()"></div>
    <div class="item" *ngFor="let item of cookiesLists[currentCookies]">
      <div class="first-row">
        <div [innerHTML]="'cookies_config_Data17' | i18n"></div>
        <div [innerHTML]="'cookies_config_Data18' | i18n"></div>
        <div [innerHTML]="'cookies_config_Data19' | i18n"></div>
      </div>
      <div class="second-row">
        <div>{{ item.name }}</div>
        <div [innerHTML]="item.type | i18n"></div>
        <div [innerHTML]="item.duration | i18n"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #pcCookies>
  <div class="nc-info-container">
    <div class="nc-navbar-container">
      <div *ngFor="let cookie of cookieTypes; let last = last">
        <div class="nc-navbar essential" [ngClass]="{'selected': currentCookies === cookie.type}" (click)="setCurrentCookies(cookie.type)"
          [innerHTML]="cookie.label | i18n">
        </div>
        <div class="line" *ngIf="!last"></div>
      </div>
    </div>
    <div class="nc-details-container" *ngIf="!showDetails">
      <div class="nc-details essential" *ngIf="currentCookies === cookiesConfigEnum.ESSENTIAL">
        <div class="details-header">
          <div class="details-title" [innerHTML]="'cookies_config_Data6' | i18n"></div>
          <div class="details-active" [innerHTML]="'cookies_config_Data14' | i18n"></div>
        </div>
        <div class="details-description" [innerHTML]="'cookies_config_Data15' | i18n"></div>
        <div class="details-info" [innerHTML]="'cookies_config_Data16' | i18n" (click)="showCookiesDetails()"></div>
      </div>
      <div class="nc-details analysis" *ngIf="currentCookies === cookiesConfigEnum.ANALYSIS">
        <div class="details-header">
          <div class="details-title" [innerHTML]="'cookies_config_Data7' | i18n"></div>
          <div class="toggle-container">
            <div class="details-active" [ngClass]="{'disabled': !analysisToggle}"
              [innerHTML]="(analysisToggle ? 'cookies_config_Data21' : 'cookies_config_Data42') | i18n"></div>
            <mat-slide-toggle class="nc-toggle" [(ngModel)]="analysisToggle"></mat-slide-toggle>
          </div>
        </div>
        <div class="details-description" [innerHTML]="'cookies_config_Data22' | i18n"></div>
        <div class="details-info" [innerHTML]="'cookies_config_Data16' | i18n" (click)="showCookiesDetails()"></div>
      </div>
      <div class="nc-details functionality" *ngIf="currentCookies === cookiesConfigEnum.FUNCTIONALITY">
        <div class="details-header">
          <div class="details-title" [innerHTML]="'cookies_config_Data8' | i18n"></div>
          <div class="toggle-container">
            <div class="details-active" [ngClass]="{'disabled': !functionalityToggle}"
              [innerHTML]="(functionalityToggle ? 'cookies_config_Data21' : 'cookies_config_Data42') | i18n"></div>
            <mat-slide-toggle class="nc-toggle" [(ngModel)]="functionalityToggle"></mat-slide-toggle>
          </div>
        </div>
        <div class="details-description" [innerHTML]="'cookies_config_Data38' | i18n"></div>
        <div class="details-info" [innerHTML]="'cookies_config_Data16' | i18n" (click)="showCookiesDetails()"></div>
      </div>
      <div class="nc-details marketing" *ngIf="currentCookies === cookiesConfigEnum.MARKETING">
        <div class="details-header">
          <div class="details-title" [innerHTML]="'cookies_config_Data9' | i18n"></div>
          <div class="toggle-container">
            <div class="details-active" [ngClass]="{'disabled': !marketingToggle}"
              [innerHTML]="(marketingToggle ? 'cookies_config_Data21' : 'cookies_config_Data42') | i18n"></div>
            <mat-slide-toggle class="nc-toggle" [(ngModel)]="marketingToggle"></mat-slide-toggle>
          </div>
        </div>
        <div class="details-description" [innerHTML]="'cookies_config_Data39' | i18n"></div>
        <div class="details-info" [innerHTML]="'cookies_config_Data16' | i18n" (click)="showCookiesDetails()"></div>
      </div>
      <div class="nc-details fraud" *ngIf="currentCookies === cookiesConfigEnum.FRAUD">
        <div class="details-header">
          <div class="details-title" [innerHTML]="'cookies_config_Data10' | i18n"></div>
          <div class="toggle-container">
            <div class="details-active" [ngClass]="{'disabled': !fraudToggle}"
              [innerHTML]="(fraudToggle ? 'cookies_config_Data21' : 'cookies_config_Data42') | i18n"></div>
            <mat-slide-toggle class="nc-toggle" [(ngModel)]="fraudToggle"></mat-slide-toggle>
          </div>
        </div>
        <div class="details-description" [innerHTML]="'cookies_config_Data40' | i18n"></div>
        <div class="details-info" [innerHTML]="'cookies_config_Data16' | i18n" (click)="showCookiesDetails()"></div>
      </div>
      <div class="nc-details security" *ngIf="currentCookies === cookiesConfigEnum.SECURITY">
        <div class="details-header">
          <div class="details-title" [innerHTML]="'cookies_config_Data11' | i18n"></div>
          <div class="toggle-container">
            <div class="details-active" [ngClass]="{'disabled': !securityToggle}"
              [innerHTML]="(securityToggle ? 'cookies_config_Data21' : 'cookies_config_Data42') | i18n"></div>
            <mat-slide-toggle class="nc-toggle" [(ngModel)]="securityToggle"></mat-slide-toggle>
          </div>
        </div>
        <div class="details-description" [innerHTML]="'cookies_config_Data41' | i18n"></div>
        <div class="details-info" [innerHTML]="'cookies_config_Data16' | i18n" (click)="showCookiesDetails()"></div>
      </div>
    </div>

    <div class="nc-list" *ngIf="showDetails">
      <div class="back" [innerHTML]="'cookies_config_Data43' | i18n" (click)="showCookiesDetails()"></div>
      <div class="item" *ngFor="let item of cookiesLists[currentCookies]">
        <div class="first-row">
          <div [innerHTML]="'cookies_config_Data17' | i18n"></div>
          <div [innerHTML]="'cookies_config_Data18' | i18n"></div>
          <div [innerHTML]="'cookies_config_Data19' | i18n"></div>
        </div>
        <div class="second-row">
          <div>{{ item.name }}</div>
          <div [innerHTML]="item.type | i18n"></div>
          <div [innerHTML]="item.duration | i18n"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
