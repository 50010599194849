<div class="go-back" (click)="closeDialog(responses.BACK)">
  <img src="/assets/images/two-factor/back.svg" alt="Volver">
  <p [innerHTML]="'twoFactorDialog_Data9' | i18n"></p>
</div>
<div class="title" [innerHTML]="'twoFactorDialog_Data13' | i18n"></div>
<div class="subtitle" [innerHTML]="(data.source === sources.PHONE ? 'twoFactorDialog_Data14' : 'twoFactorDialog_Data15') | i18n: translationModes.INSERT_VALUE : data.data"></div>
<input type="text" [ngClass]="{'phone-code': data.source === sources.PHONE}" [(ngModel)]="code" (ngModelChange)="onCodeChange()" [placeholder]="'twoFactorDialog_Data16' | i18n: translationModes.PLACEHOLDER">
<div class="generate-new-code incorrect-code" *ngIf="isCodeIncorrect" [innerHTML]="'twoFactorDialog_Data17' | i18n"></div>
<div class="generate-new-code" *ngIf="!isCodeIncorrect">
    <p *ngIf="remainingSeconds === 0" [innerHTML]="'twoFactorDialog_Data21' | i18n"></p>
    <p *ngIf="remainingSeconds === 0" (click)="generateNewCode()" [innerHTML]="'twoFactorDialog_Data22' | i18n"></p>
    <p *ngIf="remainingSeconds !== 0" [innerHTML]="'twoFactorDialog_Data26' | i18n: translationModes.INSERT_VALUE : remainingSeconds"></p>
    <p [innerHTML]="'twoFactorDialog_Data23' | i18n"></p>
</div>
<button class="btn" [ngClass]="{'disabled': !code}" (click)="continue()" [innerHTML]="'twoFactorDialog_Data11' | i18n"></button>
