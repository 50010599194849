import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';
import { WindowService } from 'src/app/commons/services/window.service';

@Component({
  selector: 'app-three-steps',
  templateUrl: './three-steps.component.html',
  styleUrls: ['./three-steps.component.scss'],
})
export class ThreeStepsComponent implements OnInit, OnDestroy {
  currentStep: number = 0;
  private startX: number = 0;
  private startY: number = 0;
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;

  constructor() { }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  changeStep(step: number): void {
    this.currentStep = Math.min(Math.max(step, 0), 2);
  }

  onTouchStart(event: TouchEvent): void {
    this.startX = event.touches[0].clientX;
    this.startY = event.touches[0].clientY;
  }

  onTouchMove(event: TouchEvent): void { }

  onTouchEnd(event: TouchEvent): void {
    const endX = event.changedTouches[0].clientX;
    const endY = event.changedTouches[0].clientY;

    const deltaX = endX - this.startX;
    const deltaY = endY - this.startY;
    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      if (deltaX > 0) {
        this.changeStep(this.currentStep - 1);
      } else {
        this.changeStep(this.currentStep + 1);
      }
    }
  }
  swapScreen(index: number) {
    this.currentStep = this.currentStep + index;
    switch (this.currentStep) {
      case -1:
        this.currentStep = 2;
        break;
      case 3:
        this.currentStep = 0;
        break;
      default:
        break;
    }
  }
}
