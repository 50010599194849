import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';
import { GenerateRouteService } from 'src/app/commons/services/generate-route.service';
import { UpdateMetaService } from 'src/app/commons/services/meta-service.service';
import { WindowService } from 'src/app/commons/services/window.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  @ViewChild('carrousel', { static: true }) carrousel: ElementRef;
  isDown: boolean = false;
  startX: any;
  scrollLeft: any;
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;

  constructor(
    private generateRouteService: GenerateRouteService,
    private updateMetaService: UpdateMetaService,
    private gtmService: GoogleTagManagerService,
    private windowService: WindowService
  ) {
    this.updateMetaService.updateMetaTags('Home');
  }

  ngOnInit(): void {
    if (this.windowService.isBrowser) {
      const el = this.carrousel.nativeElement;

      el.addEventListener('mousedown', (e) => {
        this.isDown = true;
        this.startX = e.pageX - el.offsetLeft;
        this.scrollLeft = el.scrollLeft;
      });

      el.addEventListener('mouseleave', () => {
        this.isDown = false;
      });

      el.addEventListener('mouseup', () => {
        this.isDown = false;
      });

      el.addEventListener('mousemove', (e) => {
        if (!this.isDown) return;
        e.preventDefault();
        const x = e.pageX - el.offsetLeft;
        const walk = (x - this.startX) * 3; // Velocidad del arrastre
        el.scrollLeft = this.scrollLeft - walk;
      });
    }
  }

  getRouterLink(routeKey: any): string {
    return this.generateRouteService.generateRoute(routeKey);
  }

  sendGtmTag(origin: any) {
    const gtmTag = {
      event: 'button-click',
      class: origin,
    };
    this.gtmService.pushTag(gtmTag);
  }
}
