export const FAQS_I18N_ENTRIES = {
  faqs_Data1: {
    es: '<p>Preguntas</p><p>frecuentes</p>',
    en: '<p>Frequently Asked Questions</p>',
    pt: '<p>Perguntas</p><p>frequentes</p>',
    fr: '<p>Foire aux questions</p>',
  },
  faqs_Data2: {
    es: '<p>¿Qué tipo de dispositivos con</p><p>cámara se pueden utilizar?</p>',
    en: '<p>What types of camera-equipped devices</p><p>can be used?</p>',
    pt: '<p>Que tipos de dispositivos com</p><p>câmara podem ser utilizados?</p>',
    fr: '<p>Quel type d’appareil muni d’une</p><p>caméra puis-je utiliser ?</p>',
  },
  faqs_Data3: {
    es: '<p>¿Cómo debo tomar los vídeos y</p><p>fotos del objeto que quiero modelar?</p>',
    en: '<p>How should I take videos and</p><p>photos of the object I want to model?</p>',
    pt: '<p>Como devo gravar vídeos e tirar fotografias</p><p>do objeto que pretendo modelar?</p>',
    fr: '<p>Comment dois-je prendre des vidéos et des</p><p>photos de l’objet que je souhaite modéliser ?</p>',
  },
  faqs_Data4: {
    es: '<p>¿Cuánto se tarda en generar un</p><p>modelo 3D?</p>',
    en: '<p>How long does it take to generate a</p><p>3D model?</p>',
    pt: '<p>Quanto tempo é necessário</p><p>para criar um modelo 3D?</p>',
    fr: '<p>Combien de temps faut-il pour générer un</p><p>modèle 3D ?</p>',
  },
  faqs_Data5: {
    es: '<p>¿Cuántos vídeos y fotografías</p><p>necesito para generar un modelo 3D?</p>',
    en: '<p>How many videos and photographs</p><p>do I need to generate a 3D model?</p>',
    pt: '<p>De quantos vídeos e fotografias</p><p>necessito para criar um modelo 3D?</p>',
    fr: '<p>De combien de vidéos et de photos</p><p>ai-je besoin pour générer un modèle 3D ?</p>',
  },
  faqs_Data6: {
    es: '<p>¿En qué formatos puedo</p><p>descargar mis modelos 3D?</p>',
    en: '<p>What formats can I</p><p>download my 3D models in?</p>',
    pt: '<p>Em que formatos posso</p><p>transferir os meus modelos 3D?</p>',
    fr: '<p>Dans quels formats puis-je</p><p>télécharger mes modèles 3D ?</p>',
  },
  faqs_Data7: {
    es: '<p>Ir a FAQ</p>',
    en: '<p>Go to FAQ</p>',
    pt: '<p>Ir para FAQ</p>',
    fr: '<p>Aller à la FAQ</p>',
  },
  faqs_Data8: {
    es: '<p>Ir a preguntas frecuentes</p>',
    en: '<p>Go to frequently asked questions</p>',
    pt: '<p>Ir para perguntas frequentes</p>',
    fr: '<p>Aller à la foire aux questions</p>',
  },
  faqs_Data10: {
    es: '<p>¿Qué formatos son compatibles con</p><p>importación y descarga de modelos 3D?</p>',
    en: '<p>What formats are compatible with</p><p>importing and downloading 3D models?</p>',
    pt: '<p>Quais são os formatos compatíveis</p><p>para importar e transferir modelos 3D?</p>',
    fr: '<p>Quels sont les formats compatibles avec</p><p>l’importation et le téléchargement de modèles 3D ?</p>',
  },
  faqs_Data10v2: {
    es: '<p>¿Qué formatos son compatibles</p><p>con la importación de modelos 3D?</p>',
    en: '<p>What formats are compatible with</p><p>importing 3D models?</p>',
    pt: '<p>Quais são os formatos compatíveis</p><p>para importar modelos 3D?</p>',
    fr: '<p>Quels sont les formats compatibles</p><p>avec l’importation de modèles 3D</p>',
  },
  faqs_Data11: {
    es: '<p>¿Puedo importar mis propios modelos</p><p>3D para su edición o visualización?</p>',
    en: '<p>Can I import my own 3D models</p><p>for editing or visualization?</p>',
    pt: '<p>Posso importar os meus próprios modelos</p><p>3D para editar ou visualizar?</p>',
    fr: '<p>Puis-je importer mes propres modèles 3D</p><p>pour les modifier ou visualiser ?</p>',
  },
  faqs_Data12: {
    es: '<p>¿Cuántos modelos 3D puedo crear y</p><p>almacenar en mi cuenta?</p>',
    en: '<p>How many 3D models can I create and</p><p>store in my account?</p>',
    pt: '<p>Quantos modelos 3D posso criar e</p><p>armazenar na minha conta?</p>',
    fr: '<p>Combien de modèles 3D puis-je créer et</p><p>stocker dans mon compte ?</p>',
  },
  faqs_Data13: {
    es: '<p>¿Puedo generar modelos 3D gratis?</p>',
    en: '<p>Can I generate 3D models for free?</p>',
    pt: '<p>Posso criar modelos 3D de forma gratuita?</p>',
    fr: '<p>Puis-je générer des modèles 3D gratuitement ?</p>',
  },
  faqs_Data14: {
    es: '<span>Preguntas frecuentes</span>',
    en: '<span>Frequently Asked Questions</span>',
    pt: '<span>Perguntas frequentes</span>',
    fr: '<span>Foire aux questions</span>',
  },
  faqs_Data15: {
    es: '<p>eyesCloud3D dispone de un equipo de soporte técnico para responder lo antes posible.</p>',
    en: '<p>eyesCloud3D has a technical support team that will respond as soon as possible.</p>',
    pt: '<p>A eyesCloud3D dispõe de uma equipa de suporte técnico para responder às suas questões o mais brevemente possível.</p>',
    fr: '<p>eyesCloud3D dispose d’une équipe technique pour répondre à toutes vos questions dans les plus brefs délais.</p>',
  },
  faqs_Data16: {
    es: '<p>Resuelve cualquier duda al momento en</p>',
    en: '<p>Get all your questions answered quickly at</p>',
    pt: '<p>Esclareça qualquer dúvida no momento em</p>',
    fr: '<p>Si vous avez d’autres questions, contactez-nous à l’adresse suivante :</p>',
  },
  faqs_Data17: {
    es: '<span>VISITA NUESTRO</span>\n<span>CANAL DE YOUTUBE</span>',
    en: '<span>VISIT OUR</span>\n<span>YOUTUBE CHANNEL</span>',
    pt: '<span>VISITE O NOSSO</span>\n<span>CANAL DE YOUTUBE</span>',
    fr: '<span>DÉCOUVREZ NOTRE</span>\n<span>CHAÎNE YOUTUBE</span>',
  },
  faqs_Data18: {
    es: '<p>Encuentra tutoriales y consejos para sacar el máximo rendimiento de todas las funcionalidades de eyesCloud3D:</p>',
    en: '<p>Find tutorials and tips to get the most out of all eyesCloud3D features:</p>',
    pt: '<p>Encontre os tutoriais e dicas para tirar o máximo partido de todas as funcionalidades da eyesCloud3D:</p>',
    fr: '<p>Trouvez des tutoriels et des conseils pour tirer le meilleur parti de toutes les fonctionnalités d’eyesCloud3D :</p>',
  },
  faqs_Data19: {
    es: '<p>Ir a canal</p>',
    en: '<p>Go to our YouTube channel</p>',
    pt: '<p>Ir para o canal</p>',
    fr: '<p>Aller à la chaîne</p>',
  },
  faqs_Data20: {
    es: '<p>Puedes utilizar cualquier tipo de dispositivo que incorpore una cámara, como un smartphone de cualquier gama, una cámara digital, una GoPro o incluso un dron que incorpore una cámara.También es posible utilizar cámaras hiperespectrales.</p>',
    en: '<p>You can use any type of device that has a camera, such as a smartphone of any kind, a digital camera, a GoPro, or even a camera-equipped drone. Hyperspectral cameras can also be used.</p>',
    pt: '<p>Pode utilizar qualquer tipo de dispositivo com câmara, como um smartphone de qualquer gama, uma câmara digital, uma GoPro ou mesmo um drone com câmara. Também pode utilizar câmaras hiperespetrais.</p>',
    fr: '<p>Vous pouvez utiliser tout type d’appareil muni d’une caméra, comme un smartphone, une caméra numérique, une GoPro ou même un drone doté d’une caméra. Il est également possible d’utiliser des caméras hyperspectrales.</p>',
  },
  faqs_Data21: {
    es: '<p>Para lograr el mejor resultado, es necesario realizar vídeos y fotografías suficientes para capturar el objeto de manera completa.Para ello, se tomarán imágenes desde diferentes perspectivas alrededor del objeto,     asegurándose en todo momento, que esta inmóvil, centrado en la pantalla y correctamente enfocado; mientras la cámara se desplaza alrededor del objeto para tomar las imágenes.</p>',
    en: '<p>For best results, you must take enough videos and/or photos to capture the object completely. To do so, capture images from different perspectives around the object. Ensure that the object remains still, centered in the frame, and in focus at all times while the camera moves around the object to capture the images.</p>',
    pt: '<p>Para conseguir melhores resultados, é necessário ter vídeos e fotografias suficientes para captar o objeto na sua totalidade. Para isso, obtenha imagens de diferentes perspetivas à volta do objeto, certificando-se sempre de que este está imóvel, centrado no ecrã e corretamente focado, enquanto a câmara se move à volta do objeto para obter as imagens.</p>',
    fr: '<p>Afin d’obtenir un meilleur résultat, il est nécessaire de prendre suffisamment de vidéos et de photographies pour capturer l’objet dans son intégralité. Pour ce faire, des images doivent être prises depuis différentes perspectives autour de l’objet, en s’assurant à tout moment qu’il est immobile, centré sur l’écran et correctement mis au point, pendant que la caméra se déplace autour de l’objet pour prendre les images.</p>',
  },
  faqs_Data22: {
    es: '<p>Además, es fundamental evitar luces y sombras que puedan provocar distorsión en las imágenes usadas para la generación del modelo 3D.</p>',
    en: '<p>It is also essential to avoid lights and shadows that might distort the images used to generate the 3D model.</p>',
    pt: '<p>Além disso, é essencial evitar luzes e sombras que possam causar distorção nas imagens utilizadas para criar o modelo 3D.</p>',
    fr: '<p>En outre, il est essentiel d’éviter les reflets et les ombres qui peuvent créer des distorsions dans les images utilisées pour la génération du modèle 3D.</p>',
  },
  faqs_Data23: {
    es: '<p>Dependiendo del número de vídeos y fotos que sean necesarias para capturar para completar el objeto totalmente. Nuestras estadísticas nos indican que un video de un minuto o su equivalente de 120 fotografías, tardan de media unos 10 minutos para la generación del modelo 3D.</p>',
    en: '<p>It depends on the number of videos and/or photos needed to completely capture the object. Our statistics indicate that with a 1-minute video or its equivalent of 120 photographs, it takes an average of 10 minutes to generate the 3D model.</p>',
    pt: '<p>Dependendo do número de vídeos e fotografias que necessário captar para completar o objeto. As nossas estatísticas mostram que um vídeo de um minuto, ou o seu equivalente de 120 fotografias, demora em média dez minutos a criar o modelo 3D.</p>',
    fr: '<p>Cela dépend du nombre de vidéos et de photos qui doivent être capturées pour compléter l’objet dans son intégralité. Nos statistiques montrent que la génération du modèle 3D prend en moyenne 10 minutes avec une vidéo d’une minute ou son équivalent de 120 photos.</p>',
  },
  faqs_Data24: {
    es: '<p>Los modelos 3D se generan a partir de 10 segundos de vídeo y/ o 5 fotografías, pero teniendo presente que el número de imágenes capturadas deben ser aquellas que nos permita generar el modelo 3D de manera completa a partir de estas imágenes.</p>',
    en: '<p>3D models can be generated with as little as 10 seconds of video and/or 5 photographs. However, keep in mind that to generate a complete 3D model, you must use as many images as necessary to fully capture the object.</p>',
    pt: '<p>Os modelos 3D são criados a partir de dez segundos de vídeo e/ou cinco fotografias, mas deve ter em conta que o número de imagens captadas deve ser aquele que permite criar o modelo 3D completamente a partir dessas imagens.</p>',
    fr: '<p>Les modèles 3D sont générés à partir de 10 secondes de vidéo et/ou de 5 photographies, mais il ne faut pas oublier que le nombre d’images capturées doit nous permettre de générer le modèle 3D complet à partir de ces images.</p>',
  },
  faqs_Data25: {
    es: '<p>Adicionalmente, no es necesario realizar un gran volumen de captura de imágenes sino de aquellas que nos permitan capturar el objeto de manera completa para poder hacer el modelo 3D íntegro del mismo.En el caso de las fotos estas imágenes deben tener un overlap entre cada una de ellas del 60%, que en el caso del video no es necesario porque nosotros seleccionamos automáticamente aquellas imágenes que cumplen con este overlap.</p>',
    en: '<p>It is not necessary to capture a large number of images. As long as the images fully capture the complete object, it will be possible to make a 3D model of it. If you are taking photos, there must be a 60% overlap between each photo. If you are using video, this is not necessary because we will automatically select the images that have the required overlap.</p>',
    pt: '<p>Além disso, não é necessário captar um grande volume de imagens, apenas aquelas que nos permitem captar completamente o objeto para fazer um modelo 3D integral. No caso das fotografias, estas imagens devem ter uma sobreposição entre cada uma delas de 60%, algo que no caso de vídeo não é necessário, porque selecionamos automaticamente as imagens que têm esta sobreposição.</p>',
    fr: '<p>En outre, il n’est pas nécessaire de capturer un grand nombre d’images, mais uniquement celles qui nous permettent de capturer l’objet dans son intégralité afin de réaliser le modèle 3D complet. Dans le cas des photos, un chevauchement de 60 % est nécessaire entre chaque image. Cela n’est pas nécessaire pour les vidéos, car nous sélectionnons automatiquement les images qui respectent ce chevauchement.</p>',
  },
  faqs_Data26: {
    es: '<p>Los formatos disponibles para la descarga de modelos 3D en Malla son: .obj, .glb, .stl, .ply, .stp, .dae, .pdf, .gtiff o .png. Adicionalmente, existe la posibilidad de descargarse un obj con los polígonos reducidos para su incorporación a Realidad Aumentada y Realidad Virtual que nosotros llamamos formato arvr.</p>',
    en: '<p>The formats available for downloading 3D models in Mesh are: .obj, .glb, .stl, .ply, .stp, .dae, .pdf, .gtiff, and .png. You can also download an obj with the reduced polygons so they can be incorporated into Augmented Reality and Virtual Reality, which we call arvr format.</p>',
    pt: '<p>Os formatos disponíveis para transferir os modelos 3D em Malha são: .obj, .glb, .stl, .ply, .stp, .dae, .pdf, .gtiff o .png. Além disso, existe a possibilidade de transferir um .obj com polígonos reduzidos para incorporação em Realidade Aumentada e Realidade Virtual, a que chamamos formato arvr.</p>',
    fr: '<p>Les formats disponibles pour le téléchargement de modèles 3D en maille sont : .obj, .glb, .stl, .ply, .stp, .dae, .pdf, .gtiff ou .png. Il est également possible de télécharger un .obj avec des polygones réduits en vue de son intégration dans la réalité augmentée et la réalité virtuelle, que nous appelons format .arvr.</p>',
  },
  faqs_Data27: {
    es: '<p>La descarga de modelos 3D en Nube de Puntos se pueden realizar en los foramtos .ply, .las, .e57, .asc, .gtiff o .png.</p>',
    en: '<p>3D models in Point Cloud can be downloaded in the following formats: .ply, .las, .e57, .asc, .gtiff, and .png.</p>',
    pt: '<p>A transferência de modelos 3D em nuvem de pontos pode ser feita nos formatos .ply, .las, .e57, .asc, .gtiff ou .png.</p>',
    fr: '<p>Le téléchargement de modèles 3D dans un nuage de points peut être effectué dans les formats .ply, .las, .e57, .asc, .gtiff ou .png.</p>',
  },
  faqs_Data28: {
    es: '<p>Por supuesto. La importación de tus modelos 3D realizados con otros dispositivos y software es posible realizarla en nuestra plataforma, para que puedas usar las mismas con nuestras herramientas que permiten interactuar con el modelo 3D.</p>',
    en: '<p>Of course. You can use our platform to import 3D models made using other devices and software. This way, you can use our tools to interact with your 3D models.</p>',
    pt: '<p>Certamente. É possível importar os seus modelos 3D, realizados com outros dispositivos e software, na nossa plataforma, para que possa utilizá-los com as nossas ferramentas que lhe permitem interagir com o modelo 3D.</p>',
    fr: '<p>Bien sûr. Il est possible d’importer sur notre plateforme vos modèles 3D réalisés avec d’autres appareils et logiciels. Vous pouvez ensuite utiliser nos outils pour interagir avec vos modèles 3D.</p>',
  },
  faqs_Data29: {
    es: '<p>Los formatos en los que se pueden importar en la plataforma son en nube de puntos .ply, e57, asc, .las o .laz y en malla obj(con tres ficheros obj, html y jpg/ png) o ply(con dos ficheros ply y jpg/ png)</p>',
    en: '<p>The following formats can be used to import 3D models onto our platform in point cloud: .ply, e57, asc, .las, and .laz. In mesh, the permitted formats are obj (with three files: obj, html, and jpg/png) and ply (with two files: ply and jpg/png)</p>',
    pt: '<p>Os formatos que podem ser importados para a plataforma são em nuvem de pontos .ply, .e57, .asc, .las ou .laz e em malha .obj (com três ficheiros .obj, .html e .jpg/ .png) ou .ply (com dois ficheiros .ply e .jpg/ .png)</p>',
    fr: '<p>Les formats suivants peuvent être importés dans la plateforme : en nuages de points .ply, e57, asc, .las ou .laz ; et en maille obj (avec trois fichiers obj, html et jpg/ png) ou ply (avec deux fichiers ply et jpg/ png)</p>',
  },
  faqs_Data30: {
    es: '<p>La cuenta que crees en nuestra plataforma permite generar de manera gratuita cuantos modelos 3D quieras, pudiendo almacenar únicamente hasta 50 modelos de manera simultánea.Con las tarifas disponibles podrás almacenar hasta 2000 modelos 3D en tu propia biblioteca online.Elige la tarifa que más se ajuste a tus necesidades.</p>',
    en: '<p>By creating an account on our platform, you can create as many 3D models as you want for free. However, you can only store up to 50 models at a time. Our paid plans allow you to store up to 2,000 3D models in your own online gallery. You can choose the plan that fits your needs.</p>',
    pt: '<p>A conta que cria na nossa plataforma permite-lhe criar quantos modelos 3D pretender de forma gratuita, podendo armazenar até 50 modelos simultaneamente. Com as tarifas disponíveis pode armazenar até 2000 modelos 3D na sua própria biblioteca online. Escolha a tarifa que melhor se adapta às suas necessidades.</p>',
    fr: '<p>Le compte que vous créez sur notre plateforme vous permet de générer gratuitement autant de modèles 3D que vous le souhaitez, mais vous ne pouvez stocker que 50 modèles simultanément. Nous disposons de tarifs qui vous permettent de stocker jusqu’à 2 000 modèles 3D dans votre propre bibliothèque en ligne. Choisissez le forfait qui vous convient le mieux.</p>',
  },
  faqs_Data31: {
    es: '<p>Ir a tarifas</p>',
    en: '<p>Go to plans</p>',
    pt: '<p>Ir para tarifas</p>',
    fr: '<p>Aller aux tarifs</p>',
  },
  faqs_Data32: {
    es: '<p>Sí, la generación y la interacción a través de las Herramientas Estándar es gratuita.</p>',
    en: '<p>Yes, you can generate 3D models and interact with them using the Standard Tools for free.</p>',
    pt: '<p>Sim, a criação e a interação através das Ferramentas padrão são gratuitas.</p>',
    fr: '<p>Oui, vous pouvez générer et interagir gratuitement avec vos modèles 3D en utilisant les outils standard.</p>',
  },
  faqs_Data33: {
    es: '<p>Ir a Herramientas Estándar</p>',
    en: '<p>Go to Standard Tools</p>',
    pt: '<p>Ir para Ferramentas padrão</p>',
    fr: '<p>Aller à Outils Standard</p>',
  },
  faqs_Data34: {
    es: '<p>customercare@eyescloud3d.com</p>',
    en: '<p>customercare@eyescloud3d.com</p>',
    pt: '<p>customercare@eyescloud3d.com</p>',
    fr: '<p>customercare@eyescloud3d.com</p>',
  },

};
