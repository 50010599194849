import { Injectable } from '@angular/core';
import { meta_links } from '../meta-links/meta-links';
import { I18nService } from './i18n.service';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root',
})
export class updateMetaLinks {
  constructor(
    private i18nService: I18nService,
    private windowService: WindowService
  ) { }
  update(key: any) {
    let link1;
    let link2;
    let link3;
    let link4;
    link1 =
      meta_links[key + '_links1'][this.i18nService.getCurrentLanguage().code];
    link2 =
      meta_links[key + '_links2'][this.i18nService.getCurrentLanguage().code];
    link3 =
      meta_links[key + '_links3'][this.i18nService.getCurrentLanguage().code];
    link4 =
      meta_links[key + '_links4'][this.i18nService.getCurrentLanguage().code];

    this.removeAlternateAndCanonicalLinks();

    // Insertar los nuevos enlaces en el head
    this.insertLinkInHead(link1);
    this.insertLinkInHead(link2);
    this.insertLinkInHead(link3);
    this.insertLinkInHead(link4);
  }

  removeAlternateAndCanonicalLinks() {
    if (this.windowService.isBrowser) {
      // Obtener todos los elementos <link> en el head con rel="alternate" o rel="canonical"
      const alternateLinks = document.querySelectorAll(
        'head > link[rel="alternate"]'
      );
      const canonicalLinks = document.querySelectorAll(
        'head > link[rel="canonical"]'
      );

      // Eliminar todos los enlaces con rel="alternate" o rel="canonical" del head
      alternateLinks.forEach((link) => link.parentNode.removeChild(link));
      canonicalLinks.forEach((link) => link.parentNode.removeChild(link));
    }
  }

  insertLinkInHead(link) {
    if (this.windowService.isBrowser) {
      // Convertir el string HTML en un elemento del DOM
      const tempElement = document.createElement('div');
      tempElement.innerHTML = link;
      const linkElement = tempElement.firstElementChild;

      // Obtener el elemento <head>
      const head = document.querySelector('head');

      // Verificar el tipo de enlace y establecer el atributo rel correspondiente
      const relType = linkElement.getAttribute('rel');
      if (relType === 'canonical' || relType === 'alternate') {
        // Insertar el enlace HTML en el head
        head.appendChild(linkElement);
      } else {
        console.error('Tipo de enlace no válido: ' + relType);
      }
    }
  }
}
