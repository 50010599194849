export const BLOGPOST_I18N_ENTRIES = {
  blogpost_data1: {
    es: '<p>VOLVER AL BLOG</p>',
    en: '<p>BACK TO BLOG</p>',
    pt: '<p>REGRESSAR AO BLOG</p>',
    fr: '<p>RETOURNER AU BLOG</p>', 
  },
  blogpost_data2: {
    es: '<p>Compartir:</p>',
    en: '<p>Share:</p>',
    pt: '<p>Partilhar:</p>',
    fr: '<p>Partager :</p>', 
  },
};
