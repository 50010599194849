import { MatDialog } from '@angular/material/dialog';
import { ProtectYourAccountDialogComponent } from '../dialogs/two-factor/protect-your-account-dialog/protect-your-account-dialog.component';
import { SelectPreferenceDialogComponent } from '../dialogs/two-factor/select-preference-dialog/select-preference-dialog.component';
import { EnterPhoneNumberDialogComponent } from '../dialogs/two-factor/enter-phone-number-dialog/enter-phone-number-dialog.component';
import { EnterCodeDialogComponent } from '../dialogs/two-factor/enter-code-dialog/enter-code-dialog.component';
import { IsActiveDialogComponent } from '../dialogs/two-factor/is-active-dialog/is-active-dialog.component';
import { Injectable } from '@angular/core';
import { ProtectYourAccountResponses } from '../models/two-factor/enums/protect-your-account-responses';
import { SelectPreferenceResponses } from '../models/two-factor/enums/select-preference-response';
import { EnterPhoneNumberResponses } from '../models/two-factor/enums/enter-phone-number-responses';
import { EnterCodeResponses } from '../models/two-factor/enums/enter-code-responses';
import { TwoFactorStorage } from 'src/app/commons/services/two-factor-storage';
import { SecurityTokenStorage } from 'src/app/commons/services/security-token-storage';
import { UserToken } from 'src/app/commons/services/user-token';
import { RestClientService } from 'src/app/commons/services/rest-client.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TwoFactorRemoteService {
  private readonly URL = 'two-factor';

  constructor(private restClientService: RestClientService) {}

  public activateWithEmail(): Observable<null> {
    return this.restClientService.post(`${this.URL}/send-email`, null);
  }

  public activateWithPhone(phone_number: string): Observable<null> {
    return this.restClientService.post(`${this.URL}/send-sms`, {
      phone_number,
    });
  }

  public verifyCode(code: string, email?: string): Observable<null> {
    return this.restClientService.put(`${this.URL}/check-code`, {
      code,
      companyWide: false,
      ...(email && {
        email,
      }),
    });
  }
}
