import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogListComponent } from './components/blog-list/blog-list.component';
import { BlogPostComponent } from './components/blog-post/blog-post.component';
import { BlogComponent } from './components/blog/blog.component';
import { CommonsModule } from '../commons/commons.module';
import { BLOGLIST_I18N_ENTRIES } from './i18n/blog-list-i18n-entries';
import { BLOGPOST_I18N_ENTRIES } from './i18n/blog-post-i18n-entries';
import { I18nService } from '../commons/services/i18n.service';
import { CATEGORY_EYESCLOUD_I18N_ENTRIES } from './i18n/category-eyescloud-i18n-entries';

@NgModule({
  declarations: [BlogListComponent, BlogPostComponent, BlogComponent],
  imports: [CommonModule, CommonsModule],
})
export class BlogModule {
  constructor(private i18nService: I18nService) {
    i18nService.addI18nEntriesObject(BLOGLIST_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(BLOGPOST_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(CATEGORY_EYESCLOUD_I18N_ENTRIES);
  }
}
