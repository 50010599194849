<div class="blog-container">
  <a target="_self" [href]="getRouterLink('Blog') + '/' + lastBlog.url" *ngIf="lastBlog" class="blog-destacado">
    <div class="inicio-blog">

      <div class="left-column">
        <div class="title-blog">
          <p>{{lastBlog.title}}</p>
        </div>
        <div class="button-blog" (click)="navegar(getRouterLink('Blog') + '/' + lastBlog.url)"
          [innerHTML]="'articles_Data3'|i18n"></div>
      </div>
      <div class="imagen-blog">
        <img [src]="lastBlog?.imgDestacada" alt="blog image" loading="lazy">
        <div class="bg-border"></div>
        <div class="bg-border"></div>
      </div>
      <div class="button-blog mobile" [innerHTML]="'articles_Data3'|i18n"></div>
    </div>
  </a>

  <div class="category-blogs" [ngClass]="{'fit-content':isLoadMore }">
    <div class="category-list" [ngClass]="{'hide':checkResolution()}">
      <div class="title-categoy-list" [innerHTML]="'bloglist_data2'|i18n"></div>
      <div class="line"></div>
      <div class="lista-categorias">
        <div class="category-item" [ngClass]="{'category-selected':'bloglist_data9' === filtroCategoria}">
          <p (click)="filtroCategoriasLaterales('bloglist_data9')">{{ 'bloglist_data9' | i18n:1 }}</p>
          <p>{{sizeOfBlogs}}</p>
        </div>
        <div class="category-item" *ngFor="let categoria of categorias"
          [ngClass]="{'category-selected':categoria.name===filtroCategoria}"
          (click)="filtroCategoriasLaterales(categoria.name)">
          <p>{{ categoria.name|i18n:1 }}</p>
          <p>{{ categoria.blogs_count }}</p>
        </div>
      </div>
    </div>
    <div class="blogs">
      <div class="first-row-blog-list">
        <div class="category-selec" [ngClass]="{'hide':checkResolution()}">
          <p>{{filtroCategoria| i18n:1}}</p>
        </div>
        <div class="second-part">
          <div class="filter-by-text" [innerHTML]="'bloglist_data3'|i18n"></div>
          <div class="opciones">
            <div class="categories-select category-selector"
              [ngClass]="{'bigger-select':filtroCategoria==='category_data4'}">
              <mat-select [(ngModel)]="filtroCategoria" disableOptionCentering panelClass="categories-select-panel"
                (ngModelChange)="filtrarPorCategoria()">
                <mat-option value="bloglist_data9">{{ 'bloglist_data9' | i18n:1 }}</mat-option>
                <mat-option *ngFor="let categoria of categorias" [value]=categoria.name>
                  {{categoria.name | i18n:1}}
                </mat-option>
              </mat-select>
            </div>
            <div class="categories-select date-selector">
              <mat-select [(ngModel)]="ordenFecha" (ngModelChange)="sortDates()" disableOptionCentering
                panelClass="categories-select-panel" placeholder="{{'bloglist_data8' | i18n:1 }}">
                <mat-option [value]="'ascendente'">{{ 'bloglist_data4' | i18n:1 }}</mat-option>
                <mat-option [value]="'descendente'">{{ 'bloglist_data5' | i18n:1 }}</mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-list">
        <a target="_self" [href]="getRouterLink('Blog') + '/' + objeto.url" *ngFor="let objeto of objetosToShow"
          class="enlace-blogs">
          <div class="blog-item">
            <img [src]='objeto.imgMovil' alt="Imagen" [ngClass]="{'hide':!checkResolution()}" loading="lazy">
            <img [src]="objeto.imgDestacada" alt="Imagen" [ngClass]="{'hide':checkResolution()}" loading="lazy">
            <div class="title-blog-item">
              <p>{{ objeto.title }}</p>
            </div>
            <div class="description-blog-item">
              <div>{{ objeto.description }}<span [innerHTML]="'bloglist_data7'|i18n:1"></span></div>
            </div>
          </div>
        </a>
      </div>
      <div id="miBoton" class="load-more-blogs" (click)="cargarMas()" *ngIf="!hideMoreBtn"
        [innerHTML]="'bloglist_data6'|i18n"></div>
    </div>
  </div>


</div>