<div class="enter-phone-container" [ngClass]="{'incorrect-phone': isPhoneNumberIncorrect}">
  <div class="go-back" (click)="closeDialog(responses.BACK)">
    <img src="/assets/images/two-factor/back.svg" alt="Volver">
    <p [innerHTML]="'twoFactorDialog_Data9' | i18n"></p>
  </div>
  <div class="title" [innerHTML]="'twoFactorDialog_Data10' | i18n"></div>
  <input type="text" [ngClass]="{'incorrect': isPhoneNumberIncorrect}" [(ngModel)]="phoneNumber" [placeholder]="'twoFactorDialog_Data10' | i18n: translationModes.PLACEHOLDER">
  <div class="hint" [ngClass]="{'show-hint': isPhoneNumberIncorrect}" [innerHTML]="'twoFactorDialog_Data12' | i18n"></div>
  <button class="btn" (click)="continue()" [innerHTML]="'twoFactorDialog_Data11' | i18n"></button>
</div>
