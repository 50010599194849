import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';
import { I18nService } from 'src/app/commons/services/i18n.service';
import { Language } from 'src/app/commons/services/language';
import { GenerateRouteService } from '../../services/generate-route.service';
import { NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  selectedLanguage: string = this.i18nService.getCurrentLanguage().code;
  currentLanguage: Language = this.i18nService.getCurrentLanguage();
  translationModes: any = TranslationModeEnum;
  routeSubscription!: Subscription;
  isBlogActive: boolean = false;
  panelIsOpen: boolean = false;

  languageLabels = {
    es: 'language_selector_Data2',
    en: 'language_selector_Data3',
    pt: 'language_selector_Data4',
    fr: 'language_selector_Data5',
  };

  @ViewChild(MatSelect) matSelect: MatSelect; // Access mat-select

  constructor(
    private i18nService: I18nService,
    private generateRouteService: GenerateRouteService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.routeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      if (this.router.url.includes(this.getRouterLink('Blog'))) {
        this.isBlogActive = true;
      }
      this.selectedLanguage = this.i18nService.getCurrentLanguage().code;
    });
  }

  setCurrentLanguage(code: string) {
    let state: RouterStateSnapshot = this.router.routerState.snapshot;
    const toolParam = this.generateRouteService.extractParamFromUrl('tool', state);
    let translatedParam: string = '';

    // Extraer el valor del parámetro sin los caracteres especiales
    let urlParts;
    let pathPart;
    let queryString;
    let processedToolParam;
    if (state.url.includes('?')) {
      urlParts = state.url.split('?');
      pathPart = urlParts[0];
      queryString = urlParts[1] || '';
      processedToolParam = toolParam ? toolParam.split('?')[0] : '';
    } else {
      urlParts = state.url.split('#');

      pathPart = urlParts[0];
      queryString = urlParts[1] || '';
      processedToolParam = toolParam ? toolParam.split('#')[0] : '';
    }

    if (processedToolParam) {
      switch (processedToolParam) {
        case this.i18nService.getText('tools_Data199', TranslationModeEnum.PLACEHOLDER):
          this.i18nService.setCurrentLanguage(code);
          translatedParam = this.i18nService.getText('tools_Data199', TranslationModeEnum.PLACEHOLDER);
          break;
        case this.i18nService.getText('tools_Data200', TranslationModeEnum.PLACEHOLDER):
          this.i18nService.setCurrentLanguage(code);
          translatedParam = this.i18nService.getText('tools_Data200', TranslationModeEnum.PLACEHOLDER);
          break;
        case this.i18nService.getText('tools_Data201', TranslationModeEnum.PLACEHOLDER):
          this.i18nService.setCurrentLanguage(code);
          translatedParam = this.i18nService.getText('tools_Data201', TranslationModeEnum.PLACEHOLDER);
          break;
        case this.i18nService.getText('tools_Data202', TranslationModeEnum.PLACEHOLDER):
          this.i18nService.setCurrentLanguage(code);
          translatedParam = this.i18nService.getText('tools_Data202', TranslationModeEnum.PLACEHOLDER);
          break;
        case this.i18nService.getText('tools_Data203', TranslationModeEnum.PLACEHOLDER):
          this.i18nService.setCurrentLanguage(code);
          translatedParam = this.i18nService.getText('tools_Data203', TranslationModeEnum.PLACEHOLDER);
          break;
        default:
          this.i18nService.setCurrentLanguage(code);
          translatedParam = '';
          break;
      }
    } else {
      this.i18nService.setCurrentLanguage(code);
    }

    let finalParam;
    // Combinar el parámetro traducido con la parte de la consulta y el fragmento
    if (state.url.includes('?')) {
      finalParam = translatedParam ? `${translatedParam}?${queryString}` : '';
    } else {
      finalParam = translatedParam ? `${translatedParam}#${queryString}` : '';
    }

    document.querySelector('html')?.setAttribute('lang', code);
    this.currentLanguage = this.i18nService.getCurrentLanguage();
    if (this.isBlogActive) {
      this.router.navigate([this.getRouterLink('Blog')]);
    } else {
      this.generateRouteService.updateRoute(this.currentLanguage.code, finalParam);
    }
  }

  getRouterLink(routeKey: any): string {
    return this.generateRouteService.generateRoute(routeKey);
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
      this.isBlogActive = false;
    }
  }

  handleOpenChange() {
    this.panelIsOpen = !this.panelIsOpen;
  }

  getSelectedLanguageLabel(): string {
    return this.languageLabels[this.selectedLanguage] || '';
  }

  preventDrag(event: MouseEvent | TouchEvent, language: string): void {
    event.preventDefault();
    if (this.panelIsOpen) {
      setTimeout(() => {
        this.matSelect.close();
        this.setCurrentLanguage(language);
      }, 100);
    }
  }
}
