import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProtectYourAccountResponses } from 'src/app/auth/models/two-factor/enums/protect-your-account-responses';

@Component({
  selector: 'app-protect-your-account-dialog',
  templateUrl: './protect-your-account-dialog.component.html',
  styleUrls: ['./protect-your-account-dialog.component.scss'],
})
export class ProtectYourAccountDialogComponent {
  responses: typeof ProtectYourAccountResponses;

  constructor(
    private dialogRef: MatDialogRef<ProtectYourAccountDialogComponent>
  ) {
    this.responses = ProtectYourAccountResponses;
  }

  public closeDialog(
    response: ProtectYourAccountResponses = ProtectYourAccountResponses.DEFAULT
  ) {
    this.dialogRef.close(response);
  }
}
