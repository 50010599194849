import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResponsiveService } from '../../services/responsive.service';
import { Router } from '@angular/router';
import { GenerateRouteService } from '../../services/generate-route.service';
import { UpdateMetaService } from '../../services/meta-service.service';
import { updateMetaLinks } from '../../services/updateMetaLinks.service';
import { TranslationModeEnum } from '../../enums/translation-mode-enum';
import { Location } from '@angular/common';
import { SearchService } from 'src/app/help-center/services/search.service';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
})
export class FaqsComponent implements OnInit, OnDestroy {

  isLandscape: boolean = this.responsiveService.isLandscape();
  isPC: boolean = this.responsiveService.isPC();
  isExpanded: number | null = null;
  translationModes: any = TranslationModeEnum;
  popularItems = this.searchService.getPopularSearches();
  isFaqsActive: boolean = false;
  isHelpCenterContact: boolean = false;
  searchTerm: string = '';
  searchResults: string[] = [];
  standardRouterLink: string = this.getRouterLink('HCLibraryExtended') + '/' + this.i18nService.getText('tools_Data199', this.translationModes.PLACEHOLDER);

  constructor(
    private responsiveService: ResponsiveService,
    private generateRouteService: GenerateRouteService,
    private updateMetaService: UpdateMetaService,
    private updateMetaLinks: updateMetaLinks,
    private router: Router,
    private location: Location,
    private searchService: SearchService,
    private i18nService: I18nService
  ) { }

  ngOnInit(): void {
    this.isFaqsActive = this.router.url.includes(this.getRouterLink('Faq'));
    // this.isHelpCenterContact = this.router.url.includes(this.getRouterLink('HelpCenter'));

    if (this.isFaqsActive) {
      this.updateMetaService.updateMetaTags('Faq');
      this.updateMetaLinks.update('faq');
    }
  }

  ngOnDestroy(): void {
    this.isFaqsActive = false;
  }

  onResize(): void {
    this.isLandscape = this.responsiveService.isLandscape();
    this.isPC = this.responsiveService.isPC();
  }

  expand(questionId: number): void {
    if (this.isExpanded === questionId) {
      this.isExpanded = null;
    } else {
      this.isExpanded = questionId;
    }
  }

  getRouterLink(routeKey: any): string {
    return this.generateRouteService.generateRoute(routeKey);
  }

  goBack(): void {
    this.location.back();
  }

  onSearch(): void {
    this.searchResults = this.searchService.search(this.searchTerm);
  }

  isBlank(str: string): boolean {
    return !str || /^\s*$/.test(str);
  }

  getUrlForKey(key: string): string | undefined {
    return this.searchService.getUrlByKey(key);
  }

  //Para populares
  getUrl(item: any) {
    return item.url + item.urlParams;
  }
}
