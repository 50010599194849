import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseProfile } from '../models/base-profile';
import { AcceptedLogin } from '../models/accepted-login';
import { SocialCredential } from '../models/socialcredential';
import { RestClientService } from 'src/app/commons/services/rest-client.service';
import { Credential } from '../models/credential';
import { LoginResponse } from '../models/login-response';
import { EndPointEnum } from 'src/app/commons/enums/end-point';

@Injectable({ providedIn: 'root' })
export class LoginRemoteService {
  constructor(private restClientService: RestClientService) {}

  public login(credential: Credential): Observable<LoginResponse> {
    return this.restClientService.post('/login', credential);
  }

  public socialLogin<T extends BaseProfile>(
    credential: SocialCredential
  ): Observable<AcceptedLogin<T>> {
    return this.restClientService.post('/sociallogin', credential);
  }

  public logout(): Observable<any> {
    this.socialSignOut();
    return this.restClientService.get('/logout', null);
  }

  private socialSignOut() {
    // this.authService.signOut();
  }

  public existBimServerUSer<T extends BaseProfile>(
    credential: Credential
  ): Observable<string> {
    return this.restClientService.post('/bimserver-exist-user', credential);
  }

  public loginWithToken<T extends BaseProfile>(
    token: string
  ): Observable<AcceptedLogin<T>> {
    return this.restClientService.get(`/user-by-token/${token}`);
  }

  public blogLogin(
    username: string,
    password: string
  ): Observable<{ token: string }> {
    return this.restClientService.post(
      'user/login',
      { username, password },
      EndPointEnum.BLOG
    );
  }
}
