import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { StorageService } from './storage.service';
import { Language } from './language';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { TranslationModeEnum } from '../enums/translation-mode-enum';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  UrlTree,
} from '@angular/router';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  public static instance: I18nService;
  private currentLanguageCode: string = 'es';
  private i18nEntriesObjects: any[] = [];
  private userLogged: any;
  private subjectList: {
    key: string;
    subject: BehaviorSubject<string>;
    keyParams: any;
  }[] = [];

  constructor(
    private storageService: StorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private windowService: WindowService
  ) {
    I18nService.instance = this;
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeEs, 'es');
    try {
      this.currentLanguageCode =
        I18nService.getSupportedLanguages().find((lang) =>
          navigator?.language.includes(lang.code)
        )?.code || 'es';
    } catch (e) {
      this.currentLanguageCode = I18nService.getSupportedLanguages()[0].code;
    }
  }

  public static getSupportedLanguages(): Language[] {
    return [
      new Language('es', 'Español', 'es-ES,es;q=0.8', 1, 'es_ES'),
      new Language('en', 'English', 'en-EN,es;q=0.8', 2, 'en_US'),
      new Language('pt', 'Português', 'pt-PT,es;q=0.8', 3, 'pt_PT'),
      new Language('fr', 'Français', 'fr-FR,es;q=0.8', 4, 'fr_FR'),
      new Language('eus', 'Euskera', 'eu-EU,es;q=0.8', 5),
      new Language('eu', 'Euskera', 'eu-EU,es;q=0.8', 5),
      new Language('cat', 'Català', 'ca-ES,es;q=0.8', 6),
      new Language('ca', 'Català', 'ca-ES,es;q=0.8', 6),
    ];
  }

  public static getValueI18nKey(value: string): string {
    return value[0].toLowerCase() + value.slice(1);
  }

  private getLanguageByCode(code: string) {
    const supportedLanguages = I18nService.getSupportedLanguages();
    const index = supportedLanguages.findIndex(
      (language) => language.code === code
    );
    return index !== -1 ? supportedLanguages[index].id : index;
  }

  private getLanguageById(id: number) {
    const supportedLanguages = I18nService.getSupportedLanguages();
    const index = supportedLanguages.findIndex(
      (language) => language.id === id
    );
    return index !== -1 ? supportedLanguages[index].code : 'en';
  }

  public setCurrentLanguage(languageCode: string): void {
    let index = this.getLanguageByCode(languageCode);
    if (index === -1 && this.windowService.isBrowser) {
      const browserLang = navigator.language;
      const browserFormatted = browserLang.substring(0, 2);
      index = this.getLanguageByCode(browserFormatted);
      if (index === -1) {
        index = 1;
        languageCode = 'es';
      } else {
        languageCode = browserFormatted;
      }
    }
    this.currentLanguageCode = languageCode;
    this.storageService.set('language', this.currentLanguageCode);
    this.setLanguageCookie(this.currentLanguageCode);
    this.refreshNonPipeTexts();
  }

  private setLanguageCookie(languageCode: string): void {
    const cookieValue = `language2=${languageCode}; Path=/;Domain=.eyescloud3d.com; SameSite=Lax`;
    if (this.windowService.isBrowser) {
      document.cookie = cookieValue;
    }
  }

  private refreshNonPipeTexts() {
    for (let i = this.subjectList.length - 1; i >= 0; i--) {
      if (this.subjectList[i].subject.observers.length > 0) {
        this.subjectList[i].subject.next(
          this.getText(this.subjectList[i].key, this.subjectList[i].keyParams)
        );
      } else {
        this.subjectList.splice(i, 1);
      }
    }
  }
  private getCookie(name: string): string | null {
    if (this.windowService.isBrowser) {
      const matches = document.cookie.match(
        new RegExp(
          '(?:^|; )' +
            name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
            '=([^;]*)'
        )
      );
      return matches ? decodeURIComponent(matches[1]) : null;
    }
    return null;
  }
  public getCurrentLanguage(): Language {
    const supportedLanguages = I18nService.getSupportedLanguages();
    const languageCookie = this.getCookie('language2');
    if (languageCookie) {
      languageCookie == 'cat' || languageCookie == 'eus'
        ? (this.currentLanguageCode = 'es')
        : (this.currentLanguageCode = languageCookie);
      this.storageService.set('language', this.currentLanguageCode);
      return supportedLanguages.find(
        (x) => x.code === this.currentLanguageCode
      );
    } else if (this.storageService.get('language')) {
      this.currentLanguageCode = this.storageService.get('language');
      return (
        supportedLanguages.find((x) => x.code === this.currentLanguageCode) ||
        supportedLanguages[0]
      );
    } else if (this.userLogged != null) {
      this.currentLanguageCode = this.getLanguageById(this.userLogged.locale);
      return (
        supportedLanguages.find((x) => x.code === this.currentLanguageCode) ||
        supportedLanguages[0]
      );
    } else if (this.windowService.isBrowser) {
      const browserLang = navigator.language;
      let browserFormatted = browserLang.substr(0, 2);

      let index = this.getLanguageByCode(browserFormatted);
      if (index === -1) {
        index = 1;
        browserFormatted = 'es';
      }
      this.currentLanguageCode = browserFormatted;
      return (
        supportedLanguages.find((x) => x.code === this.currentLanguageCode) ||
        supportedLanguages[0]
      );
    } else {
      return supportedLanguages[0];
    }
  }

  public getText(key: string, keyParams?: any): string {
    return this.processTextFetch(key, keyParams);
  }

  private processTextFetch(key: string, keyParams?: any): string {
    if (key === '') {
      return '--';
    }
    let text = '';
    const keyFirstCharLowered = I18nService.getValueI18nKey(key);
    const i18nEntriesObject = this.i18nEntriesObjects.find(
      (x) => x[keyFirstCharLowered] !== undefined
    );
    if (!i18nEntriesObject) {
      return '--';
    }
    const entry = i18nEntriesObject[keyFirstCharLowered];
    text = entry[this.currentLanguageCode];

    if (text === '') {
      throw new Error(
        `Inexistent language '${this.currentLanguageCode}' for i18n entry '${key}'.`
      );
    }

    if (keyParams) {
      let textArray;
      let translationMode = keyParams;
      if (keyParams.length > 0) {
        translationMode = keyParams[0];
      }
      switch (translationMode) {
        case TranslationModeEnum.PLACEHOLDER:
        case TranslationModeEnum.TOOLTIP:
        case TranslationModeEnum.VIEWER:
          textArray = text.match(/\>(.*?)\</g);
          text = textArray[0].substring(1, textArray[0].length - 1);
          break;
        case TranslationModeEnum.INSERT_VALUE:
          textArray = text.split('%X%');
          if (textArray.length === keyParams.length) {
            text = textArray[0];
            for (let i = 1; i < textArray.length - 1; i++) {
              text += keyParams[i];
              text += textArray[i];
            }
            text += keyParams[keyParams.length - 1];
            text += textArray[textArray.length - 1];
          }
          break;
        case TranslationModeEnum.INSERT_VALUE_AND_REMOVE_TAGS:
          text = text.replace(/%X%/g, keyParams[1]);
          textArray = text.match(/\>(.*?)\</g);
          text = textArray[0].substring(1, textArray[0].length - 1);
          break;
        default:
          break;
      }
    }
    return text;
  }

  getTextAsSubscription(key: string, keyParams?: any): Observable<string> {
    const subject = new BehaviorSubject<string>('');

    let text = '';
    const keyFirstCharLowered = I18nService.getValueI18nKey(key);
    const i18nEntriesObject = this.i18nEntriesObjects.find(
      (x) => x[keyFirstCharLowered] !== undefined
    );
    if (!i18nEntriesObject) {
      if (!i18nEntriesObject) {
        text = '--';
      }
    }
    const entry = i18nEntriesObject[keyFirstCharLowered];
    text = entry[this.currentLanguageCode];

    if (text === '') {
      throw new Error(
        `Inexistent language '${this.currentLanguageCode}' for i18n entry '${key}'.`
      );
    }

    if (keyParams) {
      let textArray;
      let translationMode = keyParams;
      if (keyParams.length > 1) {
        translationMode = keyParams[0];
      }
      switch (translationMode) {
        case TranslationModeEnum.PLACEHOLDER:
        case TranslationModeEnum.TOOLTIP:
        case TranslationModeEnum.VIEWER:
          textArray = text.match(/\>(.*?)\</g);
          if (textArray) {
            text = textArray[0].substring(1, textArray[0].length - 1);
          }
          break;
        case TranslationModeEnum.INSERT_VALUE:
          textArray = text.split('%X%');
          if (textArray.length === keyParams.length) {
            text = textArray[0];
            for (let i = 1; i < textArray.length - 1; i++) {
              text += keyParams[i];
              text += textArray[i];
            }
            text += keyParams[keyParams.length - 1];
            text += textArray[textArray.length - 1];
          }
          break;
        case TranslationModeEnum.INSERT_VALUE_AND_REMOVE_TAGS:
          text = text.replace(/%X%/g, keyParams[1]);
          textArray = text.match(/\>(.*?)\</g);
          if (textArray) {
            text = textArray[0].substring(1, textArray[0].length - 1);
          }
          break;
        default:
          break;
      }
    }
    this.subjectList.push({ key, subject, keyParams });
    subject.next(text);
    return subject;
  }

  public addI18nEntriesObject(i18nEntriesObject: any): void {
    this.i18nEntriesObjects.push(i18nEntriesObject);
  }
}
