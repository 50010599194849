import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';

@Component({
  selector: 'app-contact-sent-dialog',
  templateUrl: './contact-sent-dialog.component.html',
  styleUrls: ['./contact-sent-dialog.component.scss']
})
export class ContactSentDialogComponent implements OnInit {

  translationModes: any = TranslationModeEnum;

  constructor(
    private dialog: MatDialogRef<ContactSentDialogComponent>,
  ) {
  }

  ngOnInit(): void {

  }

  close() {
    this.dialog.close();
  }
}
