import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { IsActiveResponses } from 'src/app/auth/models/two-factor/enums/is-active-responses';
import { Sources } from 'src/app/auth/models/two-factor/enums/sources';
import { IsActiveDialogData } from 'src/app/auth/models/two-factor/types/is-active-dialog-data';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { LoginRemoteService } from 'src/app/auth/services/login-remote.service';
import { TwoFactorRemoteService } from 'src/app/auth/services/two-factor-remote.service';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';

@Component({
  selector: 'app-is-active-dialog',
  templateUrl: './is-active-dialog.component.html',
  styleUrls: ['./is-active-dialog.component.scss'],
})
export class IsActiveDialogComponent {
  private readonly MAX_SECONDS = 30;

  translationModes: typeof TranslationModeEnum;

  subscriptions: Subscription[];

  code: string;

  isCodeIncorrect: boolean;

  remainingSeconds: number;

  countdown: NodeJS.Timer | undefined;

  sources: typeof Sources;

  constructor(
    private dialogRef: MatDialogRef<IsActiveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IsActiveDialogData,
    private twoFactorRemoteService: TwoFactorRemoteService,
    private authenticationService: AuthenticationService
  ) {
    this.translationModes = TranslationModeEnum;
    this.subscriptions = [];
    this.code = '';
    this.isCodeIncorrect = false;
    this.remainingSeconds = 0;
    this.sources = Sources;
  }

  public closeDialog(
    response: IsActiveResponses = IsActiveResponses.DEFAULT,
    data: any = null
  ): void {
    this.dialogRef.close({ status: response, data });
  }

  public continue(): void {
    this.subscriptions.push(
      this.twoFactorRemoteService
        .verifyCode(this.code, this.data.loginCredentials.email)
        .subscribe(
          (login) => {
            this.closeDialog(IsActiveResponses.NEXT, login);
          },
          () => {
            this.isCodeIncorrect = true;
          }
        )
    );
  }

  public generateNewCode() {
    this.authenticationService.repeatLastLogin().subscribe(
      () => {
        this.remainingSeconds = this.MAX_SECONDS;
        this.countdown = setInterval(() => {
          this.remainingSeconds--;
          if (this.remainingSeconds === 0) {
            clearInterval(this.countdown);
          }
        }, 1000);
      },
      (error) => {
        if (error.error === 3) {
          this.closeDialog(IsActiveResponses.LIMIT);
        }
      }
    );
  }

  public onCodeChange() {
    this.isCodeIncorrect = false;
  }
}
