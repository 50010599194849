<div class="explore-models-container" (window:resize)="onResize()">
  <div class="header">
    <h2 [innerHTML]="((!isPC) ? 'explore_models_Data1' : 'explore_models_Data2') | i18n"></h2>
    <button *ngIf="isPC" [innerHTML]="((!isPC) ? 'explore_models_Data4' : 'explore_models_Data5') | i18n"
      (click)="redirectToApp()" class="Cta_interaction-click-third-home"></button>
  </div>
  <div class="gallery" #gallery>
    <ng-container *ngFor="let item of items">
      <div class="item" (click)="goToModel(item.modelUrl)" [draggable]="false">
        <img src="{{item.imageUrl}}" alt="{{item.alt| i18n: translationModes.PLACEHOLDER}}" [draggable]="false">
      </div>
    </ng-container>
  </div>
  <button *ngIf="!isPC" [innerHTML]="((!isTablet) ? 'explore_models_Data4' : 'explore_models_Data5') | i18n"
    (click)="redirectToApp()"></button>
</div>
