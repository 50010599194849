export const HEADER_I18N_ENTRIES = {
  header_Data1: {
    es: '<p>Tarifas</p>',
    en: '<p>Plans</p>',
    pt: '<p>Tarifas</p>',
    fr: '<p>Tarifs</p>',
  },
  header_Data2: {
    es: '<p>FAQ</p>',
    en: '<p>FAQ</p>',
    pt: '<p>FAQ</p>',
    fr: '<p>FAQ</p>',
  },
  header_Data3: {
    es: '<p>Blog</p>',
    en: '<p>Blog</p>',
    pt: '<p>Blog</p>',
    fr: '<p>Blog</p>',
  },
  header_Data4: {
    es: '<p>Contacto</p>',
    en: '<p>Contact</p>',
    pt: '<p>Contacto</p>',
    fr: '<p>Contact</p>',
  },
  header_Data5: {
    es: '<p>Registro</p>',
    en: '<p>Register</p>',
    pt: '<p>Registo</p>',
    fr: '<p>Inscription</p>',
  },
  header_Data6: {
    es: '<p>Acceso</p>',
    en: '<p>Log in</p>',
    pt: '<p>Acesso</p>',
    fr: '<p>Accès</p>',
  },
  header_Data7: {
    es: '<p>Centro de ayuda</p>',
    en: '<p>Help Center</p>',
    pt: '<p>Centro de ajuda</p>',
    fr: '<p>Centre d\'aide</p>',
  },
};
