export const LANGUAGE_SELECTOR_I18N_ENTRIES = {
  language_selector_Data1: {
    es: '<p>Idioma</p>',
    en: '<p>Language</p>',
    pt: '<p>Idioma</p>',
    fr: '<p>Langue</p>',
  },
  language_selector_Data2: {
    es: '<p>Español</p>',
    en: '<p>Spanish</p>',
    pt: '<p>Espanhol</p>',
    fr: '<p>Espagnol</p>',
  },
  language_selector_Data3: {
    es: '<p>Inglés</p>',
    en: '<p>English</p>',
    pt: '<p>Inglês</p>',
    fr: '<p>Anglais</p>',
  },
  language_selector_Data4: {
    es: '<p>Portugués</p>',
    en: '<p>Portuguese</p>',
    pt: '<p>Português</p>',
    fr: '<p>Portugais</p>',
  },
  language_selector_Data5: {
    es: '<p>Francés</p>',
    en: '<p>French</p>',
    pt: '<p>Francês</p>',
    fr: '<p>Français</p>',
  },
  language_selector_Data6: {
    es: '<p>Euskera</p>',
    en: '<p>Basque</p>',
    pt: '<p>Euskera</p>',
    fr: '<p>Euskera</p>',
  },
  language_selector_Data7: {
    es: '<p>Catalán</p>',
    en: '<p>Catalan</p>',
    pt: '<p>Catalão</p>',
    fr: '<p>Catalan</p>',
  },

};
