import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Blog } from 'src/app/commons/models/blog';
import { AdminTokenStorage } from 'src/app/commons/services/admin-token-storage';
import { BlogService } from 'src/app/commons/services/blog.service';
import { SecurityTokenStorage } from 'src/app/commons/services/security-token-storage';
import { WindowService } from 'src/app/commons/services/window.service';

@Component({
  selector: 'app-admin-blog',
  templateUrl: './admin-blog.component.html',
  styleUrls: ['./admin-blog.component.scss'],
})
export class AdminBlogComponent implements OnInit {
  posts: Blog[] = [];
  selectedFavId: number | null = null;
  orderByAsc = true;
  orderByUpdatedAsc = true;

  platformNames = {
    1: 'ecaptureDtech',
    2: 'eyesCloud3D',
    3: 'eyesNroad',
    4: 'eyesDcar',
  };

  constructor(
    private blogService: BlogService,
    private securityTokenStorage: SecurityTokenStorage<string>,
    private adminTokenStorage: AdminTokenStorage<string>,
    private windowService: WindowService
  ) {}

  ngOnInit() {
    this.blogService.getAll().subscribe((response: any) => {
      this.posts = response;
      this.posts.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
      const favPost = this.posts.find((post) => post.is_fav);
      this.selectedFavId = favPost ? favPost.id : null;
    });
  }

  onDelete(id: any) {
    // Muestra un cuadro de diálogo de confirmación
    if (this.windowService.isBrowser) {
      const userConfirmed = window.confirm(
        '¿Estás seguro de que deseas eliminar este elemento?'
      );
      // Si el usuario hizo clic en "Aceptar" en el cuadro de diálogo
      if (userConfirmed) {
        this.blogService.delete(id).subscribe(
          () => {
            // Recargar la página después de la eliminación
            window.location.reload();
          },
          (error) => {
            window.alert(
              'No se ha podido eliminar este elemento: ' + error.message
            );
          }
        );
      }
    }
  }
  logout() {
    this.adminTokenStorage.deleteFromStorage();
    // Recargar la página después de cerrar sesión
    window.location.reload();
  }

  confirmMarkAsFav(event: Event, id: number): void {
    event.preventDefault();
    const selectedPost = this.posts.find((post) => post.id === id);
    const selectedPostFAV = this.posts.find((post) => post.id === id).is_fav;
    if (!selectedPost) return;
    const markAsFavConfirmed = window.confirm(
      '¿Quieres marcar este elemento como artículo destacado?'
    );
    if (markAsFavConfirmed) {
      const selectedPostPlatform = this.posts.find(
        (post) => post.id === id
      ).platform_id;
      const selectedPostLanguage = this.posts.find(
        (post) => post.id === id
      ).languages_id;
      this.posts.forEach((post) => {
        if (
          post.id !== id &&
          post.platform_id === selectedPostPlatform &&
          post.languages_id === selectedPostLanguage
        ) {
          post.is_fav = false;
        }
      });
      this.blogService
        .updateFavorite(id, selectedPostFAV, selectedPostPlatform)
        .subscribe(
          () => {
            window.alert('Se ha marcado correctamente');
          },
          (error) => {
            selectedPost.is_fav = false;
            window.alert(
              'No se pudo marcar el artículo como destacado: ' + error.message
            );
          }
        );
    } else {
      (event.target as HTMLInputElement).checked = !selectedPost.is_fav;
    }
  }

  toggleOrderBy() {
    this.orderByAsc = !this.orderByAsc;
    this.posts = this.posts.sort((a, b) => {
      const dateA = new Date(a.created_at).getTime();
      const dateB = new Date(b.created_at).getTime();
      return this.orderByAsc ? dateA - dateB : dateB - dateA;
    });
  }

  toggleOrderByUpdated() {
    this.orderByUpdatedAsc = !this.orderByUpdatedAsc;
    this.posts = this.posts.sort((a, b) => {
      const dateA = new Date(a.updated_at).getTime();
      const dateB = new Date(b.updated_at).getTime();
      return this.orderByUpdatedAsc ? dateA - dateB : dateB - dateA;
    });
  }
}
