import { Component, OnInit } from '@angular/core';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';
import { ResponsiveService } from 'src/app/commons/services/responsive.service';
import { AuthenticationService } from '../../services/authentication.service';
import { MatDialog } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';

import { RegisterService } from '../../services/register.service';
import { GenerateRouteService } from 'src/app/commons/services/generate-route.service';
import { UpdateMetaService } from 'src/app/commons/services/meta-service.service';
import { updateMetaLinks } from 'src/app/commons/services/updateMetaLinks.service';
import {
  GoogleLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ForgotPasswordDialogComponent } from '../login/dialogs/forgot-password-dialog/forgot-password-dialog.component';
import {
  ChangePasswordDialogComponent,
  ChangePasswordResponse,
} from '../login/dialogs/change-password-dialog/change-password-dialog.component';
import { ChangedPasswordDialogComponent } from '../login/dialogs/changed-password-dialog/changed-password-dialog.component';
import { I18nService } from 'src/app/commons/services/i18n.service';
declare var AppleID;
@Component({
  selector: 'app-android-login',
  templateUrl: './android-login.component.html',
  styleUrl: './android-login.component.scss',
})
export class AndroidLoginComponent {
  translationModes: any = TranslationModeEnum;
  showPassword: boolean = false;
  loginError: boolean = false;
  token: string = '';
  rememberMe: boolean = false;

  user = {
    email: '',
    password: '',
    rememberMe: false,
  };

  constructor(
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private registerService: RegisterService,
    private generateRouteService: GenerateRouteService,
    private updateMetaService: UpdateMetaService,
    private updateMetaLinks: updateMetaLinks,
    private socialAuthService: SocialAuthService,
    private i18nService: I18nService
  ) {}

  ngOnInit(): void {
    console.log(
      'este es el valor dle lidioma y url',
      this.i18nService.getCurrentLanguage()
    );

    this.token = this.route.snapshot.paramMap.get('token');
    if (this.token) {
      if (this.router.url.includes('forgot-password')) {
        this.openChangePasswordDialog();
      } else if (this.router.url.includes('email-verified')) {
        this.authenticationService.verifiedEmail(this.token).subscribe({
          next: () => {
            this.registerService.openRegisterStepFourDialog();
          },
          error: () => {},
        });
      }
    }
    this.updateMetaService.updateMetaTags('Android');
    this.socialAuthService.authState
      .pipe(filter((response) => response !== null))
      .subscribe((response) => {
        this.authenticationService.googleLogin(response).subscribe(() => {});
      });
    this.loadRememberedUser();
  }

  onSubmit(): void {
    this.authenticationService
      .login(this.user.email, this.user.password, false, this.rememberMe)
      .subscribe(
        (login) => {},
        (error) => {
          if (error) {
            this.loginError = true;
          }
        }
      );
  }

  hidePassword() {
    this.showPassword = !this.showPassword;
  }

  openForgotPasswordDialog(): void {
    this.dialog.open(ForgotPasswordDialogComponent, {
      autoFocus: false,
      disableClose: true,
      hasBackdrop: true,
      maxWidth: '100vw',
      panelClass: 'forgotPasswordDialog',
    });
  }

  openChangePasswordDialog(): void {
    this.dialog
      .open(ChangePasswordDialogComponent, {
        autoFocus: false,
        disableClose: true,
        hasBackdrop: true,
        maxWidth: '100vw',
        panelClass: 'changePasswordDialog',
        data: this.token,
      })
      .afterClosed()
      .subscribe((response: ChangePasswordResponse) => {
        switch (response) {
          case ChangePasswordResponse.SUCCESS:
            this.openChangedPasswordDialog(false);
            break;
          case ChangePasswordResponse.ERROR:
            this.openChangedPasswordDialog(true);
            break;
          case ChangePasswordResponse.DEFAULT:
          default:
        }
      });
  }

  openChangedPasswordDialog(error: boolean): void {
    const panelClass = error
      ? 'errorChangingPasswordDialog'
      : 'changedPasswordDialog';
    this.dialog.open(ChangedPasswordDialogComponent, {
      autoFocus: false,
      disableClose: true,
      hasBackdrop: true,
      maxWidth: '100vw',
      panelClass: panelClass,
      data: error,
    });
  }

  getRouterLink(routeKey: any): void {
    this.router.navigateByUrl(
      this.generateRouteService.generateRoute('android-register')
    );
  }

  public loginWithGoogle(): void {
    (
      document.querySelector(
        '#real-google-button > div > div > div'
      ) as HTMLButtonElement
    ).click();
  }

  public async loginWithApple() {
    try {
      AppleID.auth.init({
        clientId: 'com.eyescloud3d.api',
        scope: 'name email',
        redirectURI: window.location.href,
        state: '',
        usePopup: true,
      });
      const data = await AppleID.auth.signIn();
      data.email = JSON.parse(
        atob(
          (data.authorization.id_token as string)
            .split('.')[1]
            .replaceAll('-', '+')
            .replaceAll('_', '/')
        )
      ).email;
      this.authenticationService.appleLogin(data).subscribe(() => {});
    } catch (error) {
      console.log(error);
    }
  }

  onRememberMe() {
    this.rememberMe = !this.rememberMe;
  }

  loadRememberedUser() {
    const eyescloud3dUser = localStorage.getItem('eyescloud3dUser');
    const eyescloud3dPassword = localStorage.getItem('eyescloud3dPassword');
    if (eyescloud3dUser && eyescloud3dPassword) {
      this.user.email = eyescloud3dUser;
      this.user.password = eyescloud3dPassword;
      this.rememberMe = true;
    }
  }
}
