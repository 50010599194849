import { Injectable } from '@angular/core';
import { SpanishRoutes } from '../routes/spanish-routes';
import { PortugueseRoutes } from '../routes/portuguese-routes';
import { FrenchRoutes } from '../routes/french-routes';
import { EnglishRoutes } from '../routes/english-routes';
import { I18nService } from '../services/i18n.service';

type RouteDefinitions = typeof SpanishRoutes;

@Injectable({
  providedIn: 'root'
})

export class CheckCorrectRouteService {
  private languageRoutes: Record<string, RouteDefinitions> = {
    es: SpanishRoutes,
    us: EnglishRoutes,
    fr: FrenchRoutes,
    pt: PortugueseRoutes,
  };

  constructor(
    private i18nService: I18nService
  ) { }


  findRouteInOtherLanguage(url: string): string | undefined {
    let currentLanguage = this.i18nService.getCurrentLanguage().code.toLowerCase();
    if (currentLanguage === "en") {
      currentLanguage = "us";
    }

    // Elimina el código de idioma y el "/" del principio de la URL
    const adjustedUrl = url.startsWith(`/${currentLanguage}/`) ? url.slice(2 + currentLanguage.length) : url;
    let foundKey: string | undefined = undefined;

    // Itera sobre todos los archivos de rutas
    Object.entries(this.languageRoutes).forEach(([lang, routes]) => {
      const foundEntry = Object.entries(routes).find(([key, value]) => {
        const routeUrl = `/${value.URL}`; // Añade "/" al inicio de la URL
        return routeUrl.includes(adjustedUrl); // Comprueba si la URL ajustada está incluida en la ruta
      });

      if (foundEntry) {
        foundKey = foundEntry[0]; // Guarda la clave de la ruta encontrada
      }
    });

    // Busca la URL correspondiente en el idioma actual
    const languageSpecificRoutes = this.languageRoutes[currentLanguage];
    const languageSpecificValue = languageSpecificRoutes && languageSpecificRoutes[foundKey];
    const foundRoute = languageSpecificValue && languageSpecificValue.URL;

    // Retorna la URL original si no coincide con el idioma actual
    return foundRoute || url;
  }



}
