export const ARTICLES_I18N_ENTRIES = {
  articles_Data1: {
    es: '<p>Últimos artículos</p>',
    en: '<p>Latest Articles</p>',
    pt: '<p>Últimos artigos</p>',
    fr: '<p>Derniers articles</p>',
  },

  articles_Data2: {
    es: '<p>Ir al blog</p>',
    en: '<p>Go to Blog</p>',
    pt: '<p>Ir para o blog</p>',
    fr: '<p>Aller au blog</p>', 
  },

  articles_Data3: {
    es: '<p>Ir a artículo</p>',
    en: '<p>Go to Article</p>',
    pt: '<p>Ir para o artigo</p>',
    fr: '<p>Aller à l\'article</p>', 
  },

  articles_Data4: {
    es: '<p>Categorías</p>',
    en: '<p>Categories</p>',
    pt: '<p>Categorias</p>',
    fr: '<p>Catégories</p>', 
  },

  articles_Data5: {
    es: '<p>Filtrar y ordenar por:</p>',
    en: '<p>Filter and sort by:</p>',
    pt: '<p>Filtrar e ordenar por:</p>',
    fr: '<p>Filtrer et trier par:</p>', 
  },

  articles_Data6: {
    es: '<p>Categorías</p>',
    en: '<p>Categories</p>',
    pt: '<p>Categorias</p>',
    fr: '<p>Catégories</p>', 
  },

  articles_Data7: {
    es: '<p>Fecha</p>',
    en: '<p>Date</p>',
    pt: '<p>Data</p>',
    fr: '<p>Date</p>', 
  },

  articles_Data8: {
    es: '<p>Cargas más</p>',
    en: '<p>Load More</p>',
    pt: '<p>Carregar mais</p>',
    fr: '<p>Voir plus</p>', 
  },
};
