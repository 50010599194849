export const METADATA = {
  'tetera-utah': {
    TITLE: '¿Qué es la tetera de Utah o Newell? - eyesCloud3D',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/24/destacada',
    DESCRIPTION: '¿Sabes por qué la tetera es un símbolo dentro del mundo 3D? Conoce la historia de la tetera de Utah o Newell',
  },
  'lidar': {
    TITLE: 'Introducción al sensor LiDAR, qué es y cómo funciona',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/get-data-from-path?path=%252Fmnt%252Feyescloud3d%252Fecapturedtech%252FpublicImages%252FImagen%2Bcuerpo%2B%2B2.jpg.webp',
    DESCRIPTION: 'Conoce el sistema de escaneo láser para la generación de modelos 3D. Descubre los tipos de sensores LiDAR, sus ventajas y desventajas.',
  },
  'apriltags-generacion-3d': {
    TITLE: 'Targets, los mejores aliados para una generación de modelos 3D precisa',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/9/destacada',
    DESCRIPTION: 'Conoce las funciones que tienen los Targets (Apriltags) en la generación de modelos 3D. Afina el resultado de tus modelos 3D usando estas referencias.',
  },
  'herramientas-seguridad': {
    TITLE: 'Herramientas para la optimización de procesos investigativos en escenarios criminalísticos',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/13/destacada',
    DESCRIPTION: 'eyesCloud3D, la plataforma que digitaliza los procesos de investigación de los cuerpos de seguridad.',
  },
  'tipos-camara': {
    TITLE: 'Diferentes cámaras para generar modelos 3D',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/18/destacada',
    DESCRIPTION: 'Descubre qué cámara puedes usar para generar tu primer modelo 3D',
  },
  'fotogrametria-vs-escaner': {
    TITLE: 'Diferencias entre fotogrametría y láser escáner',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/get-data-from-path?path=%252Fmnt%252Feyescloud3d%252Fecapturedtech%252FpublicImages%252FImagen%2Bcuerpo%2B1.jpg',
    DESCRIPTION: 'Conoce las diferencias entre fotogrametría y láser escáner. Descubre las ventajas y las desventajas de cada uno de los sistemas y en qué destaca cada uno.',
  },
  'agricultura-precision': {
    TITLE: '¿Qué es la agricultura de precisión?',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/31/destacada?date=1713795043429',
    DESCRIPTION: 'Conoce las ventajas de la agricultura de precisión, en conjunto con la aplicación de la tecnología 3D. Descubre las ventajas y desventajas que puede tener su uso',
  },
  'gaussian-splatting': {
    TITLE: 'Desglosando el gaussian splatting: funcionamiento, alcance y limitaciones',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/35/destacada?date=1714985651709',
    DESCRIPTION: 'Conoce como funciona el Gaussian Splatting, cuales son sus ventajas y desventajas. Descubre qué usos tiene dentro del modelaje 3D [Leer más]',
  },
  'photogrammetrie-et-scanner-laser': {
    TITLE: 'Photogrammétrie et scanner laser | eyesCloud3D',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/37/destacada?date=1715585406043',
    DESCRIPTION: 'Connaître les différences entre la photogrammétrie et le balayage laser. Découvrez les avantages de chacun des systèmes utilisés en modélisation 3D',
  },
  'outils-scenes-de-crime': {
    TITLE: 'Outils pour l\'optimisation des processus d\'enquête dans les scènes de crime',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/38/destacada?date=1715676312192',
    DESCRIPTION: 'eyeCloud3D dispose des outils parfaits pour optimiser les processus d’enquête dans les scénarios criminels. Découvrez les possibilités offertes par eyeCloud3D',
  },
  'guia-drones': {
    TITLE: 'Pasos para generar modelos 3D a partir de las imágenes capturadas por un dron',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/39/destacada?date=1716385423272',
    DESCRIPTION: 'Aprende a generar modelos 3D a partir de las imágenes capturadas por un dron y ten en cuenta las consideraciones previas al vuelo',
  },
  'cameras-modeles-3d': {
    TITLE: 'Différentes caméras pour générer des modèles 3D',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/42/destacada?date=1717139680388',
    DESCRIPTION: 'Découvrez quel appareil photo vous pouvez utiliser pour créer votre premier modèle 3D. Créez votre premier modèle 3D avec n\'importe quel appareil [En savoir plus]',
  },
  'nube-de-puntos': {
    TITLE: 'Nube de Puntos en Modelos 3D: ¿Qué es? ¿cómo se genera?',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/get-data-from-path?path=%252Fmnt%252Feyescloud3d%252Fecapturedtech%252FpublicImages%252Fnube-de-puntos.jpeg',
    DESCRIPTION:
      'Conoce qué es la nube de puntos, cuáles son los pasos para generar una y cuáles son sus aplicaciones en los diferentes sectores',
  },
  'april-tags': {
    TITLE: 'April tags, les meilleurs alliés pour une génération précise de modèles 3D',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/43/destacada?date=1718610786181',
    DESCRIPTION:
      'Les balises d’avril ou les cibles de mise à l’échelle 3D servent au logiciel de point de référence pour la génération des modèles 3D.',
  },
  'nerf': {
    TITLE: 'Neural Radiance Field o NERF: Una Revolución en la Generación 3D',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/47/destacada?date=1718882486898',
    DESCRIPTION:
      'El mundo de la generación 3D siempre está en continua expansión. Conoce las ventajas y cómo funciona NERF, para la generación de imágenes 3D',
  },  
  'topografia-y-fotogrametria': {
    TITLE: 'Topografía y Fotogrametría:  Representación 3D del Terreno',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/49/destacada?date=1718968479969',
    DESCRIPTION:
      '¿Cómo la Topografía y la Fotogrametría se unen para mejorar la representación del terreno? Conoce como se unen las dos tecnologías',
  },  
  'neural-radiance-field': {
    TITLE: 'Neural Radiance Field: Une révolution dans la génération d’images 3D',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/50/destacada?date=1718970755593',
    DESCRIPTION:
      'Le monde de la génération 3D est en constante expansion et les avantages et la façon dont NERF fonctionne pour la génération d’images 3D sont toujours en expansion.',
  },
  'industrias-fotogrametria': {
    TITLE: 'Industrias que usan la fotogrametría - eyesCloud3D',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/51/destacada?date=1719826568026',
    DESCRIPTION:
      'La fotogrametría ha permitido a numerosas empresas y entidades públicas optimizar procesos. Conoce como la fotogrametría agiliza procesos en agricultura, topografía, construcción y seguridad',
  },  
  'fotogrametria-educacion': {
    TITLE: 'La fotogrametría, una herramienta clave para el aprendizaje interactivo',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/63/destacada?date=1720601050504',
    DESCRIPTION:
      'La tecnología es sinónimo de evolución de todos los campos. Con la fotogrametría a la educación, la enseñanza se hace bastante más interactiva y atractiva',
  },  
  'beneficios-3d-arquitectura': {
    TITLE: 'Beneficios del uso de modelos 3D en arquitectura',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/66/destacada?date=1721386126717',
    DESCRIPTION:
      'Conoce las ventajas que tiene usar modelos 3D en el campo de la arquitectura. Descubre este formato y sus posibilidades a la hora de mostrar el producto final.',
  }, 
  'nube-vs-malla': {
    TITLE: 'Nube de puntos vs Malla',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/68/destacada?date=1721643232991',
    DESCRIPTION:
      'Conoce estas dos formas de visualizar e interpretar un modelo 3D, nube de puntos o malla. Descubre las ventajas y desventajas del uso de cada una de ellas.',
  },
  '3d-persona': {
    TITLE: '¿Cómo hacer un modelo 3D de una persona?',
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/70/destacada?date=1722243537737',
    DESCRIPTION:
      'Conoce nuestras instrucciones para conseguir un buen resultado generando modelos de personas. Sigue estos sencillos pasos para hacer el modelo 3D de una persona.',
  },   
  'theiere-3d': {
    TITLE: "Qu’est-ce que la théière de l’Utah ou de Newell?",
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/73/destacada?date=1723117770264',
    DESCRIPTION:
      "Savez-vous pourquoi la théière est un symbole dans le monde 3D? Découvrez l'histoire de la théière Utah ou Newell.",
  },
  'introduction-lidar': {
    TITLE: "Introduction au capteur LiDAR",
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/75/destacada',
    DESCRIPTION:
      "Découvrez le système de balayage laser pour la génération de modèles 3D. Découvrez les types de capteurs LiDAR, leurs avantages et leurs inconvénients.",
  },  
  'gaussian-splatting-fonctionnement': {
    TITLE: "Décomposons le Gaussian Splatting",
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/76/destacada?date=1723536786528',
    DESCRIPTION:
      "Découvrez le fonctionnement du Gaussian Splatting, ses avantages et ses inconvénients. Découvrez ses utilisations dans le cadre de la modélisation 3D [En savoir plus].",
  }, 
  'agriculture-precision': {
    TITLE: "Qu'est-ce que l'agriculture de précision?",
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/77/destacada?date=1723628768154',
    DESCRIPTION:
      "Découvrez les avantages de l'agriculture de précision, associée à l'application de la technologie 3D. Découvrez les avantages et les inconvénients de son utilisation..",
  },
  'fotogrametria-accidentes': {
    TITLE: "Fotogrametría en accidentes de tráfico",
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/81/destacada?date=1726146582296',
    DESCRIPTION:
      "La reconstrucción de accidentes de tráfico ha ido evolucionando de la mano de la tecnología. Conoce qué función cumple la fotogrametría en la reconstrucción de accidentes.",
  },   
  'modeles-3d-drone': {
    TITLE: "Etapes pour générer des modèles 3D à partir d'images de drones",
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/83/destacada?date=1727078526329',
    DESCRIPTION:
      "Apprenez à générer des modèles 3D à partir d'images capturées par un drone et tenez compte des considérations précédentes.",
  },
  'importancia-reconstruccion-accidentes': {
    TITLE: "Importancia de la Reconstrucción de Accidentes de Tráfico",
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/84/destacada?date=1727363969318',
    DESCRIPTION:
      "Conoce la importancia que tienen la importancia de la reconstrucción de accidentes. Descubre qué tipos de reconstrucción de accidentes hay y las funciones que tienen.",
  },             
  '3d-ecommerce': {
    TITLE: "Modelos 3D, La Revolución del ecommerce",
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/85/destacada?date=1727688515165',
    DESCRIPTION:
      "Los ecommerce están integrando cada vez más modelos 3D. Descubre los beneficios de integrar los modelos 3D dentro del ecosistema del comercio electrónico.",
  },
  'errores-comunes-generacion': {
    TITLE: "Modelos 3D, La Revolución del ecommerce",
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/87/destacada?date=1728024609702',
    DESCRIPTION:
      "Conoce los errores más usuales que tienen los usuarios cuando pretenden generar modelos 3D con fotogrametría. Te contamos 4 errores comunes.",
  },              
  'eyescloud3d-blender': {
    TITLE: "Cómo importar un modelo 3D de eyesCloud3D a Blender",
    IMAGEN:
      'https://api.ecapturedtech.com/blog/item/89/destacada?date=1728634057180',
    DESCRIPTION:
      "Descubre cómo conseguir un modelo base en eyesCloud3D para usar en Blender. Importa el modelo 3D a Blender con estos consejos.",
  },               
};
