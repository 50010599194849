export const MetaConfig = {
  Home: {
    title: 'meta_config_Data5',
    description: 'meta_config_Data6',
    robots: 'meta_config_Data1',
  },
  Rates: {
    title: 'meta_config_Data7',
    description: 'meta_config_Data8',
    robots: 'meta_config_Data1',
  },
  Faq: {
    title: 'meta_config_Data9',
    description: 'meta_config_Data10',
    robots: 'meta_config_Data1',
  },
  Contact: {
    title: 'meta_config_Data11',
    description: 'meta_config_Data12',
    robots: 'meta_config_Data1',
  },
  Blog: {
    title: 'meta_config_Data13',
    description: 'meta_config_Data14',
    robots: 'meta_config_Data1',
  },
  Register: {
    title: 'meta_config_Data15',
    description: 'meta_config_Data16',
    robots: 'meta_config_Data1',
  },
  Login: {
    title: 'meta_config_Data17',
    description: 'meta_config_Data18',
    robots: 'meta_config_Data1',
  },
  TermsOfUse: {
    title: 'meta_config_Data19',
    description: 'meta_config_Data6',
    robots: 'meta_config_Data2',
  },
  Cookies: {
    title: 'meta_config_Data20',
    description: 'meta_config_Data6',
    robots: 'meta_config_Data2',
  },
  Privacy: {
    title: 'meta_config_Data21',
    description: 'meta_config_Data6',
    robots: 'meta_config_Data2',
  },
  Hiring: {
    title: 'meta_config_Data22',
    description: 'meta_config_Data6',
    robots: 'meta_config_Data2',
  },
  Android: {
    title: 'meta_config_Data17',
    description: 'meta_config_Data18',
    robots: 'meta_config_Data2',
  },
  AndroidRegister: {
    title: 'meta_config_Data15',
    description: 'meta_config_Data16',
    robots: 'meta_config_Data2',
  },

  //Help center tools
  HelpCenter: {
    title: 'meta_config_Data23',
    description: 'meta_config_Data24',
    robots: 'meta_config_Data1',
  },
  HCLibrary: {
    title: 'meta_config_Data25',
    description: 'meta_config_Data26',
    robots: 'meta_config_Data1',
  },
  HCLibraryStandard: {
    title: 'meta_config_Data27',
    description: 'meta_config_Data28',
    robots: 'meta_config_Data1',
  },
  HCLibraryStandardZAxis: {
    title: 'meta_config_Data29',
    description: 'meta_config_Data30',
    robots: 'meta_config_Data2',
  },
  HCLibraryStandardNotes: {
    title: 'meta_config_Data31',
    description: 'meta_config_Data32',
    robots: 'meta_config_Data2',
  },
  HCLibraryStandardCrop: {
    title: 'meta_config_Data33',
    description: 'meta_config_Data34',
    robots: 'meta_config_Data2',
  },
  HCLibraryStandardReducedVirtualTour: {
    title: 'meta_config_Data35',
    description: 'meta_config_Data36',
    robots: 'meta_config_Data2',
  },
  HCLibraryStandardInitialPosition: {
    title: 'meta_config_Data37',
    description: 'meta_config_Data38',
    robots: 'meta_config_Data2',
  },
  HCLibraryStandardFeature: {
    title: 'meta_config_Data39',
    description: 'meta_config_Data40',
    robots: 'meta_config_Data1',
  },
  HCLibraryStandardInteractionFeature: {
    title: 'meta_config_Data41',
    description: 'meta_config_Data42',
    robots: 'meta_config_Data2',
  },
  HCLibraryStandardCustomizationFeature: {
    title: 'meta_config_Data43',
    description: 'meta_config_Data44',
    robots: 'meta_config_Data2',
  },
  HCLibraryAdvanced: {
    title: 'meta_config_Data45',
    description: 'meta_config_Data46',
    robots: 'meta_config_Data1',
  },
  HCLibraryAdvancedMeasurement: {
    title: 'meta_config_Data47',
    description: 'meta_config_Data48',
    robots: 'meta_config_Data1',
  },
  HCLibraryAdvancedHeight: {
    title: 'meta_config_Data49',
    description: 'meta_config_Data50',
    robots: 'meta_config_Data2',
  },
  HCLibraryAdvancedArea: {
    title: 'meta_config_Data51',
    description: 'meta_config_Data52',
    robots: 'meta_config_Data2',
  },
  HCLibraryAdvancedVolume: {
    title: 'meta_config_Data53',
    description: 'meta_config_Data54',
    robots: 'meta_config_Data2',
  },
  HCLibraryAdvancedSurfaces: {
    title: 'meta_config_Data55',
    description: 'meta_config_Data56',
    robots: 'meta_config_Data2',
  },
  HCLibraryAdvancedMultiple: {
    title: 'meta_config_Data57',
    description: 'meta_config_Data58',
    robots: 'meta_config_Data2',
  },
  HCLibraryAdvancedOutline: {
    title: 'meta_config_Data59',
    description: 'meta_config_Data60',
    robots: 'meta_config_Data2',
  },
  HCLibraryAdvancedAngle: {
    title: 'meta_config_Data61',
    description: 'meta_config_Data62',
    robots: 'meta_config_Data2',
  },
  HCLibraryScale: {
    title: 'meta_config_Data63',
    description: 'meta_config_Data64',
    robots: 'meta_config_Data1',
  },
  HCLibraryScaleCoordinate: {
    title: 'meta_config_Data65',
    description: 'meta_config_Data66',
    robots: 'meta_config_Data2',
  },
  HCLibraryScaleReferenceSystem: {
    title: 'meta_config_Data67',
    description: 'meta_config_Data68',
    robots: 'meta_config_Data2',
  },
  HCLibraryScaleGeoreferenceSystem: {
    title: 'meta_config_Data69',
    description: 'meta_config_Data70',
    robots: 'meta_config_Data2',
  },
  HCLibraryScaleGround: {
    title: 'meta_config_Data71',
    description: 'meta_config_Data72',
    robots: 'meta_config_Data2',
  },
  HCLibraryScaleZAxis: {
    title: 'meta_config_Data73',
    description: 'meta_config_Data74',
    robots: 'meta_config_Data2',
  },
  HCLibraryModification: {
    title: 'meta_config_Data75',
    description: 'meta_config_Data76',
    robots: 'meta_config_Data1',
  },
  HCLibraryModificationCrop: {
    title: 'meta_config_Data77',
    description: 'meta_config_Data78',
    robots: 'meta_config_Data2',
  },
  HCLibraryModificationMerge: {
    title: 'meta_config_Data79',
    description: 'meta_config_Data80',
    robots: 'meta_config_Data2',
  },
  HCLibraryModificationSelection: {
    title: 'meta_config_Data81',
    description: 'meta_config_Data82',
    robots: 'meta_config_Data2',
  },
  HCLibraryModificationSection: {
    title: 'meta_config_Data83',
    description: 'meta_config_Data84',
    robots: 'meta_config_Data2',
  },
  HCLibraryView: {
    title: 'meta_config_Data85',
    description: 'meta_config_Data86',
    robots: 'meta_config_Data1',
  },
  HCLibraryViewLevelMap: {
    title: 'meta_config_Data87',
    description: 'meta_config_Data88',
    robots: 'meta_config_Data2',
  },
  HCLibraryViewVertex: {
    title: 'meta_config_Data89',
    description: 'meta_config_Data90',
    robots: 'meta_config_Data2',
  },
  HCLibraryViewVirtualTour: {
    title: 'meta_config_Data91',
    description: 'meta_config_Data92',
    robots: 'meta_config_Data2',
  },
  HCLibraryViewFP: {
    title: 'meta_config_Data93',
    description: 'meta_config_Data94',
    robots: 'meta_config_Data2',
  },
  HCLibraryViewIOTA: {
    title: 'meta_config_Data95',
    description: 'meta_config_Data96',
    robots: 'meta_config_Data1',
  },
  HCLibraryMovement: {
    title: 'meta_config_Data97',
    description: 'meta_config_Data98',
    robots: 'meta_config_Data1',
  },
  HCLibraryMovementPosition: {
    title: 'meta_config_Data99',
    description: 'meta_config_Data100',
    robots: 'meta_config_Data2',
  },
  HCLibraryMovementMulticam: {
    title: 'meta_config_Data101',
    description: 'meta_config_Data102',
    robots: 'meta_config_Data1',
  },
  HCLibraryMovementMap: {
    title: 'meta_config_Data103',
    description: 'meta_config_Data104',
    robots: 'meta_config_Data1',
  },
  HCLibrarySecurity: {
    title: 'meta_config_Data105',
    description: 'meta_config_Data106',
    robots: 'meta_config_Data1',
  },
  HCLibrarySecurity3DProjection: {
    title: 'meta_config_Data107',
    description: 'meta_config_Data108',
    robots: 'meta_config_Data2',
  },
  HCLibrarySecurityBullet: {
    title: 'meta_config_Data109',
    description: 'meta_config_Data110',
    robots: 'meta_config_Data2',
  },
  HCLibrarySecurityBloodSpatter: {
    title: 'meta_config_Data111',
    description: 'meta_config_Data112',
    robots: 'meta_config_Data2',
  },
  HCLibrarySecurity3DMatching: {
    title: 'meta_config_Data113',
    description: 'meta_config_Data114',
    robots: 'meta_config_Data2',
  },
  HCLibrarySecurityThickness: {
    title: 'meta_config_Data115',
    description: 'meta_config_Data116',
    robots: 'meta_config_Data2',
  },
  HCLibrarySecuritySensory: {
    title: 'meta_config_Data117',
    description: 'meta_config_Data118',
    robots: 'meta_config_Data2',
  },
  HCLibrarySecurityInteriorBlueprint: {
    title: 'meta_config_Data119',
    description: 'meta_config_Data120',
    robots: 'meta_config_Data2',
  },
  HCLibrarySecurityInteriorExterior: {
    title: 'meta_config_Data121',
    description: 'meta_config_Data122',
    robots: 'meta_config_Data2',
  },
  HCLibrarySecurityOfflineViewer: {
    title: 'meta_config_Data123',
    description: 'meta_config_Data124',
    robots: 'meta_config_Data2',
  },
  HCLibrarySecurityHyper: {
    title: 'meta_config_Data125',
    description: 'meta_config_Data126',
    robots: 'meta_config_Data2',
  },
  HCLibrarySecuritySegmentation: {
    title: 'meta_config_Data127',
    description: 'meta_config_Data128',
    robots: 'meta_config_Data2',
  },
  HCLibraryEngineering: {
    title: 'meta_config_Data129',
    description: 'meta_config_Data130',
    robots: 'meta_config_Data1',
  },
  HCLibraryEngineering3DProjection: {
    title: 'meta_config_Data131',
    description: 'meta_config_Data132',
    robots: 'meta_config_Data2',
  },
  HCLibraryEngineeringVegetation: {
    title: 'meta_config_Data133',
    description: 'meta_config_Data134',
    robots: 'meta_config_Data2',
  },
  HCLibraryEngineering3DMatching: {
    title: 'meta_config_Data135',
    description: 'meta_config_Data136',
    robots: 'meta_config_Data2',
  },
  HCLibraryEngineeringThickness: {
    title: 'meta_config_Data137',
    description: 'meta_config_Data138',
    robots: 'meta_config_Data2',
  },
  HCLibraryEngineeringSensory: {
    title: 'meta_config_Data139',
    description: 'meta_config_Data140',
    robots: 'meta_config_Data2',
  },
  HCLibraryEngineeringInteriorBlueprint: {
    title: 'meta_config_Data141',
    description: 'meta_config_Data142',
    robots: 'meta_config_Data2',
  },
  HCLibraryEngineeringInteriorExterior: {
    title: 'meta_config_Data143',
    description: 'meta_config_Data144',
    robots: 'meta_config_Data2',
  },
  HCLibraryEngineeringOfflineViewer: {
    title: 'meta_config_Data145',
    description: 'meta_config_Data146',
    robots: 'meta_config_Data2',
  },
  HCLibraryEngineeringHyper: {
    title: 'meta_config_Data147',
    description: 'meta_config_Data148',
    robots: 'meta_config_Data2',
  },
  HCLibraryEngineeringSegmentation: {
    title: 'meta_config_Data149',
    description: 'meta_config_Data150',
    robots: 'meta_config_Data2',
  },
  HCLibraryAgriculture: {
    title: 'meta_config_Data151',
    description: 'meta_config_Data152',
    robots: 'meta_config_Data1',
  },
  HCLibraryAgricultureInteriorExterior: {
    title: 'meta_config_Data153',
    description: 'meta_config_Data154',
    robots: 'meta_config_Data2',
  },
  HCLibraryAgricultureOfflineViewer: {
    title: 'meta_config_Data155',
    description: 'meta_config_Data156',
    robots: 'meta_config_Data2',
  },
  HCLibraryAgricultureThickness: {
    title: 'meta_config_Data157',
    description: 'meta_config_Data158',
    robots: 'meta_config_Data2',
  },
};
