import { MatDialog } from '@angular/material/dialog';
import { ProtectYourAccountDialogComponent } from '../dialogs/two-factor/protect-your-account-dialog/protect-your-account-dialog.component';
import { SelectPreferenceDialogComponent } from '../dialogs/two-factor/select-preference-dialog/select-preference-dialog.component';
import { EnterPhoneNumberDialogComponent } from '../dialogs/two-factor/enter-phone-number-dialog/enter-phone-number-dialog.component';
import { EnterCodeDialogComponent } from '../dialogs/two-factor/enter-code-dialog/enter-code-dialog.component';
import { IsActiveDialogComponent } from '../dialogs/two-factor/is-active-dialog/is-active-dialog.component';
import { Injectable } from '@angular/core';
import { ProtectYourAccountResponses } from '../models/two-factor/enums/protect-your-account-responses';
import { SelectPreferenceResponses } from '../models/two-factor/enums/select-preference-response';
import { EnterPhoneNumberResponses } from '../models/two-factor/enums/enter-phone-number-responses';
import { EnterCodeResponses } from '../models/two-factor/enums/enter-code-responses';
import { TwoFactorStorage } from 'src/app/commons/services/two-factor-storage';
import { SecurityTokenStorage } from 'src/app/commons/services/security-token-storage';
import { UserToken } from 'src/app/commons/services/user-token';
import { TwoFactorRemoteService } from './two-factor-remote.service';
import { Sources } from '../models/two-factor/enums/sources';
import { IsActiveResponses } from '../models/two-factor/enums/is-active-responses';
import { EnterPhoneNumberResponse } from '../models/two-factor/types/enter-phone-number-response';
import { IsActiveResponse } from '../models/two-factor/types/is-active-response';
import { LimitReachedDialogComponent } from '../dialogs/two-factor/limit-reached-dialog/limit-reached-dialog.component';
import { Credential } from '../models/credential';
import { WindowService } from 'src/app/commons/services/window.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TwoFactorService {
  private userData: any;

  constructor(
    private dialog: MatDialog,
    private twoFactorStorage: TwoFactorStorage<boolean>,
    private securityTokenStorage: SecurityTokenStorage<UserToken>,
    private twoFactorRemoteService: TwoFactorRemoteService,
    private windowService: WindowService
  ) {
    this.userData = {};
  }

  /**
   * Abre un popup preguntando al usuario si desea activar la verificación en dos pasos.
   */
  public openProtectYourAccountDialog(userData: any = this.userData): void {
    this.userData = userData;
    this.dialog
      .open(ProtectYourAccountDialogComponent, {
        maxWidth: '100vw',
        hasBackdrop: true,
        disableClose: true,
        panelClass: 'protect-your-account-dialog',
        backdropClass: 'custom-dialog-backdrop',
      })
      .afterClosed()
      .subscribe((response: ProtectYourAccountResponses) => {
        switch (response) {
          case ProtectYourAccountResponses.ACTIVATE:
            this.openSelectPreferenceDialog();
            break;
          case ProtectYourAccountResponses.DEFAULT:
          default:
            this.twoFactorStorage.saveObject(false);
            this.goToAppEyescloud3d();
        }
      });
  }

  /**
   * Abre un popup para seleccionar el modo de verificación en dos pasos (correo electrónico o teléfono).
   */
  public openSelectPreferenceDialog(): void {
    this.dialog
      .open(SelectPreferenceDialogComponent, {
        maxWidth: '100vw',
        hasBackdrop: true,
        disableClose: true,
        panelClass: 'select-preference-dialog',
        backdropClass: 'custom-dialog-backdrop',
      })
      .afterClosed()
      .subscribe((response: SelectPreferenceResponses) => {
        switch (response) {
          case SelectPreferenceResponses.EMAIL:
            this.openEnterCodeDialog(Sources.EMAIL, this.userData.email);
            break;
          case SelectPreferenceResponses.PHONE:
            this.openEnterPhoneNumberDialog();
            break;
          case SelectPreferenceResponses.BACK:
            this.openProtectYourAccountDialog();
            break;
          case SelectPreferenceResponses.LIMIT:
            this.openLimitReachedDialog();
            break;
          case SelectPreferenceResponses.DEFAULT:
          default:
        }
      });
  }

  /**
   * Abre el popup para introducir el número de teléfono al que se asociará la verificación en dos pasos.
   */
  public openEnterPhoneNumberDialog(): void {
    this.dialog
      .open(EnterPhoneNumberDialogComponent, {
        maxWidth: '100vw',
        hasBackdrop: true,
        disableClose: true,
        panelClass: 'enter-phone-number-dialog',
        backdropClass: 'custom-dialog-backdrop',
      })
      .afterClosed()
      .subscribe((response: EnterPhoneNumberResponse) => {
        if (response) {
          switch (response.status) {
            case EnterPhoneNumberResponses.NEXT:
              this.openEnterCodeDialog(Sources.PHONE, response.phoneNumber);
              break;
            case EnterPhoneNumberResponses.BACK:
              this.openSelectPreferenceDialog();
              break;
            case EnterPhoneNumberResponses.LIMIT:
              this.openLimitReachedDialog();
              break;
            case EnterPhoneNumberResponses.DEFAULT:
            default:
          }
        }
      });
  }

  /**
   * Abre el popup para introducir el código de verificación en dos pasos cuando el usuario no la tiene activada.
   */
  public openEnterCodeDialog(source: Sources, data: string): void {
    this.dialog
      .open(EnterCodeDialogComponent, {
        data: {
          source,
          data,
        },
        maxWidth: '100vw',
        hasBackdrop: true,
        disableClose: true,
        panelClass: 'enter-code-dialog',
        backdropClass: 'custom-dialog-backdrop',
      })
      .afterClosed()
      .subscribe((response: EnterCodeResponses) => {
        switch (response) {
          case EnterCodeResponses.NEXT:
            this.goToAppEyescloud3d();
            break;
          case EnterCodeResponses.BACK:
            source === Sources.EMAIL
              ? this.openSelectPreferenceDialog()
              : this.openEnterPhoneNumberDialog();
            break;
          case EnterCodeResponses.LIMIT:
            this.openLimitReachedDialog();
            break;
          case EnterCodeResponses.DEFAULT:
          default:
        }
      });
  }

  /**
   * Abre el popup para introducir el código de verificación en dos pasos cuando el usuario ya la tiene activada.
   */
  public openIsActiveDialog(
    source: Sources,
    data: string,
    loginCredentials: Credential
  ): void {
    this.dialog
      .open(IsActiveDialogComponent, {
        disableClose: true,
        data: {
          source,
          data,
          loginCredentials,
        },
        maxWidth: '100vw',
        hasBackdrop: true,
        panelClass: 'is-active-dialog',
        backdropClass: 'custom-dialog-backdrop',
      })
      .afterClosed()
      .subscribe((response: IsActiveResponse) => {
        if (response) {
          switch (response.status) {
            case IsActiveResponses.NEXT:
              this.userData = response.data;
              this.goToAppEyescloud3d();
              break;
            case IsActiveResponses.LIMIT:
              this.openLimitReachedDialog();
              break;
            case IsActiveResponses.DEFAULT:
            default:
          }
        }
      });
  }

  /**
   * Abre el popup que indica que se ha alcanzado el límite de generaciones de códigos de verificación.
   */
  public openLimitReachedDialog(): void {
    this.dialog
      .open(LimitReachedDialogComponent, {
        maxWidth: '100vw',
        hasBackdrop: true,
        disableClose: true,
        panelClass: 'limit-reached-dialog',
        backdropClass: 'custom-dialog-backdrop',
      })
      .afterClosed()
      .subscribe(() => {});
  }

  public setUserData(userData: any) {
    this.userData = userData;
  }

  public goToAppEyescloud3d() {
    if (this.windowService.isBrowser) {
      this.twoFactorStorage.saveObject(false);
      if (this.userData) {
        window.location.href = `${environment.appUrl}/login/${
          this.userData.token.split(' ')[1]
        }`;
      } else {
      }
    }
  }
}
