<img src="/assets/images/login/dialog/close.svg" alt="Cerrar" class="close-icon" (click)="close()">
<div class="title" [innerHTML]="'changePasswordDialog_Data1' | i18n"></div>
<form [formGroup]="resetPasswordForm">
  <div class="form-group password">
    <input class="input" [type]="showPassword[0] ? 'text' : 'password'" id="password" formControlName="password"
      required placeholder="{{ 'changePasswordDialog_Data2' | i18n: translationModes.PLACEHOLDER }}">
    <img class="eye-icon" (click)="hidePassword(0)" *ngIf="showPassword[0]" src="/assets/images/login/show.svg">
    <img class="eye-icon" (click)="hidePassword(0)" *ngIf="!showPassword[0]" src="/assets/images/login/hide.svg">
  </div>
  <div class="form-group password confirm">
    <input class="input" [type]="showPassword[1] ? 'text' : 'password'" id="passwordConfirmation"
      formControlName="passwordConfirmation" required
      placeholder="{{ 'changePasswordDialog_Data3' | i18n: translationModes.PLACEHOLDER }}">
    <img class="eye-icon" (click)="hidePassword(1)" *ngIf="showPassword[1]" src="/assets/images/login/show.svg">
    <img class="eye-icon" (click)="hidePassword(1)" *ngIf="!showPassword[1]" src="/assets/images/login/hide.svg">
  </div>
  <div class="password-match-error"
    *ngIf="f['passwordConfirmation'].errors && f['passwordConfirmation'].errors['mustMatch'] && !f['passwordConfirmation'].hasError('minlength')"
    [innerHTML]="'changePasswordDialog_Data5' | i18n"></div>
  <div class="password-match-error"
    *ngIf="f['password'].hasError('minlength') && f['password'].touched"
    [innerHTML]="'changePasswordDialog_Data6' | i18n"></div>
  <div (click)="send()" class="send-btn" [innerHTML]="'changePasswordDialog_Data4' | i18n"
    [ngClass]="{'disabled': !resetPasswordForm.valid}"></div>
</form>
