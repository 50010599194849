export class Category {
  id: number;
  name: string;
  blogs_count: number;

  constructor(id: any, blogs_count: number) {
    this.id = id;
    this.name = this.getI18n();
    this.blogs_count = blogs_count;
  }

  private getI18n(): string {
    return `category_data${this.id - 8}`;
  }
}
