<div class="home-container">
  <div class="register-container">
    <h1 [innerHTML]="'start_Data1' | i18n"></h1>
    <a target="_self" [href]="getRouterLink('Register')" class="seo-first-paint-btn-register"
      (click)="sendGtmTag('seo-first-paint-btn-register')">
      <button class="register-btn" [innerHTML]="'start_Data2' | i18n"></button>
    </a>
    <img class="bouncer" src="/assets/images/home/scroll.svg" alt="Scroll">
  </div>
  <div class="model-carrousel" #carrousel>
    <img src="/assets/images/home/memorial.webp" alt="{{ 'start_Data3' | i18n: translationModes.PLACEHOLDER }}"
      class="model-3d" [draggable]="false">
    <img src="/assets/images/home/statue.webp" alt="Modelo 3D" class="model-3d" [draggable]="false">
    <img src="/assets/images/home/car.webp" alt="Modelo 3D" class="model-3d" [draggable]="false">
  </div>
  <div class="mobile-container">
    <img src="/assets/images/home/scroll.svg" alt="Scroll">
    <a target="_self" [href]="getRouterLink('Register')">
      <button class="register-btn" [innerHTML]="'start_Data2' | i18n"></button>
    </a>
  </div>
</div>