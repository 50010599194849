import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RegisterStepTwoResponse } from 'src/app/auth/models/account-verification/register-step-two-response';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';

@Component({
  selector: 'app-register-step-two-dialog',
  templateUrl: './register-step-two-dialog.component.html',
  styleUrls: ['./register-step-two-dialog.component.scss'],
})
export class RegisterStepTwoDialogComponent {
  responses: typeof RegisterStepTwoResponse;
  translationModes: any = TranslationModeEnum;

  constructor(
    private matDialogRef: MatDialogRef<RegisterStepTwoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public email: string
  ) {
    this.responses = RegisterStepTwoResponse;
  }

  public closeDialog(
    response: RegisterStepTwoResponse = RegisterStepTwoResponse.DEFAULT
  ): void {
    this.matDialogRef.close(response);
  }
}
