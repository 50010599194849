export enum ToolsEnum {
  NONE,
  MEASURE,
  Z_AXIS,
  NOTES,
  CLIPPING,
  REDUCED_VIRTUAL_TOUR,
  INITIAL_POSITION,
  FUNCTIONALITIES,
  DOWNLOADS,
  INTERACTION,
  PERSONALIZATION,
  HEIGHT,
  AREA,
  VOLUME,
  SURFACE,
  MEASURE_MULTIPLE,
  CONTOUR,
  ANGLE,
  SCALE,
  COORDINATE,
  REFERENCE_SYSTEM,
  GEOREFERENCE_SYSTEM,
  FLOOR,
  MODEL_JOIN,
  OBJECT_SELECTION,
  SECTION,
  PROJECTION,
  ELEVATION_MAP,
  VERTEX_POINTS,
  VIRTUAL_TOUR,
  FIRST_PERSON,
  ROTATION_CENTER,
  IOTA,
  MAP,
  MULTICAM,
  DEFORMATION_ENERGY,
  THREE_D_PROJECTION_S,
  THREE_D_PROJECTION_E,
  BULLET_PATH,
  BLOOD_SPLATTER,
  THREE_D_MATCHING_S,
  THREE_D_MATCHING_E,
  THICKNESS_S,
  THICKNESS_E,
  THICKNESS_A,
  SENSORIC_S,
  SENSORIC_E,
  SENSORIC_A,
  INTERIOR_BLUEPRINTS_S,
  INTERIOR_BLUEPRINTS_E,
  EXTERIOR_INTERIOR_S,
  EXTERIOR_INTERIOR_E,
  EXTERIOR_INTERIOR_A,
  OFFLINE_VIEWER_S,
  HYPERSPECTRAL_S,
  HYPERSPECTRAL_E,
  POINT_TO_POINT_S,
  POINT_TO_POINT_E,
  POINT_TO_POINT_A,
  PHOTORECTIFICATION_S,
  PHOTORECTIFICATION_E,
  PHOTORECTIFICATION_A,
  SEGMENTATION_S,
  SEGMENTATION_E,
  IFC,
  VEGETATION_CALCULATION_E,
  VEGETATION_CALCULATION_A,
  MEASURE_COLLECTION,
  SCALE_COLLECTION,
  MODIFY_COLLECTION,
  VIEW_COLLECTION,
  IOTA_COLLECTION,
  MOVEMENT_COLLECTION,
  MULTICAM_COLLECTION,
  MAP_COLLECTION,
  OFFLINE_VIEWER_A,
  OFFLINE_VIEWER_E,
}

export const ToolDetails = [
  {
    tool: ToolsEnum.MEASURE,
    text: 'tools_Data1',
    title: 'tools_Data2',
    description: 'tools_Data3',
    manualLinks: 'tools_Data4',
    videoUrl: 'tools_Data204',
  },
  {
    tool: ToolsEnum.Z_AXIS,
    text: 'tools_Data5',
    title: 'tools_Data6',
    description: 'tools_Data7',
    manualLinks: 'tools_Data8',
    videoUrl: 'tools_Data205',
  },
  {
    tool: ToolsEnum.NOTES,
    text: 'tools_Data9',
    title: 'tools_Data10',
    description: 'tools_Data11',
    manualLinks: 'tools_Data12',
    videoUrl: 'tools_Data206',
  },
  {
    tool: ToolsEnum.CLIPPING,
    text: 'tools_Data13',
    title: 'tools_Data14',
    description: 'tools_Data15',
    manualLinks: 'tools_Data16',
    videoUrl: 'tools_Data207',
  },
  {
    tool: ToolsEnum.REDUCED_VIRTUAL_TOUR,
    text: 'tools_Data17',
    title: 'tools_Data18',
    description: 'tools_Data19',
    manualLinks: 'tools_Data20',
    videoUrl: 'tools_Data208',
  },
  {
    tool: ToolsEnum.INITIAL_POSITION,
    text: 'tools_Data21',
    title: 'tools_Data22',
    description: 'tools_Data23',
    manualLinks: 'tools_Data24',
    videoUrl: 'tools_Data209',
  },
  {
    tool: ToolsEnum.DOWNLOADS,
    text: 'tools_Data26',
    title: 'tools_Data26',
    description: 'tools_Data27',
    manualLinks: 'tools_Data28',
    videoUrl: 'tools_Data210',
  },
  {
    tool: ToolsEnum.INTERACTION,
    text: 'tools_Data29',
    title: 'tools_Data29',
    description: 'tools_Data30',
    manualLinks: 'tools_Data31',
    videoUrl: 'tools_Data211',
  },
  {
    tool: ToolsEnum.PERSONALIZATION,
    text: 'tools_Data32',
    title: 'tools_Data32',
    description: 'tools_Data33',
    manualLinks: 'tools_Data34',
    videoUrl: 'tools_Data212',
  },
  {
    tool: ToolsEnum.HEIGHT,
    text: 'tools_Data35',
    title: 'tools_Data36',
    description: 'tools_Data37',
    manualLinks: 'tools_Data38',
    videoUrl: 'tools_Data213',
  },
  {
    tool: ToolsEnum.AREA,
    text: 'tools_Data39',
    title: 'tools_Data40',
    description: 'tools_Data41',
    manualLinks: 'tools_Data42',
    videoUrl: 'tools_Data214',
  },
  {
    tool: ToolsEnum.VOLUME,
    text: 'tools_Data43',
    title: 'tools_Data44',
    description: 'tools_Data45',
    manualLinks: 'tools_Data46',
    videoUrl: 'tools_Data215',
  },
  {
    tool: ToolsEnum.SURFACE,
    text: 'tools_Data47',
    title: 'tools_Data48',
    description: 'tools_Data49',
    manualLinks: 'tools_Data50',
    videoUrl: 'tools_Data216',
  },
  {
    tool: ToolsEnum.MEASURE_MULTIPLE,
    text: 'tools_Data51',
    title: 'tools_Data52',
    description: 'tools_Data53',
    manualLinks: 'tools_Data54',
    videoUrl: 'tools_Data217',
  },
  {
    tool: ToolsEnum.CONTOUR,
    text: 'tools_Data55',
    title: 'tools_Data56',
    description: 'tools_Data57',
    manualLinks: 'tools_Data58',
    videoUrl: 'tools_Data218',
  },
  {
    tool: ToolsEnum.ANGLE,
    text: 'tools_Data59',
    title: 'tools_Data60',
    description: 'tools_Data61',
    manualLinks: 'tools_Data62',
    videoUrl: 'tools_Data219',
  },
  {
    tool: ToolsEnum.SCALE,
    text: 'tools_Data63',
    title: 'tools_Data64',
    description: 'tools_Data65',
    manualLinks: 'tools_Data66',
    videoUrl: 'tools_Data220',
  },
  {
    tool: ToolsEnum.COORDINATE,
    text: 'tools_Data67',
    title: 'tools_Data68',
    description: 'tools_Data69',
    manualLinks: 'tools_Data70',
    videoUrl: 'tools_Data221',
  },
  {
    tool: ToolsEnum.REFERENCE_SYSTEM,
    text: 'tools_Data71',
    title: 'tools_Data72',
    description: 'tools_Data73',
    manualLinks: 'tools_Data74',
    videoUrl: 'tools_Data222',
  },
  {
    tool: ToolsEnum.GEOREFERENCE_SYSTEM,
    text: 'tools_Data75',
    title: 'tools_Data76',
    description: 'tools_Data77',
    manualLinks: 'tools_Data78',
    videoUrl: 'tools_Data223',
  },
  {
    tool: ToolsEnum.FLOOR,
    text: 'tools_Data79',
    title: 'tools_Data80',
    description: 'tools_Data81',
    manualLinks: 'tools_Data82',
    videoUrl: 'tools_Data224',
  },
  {
    tool: ToolsEnum.MODEL_JOIN,
    text: 'tools_Data83',
    title: 'tools_Data84',
    description: 'tools_Data85',
    manualLinks: 'tools_Data86',
    videoUrl: 'tools_Data225',
  },
  {
    tool: ToolsEnum.OBJECT_SELECTION,
    text: 'tools_Data87',
    title: 'tools_Data88',
    description: 'tools_Data89',
    manualLinks: 'tools_Data90',
    videoUrl: 'tools_Data226',
  },
  {
    tool: ToolsEnum.SECTION,
    text: 'tools_Data91',
    title: 'tools_Data92',
    description: 'tools_Data93',
    manualLinks: 'tools_Data94',
    videoUrl: 'tools_Data227',
  },
  {
    tool: ToolsEnum.PROJECTION,
    text: 'tools_Data95',
    title: 'tools_Data96',
    description: 'tools_Data97',
    manualLinks: 'tools_Data98',
    videoUrl: 'tools_Data228',
  },
  {
    tool: ToolsEnum.ELEVATION_MAP,
    text: 'tools_Data99',
    title: 'tools_Data100',
    description: 'tools_Data101',
    manualLinks: 'tools_Data102',
    videoUrl: 'tools_Data229',
  },
  {
    tool: ToolsEnum.VERTEX_POINTS,
    text: 'tools_Data103',
    title: 'tools_Data104',
    description: 'tools_Data105',
    manualLinks: 'tools_Data106',
    videoUrl: 'tools_Data230',
  },
  {
    tool: ToolsEnum.VIRTUAL_TOUR,
    text: 'tools_Data107',
    title: 'tools_Data108',
    description: 'tools_Data109',
    manualLinks: 'tools_Data110',
    videoUrl: 'tools_Data231',
  },
  {
    tool: ToolsEnum.FIRST_PERSON,
    text: 'tools_Data111',
    title: 'tools_Data112',
    description: 'tools_Data113',
    manualLinks: 'tools_Data114',
    videoUrl: 'tools_Data232',
  },
  {
    tool: ToolsEnum.ROTATION_CENTER,
    text: 'tools_Data115',
    title: 'tools_Data116',
    description: 'tools_Data117',
    manualLinks: 'tools_Data118',
    videoUrl: 'tools_Data233',
  },
  {
    tool: ToolsEnum.IOTA,
    text: 'tools_Data119',
    title: 'tools_Data120',
    description: 'tools_Data121',
    manualLinks: 'tools_Data122',
    videoUrl: 'tools_Data234',
  },
  {
    tool: ToolsEnum.MAP,
    text: 'tools_Data123',
    title: 'tools_Data124',
    description: 'tools_Data125',
    manualLinks: 'tools_Data126',
    videoUrl: 'tools_Data235',
  },
  {
    tool: ToolsEnum.MULTICAM,
    text: 'tools_Data127',
    title: 'tools_Data128',
    description: 'tools_Data129',
    manualLinks: 'tools_Data130',
    videoUrl: 'tools_Data236',
  },

  {
    tool: ToolsEnum.DEFORMATION_ENERGY,
    text: 'tools_Data131',
    title: 'tools_Data132',
    description: 'tools_Data133',
    manualLinks: 'tools_Data134',
    videoUrl: 'tools_Data237',
  },
  //////////////////////////////////////////////////////////////////////////////////////////////
  {
    tool: ToolsEnum.THREE_D_PROJECTION_S,
    text: 'tools_Data135',
    title: 'tools_Data136',
    description: 'tools_Data137',
    manualLinks: 'tools_Data138',
    videoUrl: 'tools_Data238',
  },

  {
    tool: ToolsEnum.THREE_D_PROJECTION_E,
    text: 'tools_Data135',
    title: 'tools_Data136',
    description: 'tools_Data137',
    manualLinks: 'tools_Data138',
    videoUrl: 'tools_Data239',
  },

  {
    tool: ToolsEnum.BULLET_PATH,
    text: 'tools_Data139',
    title: 'tools_Data140',
    description: 'tools_Data141',
    manualLinks: 'tools_Data142',
    videoUrl: 'tools_Data240',
  },
  {
    tool: ToolsEnum.BLOOD_SPLATTER,
    text: 'tools_Data143',
    title: 'tools_Data144',
    description: 'tools_Data145',
    manualLinks: 'tools_Data146',
    videoUrl: 'tools_Data241',
  },
  {
    tool: ToolsEnum.THREE_D_MATCHING_S,
    text: 'tools_Data147',
    title: 'tools_Data148',
    description: 'tools_Data149',
    manualLinks: 'tools_Data150',
    videoUrl: 'tools_Data242',
  },
  {
    tool: ToolsEnum.THREE_D_MATCHING_E,
    text: 'tools_Data147',
    title: 'tools_Data148',
    description: 'tools_Data149',
    manualLinks: 'tools_Data150',
    videoUrl: 'tools_Data243',
  },

  {
    tool: ToolsEnum.THICKNESS_S,
    text: 'tools_Data151',
    title: 'tools_Data152',
    description: 'tools_Data153',
    manualLinks: 'tools_Data154',
    videoUrl: 'tools_Data244',
  },
  {
    tool: ToolsEnum.THICKNESS_E,
    text: 'tools_Data151',
    title: 'tools_Data152',
    description: 'tools_Data153',
    manualLinks: 'tools_Data154',
    videoUrl: 'tools_Data245',
  },
  {
    tool: ToolsEnum.THICKNESS_A,
    text: 'tools_Data151',
    title: 'tools_Data152',
    description: 'tools_Data153',
    manualLinks: 'tools_Data154',
    videoUrl: 'tools_Data246',
  },
  {
    tool: ToolsEnum.SENSORIC_S,
    text: 'tools_Data155',
    title: 'tools_Data156',
    description: 'tools_Data157',
    manualLinks: 'tools_Data158',
    videoUrl: 'tools_Data247',
  },
  {
    tool: ToolsEnum.SENSORIC_E,
    text: 'tools_Data155',
    title: 'tools_Data156',
    description: 'tools_Data157',
    manualLinks: 'tools_Data158',
    videoUrl: 'tools_Data248',
  },
  {
    tool: ToolsEnum.SENSORIC_A,
    text: 'tools_Data155',
    title: 'tools_Data156',
    description: 'tools_Data157',
    manualLinks: 'tools_Data158',
    videoUrl: 'tools_Data249',
  },
  {
    tool: ToolsEnum.INTERIOR_BLUEPRINTS_S,
    text: 'tools_Data159',
    title: 'tools_Data160',
    description: 'tools_Data161',
    manualLinks: 'tools_Data162',
    videoUrl: 'tools_Data250',
  },
  {
    tool: ToolsEnum.INTERIOR_BLUEPRINTS_E,
    text: 'tools_Data159',
    title: 'tools_Data160',
    description: 'tools_Data161',
    manualLinks: 'tools_Data162',
    videoUrl: 'tools_Data251',
  },
  {
    tool: ToolsEnum.EXTERIOR_INTERIOR_S,
    text: 'tools_Data163',
    title: 'tools_Data164',
    description: 'tools_Data165',
    manualLinks: 'tools_Data166',
    videoUrl: 'tools_Data252',
  },
  {
    tool: ToolsEnum.EXTERIOR_INTERIOR_E,
    text: 'tools_Data163',
    title: 'tools_Data164',
    description: 'tools_Data165',
    manualLinks: 'tools_Data166',
    videoUrl: 'tools_Data253',
  },
  {
    tool: ToolsEnum.EXTERIOR_INTERIOR_A,
    text: 'tools_Data163',
    title: 'tools_Data164',
    description: 'tools_Data165',
    manualLinks: 'tools_Data166',
    videoUrl: 'tools_Data254',
  },
  {
    tool: ToolsEnum.OFFLINE_VIEWER_S,
    text: 'tools_Data167',
    title: 'tools_Data168',
    description: 'tools_Data169',
    manualLinks: 'tools_Data170',
    videoUrl: 'tools_Data255',
  },
  {
    tool: ToolsEnum.OFFLINE_VIEWER_A,
    text: 'tools_Data167',
    title: 'tools_Data168',
    description: 'tools_Data169',
    manualLinks: 'tools_Data170',
    videoUrl: 'tools_Data256',
  },
  {
    tool: ToolsEnum.OFFLINE_VIEWER_E,
    text: 'tools_Data167',
    title: 'tools_Data168',
    description: 'tools_Data169',
    manualLinks: 'tools_Data170',
    videoUrl: 'tools_Data257',
  },
  {
    tool: ToolsEnum.HYPERSPECTRAL_S,
    text: 'tools_Data171',
    title: 'tools_Data172',
    description: 'tools_Data173',
    manualLinks: 'tools_Data174',
    videoUrl: 'tools_Data258',
  },
  {
    tool: ToolsEnum.HYPERSPECTRAL_E,
    text: 'tools_Data171',
    title: 'tools_Data172',
    description: 'tools_Data173',
    manualLinks: 'tools_Data174',
    videoUrl: 'tools_Data259',
  },
  {
    tool: ToolsEnum.POINT_TO_POINT_S,
    text: 'tools_Data175',
    title: 'tools_Data176',
    description: 'tools_Data177',
    manualLinks: 'tools_Data178',
    videoUrl: '',
  },
  {
    tool: ToolsEnum.POINT_TO_POINT_E,
    text: 'tools_Data175',
    title: 'tools_Data176',
    description: 'tools_Data177',
    manualLinks: 'tools_Data178',
    videoUrl: '',
  },
  {
    tool: ToolsEnum.POINT_TO_POINT_A,
    text: 'tools_Data175',
    title: 'tools_Data176',
    description: 'tools_Data177',
    manualLinks: 'tools_Data178',
    videoUrl: '',
  },
  {
    tool: ToolsEnum.PHOTORECTIFICATION_S,
    text: 'tools_Data179',
    title: 'tools_Data180',
    description: 'tools_Data181',
    manualLinks: 'tools_Data182',
    videoUrl: '',
  },
  {
    tool: ToolsEnum.PHOTORECTIFICATION_E,
    text: 'tools_Data179',
    title: 'tools_Data180',
    description: 'tools_Data181',
    manualLinks: 'tools_Data182',
    videoUrl: '',
  },
  {
    tool: ToolsEnum.PHOTORECTIFICATION_A,
    text: 'tools_Data179',
    title: 'tools_Data180',
    description: 'tools_Data181',
    manualLinks: 'tools_Data182',
    videoUrl: '',
  },
  {
    tool: ToolsEnum.SEGMENTATION_S,
    text: 'tools_Data183',
    title: 'tools_Data184',
    description: 'tools_Data185',
    manualLinks: 'tools_Data186',
    videoUrl: 'tools_Data260',
  },
  {
    tool: ToolsEnum.SEGMENTATION_E,
    text: 'tools_Data183',
    title: 'tools_Data184',
    description: 'tools_Data185',
    manualLinks: 'tools_Data186',
    videoUrl: 'tools_Data261',
  },

  {
    tool: ToolsEnum.IFC,
    text: 'tools_Data187',
    title: 'tools_Data188',
    description: 'tools_Data189',
    manualLinks: 'tools_Data190',
    videoUrl: 'tools_Data262',
  },
  {
    tool: ToolsEnum.VEGETATION_CALCULATION_E,
    text: 'tools_Data191',
    title: 'tools_Data192',
    description: 'tools_Data193',
    manualLinks: 'tools_Data194',
    videoUrl: 'tools_Data263',
  },
  {
    tool: ToolsEnum.VEGETATION_CALCULATION_A,
    text: 'tools_Data191',
    title: 'tools_Data192',
    description: 'tools_Data193',
    manualLinks: 'tools_Data194',
    videoUrl: 'tools_Data264',
  },
];

// Función para obtener los detalles de una herramienta específica
export const getToolDetails = (tool: ToolsEnum) => {
  return ToolDetails.find((item) => item.tool === tool);
};
