import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from 'src/app/commons/services/i18n.service';
/**
 * Clase que permite internacionalizar los texto.
 */
@Pipe({ name: 'i18n', pure: false })
export class I18nPipe implements PipeTransform {
  /**
   * Constructor vacio
   * @param {I18nService} i18nService Inicializa la instacia de I18nService
   */
  constructor(private i18nService: I18nService) {}
  /**
   * Transforma una el valor en un idoma y lo traduce a otro idioma
   * @param value Retorna el valor que se quiere internacionalizar
   * @param keyParams Obtiene la clave con la que se va a internacionalizar
   * @returns {string} Valor internacionalizado
   */
  public transform(value: any, ...keyParams: any[]): string {
    return this.i18nService.getText(value, keyParams);
  }
}
