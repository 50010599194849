import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformsEnum } from 'src/app/commons/enums/platforms-enum';
import { Blog } from 'src/app/commons/models/blog';
import { Category } from 'src/app/commons/models/category';
import { BlogService } from 'src/app/commons/services/blog.service';
import { CategoryService } from 'src/app/commons/services/category.service';
import { GenerateRouteService } from 'src/app/commons/services/generate-route.service';
import { I18nService } from 'src/app/commons/services/i18n.service';
import { UpdateMetaService } from 'src/app/commons/services/meta-service.service';
import { ResponsiveService } from 'src/app/commons/services/responsive.service';
import { updateMetaLinks } from 'src/app/commons/services/updateMetaLinks.service';
import { WebpConverterService } from 'src/app/commons/services/webpConverter.service';
import { WelcomeService } from 'src/app/commons/services/welcome.service';
import { WindowService } from 'src/app/commons/services/window.service';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss'],
})
export class BlogListComponent implements OnInit {
  posts: Blog[] = [];

  categorias: any[] | undefined;
  timeStamp: any;
  listaCompleta: any[] = [];
  filtroCategoria: string = 'bloglist_data9';
  ordenFecha: string = '';
  ordenActual: string = ''; // Guarda el orden actual
  numElementosMostrados = 4;
  lastBlog!: Blog;
  lastBlogCategories: any = 'TEST';
  sizeOfBlogs: number = 0;

  hideMoreBtn: boolean = false;
  isLoadMore = false;
  platforms: PlatformsEnum;

  constructor(
    private router: Router,
    private blogService: BlogService,
    private categoryService: CategoryService,
    private welcomeService: WelcomeService,
    private responsiveService: ResponsiveService,
    private updateMetaService: UpdateMetaService,
    private generateRouteService: GenerateRouteService,
    private i18nService: I18nService,
    private windowService: WindowService,
    private updateMetaLinks: updateMetaLinks,
    private webpConverterSerivce: WebpConverterService
  ) {}

  ngOnInit() {
    this.updateMetaLinks.update('blog');
    this.categoryService
      .getAll((this.platforms = PlatformsEnum.EYESCLOUD3D))
      .subscribe((data: Category[]) => {
        this.categorias = data;
        console.log(this.categorias);
      });
    if (this.responsiveService.isPC()) {
      this.numElementosMostrados = 8;
    } else {
      this.numElementosMostrados = 4;
    }
    this.blogService
      .getAll((this.platforms = PlatformsEnum.EYESCLOUD3D))
      .subscribe((response: any) => {
        this.posts = response.filter((post: any) => {
          return (
            post.languages_id === this.i18nService.getCurrentLanguage().id &&
            post.is_published
          );
        });

        this.posts.sort(
          (a: any, b: any) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );

        this.checkRemainingPosts();
        this.sizeOfBlogs = this.posts.length;
        if (this.posts.length > 0) {
          let blogMasReciente = this.posts[0]; // Inicializar con el primer blog

          this.posts.forEach((blogActual) => {
            this.webpConverterSerivce
              .toWebP(blogActual.imgMovil)
              .then((webpData) => {
                blogActual.imgMovil = webpData; // Aquí tienes los datos de la imagen en formato WebP
              })
              .catch((error) => {
                // Manejo de errores si la carga de la imagen falla
              });

            this.webpConverterSerivce
              .toWebP(blogActual.imgPC)
              .then((webpData) => {
                blogActual.imgPC = webpData; // Aquí tienes los datos de la imagen en formato WebP
              })
              .catch((error) => {
                console.error(error); // Manejo de errores si la carga de la imagen falla
              });
            if (blogActual.is_fav) {
              blogMasReciente = blogActual;
            }
          });
          this.lastBlog = blogMasReciente;
          this.webpConverterSerivce
            .toWebP(this.lastBlog.imgDestacada)
            .then((webpData) => {
              this.lastBlog.imgDestacada = webpData; // Aquí tienes los datos de la imagen en formato WebP
            })
            .catch((error) => {
              // Manejo de errores si la carga de la imagen falla
            });
          if (
            this.lastBlog &&
            this.lastBlog.categories &&
            this.lastBlog.categories.length > 0
          ) {
            // Accede a la primera categoría si existe
            this.lastBlogCategories = `category_data${this.lastBlog.categories[0]}`;
          }
        }
        const date = new Date().getTime();
        this.posts.map((post) => {
          post.imgMovil += '?' + date;
          post.imgPC += '?' + date;
          this.listaCompleta = this.posts;
        });
      });
    this.welcomeService.getShowWelcome().subscribe((response: any) => {
      if (response) {
        this.welcomeService.setShowWelcome();
      }
    });
    this.updateMetaService.updateMetaTags('Blog');
  }

  /**
   * Filtra la lista de objetos por categoría.
   * Si se selecciona la categoría 'todos', muestra todos los objetos.
   * Si se elige una categoría específica, filtra los objetos por esa categoría.
   * Restaura el orden después de aplicar el filtro por categoría.
   * @returns {void}
   */
  filtrarPorCategoria() {
    if (this.filtroCategoria === 'bloglist_data9') {
      // Si se elige "Todos", muestra todos los objetos
      this.posts = [...this.listaCompleta]; // Restaura la lista completa desde la copia de respaldo
    } else {
      // Filtra los objetos por la(s) categoría(s) seleccionada(s)

      this.posts = this.listaCompleta.filter((objeto) =>
        objeto.categories.includes(
          parseInt(this.filtroCategoria.slice(-1), 10) + 8
        )
      );
    }
    // Restaura el orden después de cambiar la categoría
    this.ordenarLista();
    this.checkRemainingPosts();
  }

  /**
   * Ordena la lista de objetos por fecha en orden ascendente o descendente según el estado actual del orden.
   * Si el orden actual es 'ascendente', ordena la lista por fecha en orden ascendente.
   * Si el orden actual es 'descendente', ordena la lista por fecha en orden descendente.
   * @returns {void}
   */
  ordenarLista() {
    if (this.ordenActual === 'ascendente') {
      // Ordena la lista por fecha ascendente
      this.posts.sort(
        (a: any, b: any) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
    } else {
      // Ordena la lista por fecha descendente
      this.posts.sort(
        (a: any, b: any) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    }
  }

  /**
   * Ordena las fechas en orden ascendente o descendente según el estado actual del orden.
   * Si el orden actual es 'ascendente', ordena la lista en orden ascendente.
   * Si el orden actual es 'descendente', ordena la lista en orden descendente.
   * @returns {void}
   */
  sortDates() {
    if (this.ordenFecha === 'ascendente') {
      this.ordenActual = 'ascendente'; // Actualiza el orden actual
      this.ordenarLista();
    } else {
      this.ordenActual = 'descendente'; // Actualiza el orden actual
      this.ordenarLista();
    }
  }

  /**
   * Aumenta el número de elementos mostrados según el ancho de la ventana.
   * Si el ancho de la ventana es menor o igual a 834 píxeles, agrega 4 elementos, de lo contrario, agrega 8.
   * @returns {void}
   */
  cargarMas() {
    this.isLoadMore = true;
    if (this.responsiveService.isPC()) {
      this.numElementosMostrados = this.numElementosMostrados + 8;
    } else {
      this.numElementosMostrados = this.numElementosMostrados + 4;
    }
    this.checkRemainingPosts();
  }

  /**
   * Getter que devuelve una lista de objetos limitada por el número de elementos mostrados.
   * @returns {Array<any>} Una lista de objetos limitada por el número de elementos mostrados.
   */
  get objetosToShow() {
    return this.posts.slice(0, this.numElementosMostrados); // Limita los objetos a mostrar
  }

  /**
   * Comprueba si el ancho de la ventana es menor o igual a 834 píxeles.
   * @returns {boolean} Devuelve true si el ancho de la ventana es menor o igual a 834 píxeles, de lo contrario, false.
   */
  checkResolution() {
    return !this.responsiveService.isPC();
  }

  getURL(url: string) {
    this.timeStamp = new Date().getTime();
    if (this.timeStamp) {
      return url + '?' + this.timeStamp;
    }
    return url;
  }
  /**
   * Navega a la ruta especificada utilizando el servicio Router de Angular.
   * Si no se proporciona ninguna ruta, la función no realiza ninguna navegación.
   * @param {string} ruta - La ruta a la que se desea navegar.
   * @returns {void}
   */
  navegar(ruta: string = '') {
    this.router.navigateByUrl(ruta);
  }

  /**
   * Revisa si hay más elementos mostrados que cantidad de post para ocultar
   * el botón de "cargar más".
   */
  checkRemainingPosts(): void {
    this.hideMoreBtn = this.posts.length <= this.numElementosMostrados;
  }

  getRouterLink(routeKey: any): string {
    return this.generateRouteService.generateRoute(routeKey);
  }
  filtroCategoriasLaterales(categoria) {
    this.filtroCategoria = categoria;
    this.filtrarPorCategoria();
  }
}
