<div class="container">
  <form (ngSubmit)="onSubmit()" *ngIf="post" #blogForm="ngForm">
    <div class="buttons">
      <button class="btn save"
        [ngClass]="{'disabled': !blogForm.valid ||   !post.imgMovil || !atLeastOneCategorySelected()}">Publicar</button>
      <button class="btn temporary" (click)="temporaryBlog()">Guardar borrador</button>
      <button class="btn cancel" (click)="onCancel()">Cancelar</button>
    </div>
    <div class="form-group">
      <label for="title">Título:</label>
      <input type="text" name="title" class="input" id="title" [(ngModel)]="post.title" required>
    </div>
    <div class="form-group">
      <label for="subtitle">Subtítulo:</label>
      <input type="text" name="subtitle" class="input" id="subtitle" [(ngModel)]="post.subtitle">
    </div>
    <div class="form-group">
      <label for="url">Url:</label>
      <input type="text" name="url" class="input" id="url" [(ngModel)]="post.url" required>
    </div>
    <div class="form-group">
      <label for="subtitle">Meta - Título:</label>
      <input type="text" name="metatitle" class="input" id="metatitle" [(ngModel)]="post.metatitle" required>
    </div>
    <div class="form-group">
      <label for="subtitle">Meta - Descripción:</label>
      <input type="text" name="metadescription" class="input" id="metadescription" [(ngModel)]="post.metadescription"
        required>
    </div>
    <div class="form-group">
      <label for="subtitle">Schema mark-up:</label>
      <textarea name="schema" class="input" id="schema" [(ngModel)]="post.schema"></textarea>
    </div>
    <!-- <div class="form-group">
      <label for="subtitle">Título RRSS:</label>
      <textarea name="RRSS" class="input" id="RRSS" [(ngModel)]="post.titulo_rrss"></textarea>
    </div> -->
    <div class="form-group intro">
      <label for="url">Introducción:</label>
      <textarea class="textarea" id="description" name="description" [(ngModel)]="post.description" required></textarea>
    </div>
    <div class="two-columns">
      <div class="form-group">
        <label for="imagenmovil">Imagen móvil: (No acepta .webp)</label>
        <input type="file" id="imagenmovil" name="imagenmovil" (change)="selectImgMovil($event)" required>
        <img [src]="previewMobile" alt="Imagen seleccionada" width="200" height="200" *ngIf="previewMobile !== ''">
      </div>
      <!-- <div class="form-group">
        <label for="imagenpc">Imagen PC: (No acepta .webp)</label>
        <input type="file" id="imagenpc" name="imagenpc" (change)="selectImgPC($event)" required>
        <img [src]="previewPC" alt="Imagen seleccionada" width="200" height="200" *ngIf="previewPC !== ''">
      </div> -->
      <div class="form-group">
        <label for="imagendestacada">Imagen Destacada: (No acepta .webp)</label>
        <input type="file" id="imagendestacada" name="imagendestacada" (change)="selectImgDestacada($event)" required>
        <img [src]="previewDestacada" alt="Imagen seleccionada" width="200" height="200"
          *ngIf="previewDestacada !== ''">
      </div>
    </div>
    <div class="form-group">
      <label for="imagendestacada">Imagen RRSS: (No acepta .webp)</label>
      <input type="file" id="imagenRRSS" name="imagenRRSS" (change)="selectImgRRSS($event)" required>
      <img [src]="previewRRSS" alt="Imagen seleccionada" width="200" height="200" *ngIf="previewRRSS !== ''">
    </div>

    <div *ngIf="post.created_at">
      <label for="fechaInput">Fecha: </label>
      <span>{{post.created_at| date:'dd-MM-yyyy'}}</span>
    </div>

    <div class="form-group categories-container">
      <label>Selecciona categorías:</label>
      <div class="categories">
        <div *ngFor="let opcion of categoriasChecked">
          <input type="checkbox" id="{{ opcion.id+8 }}" name="{{ opcion.name| i18n:1 }}" [(ngModel)]="opcion.presente">
          <label for="{{ opcion.name | i18n:1 }}">{{ opcion.name | i18n:1 }}</label>
        </div>
      </div>
    </div>

    <div class="two-columns">
      <div class="form-group platform-container">
        <label>Asigna una plataforma al artículo:</label>
        <mat-select name="platform_id" [(ngModel)]="post.platform_id" disableOptionCentering required>
          <mat-option *ngFor="let platform of platforms" [value]="platform.id">{{ platform.name }}</mat-option>
        </mat-select>
      </div>

      <div class="form-group platform-container">
        <label>¿En qué idioma está el artículo?:</label>
        <mat-select name="language_id" [(ngModel)]="post.languages_id" disableOptionCentering required>
          <mat-option *ngFor="let language of languages" [value]="language.id">{{ language.name }}</mat-option>
        </mat-select>
      </div>
    </div>
    <label for="selectBlogs">Selecciona uno o varios blogs:</label>
    <mat-select id="selectBlogs" multiple class="select-blogs" [panelClass]="'custom-panel-class'"
      [(ngModel)]="selectedBlogs" [(value)]="selectedBlogs" name="firstName">
      <mat-option *ngFor="let blog of allBlogs" [value]="blog.id">{{ blog.title }}</mat-option>
    </mat-select>
    <div class="form-group-tiny">
      <label for="body">Post:</label>
      <textarea name="body" class="editor" [(ngModel)]="post.body">
      </textarea>
    </div>
  </form>
  <div class="conversor">
    <app-wep-conversor></app-wep-conversor>
  </div>
</div>