import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SecurityTokenStorage } from 'src/app/commons/services/security-token-storage';
import { UserToken } from 'src/app/commons/services/user-token';
import { UserService } from 'src/app/commons/services/user.service';

@Component({
  selector: 'app-admin-blog-login',
  templateUrl: './admin-blog-login.component.html',
  styleUrls: ['./admin-blog-login.component.scss'],
})
export class AdminBlogLoginComponent {
  formulario: FormGroup; // Declaración del formulario

  constructor(
    private router: Router,
    private userService: UserService,
    private securityTokenStorage: SecurityTokenStorage<UserToken>
  ) {
    this.formulario = new FormGroup({
      name: new FormControl(''),
      password: new FormControl(''),
    });
  }

  ngOnInit() { }

  onSubmit(formValue: any) {
    this.userService
      .blogLogin(formValue.name, formValue.password)
      .subscribe(() => {
        this.router.navigate(['/admin']);
      });
  }
}
