import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { HEADER_I18N_ENTRIES } from './i18n/header-i18n-entries';
import { LANGUAGE_SELECTOR_I18N_ENTRIES } from './i18n/language-selector-i18n-entries';
import { I18nService } from './services/i18n.service';
import { I18nPipe } from './services/i18n.pipe';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { RouterModule } from '@angular/router';
import { FOOTER_I18N_ENTRIES } from './i18n/footer-i18n-entries';
import { MatDialogModule } from '@angular/material/dialog';
import { FaqsComponent } from './components/faqs/faqs.component';
import { FAQS_I18N_ENTRIES } from './i18n/faqs-i18n-entries';
import { ARTICLES_I18N_ENTRIES } from './i18n/articles-i18n-entries';
import { ErrorComponent } from './components/error/error.component';
import { ContactComponent } from './components/contact/contact.component';
import { ERROR_I18N_ENTRIES } from './i18n/error-i18n-entries';
import { CONTACT_I18N_ENTRIES } from './i18n/contact-i18n-entries';
import { ContactSentDialogComponent } from './components/contact/dialogs/contact-sent-dialog/contact-sent-dialog.component';
import { RedirectToAppComponent } from './components/redirect-to-app/redirect-to-app.component';
import { LANDING_RATES_I18N_ENTRIES } from '../home/i18n/landing-rates-i18n-entries';
import { SafeHtmlPipe } from './services/safe-html.pipe';
import { META_CONFIG_I18N_ENTRIES } from '../home/i18n/meta-config-i18n-entries';
import { NcConfigComponent } from './components/cookies-config/cookies-config.component';
import { COOKIES_CONFIG_I18N_ENTRIES } from '../home/i18n/cookies-config-i18n-entries';
import { OptionalNcDialogComponent } from './components/cookies-config/optional-cookies-dialog/optional-cookies-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LastBlogsComponent } from './components/last-blogs/last-blogs.component';
import { MaintanceComponent } from './components/maintance/maintance.component';
import { MAINTANCE_I18N_ENTRIES } from './i18n/maintance-i18n-entries';
import { LazyLoadDirective } from '../lazy-load.directive';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LanguageSelectorComponent,
    I18nPipe,
    FaqsComponent,
    ErrorComponent,
    ContactComponent,
    ContactSentDialogComponent,
    RedirectToAppComponent,
    SafeHtmlPipe,
    NcConfigComponent,
    OptionalNcDialogComponent,
    LastBlogsComponent,
    MaintanceComponent,
    LazyLoadDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatSelectModule,
    RouterModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    I18nPipe,
    FormsModule,
    RouterModule,
    MatSelectModule,
    MatDialogModule,
    FaqsComponent,
    ErrorComponent,
    ContactComponent,
    ReactiveFormsModule,
    SafeHtmlPipe,
    NcConfigComponent,
    OptionalNcDialogComponent,
    LastBlogsComponent,
    LazyLoadDirective
  ],
})
export class CommonsModule {
  constructor(private i18nService: I18nService) {
    i18nService.addI18nEntriesObject(HEADER_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(LANGUAGE_SELECTOR_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(FOOTER_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(FAQS_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(ARTICLES_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(ERROR_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(CONTACT_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(LANDING_RATES_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(META_CONFIG_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(COOKIES_CONFIG_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(MAINTANCE_I18N_ENTRIES);
  }
}
