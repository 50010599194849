// generate-route.service.ts
import { Injectable } from '@angular/core';
import { I18nService } from './i18n.service';
import { SpanishRoutes } from '../routes/spanish-routes';
import { PortugueseRoutes } from '../routes/portuguese-routes';
import { FrenchRoutes } from '../routes/french-routes';
import { EnglishRoutes } from '../routes/english-routes';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Language } from './language';
import { TranslationModeEnum } from '../enums/translation-mode-enum';

// Define el tipo basado en SpanishRoutes
export type RouteDefinitions = typeof SpanishRoutes;

@Injectable({
  providedIn: 'root'
})
export class GenerateRouteService {
  private languageRoutes: Record<string, RouteDefinitions> = {
    es: SpanishRoutes,
    en: EnglishRoutes,
    fr: FrenchRoutes,
    pt: PortugueseRoutes,
  };

  currentLanguageCode: Language;

  constructor(
    private i18nService: I18nService,
    private router: Router
  ) {
  }

  private getLanguageRoutes(): RouteDefinitions {
    const currentLanguage = this.i18nService.getCurrentLanguage().code.toLowerCase();
    return this.languageRoutes[currentLanguage] || {} as RouteDefinitions;
  }

  generateRoute(routeKey: keyof RouteDefinitions): string {
    const languageRoutes = this.getLanguageRoutes();
    return languageRoutes[routeKey]?.URL || '';
  }

  updateRoute(languageCode: string, param): void {
    let state: RouterStateSnapshot = this.router.routerState.snapshot;
    const toolParam = this.extractParamFromUrl('tool', state);
    let currentUrl = state.url;
    if (toolParam) {
      // Reemplazar el parámetro en la URL
      currentUrl = currentUrl.replace(`/${toolParam}`, '');
    }

    const foundRoutes: { language: string, key: string }[] = [];

    // Itera sobre todos los archivos de rutas
    Object.entries(this.languageRoutes).forEach(([lang, routes]) => {
      const foundRoute = Object.entries(routes).find(([key, value]) => value.URL === currentUrl || `/${value.URL}` === currentUrl);

      if (foundRoute) {
        foundRoutes.push({ language: lang, key: foundRoute[0] });
      }
    });

    if (foundRoutes.length > 0) {
      foundRoutes.forEach(({ language, key }) => {
        // Busca en el archivo de rutas correspondiente al idioma
        const languageRoutes = this.languageRoutes[language];
        const correspondingRoute = languageRoutes[key];
        if (correspondingRoute) {
          // Ahora, busca la clave en el nuevo idioma
          const newLanguageRoutes = this.languageRoutes[languageCode];
          const translatedRoute = newLanguageRoutes[key];
          if (translatedRoute) {
            // Realiza la redirección a la nueva ruta traducida
            this.router.navigateByUrl(!param ? `/${translatedRoute.URL}` : `/${translatedRoute.URL}/${param}`);
          } else {
          }
        } else {
        }
      });
    } else {
    }
  }

  public extractParamFromUrl(paramName: string, state): string | null {
    const currentUrl = state.url;

    // Encuentra la ruta que coincide con el patrón
    for (const routes of Object.values(this.languageRoutes)) {
      for (const route of Object.values(routes)) {
        const routePattern = `/${route.URL}`;
        const routePatternRegex = new RegExp('^' + routePattern.replace(/:[a-zA-Z0-9_]+/g, '([^/]+)') + '$');

        const match = currentUrl.match(routePatternRegex);
        if (match) {
          const paramNames = (route.URL.match(/:[a-zA-Z0-9_]+/g) || []).map(param => param.substring(1));
          const paramIndex = paramNames.indexOf(paramName);
          if (paramIndex > -1) {
            return match[paramIndex + 1] || null;
          }
        }
      }
    }

    return null;
  }
}
