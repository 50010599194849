import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestClientService } from './rest-client.service';
import { EndPointEnum } from '../enums/end-point';
import { Platform } from '../models/platforms';

@Injectable({
  providedIn: 'root',
})
export class PlatformsService {
  public static scope: PlatformsService;
  private URL = '/platforms';
  private URL_LANGUAGES = '/languages';
  constructor(
    private restClientService: RestClientService
  ) {
    PlatformsService.scope = this;
  }

  getAll(): Observable<Platform[]> {
    return this.restClientService.get<Platform[]>(`${this.URL}/all`, null, EndPointEnum.BLOG);
  }

  getAllLanguages(): Observable<Platform[]> {
    return this.restClientService.get<Platform[]>(`${this.URL_LANGUAGES}/all`, null, EndPointEnum.BLOG);
  }
}
