import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';
import { GenerateRouteService } from 'src/app/commons/services/generate-route.service';

@Component({
  selector: 'app-changed-password-dialog',
  templateUrl: './changed-password-dialog.component.html',
  styleUrls: ['./changed-password-dialog.component.scss']
})
export class ChangedPasswordDialogComponent implements OnInit {

  translationModes: any = TranslationModeEnum;
  isError: boolean;

  constructor(
    private dialog: MatDialogRef<ChangedPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private generateRouteService: GenerateRouteService
  ) {
  }

  ngOnInit(): void {
    this.isError = this.data;
  }

  close(): void {
    this.dialog.close();
  }

  getRouterLink(routeKey: any): string {
    return this.generateRouteService.generateRoute(routeKey);
  }

}
