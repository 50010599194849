<div class="register-container" [ngClass]="{'error': (submitted && formGroup.get('email').invalid && (formGroup.get('email').dirty || formGroup.get('email').touched))
    || (submitted && formGroup.controls['password'].errors)
    || (submitted && formGroup.controls['confirmPassword'].value !== formGroup.controls['password'].value)
    || submitted && formGroup.controls['rememberMe'].value === false}">
    <!-- <div class="first-column">
        <h2 [innerHTML]="'login_Data1' | i18n" *ngIf="intSicurIntMwc==0"></h2>
        <div [innerHTML]="'login_Data2' | i18n" *ngIf="intSicurIntMwc==0"></div>

        <h2 [innerHTML]="'login_Data15' | i18n" *ngIf="intSicurIntMwc==1"></h2>
        <div [innerHTML]="'login_Data17' | i18n" *ngIf="intSicurIntMwc==1"></div>

        <h2 [innerHTML]="'login_Data16' | i18n" *ngIf="intSicurIntMwc==2"></h2>
        <div [innerHTML]="'login_Data18' | i18n" *ngIf="intSicurIntMwc==2"></div>
        <div class="video" [ngClass]="{'imagen-mwc': intSicurIntMwc == 2,'imagen-sicur': intSicurIntMwc == 1}">
            <img src="/assets/images/login/login-video.svg" alt="eyesCloud3D vídeo" *ngIf="intSicurIntMwc==0">
            <img src="/assets/images/register/burgos 2 video Render.svg" alt="eyesCloud3D vídeo"
                *ngIf="intSicurIntMwc==1">
            <img src="/assets/images/register/Thomas Jefferson Memorial Render.svg" alt="eyesCloud3D vídeo"
                *ngIf="intSicurIntMwc==2">
        </div>
    </div> -->
    <img src="../../../../assets/images/login/icono-apk.svg" class="icono-apk">
    <div class="second-column">
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
            <div class="go-back" (click)="getRouterLink('Register')">
                <img src="../../../../assets/images/register/go-back.svg">
                <div class="texto-go-back" [innerHTML]="'twoFactorDialog_Data9' | i18n"></div>
            </div>
            <div class="form-title" [innerHTML]="'register_Data9' | i18n"></div>
            <div class="form-group user-info">
                <ng-container formGroupName="profile">
                    <input class="input" type="text" id="name" formControlName="name" name="name" required
                        placeholder="{{ 'register_Data2' | i18n: translationModes.PLACEHOLDER }}">
                    <p *ngIf="submitted && formGroup.get('profile').get('name').invalid"
                        class="error-message required-field" [innerHTML]="'register_Data19' | i18n">
                    </p>
                    <div *ngIf="submitted && formGroup.get('profile').get('name').valid"
                        class="required-field-white-space">
                    </div>
                    <input class="input" type="text" id="surname" formControlName="surname" name="surname" required
                        placeholder="{{ 'register_Data3' | i18n: translationModes.PLACEHOLDER }}">
                    <p *ngIf="submitted && formGroup.get('profile').get('surname').invalid"
                        class="error-message required-field"[innerHTML]="'register_Data19' | i18n">
                    </p>
                    <div *ngIf="submitted && formGroup.get('profile').get('surname').valid"
                        class="required-field-white-space">
                    </div>
                </ng-container>
                <input class="input" type="email" id="email" formControlName="email" name="email" required
                    placeholder="{{ 'register_Data4' | i18n: translationModes.PLACEHOLDER }}">
                <div *ngIf="submitted && formGroup.get('email').invalid"
                    class="error-message">
                    <p [innerHTML]="'register_Data13' | i18n"></p>
                </div>
            </div>
            <div class="form-group password">
                <input class="input" [type]="showPassword[0] ? 'text' : 'password'" id="password"
                    formControlName="password" name="password" required
                    placeholder="{{ 'register_Data5' | i18n: translationModes.PLACEHOLDER }}">
                <img class="eye-icon" (click)="hidePassword(0)" *ngIf="showPassword[0]"
                    src="/assets/images/login/show.svg">
                <img class="eye-icon" (click)="hidePassword(0)" *ngIf="!showPassword[0]"
                    src="/assets/images/login/hide.svg">
            </div>
            <div *ngIf="submitted && formGroup.controls['password'].errors"
                class="error-message error-message-password">
                <p [innerHTML]="'register_Data14' | i18n"></p>
            </div>
            <div class="form-group password confirm">
                <input class="input" [type]="showPassword[1] ? 'text' : 'password'" id="confirmPassword"
                    formControlName="confirmPassword" name="password" required
                    placeholder="{{ 'register_Data6' | i18n: translationModes.PLACEHOLDER }}">
                <img class="eye-icon" (click)="hidePassword(1)" *ngIf="showPassword[1]"
                    src="/assets/images/login/show.svg">
                <img class="eye-icon" (click)="hidePassword(1)" *ngIf="!showPassword[1]"
                    src="/assets/images/login/hide.svg">
            </div>
            <div *ngIf="submitted && formGroup.controls['confirmPassword'].value !== formGroup.controls['password'].value"
                class="error-message">
                <p [innerHTML]="'register_Data15' | i18n"></p>
            </div>
            <div class="form-group checkbox">
                <div>
                    <input type="checkbox" id="remember" formControlName="rememberMe" name="rememberMe" required>
                    <div [innerHTML]="'register_Data7' | i18n"></div>
                    <div class="terms-link" (click)="getRouterLink('TermsOfUse')" [innerHTML]="'register_Data8' | i18n">
                    </div>
                </div>
                <div *ngIf="submitted && formGroup.controls['rememberMe'].value === false" class="error-message">
                    <p [innerHTML]="'register_Data16' | i18n"></p>
                </div>
            </div>
            <button class="register-btn" type="submit" [innerHTML]="'register_Data9' | i18n"
                id="Form_interaction-click-register"></button>
        </form>
        <div class="register-with">
            <div class="register-google" (click)="loginWithGoogle()">
                <asl-google-signin-button id="real-google-button"></asl-google-signin-button>
                <div [innerHTML]="'register_Data10' | i18n" id="auto-login-google"> </div>
                <img src="/assets/images/login/google.svg" alt="Google">
            </div>
            <!-- <div class="register-google">
                <div [innerHTML]="'register_Data11' | i18n"></div>
                <img src="/assets/images/login/apple.svg" alt="Apple">
            </div> -->
        </div>
        <!-- <div class="login" [innerHTML]="'register_Data12' | i18n" (click)="getRouterLink('Login')"
            id="Form_interaction-click-register-login"></div> -->
    </div>
</div>