import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';
import { ChangedPasswordDialogComponent } from '../changed-password-dialog/changed-password-dialog.component';

export enum ChangePasswordResponse {
  DEFAULT,
  SUCCESS,
  ERROR,
}

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent implements OnInit {
  translationModes: any = TranslationModeEnum;
  errorToken: boolean = false;
  notActivated: boolean = false;
  submitted: boolean = false;
  showPassword = [false, false];
  resetPasswordForm: FormGroup;
  private passwordLength = 8;
  user: any = {}; // Declare the user variable

  get f() {
    return this.resetPasswordForm.controls;
  }

  constructor(
    private dialog: MatDialogRef<ChangePasswordDialogComponent>,
    private dialogService: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private token: string
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  hidePassword(index: number): void {
    this.showPassword[index] = !this.showPassword[index];
  }

  send(): void {
    this.submitted = true;
    if (this.resetPasswordForm.valid) {
      const parameters = {
        password: this.resetPasswordForm.value.password,
        token: this.token,
      };
      this.authenticationService.resetPassword(parameters).subscribe(
        () => {
          this.close(ChangePasswordResponse.SUCCESS);
        },
        (error) => {
          this.submitted = false;
          this.close(ChangePasswordResponse.ERROR);
        }
      );
    }
  }

  close(response: ChangePasswordResponse = ChangePasswordResponse.DEFAULT) {
    this.dialog.close(response);
  }

  private createForm(): void {
    this.resetPasswordForm = this.formBuilder.group(
      {
        password: [
          '',
          [Validators.required, Validators.minLength(this.passwordLength)],
        ],
        passwordConfirmation: [
          '',
          [Validators.required, Validators.minLength(this.passwordLength)],
        ],
      },
      {
        validator: this.mustMatch('password', 'passwordConfirmation'),
      }
    );
  }

  public isValid() {
    let valid = true;
    this.errorToken = false;
    if (this.resetPasswordForm) {
      valid = this.resetPasswordForm.valid;
    }
    return valid;
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
