import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EnterPhoneNumberResponses } from 'src/app/auth/models/two-factor/enums/enter-phone-number-responses';
import { TwoFactorRemoteService } from 'src/app/auth/services/two-factor-remote.service';
import { TranslationModeEnum } from 'src/app/commons/enums/translation-mode-enum';

@Component({
  selector: 'app-enter-phone-number-dialog',
  templateUrl: './enter-phone-number-dialog.component.html',
  styleUrls: ['./enter-phone-number-dialog.component.scss'],
})
export class EnterPhoneNumberDialogComponent implements OnDestroy {
  subscriptions: Subscription[];

  phoneNumber: string;

  isPhoneNumberIncorrect: boolean;

  translationModes: typeof TranslationModeEnum;

  responses: typeof EnterPhoneNumberResponses;

  constructor(
    private dialogRef: MatDialogRef<EnterPhoneNumberDialogComponent>,
    private twoFactorRemoteService: TwoFactorRemoteService
  ) {
    this.subscriptions = [];
    this.phoneNumber = '';
    this.isPhoneNumberIncorrect = false;
    this.translationModes = TranslationModeEnum;
    this.responses = EnterPhoneNumberResponses;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public closeDialog(
    response: EnterPhoneNumberResponses = EnterPhoneNumberResponses.DEFAULT
  ) {
    this.dialogRef.close({ status: response, phoneNumber: this.phoneNumber });
  }

  public continue() {
    this.subscriptions.push(
      this.twoFactorRemoteService.activateWithPhone(this.phoneNumber).subscribe(
        () => {
          this.closeDialog(EnterPhoneNumberResponses.NEXT);
        },
        (error) => {
          if (error.error === 3) {
            this.closeDialog(EnterPhoneNumberResponses.LIMIT);
          } else {
            this.isPhoneNumberIncorrect = true;
          }
        }
      )
    );
  }
}
