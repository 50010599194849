export const META_CONFIG_I18N_ENTRIES = {
  meta_config_Data1: {
    es: '<p>index, follow</p>',
    en: '<p>index, follow</p>',
    pt: '<p>index, follow</p>',
    fr: '<p>index, follow</p>',
  },

  meta_config_Data2: {
    es: '<p>noindex, follow</p>',
    en: '<p>noindex, follow</p>',
    pt: '<p>noindex, follow</p>',
    fr: '<p>noindex, follow</p>',
  },

  meta_config_Data3: {
    es: '<p>index, nofollow</p>',
    en: '<p>index, nofollow</p>',
    pt: '<p>index, nofollow</p>',
    fr: '<p>index, nofollow</p>',
  },

  meta_config_Data4: {
    es: '<p>noindex, nofollow</p>',
    en: '<p>noindex, nofollow</p>',
    pt: '<p>noindex, nofollow</p>',
    fr: '<p>noindex, nofollow</p>',
  },

  meta_config_Data5: {
    es: '<p>Generación automática de modelos 3D - eyesCloud3D</p>',
    en: '<p>Automatic 3D model generation - eyesCloud3D</p>',
    pt: '<p>Criação automática de modelos 3D - eyesCloud3D</p>',
    fr: '<p>Génération automatique de modèles 3D - eyesCloud3D</p>',
  },

  meta_config_Data6: {
    es: '<p>Crea modelos 3D a partir de fotos o videos con android, iOS o en web. Conoce la fotogrametría de la mano de eyesCloud3D</p>',
    en: '<p>Create 3D models with android, iphone or online. Discover the power of accurate photogrammetry with eyesCloud3D</p>',
    pt: '<p>Crie modelos 3D a partir de fotografias ou vídeos com Android, iOS ou na Web. Descubra a fotogrametria da mão da eyesCloud3D</p>',
    fr: '<p>Créez des modèles 3D à partir de photos ou de vidéos sur Android, iOS ou le web. Découvrez la photogrammétrie avec eyesCloud3D</p>',
  },

  meta_config_Data7: {
    es: '<p>Tarifas y precios - eyesCloud3D</p>',
    en: '<p>Plans and pricing - eyesCloud3D</p>',
    pt: '<p>Tarifas e preços - eyesCloud3D</p>',
    fr: '<p>Tarifs et prix - eyesCloud3D</p>',
  },

  meta_config_Data8: {
    es: '<p>Consulta los precios y las tarifas de eyesCloud3D, el software de fotogrametría con android o iOS. Empieza a escanear con tu móvil ahora</p>',
    en: '<p>Check pricing and plans for eyesCloud3D, the photogrammetry software program for android or iphone. Start scanning with your smartphone now</p>',
    pt: '<p>Consulte os preços e as tarifas da eyesCloud3D, o software de fotogrametria para Android ou iOS. Comece já a digitalizar com o seu telemóvel</p>',
    fr: '<p>Consultez les prix et les tarifs d’eyesCloud3D, le logiciel de photogrammétrie pour Android ou iOS. Commencez à numériser avec votre mobile dès maintenant</p>',
  },

  meta_config_Data9: {
    es: '<p>Preguntas frecuentes - eyesCloud3D</p>',
    en: '<p>Frequently asked questions - eyesCloud3D</p>',
    pt: '<p>Perguntas mais frequentes - eyesCloud3D</p>',
    fr: '<p>Foire aux questions - eyesCloud3D</p>',
  },

  meta_config_Data10: {
    es: '<p>Consulta las dudas más frecuentes que tienen nuestros usuarios -¿Qué dispositivos puedo usar?  - ¿Cuánto se tarda en generar un modelo 3D?</p>',
    en: '<p>Check the most frequent questions our users have -What devices can I use? -How long does it take to generate a 3D model?</p>',
    pt: '<p>Consulte as perguntas mais frequentes dos nossos utilizadores - Que dispositivos posso utilizar? - Quanto tempo é necessário para criar um modelo 3D?</p>',
    fr: '<p>Consultez les questions les plus fréquentes de nos utilisateurs -Quels appareils puis-je utiliser ?  - Combien de temps faut-il pour générer un modèle 3D ?</p>',
  },

  meta_config_Data11: {
    es: '<p>Contacta con nosotros - eyesCloud3D</p>',
    en: '<p>Contact us - eyesCloud3D</p>',
    pt: '<p>Contacte-nos - eyesCloud3D</p>',
    fr: '<p>Contactez-nous - eyesCloud3D</p>',
  },

  meta_config_Data12: {
    es: '<p>Si tienes cualquier duda, sugerencia o problema contacta con nuestro equipo. </p>',
    en: '<p>If you have any questions, suggestions or problems, please contact our team.</p>',
    pt: '<p>Se tiver dúvidas, sugestões ou problemas, contacte a nossa equipa.</p>',
    fr: '<p>Si vous avez des questions, des doutes ou des suggestions, veuillez contacter notre équipe.</p>',
  },
  meta_config_Data13: {
    es: '<p>Artículos, noticias y blogs - eyesCloud3D</p>',
    en: '<p>Articles, news and blogs - eyesCloud3D</p>',
    pt: '<p>Artigos, notícias e blogues - eyesCloud3D</p>',
    fr: '<p>Articles, actualités et blogs - eyesCloud3D</p>',
  },

  meta_config_Data14: {
    es: '<p>Mantente informado de todo lo que pasa en eyesCloud3D con nuestro blog. Aprende a escanear en 3D con cualquier dispositivo con nuestros consejos</p>',
    en: '<p>Keep up to date with everything that happens in eyesCloud3D on our blog. With our tips, learn how to scan in 3D using any device</p>',
    pt: '<p>Mantenha-se atualizado sobre o que se passa na eyesCloud3D com o nosso blogue. Aprenda a digitalizar em 3D com qualquer dispositivo com as nossas dicas</p>',
    fr: '<p>Restez au courant de ce qui se passe chez eyesCloud3D grâce à notre blog. Apprenez à numériser en 3D avec n’importe quel appareil grâce à nos conseils.</p>',
  },

  meta_config_Data15: {
    es: '<p>Registro - eyesCloud3D</p>',
    en: '<p>Register - eyesCloud3D</p>',
    pt: '<p>Registo - eyesCloud3D</p>',
    fr: '<p>Inscription - eyesCloud3D</p>',
  },

  meta_config_Data16: {
    es: '<p>Registrate facilmente en menos de un minuto en uno de los mejores software de fotogrametría.</p>',
    en: '<p>Register easily for one of the best photogrammetry software programs in less than a minute.</p>',
    pt: '<p>Registe-se facilmente em menos de um minuto num dos melhores softwares de fotogrametria.</p>',
    fr: '<p>Inscrivez-vous facilement en moins d\'une minute sur l\'un des meilleurs logiciels de photogrammétrie.</p>',
  },

  meta_config_Data17: {
    es: '<p>Iniciar sesión - eyesCloud3D</p>',
    en: '<p>Log in - eyesCloud3D</p>',
    pt: '<p>Iniciar sessão - eyesCloud3D</p>',
    fr: '<p>Se connecter- eyesCloud3D</p>',
  },

  meta_config_Data18: {
    es: '<p>Inicia sesión en el software de fotogrametría que estás buscando. Introduce tu mail y contraseña para crear tu modelo 3D.</p>',
    en: '<p>Log in to the photogrammetry software you are looking for. Enter your email and password to create your 3D model.</p>',
    pt: '<p>Inicie sessão no software de fotogrametria que procura. Introduza o seu e-mail e a sua palavra-passe para criar o seu modelo 3D.</p>',
    fr: '<p>Connectez-vous au logiciel de photogrammétrie que vous recherchez. Entrez votre e-mail et votre mot de passe pour créer votre modèle 3D.</p>',
  },

  meta_config_Data19: {
    es: '<p>Términos de uso - eyesCloud3D</p>',
    en: '<p>Terms of use - eyesCloud3D</p>',
    pt: '<p>Termos de utilização - eyesCloud3D</p>',
    fr: '<p>Conditions générales d’utilisation - eyesCloud3D</p>',
  },

  meta_config_Data20: {
    es: '<p>Política de cookies - eyesCloud3D</p>',
    en: '<p>Cookie policy - eyesCloud3D</p>',
    pt: '<p>Política de cookies - eyesCloud3D</p>',
    fr: '<p>Politique de cookies - eyesCloud3D</p>',
  },

  meta_config_Data21: {
    es: '<p>Política de privacidad - eyesCloud3D</p>',
    en: '<p>Privacy policy - eyesCloud3D</p>',
    pt: '<p>Política de privacidade - eyesCloud3D</p>',
    fr: '<p>Politique de confidentialité - eyesCloud3D</p>',
  },

  meta_config_Data22: {
    es: '<p>Política de contratación - eyesCloud3D</p>',
    en: '<p>Purchase policy - eyesCloud3D</p>',
    pt: '<p>Política de contratação - eyesCloud3D</p>',
    fr: '<p>Conditions générales de vente - eyesCloud3D</p>',
  },

  meta_config_Data23: {
    es: '<p>Centro de ayuda - eyesCloud3D</p>',
    en: '<p>Help Center - eyesCloud3D</p>',
    pt: '<p>Centro de Ajuda - eyesCloud3D</p>',
    fr: '<p>Centre d’aide - eyesCloud3D</p>',
  },

  meta_config_Data24: {
    es: '<p>Tienes dudas acerca del uso de eyesCloud3D ¿en qué podemos ayudarte? Tutoriales, dudas frecuentes, manuales...</p>',
    en: '<p>Do you have questions about how to use eyesCloud3D? How can we help you? Tutorials, frequently asked questions, and more.</p>',
    pt: '<p>Tem dúvidas acerca da utilização de eyesCloud3D? Em que podemos ajudar? Tutoriais, dúvidas frequentes, manuais...</p>',
    fr: '<p>Vous avez des questions sur l’utilisation d’eyesCloud3D ? Comment pouvons-nous vous aider ? Tutoriels, foire aux questions, manuels…</p>',
  },

  meta_config_Data25: {
    es: '<p>Biblioteca de herramientas - eyesCloud3D</p>',
    en: '<p>Tool Library - eyesCloud3D</p>',
    pt: '<p>Biblioteca de Ferramentas - eyesCloud3D</p>',
    fr: '<p>Bibliothèque d’outils - eyesCloud3D</p>',
  },

  meta_config_Data26: {
    es: '<p>Descubre las Herramientas Básicas, Avanzadas y los Addons. Descubre las posibilidades de las herramientas en los modelos 3D</p>',
    en: '<p>See the Basic and Advanced Tools, plus Add-ons. Discover all the many things these tools can do in the 3D models.</p>',
    pt: '<p>Descubra as Ferramentas Básicas, Avançadas e os Addons. Descubra as possibilidades das ferramentas nos modelos 3D</p>',
    fr: '<p>Découvrez les outils de base, les outils avancés et les extensions. Découvrez les possibilités des outils dans les modèles 3D.</p>',
  },

  meta_config_Data27: {
    es: '<p>Herramientas estándar - eyesCloud3D</p>',
    en: '<p>Standard Tool Library - eyesCloud3D</p>',
    pt: '<p>Biblioteca de Ferramentas Padrão - eyesCloud3D</p>',
    fr: '<p>Bibliothèque d’outils standard - eyesCloud3D</p>',
  },

  meta_config_Data28: {
    es: '<p>Aprende a medir, recortar tus modelos 3D y mucho más con estos tutoriales. Conoce al completo las Herramientas Estándar [Leer más]</p>',
    en: '<p>Learn to measure and crop your 3D models and much more with these tutorials. Learn all about the Standard Tools [Read more].</p>',
    pt: '<p>Aprenda a medir, recortar os seus modelos 3D e muito mais com estes tutoriais. Conheça por completo as Ferramentas Padrão [Ler mais]</p>',
    fr: '<p>Découvrez l’intégralité des outils standard dans ces tutoriels et apprenez à mesurer, découper vos modèles 3D et bien plus encore. [Lire la suite]</p>',
  },

  meta_config_Data29: {
    es: '<p>Herramienta eje z - eyesCloud3D</p>',
    en: '<p>Z Axis Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Eixo Z - eyesCloud3D</p>',
    fr: '<p>Outil Axe Z - eyesCloud3D</p>',
  },

  meta_config_Data30: {
    es: '<p>Conoce cómo funciona la heramienta eje Z. Establece el eje Z en tu modelo.</p>',
    en: '<p>Learn how the Z axis tool works. Set the Z axis in your model.</p>',
    pt: '<p>Saiba como funciona a ferramenta eixo Z. Estabeleça o eixo Z no seu modelo.</p>',
    fr: '<p>Découvrez le fonctionnement de l’outil Axe Z. Définissez l’axe Z dans votre modèle.</p>',
  },

  meta_config_Data31: {
    es: '<p>Herramienta notas - eyesCloud3D</p>',
    en: '<p>Notes Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Notas - eyesCloud3D</p>',
    fr: '<p>Outil Notes - eyesCloud3D</p>',
  },

  meta_config_Data32: {
    es: '<p>Aprende cómo añadir notas a tu modelo 3D. Inserta comentarios para hacer tu modelo más accesible.</p>',
    en: '<p>Learn how to add Notes to your 3D model. Include comments to make your model more accessible.</p>',
    pt: '<p>Aprenda a adicionar Notas ao seu modelo 3D. Insira comentários para tornar o seu modelo mais acessível.</p>',
    fr: '<p>Apprenez à ajouter des notes à votre modèle 3D. Insérez des commentaires pour rendre votre modèle plus accessible.</p>',
  },

  meta_config_Data33: {
    es: '<p>Herramienta recorte - eyesCloud3D</p>',
    en: '<p>Crop Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Recorte - eyesCloud3D</p>',
    fr: '<p>Outil Découpe - eyesCloud3D</p>',
  },

  meta_config_Data34: {
    es: '<p>Aprende a recortar tu modelo 3D para eliminar elementos generados no deseados en el modelo 3D final</p>',
    en: '<p>Learn how to crop your 3D model to remove the parts you wish to exclude from the final 3D model.</p>',
    pt: '<p>Aprenda a recortar o seu modelo 3D para eliminar elementos criados não desejados no modelo 3D final</p>',
    fr: '<p>Apprenez à découper votre modèle 3D pour supprimer les éléments générés indésirables dans le modèle 3D final.</p>',
  },

  meta_config_Data35: {
    es: '<p>Herramienta Tour Virtual Reducido - eyesCloud3D</p>',
    en: '<p>Reduced Virtual Tour Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Tour Virtual Reduzido - eyesCloud3D</p>',
    fr: '<p>Outil Visite virtuelle réduite - eyesCloud3D</p>',
  },

  meta_config_Data36: {
    es: '<p>Apende a utilizar el Tour virual para ver tu modelo de forma continua. Selecciona las perspectivas y crea el tour virtual.</p>',
    en: '<p>Learn how to use the Virtual Tour for a continuous view of your model. Select the perspectives and create the Virtual Tour.</p>',
    pt: '<p>Aprenda a utilizar o Tour Virtual para ver o seu modelo de forma contínua. Selecione as perspetivas e crie o Tour Virtual.</p>',
    fr: '<p>Apprenez à utiliser la visite virtuelle pour visualiser votre modèle en continu. Sélectionnez les perspectives et créez la visite virtuelle.</p>',
  },

  meta_config_Data37: {
    es: '<p>Herramienta establecer posición inicial - eyesCloud3D</p>',
    en: '<p>Establish Initial Position Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Estabelecer Posição Inicial - eyesCloud3D</p>',
    fr: '<p>Outil Définir position initiale - eyesCloud3D</p>',
  },

  meta_config_Data38: {
    es: '<p>Establece la posición inicial en la que se abrirá el modelo generado con esta herramienta de visualización.</p>',
    en: '<p>Set the initial position to be shown when the 3D model made with this visualization tool is opened.</p>',
    pt: '<p>Estabeleça a Posição Inicial na qual será aberto o modelo criado com esta ferramenta de visualização.</p>',
    fr: '<p>Définissez la position initiale depuis laquelle le modèle généré à l’aide de cet outil de visualisation sera ouvert.</p>',
  },

  meta_config_Data39: {
    es: '<p>Funcionalidades - eyesCloud3D</p>',
    en: '<p>Feature Collection - eyesCloud3D</p>',
    pt: '<p>Coleção de Funcionalidades - eyesCloud3D</p>',
    fr: '<p>Collection de fonctionnalités - eyesCloud3D</p>',
  },

  meta_config_Data40: {
    es: '<p>Conoce las funcionalidades de eyesCloud3D y cómo utilizarlas </p>',
    en: '<p>Learn about eyesCloud3D’s Download, Customization, and Interaction features and how to use them.</p>',
    pt: '<p>Conheça as funcionalidades de Transferência, Personalização e Interação de eyesCloud3D e como utilizá-las</p>',
    fr: '<p>Découvrez les fonctions de téléchargement, de personnalisation et d’interaction d’eyesCloud3D et comment les utiliser.</p>',
  },

  meta_config_Data41: {
    es: '<p>Funcionalidades de Interacción - eyesCloud3D</p>',
    en: '<p>Interaction Features - eyesCloud3D</p>',
    pt: '<p>Funcionalidades de Interação - eyesCloud3D</p>',
    fr: '<p>Fonctionnalités d’interaction - eyesCloud3D</p>',
  },

  meta_config_Data42: {
    es: '<p>Interactúa con el modelo 3D usando esta herramienta. Desplazate alrededor del modelo con estas herramientas.</p>',
    en: '<p>Interact with the 3D model by using this tool. Use orbital movement, and in axes, show or hide the ground and center the model.</p>',
    pt: '<p>Interaja com o modelo 3D utilizando esta ferramenta. Utilize o movimento orbital e, nos eixos, mostre ou oculte o chão e centre o modelo.</p>',
    fr: '<p>Interagissez avec le modèle 3D à l’aide de cet outil. Utilisez le mouvement orbital et axial, affichez ou masquez le sol et centrez le modèle.</p>',
  },

  meta_config_Data43: {
    es: '<p>Funcionales de Personalización - eyesCloud3D</p>',
    en: '<p>Customization Features - eyesCloud3D</p>',
    pt: '<p>Funcionalidades de Personalização - eyesCloud3D</p>',
    fr: '<p>Fonctionnalités de personnalisation - eyesCloud3D</p>',
  },

  meta_config_Data44: {
    es: '<p>Aprende a cambiar el idioma, cambiar el fondo de pantalla o elige ver el modelo 3D en pantalla en pantalla completa</p>',
    en: '<p>Learn how to change the language, change the background, or view the 3D model in full screen.</p>',
    pt: '<p>Aprenda a alterar o idioma, alterar o fundo do ecrã ou escolha ver o modelo 3D no ecrã ou em ecrã completo</p>',
    fr: '<p>Apprenez à changer de langue, modifier le fond d’écran ou afficher le modèle 3D en mode plein écran.</p>',
  },

  meta_config_Data45: {
    es: '<p>Biblioteca de herramientas avanzadas - eyesCloud3D</p>',
    en: '<p>Advanced Tool Library - eyesCloud3D</p>',
    pt: '<p>Biblioteca de Ferramentas Avançadas - eyesCloud3D</p>',
    fr: '<p>Bibliothèque d’outils avancés - eyesCloud3D</p>',
  },

  meta_config_Data46: {
    es: '<p>Aprovecha todo el potencial de las Herramientas Avanzadas de eyesCloud3D. Aprende a escalar, tomar altura, área, ángulos, entre otras. </p>',
    en: '<p>Unlock the full potential of eyesCloud3D’s Advanced Tools. Learn how to scale, check the height, area, angles, and more.</p>',
    pt: '<p>Aproveite todo o potencial das Ferramentas Avançadas de eyesCloud3D. Aprenda a escalar, tirar a altura, área, ângulos, entre outras.</p>',
    fr: '<p>Profitez de tout le potentiel des outils avancés d’eyesCloud3D. Apprenez à mettre à l’échelle, mesurer la hauteur, la surface, les angles, etc.</p>',
  },

  meta_config_Data47: {
    es: '<p>Herramientas de medición avanzada - eyesCloud3D</p>',
    en: '<p>Advanced Measurement Tool Collection - eyesCloud3D</p>',
    pt: '<p>Coleção de Ferramentas de Medição Avançada - eyesCloud3D</p>',
    fr: '<p>Collection d’outils de mesure avancés - eyesCloud3D</p>',
  },

  meta_config_Data48: {
    es: '<p>Conoce las herramientas de medición avanzada. Aprende cómo se calcula el área, el volumen, superficies, contorno o ángulo</p>',
    en: '<p>Discover the Measurement tools. Learn how to calculate Area, Volume, Surfaces, Outlines, and Angles.</p>',
    pt: '<p>Conheça as ferramentas de Medição. Aprenda a calcular a Área, o Volume, Superfícies, Contorno ou Ângulo</p>',
    fr: '<p>Découvrez les outils de mesure. Apprenez à calculer l’aire, le volume, les surfaces, les contours ou les angles.</p>',
  },

  meta_config_Data49: {
    es: '<p>Herramienta de cálculo de altura - eyesCloud3D</p>',
    en: '<p>Height Calculation Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Cálculo de Altura - eyesCloud3D</p>',
    fr: '<p>Outil Calcul de hauteur - eyesCloud3D</p>',
  },

  meta_config_Data50: {
    es: '<p>Conoce cómo se mide la altura o la profundidad de un modelo 3D con la herramienta de cálculo de altura.</p>',
    en: '<p>See how to measure a 3D model’s Height or Depth with the Height Calculation tool.</p>',
    pt: '<p>Saiba como medir a Altura ou a Profundidade de um modelo 3D com a ferramenta de Cálculo de Altura.</p>',
    fr: '<p>Apprenez à mesurer la hauteur ou la profondeur d’un modèle 3D à l’aide de l’outil Calcul de hauteur.</p>',
  },

  meta_config_Data51: {
    es: '<p>Herramienta de cálculo de área - eyesCloud3D</p>',
    en: '<p>Area Calculation Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Cálculo de Área - eyesCloud3D</p>',
    fr: '<p>Outil Calcul d’aire - eyesCloud3D</p>',
  },

  meta_config_Data52: {
    es: '<p>Descubre cómo se mide el área con la herramienta de cálculo de área que te proporciona eyesCloud3D</p>',
    en: '<p>See how to measure Area with the Area Calculation Tool provided by eyesCloud3D.</p>',
    pt: '<p>Descubra como medir a Área com a Ferramenta de Cálculo de Área oferecida pela eyesCloud3D</p>',
    fr: '<p>Découvrez comment mesurer l’aire avec l’outil Calcul d’aire fourni par eyesCloud3D.</p>',
  },

  meta_config_Data53: {
    es: '<p>Herramienta de cálculo de volumen - eyesCloud3D</p>',
    en: '<p>Volume Calculation Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Cálculo de Volume - eyesCloud3D</p>',
    fr: '<p>Outil Calcul de volume - eyesCloud3D</p>',
  },

  meta_config_Data54: {
    es: '<p>Aprende a cálcular el volumen de tu modelo 3D con la herramienta de cálculo de volumen</p>',
    en: '<p>Learn how to calculate your 3D model’s Volume with the Volume Calculation Tool.</p>',
    pt: '<p>Aprenda a calcular o Volume do seu modelo 3D com a Ferramenta de Cálculo de Volume</p>',
    fr: '<p>Apprenez à calculer le volume de votre modèle 3D à l’aide de l’outil Calcul de volume.</p>',
  },

  meta_config_Data55: {
    es: '<p>Herramienta de cálculo de superficies - eyesCloud3D</p>',
    en: '<p>Surface Calculation Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Cálculo de Superfícies - eyesCloud3D</p>',
    fr: '<p>Outil Calcul de surfaces - eyesCloud3D</p>',
  },

  meta_config_Data56: {
    es: '<p>Conoce cómo calcular las superficies en un modelo 3D generado por eyesCloud3D</p>',
    en: '<p>See how to calculate Surface Measurements in a 3D model made by eyesCloud3D.</p>',
    pt: '<p>Saiba como calcular as Medidas de Superfícies num modelo 3D criado pela eyesCloud3D</p>',
    fr: '<p>Apprenez à calculer les mesures de surface dans un modèle 3D généré par eyesCloud3D.</p>',
  },

  meta_config_Data57: {
    es: '<p>Herramienta de medidas múltiples - eyesCloud3D</p>',
    en: '<p>Multiple Measurements Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Várias Medidas - eyesCloud3D</p>',
    fr: '<p>Outil Mesures multiples - eyesCloud3D</p>',
  },

  meta_config_Data58: {
    es: '<p>Calcula simultaneamente las distancias, la altura, áreas, volúmenes o contornos de estas superficies con la herramienta medición múltiple.</p>',
    en: '<p>Simultaneously calculate Distances, Height, Areas, Volumes, and Outlines of these surfaces with the Multiple Measurement Tool.</p>',
    pt: '<p>Calcule simultaneamente as Distâncias, a Altura, Áreas, Volumes ou Contornos destas superfícies com a Ferramenta Medição Múltipla.</p>',
    fr: '<p>Calculez simultanément les distances, la hauteur, les aires, les volumes ou les contours de ces surfaces grâce à l’outil Mesures multiples.</p>',
  },

  meta_config_Data59: {
    es: '<p>Herramienta de cálculo de contorno - eyesCloud3D</p>',
    en: '<p>Outline Calculation Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Cálculo de Contorno - eyesCloud3D</p>',
    fr: '<p>Outil Calcul du contour - eyesCloud3D</p>',
  },

  meta_config_Data60: {
    es: '<p>Calcula el contorno con nuestras herramientas avanzadas. Conoce su funcionamiento en profundidad.</p>',
    en: '<p>Calculate the outline of your 3D model. Learn all about its features in detail.</p>',
    pt: '<p>Calcule o contorno de um modelo 3D. Conheça o seu funcionamento em profundidade.</p>',
    fr: '<p>Calculez le contour d’un modèle 3D. Découvrez le fonctionnement détaillé de l’outil.</p>',
  },

  meta_config_Data61: {
    es: '<p>Herramienta de cálculo de ángulo - eyesCloud3D</p>',
    en: '<p>Angle Calculation Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Cálculo de Ângulo - eyesCloud3D</p>',
    fr: '<p>Outil Calcul d’angle - eyesCloud3D</p>',
  },

  meta_config_Data62: {
    es: '<p>Calcula el ángulo entre dos líneas dentro de un modelo 3D</p>',
    en: '<p>Calculate the angle between two lines in a 3D model on the eyesCloud3D platform.</p>',
    pt: '<p>Calcule o Ângulo entre duas linhas que existem dentro de um modelo 3D</p>',
    fr: '<p>Apprenez à calculer l’angle entre deux lignes dans un modèle 3D.</p>',
  },

  meta_config_Data63: {
    es: '<p>Herramientas de escala - eyesCloud3D</p>',
    en: '<p>Scale Tool Collection - eyesCloud3D</p>',
    pt: '<p>Coleção de Ferramentas de Escala - eyesCloud3D</p>',
    fr: '<p>Collection d’outils d’échelle - eyesCloud3D</p>',
  },

  meta_config_Data64: {
    es: '<p>Conoce la colección de herramientas de escala. Aprende a consultar, modificar y ajustar el sistema de referencia del modelo.</p>',
    en: '<p>Discover the Scale Collection. Learn how to check, modify, and adjust the model’s Reference System.</p>',
    pt: '<p>Conheça a Coleção Escala. Aprenda a consultar, modificar e ajustar o Sistema de Referência do modelo.</p>',
    fr: '<p>Découvrez la collection Échelle. Apprenez à consulter, modifier et ajuster le système de référence du modèle.</p>',
  },

  meta_config_Data65: {
    es: '<p>Herramienta de cálculo de coordenada - eyesCloud3D</p>',
    en: '<p>Coordinate Calculation Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Cálculo de Coordenada - eyesCloud3D</p>',
    fr: '<p>Outil Calcul de coordonnée - eyesCloud3D</p>',
  },

  meta_config_Data66: {
    es: '<p>Selecciona un punto del modelo 3D para conocer el valor del mismo en un sistema de referencia relativo (XYZ), WGS 84 o UTM</p>',
    en: '<p>Select a point on the 3D model to see its value in a Relative Reference System (XYZ), WGS 84, or UTM.</p>',
    pt: '<p>Selecione um ponto do modelo 3D para conhecer o valor do mesmo num Sistema de Referência Relativo (XYZ), WGS 84 ou UTM</p>',
    fr: '<p>Sélectionnez un point dans le modèle 3D pour connaître sa valeur dans un système de référence relatif (XYZ), WGS 84 ou UTM.</p>',
  },

  meta_config_Data67: {
    es: '<p>Herramienta de sistema de referencia - eyesCloud3D</p>',
    en: '<p>Reference System Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Sistema de Referência - eyesCloud3D</p>',
    fr: '<p>Outil Système de référence - eyesCloud3D</p>',
  },

  meta_config_Data68: {
    es: '<p>Aprende a modificar el sistema de referencia del modelo para establecer nuevas dimensiones del modelo.</p>',
    en: '<p>Learn how to change the 3D model’s Reference System in eyesCloud3D.</p>',
    pt: '<p>Saiba como alterar o Sistema de Referência do modelo 3D na eyesCloud3D.</p>',
    fr: '<p>Apprenez à changer le système de référence du modèle 3D dans eyesCloud3D.</p>',
  },

  meta_config_Data69: {
    es: '<p>Herramienta de sistema de georreferenciación - eyesCloud3D</p>',
    en: '<p>Georeferencing System Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Sistema de Georreferenciação - eyesCloud3D</p>',
    fr: '<p>Outil Système de géoréférencement - eyesCloud3D</p>',
  },

  meta_config_Data70: {
    es: '<p>Aprende a usar el sistema de georreferenciación de eyesCloud3D.</p>',
    en: '<p>Learn how to use the Georeferencing System Tool in eyesCloud3D.</p>',
    pt: '<p>Aprenda a utilizar a Ferramenta de Sistema de Georreferenciação da eyesCloud3D.</p>',
    fr: '<p>Apprenez à utiliser l’outil Système de géoréférencement d’eyesCloud3D.</p>',
  },

  meta_config_Data71: {
    es: '<p>Herramienta de establecer suelo - eyesCloud3D</p>',
    en: '<p>Establish Ground Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Estabelecer Chão - eyesCloud3D</p>',
    fr: '<p>Outil Définir le sol - eyesCloud3D</p>',
  },

  meta_config_Data72: {
    es: '<p>Conoce cómo establecer el suelo de tu modelo 3D generado con eyesCloud3D </p>',
    en: '<p>Learn how to Establish the Ground in your 3D model made with eyesCloud3D.</p>',
    pt: '<p>Saiba como Estabelecer o Chão do seu modelo 3D criado com eyesCloud3D</p>',
    fr: '<p>Apprenez à définir le sol de votre modèle 3D généré avec eyesCloud3D.</p>',
  },

  meta_config_Data73: {
    es: '<p>Herramienta de Eje Z - eyesCloud3D</p>',
    en: '<p>Z Axis Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Eixo Z - eyesCloud3D</p>',
    fr: '<p>Outil Axe Z - eyesCloud3D</p>',
  },

  meta_config_Data74: {
    es: '<p>Conoce cómo funciona la heramienta eje Z. Establece el eje Z en tu modelo.</p>',
    en: '<p>Learn how the Z Axis Tool works. Set the Z axis in your model.</p>',
    pt: '<p>Saiba como funciona a Ferramenta Eixo Z. Estabeleça o Eixo Z no seu modelo.</p>',
    fr: '<p>Découvrez le fonctionnement de l’outil Axe Z. Définissez l’axe Z dans votre modèle.</p>',
  },

  meta_config_Data75: {
    es: '<p>Herramientas modificar - eyesCloud3D</p>',
    en: '<p>Modification Tools - eyesCloud3D</p>',
    pt: '<p>Ferramentas Modificar - eyesCloud3D</p>',
    fr: '<p>Outils Modifier - eyesCloud3D</p>',
  },

  meta_config_Data76: {
    es: '<p>Conoce la colección de herramientas "modificar". Aprende a unir modelos, recortar, seleccionar objetos o la herramienta sección.</p>',
    en: '<p>Discover the Modify tool collection. Learn how to Merge, Crop, Select, or Make Sections of 3D Models.</p>',
    pt: '<p>Conheça a coleção de ferramentas Modificar. Aprenda a Unir, Recortar, Selecionar ou Seccionar modelos 3D</p>',
    fr: '<p>Découvrez la collection d’outils Modifier. Apprenez à unir, découper, sélectionner ou sectionner des modèles 3D.</p>',
  },

  meta_config_Data77: {
    es: '<p>Herramienta de recorte - eyesCloud3D</p>',
    en: '<p>Crop Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Recorte - eyesCloud3D</p>',
    fr: '<p>Outil Découpe - eyesCloud3D</p>',
  },

  meta_config_Data78: {
    es: '<p>Aprende a recortar modelos 3D. Quédate solo con lo que te interesa del modelo.</p>',
    en: '<p>Learn how to crop 3D models. Keep only the parts of the model that are relevant to you.</p>',
    pt: '<p>Aprenda a Recortar Modelos 3D. Fique apenas com o que lhe interessa do modelo.</p>',
    fr: '<p>Apprenez à découper des modèles 3D. Ne gardez que ce qui vous intéresse du modèle.</p>',
  },

  meta_config_Data79: {
    es: '<p>Herramienta de unión de modelos - eyesCloud3D</p>',
    en: '<p>Model Merge Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de União de Modelos - eyesCloud3D</p>',
    fr: '<p>Outil Union de modèles - eyesCloud3D</p>',
  },

  meta_config_Data80: {
    es: '<p>Aprende a unir modelos 3D. Conoce todas las posibilidades que tiene la herramienta.</p>',
    en: '<p>Learn how to Merge 3D models. Discover all the many things this tool can do.</p>',
    pt: '<p>Aprenda a Unir modelos 3D. Conheça todas as possibilidades que a ferramenta oferece.</p>',
    fr: '<p>Apprenez à unir des modèles 3D. Découvrez toutes les possibilités de l’outil.</p>',
  },

  meta_config_Data81: {
    es: '<p>Herramienta de selección de Objetos - eyesCloud3D</p>',
    en: '<p>Object Selection Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Seleção de Objetos - eyesCloud3D</p>',
    fr: '<p>Outil Sélection d’objets - eyesCloud3D</p>',
  },

  meta_config_Data82: {
    es: '<p>Aprende a seleccionar uno o varios objetos dentro de tu generación 3D.</p>',
    en: '<p>Learn how to Select one or several objects within your 3D generation.</p>',
    pt: '<p>Aprenda a Selecionar um ou vários objetos dentro da sua criação 3D.</p>',
    fr: '<p>Apprenez à sélectionner un ou plusieurs objets dans votre génération 3D.</p>',
  },

  meta_config_Data83: {
    es: '<p>Herramienta de sección - eyesCloud3D</p>',
    en: '<p>Section Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Secção - eyesCloud3D</p>',
    fr: '<p>Outil Section - eyesCloud3D</p>',
  },

  meta_config_Data84: {
    es: '<p>Aprende a utilizar la herramienta sección en cualquier modelo 3D.</p>',
    en: '<p>Learn how to use the Section Tool in any 3D model on the eyesCloud3D platform.</p>',
    pt: '<p>Aprenda a utilizar a Ferramenta Secção em qualquer modelo 3D.</p>',
    fr: '<p>Apprenez à utiliser l’outil Section dans n’importe quel modèle 3D.</p>',
  },

  meta_config_Data85: {
    es: '<p>Herramientas vista - eyesCloud3D</p>',
    en: '<p>View Tools Collection - eyesCloud3D</p>',
    pt: '<p>Coleção de Ferramentas Vista - eyesCloud3D</p>',
    fr: '<p>Collection d’outils d’affichage - eyesCloud3D</p>',
  },

  meta_config_Data86: {
    es: '<p>Exprime la visualización y la exploración de los modelos 3D. Conoce Proyección, Tour Virual o visualiza la densidad de la nube de puntos,</p>',
    en: '<p>Leverage the visualization and exploration options for the 3D models. Learn about Projection, Virtual Tour, or view point cloud Density.</p>',
    pt: '<p>Potencie a visualização e a exploração dos modelos 3D. Conheça a Projeção, Tour Virtual ou visualize a Densidade da nuvem de pontos,</p>',
    fr: '<p>Visualisez et explorez les modèles 3D. Découvrez la projection, la visite virtuelle ou visualisez la densité du nuage de points.</p>',
  },

  meta_config_Data87: {
    es: '<p>Herramienta mapa de niveles - eyesCloud3D</p>',
    en: '<p>Level Map Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Mapa de Níveis - eyesCloud3D</p>',
    fr: '<p>Outil Carte de niveaux - eyesCloud3D</p>',
  },

  meta_config_Data88: {
    es: '<p>Utiliza diferentes niveles de altura o profundidad con la herramienta de mapa de niveles.</p>',
    en: '<p>Use different levels of Height or Depth with the Level Map Tool.</p>',
    pt: '<p>Utilize diferentes níveis de Altura ou Profundidade com a Ferramenta de Mapa de Níveis.</p>',
    fr: '<p>Utilisez différents niveaux de hauteur ou de profondeur à l’aide de l’outil Carte de niveaux.</p>',
  },

  meta_config_Data89: {
    es: '<p>Herramienta vertices/puntos - eyesCloud3D</p>',
    en: '<p>Vertices/Points Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Vértices/Pontos - eyesCloud3D</p>',
    fr: '<p>Outil Sommets/Points - eyesCloud3D</p>',
  },

  meta_config_Data90: {
    es: '<p>Descubre los vertices de los polígonos con esta herramienta. Descubre cómo se usa.</p>',
    en: '<p>See the vertices of the polygons with this tool. Learn how to use it.</p>',
    pt: '<p>Descubra os vértices dos polígonos com esta ferramenta. Saiba como se utiliza.</p>',
    fr: '<p>Découvrez les sommets des polygones avec cet outil. Apprenez à l’utiliser.</p>',
  },

  meta_config_Data91: {
    es: '<p>Herramienta Tour Virtual - eyesCloud3D</p>',
    en: '<p>Virtual Tour Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Tour Virtual - eyesCloud3D</p>',
    fr: '<p>Outil Visite virtuelle - eyesCloud3D</p>',
  },

  meta_config_Data92: {
    es: '<p>Apende a utilizar el Tour virual para ver tu modelo de forma continua. Selecciona las perspectivas y crea el tour virtual.</p>',
    en: '<p>Learn how to use the Virtual Tour for a continuous view of your model. Select the Perspectives and create the Virtual Tour.</p>',
    pt: '<p>Aprenda a utilizar o Tour Virtual para ver o seu modelo de forma contínua. Selecione as Perspetivas e crie o Tour Virtual.</p>',
    fr: '<p>Apprenez à utiliser la visite virtuelle pour visualiser votre modèle en continu. Sélectionnez les perspectives et créez la visite virtuelle.</p>',
  },

  meta_config_Data93: {
    es: '<p>Herramienta Primera Persona - eyesCloud3D</p>',
    en: '<p>First Person Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Primeira Pessoa - eyesCloud3D</p>',
    fr: '<p>Outil Première personne - eyesCloud3D</p>',
  },

  meta_config_Data94: {
    es: '<p>Descubre las ventajas de ver tus modelos en primera persona (POV) con nuestra herramienta</p>',
    en: '<p>Discover the benefits of viewing your model in First Person (POV) with our tool.</p>',
    pt: '<p>Descubra as vantagens de ver os seus modelos na Primeira Pessoa (POV) com a nossa ferramenta</p>',
    fr: '<p>Découvrez les avantages de voir vos modèles à la première personne (POV) grâce à notre outil.</p>',
  },

  meta_config_Data95: {
    es: '<p>Protocolo IOTA - eyesCloud3D</p>',
    en: '<p>IOTA Protocol - eyesCloud3D</p>',
    pt: '<p>Protocolo IOTA - eyesCloud3D</p>',
    fr: '<p>Protocole IOTA - eyesCloud3D</p>',
  },

  meta_config_Data96: {
    es: '<p>Conoce el protocolo IOTA y cómo usarlo. La seguridad de los datos es prioritaria en eyesCloud3D,</p>',
    en: '<p>Learn about the IOTA Protocol and what it is used for in eyesCloud3D.</p>',
    pt: '<p>Conheça o Protocolo IOTA e para que é utilizado na eyesCloud3D.</p>',
    fr: '<p>Découvrez le protocole IOTA et son utilisation dans eyesCloud3D.</p>',
  },

  meta_config_Data97: {
    es: '<p>Herramientas de movimiento - eyesCloud3D</p>',
    en: '<p>Movement Tools Collection - eyesCloud3D</p>',
    pt: '<p>Coleção de Ferramentas de Movimento - eyesCloud3D</p>',
    fr: '<p>Collection d’outils de mouvement - eyesCloud3D</p>',
  },

  meta_config_Data98: {
    es: '<p>Aprende a usar las herramientas de movimiento de eyesCloud3D. Establece la posición o activa el centro de rotación</p>',
    en: '<p>Learn how to use eyesCloud3D’s Movement Collection. Set the Initial Position or activate the Center of Rotation.</p>',
    pt: '<p>Aprenda a utilizar a Coleção de Movimento da eyesCloud3D. Estabeleça a Posição Inicial ou proceda à ativação do Centro de Rotação</p>',
    fr: '<p>Apprenez à utiliser la collection Mouvement d’eyesCloud3D. Définissez la position initiale ou activez le centre de rotation.</p>',
  },

  meta_config_Data99: {
    es: '<p>Herramienta de establecer posición - eyesCloud3D</p>',
    en: '<p>Establish Initial Position Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Estabelecer Posição Inicial - eyesCloud3D</p>',
    fr: '<p>Outil Définir la position - eyesCloud3D</p>',
  },

  meta_config_Data100: {
    es: '<p>Con esta herramienta puedes establecer la posición inicial en la que se vera tu modelo generado con eyesCloud3D</p>',
    en: '<p>With this tool, you can Set the Initial Position from which your model created with eyesCloud3D will be viewed.</p>',
    pt: '<p>Com esta ferramenta, pode Estabelecer a Posição Inicial na qual será visualizado o seu modelo criado com eyesCloud3D</p>',
    fr: '<p>Cet outil vous permet de définir la position initiale depuis laquelle votre modèle généré par eyesCloud3D sera visualisé.</p>',
  },

  meta_config_Data101: {
    es: '<p>Herramienta multicam - eyesCloud3D</p>',
    en: '<p>Multicam Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Multicâmara - eyesCloud3D</p>',
    fr: '<p>Outil Multicaméra - eyesCloud3D</p>',
  },

  meta_config_Data102: {
    es: '<p>Descubre cómo ver tu modelo desde diferentes cámaras y perspectivas de forma simultánea</p>',
    en: '<p>Discover how to view your model from different cameras and perspectives simultaneously.</p>',
    pt: '<p>Descubra como ver o seu modelo a partir de diferentes câmaras e perspetivas de forma simultânea</p>',
    fr: '<p>Découvrez comment visualiser votre modèle à partir de différentes caméras et perspectives simultanément.</p>',
  },

  meta_config_Data103: {
    es: '<p>Herramienta mapa - eyesCloud3D</p>',
    en: '<p>Map Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Mapa - eyesCloud3D</p>',
    fr: '<p>Outil Carte - eyesCloud3D</p>',
  },

  meta_config_Data104: {
    es: '<p>Visualiza tus modelos sobre un mapa, incluso, ajusta la ubicación del modelo con esta herramienta.</p>',
    en: '<p>Visualize your models on a Map and even adjust the model’s Location on a map with this tool.</p>',
    pt: '<p>Visualize os seus modelos sobre um Mapa e ajuste a Localização do modelo sobre um mapa com esta ferramenta.</p>',
    fr: '<p>Visualisez vos modèles sur une carte, et ajustez même l’emplacement du modèle sur une carte grâce à cet outil.</p>',
  },

  meta_config_Data105: {
    es: '<p>Herramientas de seguridad - eyesCloud3D</p>',
    en: '<p>Security Tool Library - eyesCloud3D</p>',
    pt: '<p>Biblioteca de Ferramentas de Segurança - eyesCloud3D</p>',
    fr: '<p>Bibliothèque d’outils de sécurité - eyesCloud3D</p>',
  },

  meta_config_Data106: {
    es: '<p>Conoce las herramientas de seguridad. Calcula la energía de deformación o la trayoría de una bala entre otras muchas herramientas.</p>',
    en: '<p>Discover the Security Tools. Calculate Deformation Energy or bullet trajectory, among many other tools.</p>',
    pt: '<p>Conheça as Ferramentas de Segurança. Calcule a Energia de Deformação ou a trajetória de uma bala, entre muitas outras ferramentas.</p>',
    fr: '<p>Découvrez les outils de sécurité. Calculez l’énergie de déformation ou la trajectoire d’une balle parmi de nombreux autres outils.</p>',
  },

  meta_config_Data107: {
    es: '<p>Herramienta proyección en 3D - eyesCloud3D</p>',
    en: '<p>3D Projection Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Projeção em 3D - eyesCloud3D</p>',
    fr: '<p>Outil Projection en 3D - eyesCloud3D</p>',
  },

  meta_config_Data108: {
    es: '<p>Corrige la distorsión de la perspectiva de una cámara de vigilancia con su modelo 3D</p>',
    en: '<p>Correct the distorted perspective of a security camera with your 3D model.</p>',
    pt: '<p>Corrija a distorção da perspetiva de uma câmara de vigilância com o seu modelo 3D</p>',
    fr: '<p>Corrigez la distorsion de perspective d’une caméra de surveillance grâce à votre modèle 3D.</p>',
  },

  meta_config_Data109: {
    es: '<p>Herramienta de trayectoria de balas - eyesCloud3D</p>',
    en: '<p>Bullet Trajectory Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Trajetória das Balas - eyesCloud3D</p>',
    fr: '<p>Outil Trajectoire des balles - eyesCloud3D</p>',
  },

  meta_config_Data110: {
    es: '<p>Calcula la trayectoria de una bala a través del orificio de impacto y otros parámetros como el arma o la bala.</p>',
    en: '<p>Calculate Bullet Trajectory using the impact hole and other parameters such as the weapon or the bullet.</p>',
    pt: '<p>Calcule a Trajetória de uma Bala através do orifício de impacto e outros parâmetros, como a arma ou a bala.</p>',
    fr: '<p>Calculez la trajectoire d’une balle à travers le trou d’impact et d’autres paramètres tels que l’arme ou la balle.</p>',
  },

  meta_config_Data111: {
    es: '<p>Herramienta de salpicadura de sangre - eyesCloud3D</p>',
    en: '<p>Blood Spatter Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Salpicos de Sangue - eyesCloud3D</p>',
    fr: '<p>Outil Éclaboussure de sang - eyesCloud3D</p>',
  },

  meta_config_Data112: {
    es: '<p>Aprende a utilizar la herramienta salpicadura de sangre en tu modelo 3D.</p>',
    en: '<p>Learn how to use the Blood Spatter Tool in your 3D model on the eyesCloud3D platform.</p>',
    pt: '<p>Aprenda a utilizar a Ferramenta Salpicos de Sangue no seu modelo 3D.</p>',
    fr: '<p>Apprenez à utiliser l’outil Éclaboussure de sang dans votre modèle 3D.</p>',
  },

  meta_config_Data113: {
    es: '<p>Herramienta de Matching 3D - eyesCloud3D</p>',
    en: '<p>3D Matching Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Matching 3D - eyesCloud3D</p>',
    fr: '<p>Outil Matching 3D - eyesCloud3D</p>',
  },

  meta_config_Data114: {
    es: '<p>Conoce el grado de coincidencia entre dos modelos 3D. Descubre cómo usar la herramienta.</p>',
    en: '<p>Determine the Degree of Matching between two 3D models. Learn how to use the tool.</p>',
    pt: '<p>Conheça o Grau de Coincidência entre dois modelos 3D. Descubra como utilizar a ferramenta.</p>',
    fr: '<p>Découvrez le niveau de chevauchement entre deux modèles 3D. Apprenez à utiliser l’outil.</p>',
  },

  meta_config_Data115: {
    es: '<p>Herramienta de grosor - eyesCloud3D</p>',
    en: '<p>Thickness Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Espessura - eyesCloud3D</p>',
    fr: '<p>Outil Épaisseur - eyesCloud3D</p>',
  },

  meta_config_Data116: {
    es: '<p>Descubre la distancia entre la superficie exterior e interior de un modelo 3D</p>',
    en: '<p>Find the Distance between the interior and exterior surface of a 3D model.</p>',
    pt: '<p>Descubra a Distância entre a superfície Exterior e Interior de um modelo 3D</p>',
    fr: '<p>Découvrez la distance entre la surface extérieure et la surface intérieure d’un modèle 3D.</p>',
  },

  meta_config_Data117: {
    es: '<p>Herramienta de sensórica - eyesCloud3D</p>',
    en: '<p>Sensory Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Sensorial - eyesCloud3D</p>',
    fr: '<p>Outil Sensorique - eyesCloud3D</p>',
  },

  meta_config_Data118: {
    es: '<p>Aprende a usar la herramienta "sensórica" para extraer datos meterológicos o contaminación históricos.</p>',
    en: '<p>Learn how to use the Sensory Tool to extract past weather or pollution data.</p>',
    pt: '<p>Aprenda a utilizar a Ferramenta Sensorial para extrair dados meteorológicos ou históricos de contaminação.</p>',
    fr: '<p>Apprenez à utiliser l’outil Sensorique pour extraire des données historiques sur la météo ou la pollution.</p>',
  },

  meta_config_Data119: {
    es: '<p>Herramienta de planos interiores - eyesCloud3D</p>',
    en: '<p>Interior Blueprints Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Planos Interiores - eyesCloud3D</p>',
    fr: '<p>Outil Plans intérieurs - eyesCloud3D</p>',
  },

  meta_config_Data120: {
    es: '<p>Integra planos 2D en modelos 3D con esta herramienta de integración.</p>',
    en: '<p>Integrate 2D blueprints into 3D models with this Integration Tool.</p>',
    pt: '<p>Integre planos 2D em modelos 3D com esta Ferramenta de Integração.</p>',
    fr: '<p>Intégrez des plans en 2D dans des modèles 3D avec cet outil d’intégration.</p>',
  },

  meta_config_Data121: {
    es: '<p>Herramienta de unión exteriores/interiores - eyesCloud3D</p>',
    en: '<p>Interior/Exterior Merge Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de União Exteriores/Interiores - eyesCloud3D</p>',
    fr: '<p>Outil Union Intérieur/Extérieur - eyesCloud3D</p>',
  },

  meta_config_Data122: {
    es: '<p>Crea un modelo 3D más completo integrando el interior en 3D de cualquier edificio o construcción.</p>',
    en: '<p>Create a more complete 3D model by integrating the 3D interior of any 3D-generated building or construction.</p>',
    pt: '<p>Crie um modelo 3D mais completo integrando o interior em 3D de qualquer edifício ou construção criado em 3D.</p>',
    fr: '<p>Créez un modèle 3D plus complet en intégrant l’intérieur en 3D de n’importe quel bâtiment ou construction généré en 3D.</p>',
  },

  meta_config_Data123: {
    es: '<p>Herramienta visor offline - eyesCloud3D</p>',
    en: '<p>Offline Viewer Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Visor Offline - eyesCloud3D</p>',
    fr: '<p>Outil Visionneuse hors ligne - eyesCloud3D</p>',
  },

  meta_config_Data124: {
    es: '<p>Aprende a usar el visor de eyesCloud3D sin conexión a internet.</p>',
    en: '<p>Use the eyesCloud3D Viewer without an internet connection. See what tools you can use offline.</p>',
    pt: '<p>Utilize o Visor da eyesCloud3D sem ser necessário ter uma ligação à internet. Descubra que ferramentas pode utilizar offline</p>',
    fr: '<p>Utilisez la visionneuse d’eyesCloud3D sans avoir besoin d’une connexion internet. Découvrez les outils que vous pouvez utiliser hors ligne.</p>',
  },

  meta_config_Data125: {
    es: '<p>Herramienta hiperespectral - eyesCloud3D</p>',
    en: '<p>Hyperspectral Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Hiperespetral - eyesCloud3D</p>',
    fr: '<p>Outil Hyperspectral - eyesCloud3D</p>',
  },

  meta_config_Data126: {
    es: '<p>Aprende a sacarle provecho a las imagenes extraidas de una cámara hiperespectral. </p>',
    en: '<p>Learn how to take advantage of images taken with a Hyperspectral Camera.</p>',
    pt: '<p>Aprenda a tirar proveito das imagens extraídas de uma Câmara Hiperespetral.</p>',
    fr: '<p>Apprenez à tirer le meilleur parti des images extraites d’une caméra hyperspectrale.</p>',
  },

  meta_config_Data127: {
    es: '<p>Herramienta segmentación - eyesCloud3D</p>',
    en: '<p>Segmentation Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Segmentação - eyesCloud3D</p>',
    fr: '<p>Outil Segmentation - eyesCloud3D</p>',
  },

  meta_config_Data128: {
    es: '<p>Indentifica y clasifica distintos objetos con esta herramienta, descrubre todas sus posibilidades.</p>',
    en: '<p>Identify and classify different objects with this tool. Discover all the many things it can do.</p>',
    pt: '<p>Identifique e classifique diversos objetos com esta ferramenta, descubra todas as suas possibilidades.</p>',
    fr: '<p>Identifiez et classez différents objets avec cet outil. Découvrez toutes ses possibilités.</p>',
  },

  meta_config_Data129: {
    es: '<p>Herramientas de ingenieria - eyesCloud3D</p>',
    en: '<p>Engineering Tool Library - eyesCloud3D</p>',
    pt: '<p>Biblioteca de Ferramentas de Engenharia - eyesCloud3D</p>',
    fr: '<p>Bibliothèque d’outils d’ingénierie - eyesCloud3D</p>',
  },

  meta_config_Data130: {
    es: '<p>Aprende a usar las Herramientas para el séctor de Ingenieria de las que dispones en eyesCloud3D.</p>',
    en: '<p>Learn how to use the Engineering Tools available in eyesCloud3D.</p>',
    pt: '<p>Aprenda a utilizar as Ferramentas para o setor de Engenharia das disponíveis na eyesCloud3D.</p>',
    fr: '<p>Apprenez à utiliser les outils d’ingénierie disponibles dans eyesCloud3D.</p>',
  },

  meta_config_Data131: {
    es: '<p>Herramienta de proyección en 3D - eyesCloud3D</p>',
    en: '<p>3D Projection Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Projeção em 3D - eyesCloud3D</p>',
    fr: '<p>Outil Projection en 3D - eyesCloud3D</p>',
  },

  meta_config_Data132: {
    es: '<p>Corrige la distorsión de la perspectiva de una cámara de vigilancia con su modelo 3D</p>',
    en: '<p>Correct the distorted perspective of a security camera with your 3D model.</p>',
    pt: '<p>Corrija a distorção da perspetiva de uma câmara de vigilância com o seu modelo 3D</p>',
    fr: '<p>Corrigez la distorsion de perspective d’une caméra de surveillance grâce à votre modèle 3D.</p>',
  },

  meta_config_Data133: {
    es: '<p>Herramienta de Cálculo de Vegetación - eyesCloud3D</p>',
    en: '<p>Vegetation Calculation Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Cálculo de Vegetação - eyesCloud3D</p>',
    fr: '<p>Outil Calcul de végétation - eyesCloud3D</p>',
  },

  meta_config_Data134: {
    es: '<p>Calcula la vegetación de un área concreta con nuestra herramienta de agricultura.</p>',
    en: '<p>Calculate the Vegetation of a specific area with our Agriculture Tool.</p>',
    pt: '<p>Calcule a Vegetação de uma área concreta com a nossa Ferramenta de Agricultura.</p>',
    fr: '<p>Calculez la végétation d’une aire spécifique avec notre outil Agriculture.</p>',
  },

  meta_config_Data135: {
    es: '<p>Herramienta Matching 3D - eyesCloud3D</p>',
    en: '<p>3D Matching Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Matching 3D - eyesCloud3D</p>',
    fr: '<p>Outil Matching 3D - eyesCloud3D</p>',
  },

  meta_config_Data136: {
    es: '<p>Conoce el grado de coincidencia entre dos modelos 3D. Descubre cómo usar la herramienta.</p>',
    en: '<p>Determine the Degree of Matching between two 3D models. Learn how to use the tool.</p>',
    pt: '<p>Conheça o Grau de Coincidência entre dois modelos 3D. Descubra como utilizar a ferramenta.</p>',
    fr: '<p>Conoce el grado de coincidencia entre dos modelos 3D. Descubre cómo usar la herramienta.</p>',
  },

  meta_config_Data137: {
    es: '<p>Herramienta cálculo de grosor - eyesCloud3D</p>',
    en: '<p>Thickness Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Espessura - eyesCloud3D</p>',
    fr: '<p>Outil Épaisseur - eyesCloud3D</p>',
  },

  meta_config_Data138: {
    es: '<p>Descubre la distancia entre la superficie exterior e interior de un modelo 3D</p>',
    en: '<p>Find the Distance between the interior and exterior surface of a 3D model.</p>',
    pt: '<p>Descubra a Distância entre a superfície Exterior e Interior de um modelo 3D</p>',
    fr: '<p>Découvrez la distance entre la surface extérieure et la surface intérieure d’un modèle 3D.</p>',
  },

  meta_config_Data139: {
    es: '<p>Herramienta sensórica - eyesCloud3D</p>',
    en: '<p>Sensory Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Sensorial - eyesCloud3D</p>',
    fr: '<p>Outil Sensorique - eyesCloud3D</p>',
  },

  meta_config_Data140: {
    es: '<p>Aprende a usar la herramienta "sensórica" para extraer datos meterológicos o contaminación históricos.</p>',
    en: '<p>Learn how to use the Sensory Tool to extract past weather or pollution data.</p>',
    pt: '<p>Aprenda a utilizar a Ferramenta Sensorial para extrair dados meteorológicos ou históricos de contaminação.</p>',
    fr: '<p>Apprenez à utiliser l’outil Sensorique pour extraire des données historiques sur la météo ou la pollution.</p>',
  },

  meta_config_Data141: {
    es: '<p>Herramienta de Planos interiores - eyesCloud3D</p>',
    en: '<p>Interior Blueprints Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Planos Interiores - eyesCloud3D</p>',
    fr: '<p>Outil Plans intérieurs - eyesCloud3D</p>',
  },

  meta_config_Data142: {
    es: '<p>Integra planos 2D en modelos 3D con esta herramienta de integración.</p>',
    en: '<p>Integrate 2D blueprints into 3D models with this Integration Tool.</p>',
    pt: '<p>Integre planos 2D em modelos 3D com esta Ferramenta de Integração.</p>',
    fr: '<p>Intégrez des plans en 2D dans des modèles 3D avec cet outil d’intégration.</p>',
  },

  meta_config_Data143: {
    es: '<p>Herramienta de unión exteriores/interiores - eyesCloud3D</p>',
    en: '<p>Interior/Exterior Merge Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de União Exteriores/Interiores - eyesCloud3D</p>',
    fr: '<p>Outil Union Intérieur/Extérieur - eyesCloud3D</p>',
  },

  meta_config_Data144: {
    es: '<p>Crea un modelo 3D más completo integrando el interior en 3D de cualquier edificio o construcción.</p>',
    en: '<p>Create a more complete 3D model by integrating the 3D interior of any 3D-generated building or construction.</p>',
    pt: '<p>Crie um modelo 3D mais completo integrando o interior em 3D de qualquer edifício ou construção criado em 3D.</p>',
    fr: '<p>Créez un modèle 3D plus complet en intégrant l’intérieur en 3D de n’importe quel bâtiment ou construction généré en 3D.</p>',
  },

  meta_config_Data145: {
    es: '<p>Herramienta visor offline - eyesCloud3D</p>',
    en: '<p>Offline Viewer Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Visor Offline - eyesCloud3D</p>',
    fr: '<p>Outil Visionneuse hors ligne - eyesCloud3D</p>',
  },

  meta_config_Data146: {
    es: '<p>Aprende a usar el visor de eyesCloud3D sin conexión a internet.</p>',
    en: '<p>Use the eyesCloud3D Viewer without an internet connection. See what tools you can use offline.</p>',
    pt: '<p>Utilize o Visor da eyesCloud3D sem ser necessário ter uma ligação à internet. Descubra que ferramentas pode utilizar offline</p>',
    fr: '<p>Utilisez la visionneuse d’eyesCloud3D sans avoir besoin d’une connexion internet. Découvrez les outils que vous pouvez utiliser hors ligne.</p>',
  },

  meta_config_Data147: {
    es: '<p>Herramienta hiperespectral - eyesCloud3D</p>',
    en: '<p>Hyperspectral Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Hiperespetral - eyesCloud3D</p>',
    fr: '<p>Outil Hyperspectral - eyesCloud3D</p>',
  },

  meta_config_Data148: {
    es: '<p>Aprende a sacarle provecho a las imagenes extraidas de una cámara hiperespectral. </p>',
    en: '<p>Learn how to take advantage of images taken with a Hyperspectral Camera.</p>',
    pt: '<p>Aprenda a tirar proveito das imagens extraídas de uma Câmara Hiperespetral.</p>',
    fr: '<p>Apprenez à tirer le meilleur parti des images extraites d’une caméra hyperspectrale.</p>',
  },

  meta_config_Data149: {
    es: '<p>Herramienta segmentación - eyesCloud3D</p>',
    en: '<p>Segmentation Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Segmentação - eyesCloud3D</p>',
    fr: '<p>Outil Segmentation - eyesCloud3D</p>',
  },

  meta_config_Data150: {
    es: '<p>Indentifica y clasifica distintos objetos con esta herramienta, descrubre todas sus posibilidades.</p>',
    en: '<p>Identify and classify different objects with this tool. Discover all the many things it can do.</p>',
    pt: '<p>Identifique e classifique diversos objetos com esta ferramenta, descubra todas as suas possibilidades.</p>',
    fr: '<p>Identifiez et classez différents objets avec cet outil. Découvrez toutes ses possibilités.</p>',
  },

  meta_config_Data151: {
    es: '<p>Herramientas de agricultura - eyesCloud3D</p>',
    en: '<p>Agriculture Tool Library - eyesCloud3D</p>',
    pt: '<p>Biblioteca de Ferramentas de Agricultura - eyesCloud3D</p>',
    fr: '<p>Bibliothèque d’outils d’agriculture - eyesCloud3D</p>',
  },

  meta_config_Data152: {
    es: '<p>Conoce las herramientas para 3D dedicadas a agricultura. [Leer más]</p>',
    en: '<p>Learn about eyescloud3D\'s 3D Tools for Agriculture. [Read more]</p>',
    pt: '<p>Conheça as Ferramentas para 3D dedicadas à Agricultura. [Ler mais]</p>',
    fr: '<p>Découvrez les outils 3D pour le secteur de l’agriculture. [Lire la suite]</p>',
  },

  meta_config_Data153: {
    es: '<p>Herramienta unión exteriores/interiores - eyesCloud3D</p>',
    en: '<p>Interior/Exterior Merge Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de União Exteriores/Interiores - eyesCloud3D</p>',
    fr: '<p>Outil Union Intérieur/Extérieur - eyesCloud3D</p>',
  },

  meta_config_Data154: {
    es: '<p>Crea un modelo 3D más completo integrando el interior en 3D de cualquier edificio o construcción.</p>',
    en: '<p>Create a more complete 3D model by integrating the 3D interior of any 3D-generated building or construction.</p>',
    pt: '<p>Crie um modelo 3D mais completo integrando o interior em 3D de qualquer edifício ou construção criado em 3D.</p>',
    fr: '<p>Créez un modèle 3D plus complet en intégrant l’intérieur en 3D de n’importe quel bâtiment ou construction généré en 3D.</p>',
  },

  meta_config_Data155: {
    es: '<p>Herramienta visor offline - eyesCloud3D</p>',
    en: '<p>Offline Viewer Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta Visor Offline - eyesCloud3D</p>',
    fr: '<p>Outil Visionneuse hors ligne - eyesCloud3D</p>',
  },

  meta_config_Data156: {
    es: '<p>Aprende a usar el visor de eyesCloud3D sin conexión a internet.</p>',
    en: '<p>Use the eyesCloud3D Viewer without an internet connection. See what tools you can use offline.</p>',
    pt: '<p>Utilize o Visor da eyesCloud3D sem ser necessário ter uma ligação à internet. Descubra que ferramentas pode utilizar offline</p>',
    fr: '<p>Utilisez la visionneuse d’eyesCloud3D sans avoir besoin d’une connexion internet. Découvrez les outils que vous pouvez utiliser hors ligne.</p>',
  },

  meta_config_Data157: {
    es: '<p>Herramienta de grosor - eyesCloud3D</p>',
    en: '<p>Thickness Tool - eyesCloud3D</p>',
    pt: '<p>Ferramenta de Espessura - eyesCloud3D</p>',
    fr: '<p>Outil Épaisseur - eyesCloud3D</p>',
  },

  meta_config_Data158: {
    es: '<p>Descubre la distancia entre la superficie exterior e interior de un modelo 3D</p>',
    en: '<p>Find the Distance between the interior and exterior surface of a 3D model.</p>',
    pt: '<p>Descubra a Distância entre a superfície Exterior e Interior de um modelo 3D</p>',
    fr: '<p>Découvrez la distance entre la surface extérieure et la surface intérieure d’un modèle 3D.</p>',
  },
};
