<div class="login-container" [ngClass]="{'error': loginError}">
    <!-- <div class="first-column">
        <h2 [innerHTML]="'login_Data1' | i18n"></h2>
        <div [innerHTML]="'login_Data2' | i18n"></div>
        <div class="video">
            <img src="/assets/images/login/login-video.svg" alt="eyesCloud3D vídeo">
        </div>
    </div> -->
    <img src="../../../../assets/images/login/icono-apk.svg" class="icono-apk">
    <div class="second-column">
        <form (submit)="onSubmit()">
            <div class="form-title" [innerHTML]="'login_Data3' | i18n"></div>
            <div class="form-group email">
                <input class="input" type="email" id="email" [(ngModel)]="user.email" name="email" required
                    placeholder="{{ 'login_Data4' | i18n: translationModes.PLACEHOLDER }}">
                <div *ngIf="loginError" class="login-error" [innerHTML]="'login_Data13' | i18n"></div>
            </div>
            <div class="form-group password">
                <input class="input" [type]="showPassword ? 'text' : 'password'" id="password"
                    [(ngModel)]="user.password" name="password" required
                    placeholder="{{ 'login_Data5' | i18n: translationModes.PLACEHOLDER }}">
                <img class="eye-icon" (click)="hidePassword()" *ngIf="showPassword" src="/assets/images/login/show.svg">
                <img class="eye-icon" (click)="hidePassword()" *ngIf="!showPassword"
                    src="/assets/images/login/hide.svg">
                <div *ngIf="loginError" class="login-error" [innerHTML]="'login_Data14' | i18n"></div>
            </div>
            <div class="form-group checkbox">
                <div>
                    <input type="checkbox" id="remember" [(ngModel)]="user.rememberMe" name="rememberMe" (click)="onRememberMe()">
                    <div [innerHTML]="'login_Data6' | i18n"></div>
                </div>
                <div class="forgot-password" [innerHTML]="'login_Data7' | i18n" (click)="openForgotPasswordDialog()">
                </div>
            </div>
            <button class="login-btn" type="submit" [innerHTML]="'login_Data20' | i18n"
                id="Form_interaction-click-login"></button>
        </form>
        <div class="register-with">
            <asl-google-signin-button id="real-google-button"></asl-google-signin-button>
            <div class="register-google" (click)="loginWithGoogle()" id="auto-login-google">
                <div [innerHTML]="'login_Data19' | i18n"></div>
                <img src="/assets/images/login/google.svg" alt="Google">
            </div>
            <!-- <div class="register-google" (click)="loginWithApple()" id="auto-login-apple">
                <div [innerHTML]="'login_Data10' | i18n"></div>
                <img src="/assets/images/login/apple.svg" alt="Apple">
            </div> -->
        </div>
        <div class="register" [innerHTML]="'login_Data11' | i18n" (click)="getRouterLink('Register')"
            id="Form_interaction-click-login-register"></div>
    </div>
</div>