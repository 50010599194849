import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegisterStepOneDialogComponent } from '../dialogs/account-verification/register-step-one-dialog/register-step-one-dialog.component';
import { RegisterStepTwoDialogComponent } from '../dialogs/account-verification/register-step-two-dialog/register-step-two-dialog.component';
import { RegisterStepThreeDialogComponent } from '../dialogs/account-verification/register-step-three-dialog/register-step-three-dialog.component';
import { RegisterStepFourDialogComponent } from '../dialogs/account-verification/register-step-four-dialog/register-step-four-dialog.component';
import { RegisterStepOneResponse } from '../models/account-verification/register-step-one-response';
import { RegisterStepTwoResponse } from '../models/account-verification/register-step-two-response';
import { RegisterStepThreeResponse } from '../models/account-verification/register-step-three-response';
import { RegisterStepFourResponse } from '../models/account-verification/register-step-four-response';
import { AuthenticationService } from './authentication.service';
import { RestClientService } from 'src/app/commons/services/rest-client.service';
import { EmailRegisteredDialogComponent } from '../components/register/dialogs/email-registered-dialog/email-registered-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  public email: string;

  constructor(
    private matDialog: MatDialog,
    private restClientService: RestClientService
  ) {
    this.email = '';
  }

  public openRegisterStepOneDialog() {
    this.matDialog
      .open(RegisterStepOneDialogComponent, {
        data: this.email,
        autoFocus: false,
        disableClose: true,
        hasBackdrop: true,
        maxWidth: '100vw',
        panelClass: 'registeredDialog',
      })
      .afterClosed()
      .subscribe((response: RegisterStepOneResponse) => {
        switch (response) {
          case RegisterStepOneResponse.RESEND:
            this.resendVerificationCode();
            break;
          case RegisterStepOneResponse.DEFAULT:
          default:
        }
      });
  }

  public openRegisterStepTwoDialog() {
    this.matDialog
      .open(RegisterStepTwoDialogComponent, {
        data: this.email,
        autoFocus: false,
        disableClose: true,
        hasBackdrop: true,
        maxWidth: '100vw',
        panelClass: 'pendingVerificationDialog',
      })
      .afterClosed()
      .subscribe((response: RegisterStepTwoResponse) => {
        switch (response) {
          case RegisterStepTwoResponse.RESEND:
            this.resendVerificationCode();
            break;
          case RegisterStepTwoResponse.DEFAULT:
          default:
            break;
        }
      });
  }

  public openRegisterStepThreeDialog() {
    this.matDialog
      .open(RegisterStepThreeDialogComponent, {
        data: this.email,
        autoFocus: false,
        disableClose: true,
        hasBackdrop: true,
        maxWidth: '100vw',
        panelClass: 'verificationEmailSentDialog',
      })
      .afterClosed()
      .subscribe((response: RegisterStepThreeResponse) => {
        switch (response) {
          case RegisterStepThreeResponse.CONTACT:
            break;
          case RegisterStepThreeResponse.DEFAULT:
          default:
            break;
        }
      });
  }

  public openRegisterStepFourDialog() {
    this.matDialog
      .open(RegisterStepFourDialogComponent, {
        autoFocus: false,
        disableClose: true,
        hasBackdrop: true,
        maxWidth: '100vw',
        panelClass: 'registerCompletedDialog',
      })
      .afterClosed()
      .subscribe((response: RegisterStepFourResponse) => {
        switch (response) {
          case RegisterStepFourResponse.DEFAULT:
          default:
        }
      });
  }

  private resendVerificationCode() {
    this.restClientService
      .post('resend-validation/email', { email: this.email })
      .subscribe(() => {
        this.openRegisterStepThreeDialog();
      });
  }

  public emailAlreadyRegisteredDialog() {
    this.matDialog
      .open(EmailRegisteredDialogComponent, {
        autoFocus: false,
        disableClose: true,
        hasBackdrop: true,
        maxWidth: '100vw',
        panelClass: 'emailAlreadyRegisteredDialog',
      })
      .afterClosed()
      .subscribe((response) => {
        switch (response) {
          default:
        }
      });
  }
}
